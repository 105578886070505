'use client';
import dynamic from 'next/dynamic';
import { TrafficLocation } from '@/components/organisms/TrafficLocation';
import { switchCase } from '@/packages/switch';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));

export interface TrafficCoverageProps {
    variant: 'TrafficCoverage' | 'NavTrafficCoverage' | 'TrafficPlusCoverage';
}

export default function TrafficCoverage({ variant }: TrafficCoverageProps): JSX.Element {
    const coverageTitle = switchCase<TrafficCoverageProps['variant'], string>({
        TrafficCoverage: 'Locations covered by SiriusXM Traffic',
        NavTrafficCoverage: 'Locations covered by NavTraffic',
        TrafficPlusCoverage: 'Locations covered by SiriusXM Traffic Plus',
    })('')(variant);

    return (
        <>
            <Grid container spacing={{ xs: 32 }}>
                <Grid col={{ sm: 12, md: 10, lg: 8 }} start={{ lg: 2 }}>
                    <Text tag="h2" variant="h2" style={{ marginBottom: 24 }}>
                        {coverageTitle}
                    </Text>
                    <Text tag="p" className="text-color-gray-dark">
                        Markets with color-coded speed/flow information are listed below.
                    </Text>
                </Grid>

                <Grid col={{ sm: 12, md: 10 }} start={{ lg: 2 }}>
                    <TrafficLocation />
                </Grid>
            </Grid>
        </>
    );
}
