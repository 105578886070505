import { Options } from '@splidejs/react-splide';
import { responsiveBreakpointsPx } from '@/constants';

export const ContentShowcaseSliderOptions: Options = {
    rewind: true,
    type: 'loop',
    perPage: 3,
    gap: '10px',
    pagination: false,
    arrows: true,
    drag: false,
    perMove: 1,
    breakpoints: {
        [responsiveBreakpointsPx.md]: {
            perPage: 1,
            gap: '16px',
            padding: '42px',
            pagination: true,
            arrows: false,
            drag: true,
        },
    },
};

export const SportsSliderOptions: Options = {
    rewind: false,
    type: 'slide',
    fixedWidth: '230px',
    omitEnd: true,
    gap: '8px',
    pagination: true,
    arrows: true,
    drag: false,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            perPage: 1,
            pagination: false,
            arrows: false,
            drag: 'free',
        },
    },
    lazyLoad: true,
};

export const MediaWithCarouselOptions: Options = {
    rewind: true,
    type: 'loop',
    perPage: 4,
    gap: '10px',
    pagination: false,
    arrows: true,
    drag: false,
    perMove: 1,
    breakpoints: {
        [responsiveBreakpointsPx.md]: {
            perPage: 1,
            gap: '16px',
            padding: '42px',
            pagination: true,
            arrows: false,
            drag: true,
        },
    },
    lazyLoad: true,
};

export const GameCardSliderFourPlusOptions: Options = {
    rewind: false,
    type: 'slide',
    gap: '16px',
    pagination: false,
    omitEnd: true,
    mediaQuery: 'min',
    arrows: false,
    fixedWidth: '276px',
    drag: 'free',
    perPage: 1,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            arrows: true,
            perPage: 4,
            gap: '16px',
            drag: false,
            fixedWidth: `calc((100% / 4) - 12px)`,
        },
        [responsiveBreakpointsPx.md]: {
            fixedWidth: '340px',
            arrows: false,
            drag: 'free',
            perPage: 1,
        },
    },
    lazyLoad: true,
};

export const GameCardSliderFourOptions: Options = {
    autoHeight: true,
    rewind: false,
    type: 'slide',
    gap: '16px',
    pagination: false,
    omitEnd: true,
    mediaQuery: 'min',
    arrows: false,
    fixedWidth: '276px',
    drag: 'free',
    perPage: 1,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            perPage: 4,
            gap: '16px',
            drag: false,
            fixedWidth: `calc((100% / 4) - 12px)`,
        },
        [responsiveBreakpointsPx.md]: {
            fixedWidth: '340px',
            drag: 'free',
            perPage: 1,
        },
    },
    lazyLoad: true,
};

export const GameCardSliderThreeOptions: Options = {
    rewind: false,
    type: 'slide',
    gap: '16px',
    pagination: false,
    omitEnd: true,
    arrows: false,
    mediaQuery: 'min',
    fixedWidth: '276px',
    drag: 'free',
    perPage: 1,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            perPage: 3,
            drag: false,
            fixedWidth: `calc((33.33%) - 11px)`,
            padding: `12.5%`,
        },
        [responsiveBreakpointsPx.md]: {
            fixedWidth: '340px',
            drag: 'free',
            perPage: 1,
        },
    },
    lazyLoad: true,
};

export const GameCardSliderTwoOptions: Options = {
    rewind: false,
    type: 'slide',
    gap: '16px',
    pagination: false,
    omitEnd: true,
    arrows: false,
    mediaQuery: 'min',
    fixedWidth: '276px',
    drag: 'free',
    perPage: 1,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            perPage: 2,
            gap: '16px',
            drag: false,
            fixedWidth: `calc((50%) - 8px)`,
            padding: `25%`,
        },
        [responsiveBreakpointsPx.md]: {
            fixedWidth: `calc((50%) - 8px)`,
            padding: 0,
            drag: 'free',
            perPage: 2,
        },
    },
    lazyLoad: true,
};

export const GameCardSliderOneOption: Options = {
    autoHeight: true,
    rewind: false,
    type: 'slide',
    pagination: false,
    omitEnd: true,
    arrows: false,
    mediaQuery: 'min',
    fixedWidth: '100%',
    drag: 'free',
    perPage: 1,
    breakpoints: {
        [responsiveBreakpointsPx.xl]: {
            fixedWidth: `100%`,
            padding: '37.5%',
        },
        [responsiveBreakpointsPx.lg]: {
            fixedWidth: `100%`,
            padding: '25%',
        },
        [responsiveBreakpointsPx.md]: {
            fixedWidth: '100%',
        },
    },
    lazyLoad: true,
};
