'use client';
import { useContext, useLayoutEffect } from 'react';
import { ChannelGuideContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';

export default function All() {
    const { channels, channelGuide, platform } = useContext(ChannelGuideContext);

    const genres = ['Music', 'News', 'Sports', 'Talk'];

    const allChannels = channels
        ? genres.flatMap((genre) => channels[genre]?.['All'] ?? [])
        : [];

    useLayoutEffect(() => {
        if (typeof window !== undefined) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <>
            {allChannels ? (
                <ChannelList
                    channels={allChannels}
                    upsellLink={channelGuide?.fields?.upsellLink}
                    display_listen_live={channelGuide?.fields?.display_listen_live}
                    defaultChannelImage={channelGuide?.fields?.channelDefaultImage}
                    platform={platform}
                />
            ) : null}
        </>
    );
}
