import React from 'react';
import styles from './appmodalheromobile.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface AppModalHeroMobileProps {
    iconOptimizedWidth: number,
    iconOptimizedHeight: number,
    iconAlt: string,
    iconSrc: string,
}

export const AppModalHeroMobile = ({
    iconOptimizedWidth,
    iconOptimizedHeight,
    iconAlt,
    iconSrc,    
}: AppModalHeroMobileProps) => {
    return (
        <div className={styles.modalAppIcon}>
            <ImageWrapper
                src={iconSrc}
                alt={iconAlt}
                className={styles.image}
                optimizedWidth={iconOptimizedWidth}
                optimizedHeight={iconOptimizedHeight}
            />
        </div>
    );
}