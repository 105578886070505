'use client';
import dynamic from 'next/dynamic';
import styles from './trialEligibilityStickyBoxOfferDetails.module.scss';
import StickyBox from '../StickyBox/StickyBox';
import cn from 'classnames';
import XIcon from '@/assets/icons/icon-x-mark-flex-stroke.svg';
import { useEffect, useRef } from 'react';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

export interface TrialEligibilityStickyBoxOfferDetailsProps {
    stickyBoxProps: {
        alwaysVisibleInMobile: true;
        position?: 'bottom';
    };
    visible: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    closeButtonAriaLabel: string;
}

export default function TrialEligibilityStickyBoxOfferDetails({
    stickyBoxProps,
    visible = false,
    children,
    onClose = () => null,
    closeButtonAriaLabel,
}: TrialEligibilityStickyBoxOfferDetailsProps) {
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (visible && closeButtonRef.current) {
            closeButtonRef.current.focus();
        }
    }, [visible]);

    const classes = cn(styles.stickyBox, {
        [styles.show]: visible,
    });

    return (
        <div className={styles.trialEligibilityStickyBox}>
            <StickyBox {...stickyBoxProps} className={classes}>
                <div className={styles.container}>
                    <Grid container>
                        <Grid className={styles.detailsCol} col={{ xs: 12 }}>
                            <div className={cn(styles.wrapper, 'legal-copy')}>{children}</div>
                            <button
                                ref={closeButtonRef}
                                className={styles.closeButton}
                                onClick={() => onClose()}
                                aria-label={closeButtonAriaLabel}
                            >
                                <XIcon aria-hidden />
                            </button>
                        </Grid>
                    </Grid>
                </div>
            </StickyBox>
        </div>
    );
}
