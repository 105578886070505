import { useContext, useState } from 'react';
import { CGContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import ChannelHeader from '../ChannelHeader';
import { Genrelist } from '../interfaces';
import { useSearchParams } from 'next/navigation';
import { categoryMap } from '../ChannelGuide';

export default function Sports(): JSX.Element {
    const searchParams = useSearchParams();
    const context = useContext(CGContext);
    const sortedChannels = context.channels;
    const upsellLink = searchParams.get('upsell') !== 'false' ? context.channelGuide.fields.upsellLink : undefined;
    const display_listen_live = context.channelGuide.fields.display_listen_live;
    const [genre, setGenre] = useState('All');

    const genreList: Genrelist = [
        [
            { value: 'All', label: 'All Sports' },
            { value: 'Sports', label: 'Sports Talk' },
            { value: 'NFL Play-by-Play', label: 'NFL Play-by-Play' },
            { value: 'MLB Play-by-Play', label: 'MLB Play-by-Play' },
            { value: 'NBA Play-by-Play', label: 'NBA Play-by-Play' },
            { value: 'NHL Play-by-Play', label: 'NHL Play-by-Play' },
            { value: 'EPL Play-by-Play', label: 'EPL Play-by-Play' },
            { value: 'Sports Play-by-Play', label: 'Sports Play-by-Play' },
        ],
    ];

    return (
        <>
            <ChannelHeader genreList={genreList} onGenreChange={setGenre} />
            {sortedChannels && sortedChannels[categoryMap.Sports] && sortedChannels[categoryMap.Sports][genre] && (
                <ChannelList
                    channels={sortedChannels[categoryMap.Sports][genre]}
                    upsellLink={upsellLink}
                    display_listen_live={display_listen_live}
                />
            )}
        </>
    );
}
