'use client';
import dynamic from 'next/dynamic';
import { Link } from '@/components/atoms/Link';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import IconExternalLink from '@/assets/icons/icon-external-link.svg';
import styles from './searchresult.module.scss';
import cn from 'classnames';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

// If there is a need in a future for such a LinkWithIcon somewhere else, this could be a standalone component.
function LinkWithIcon({ href, aria_label, link_target, link_text }: Link) {
    return (
        <Link className={styles.link} href={href ?? ''} aria-label={aria_label} target={link_target}>
            <Text className={styles.linkText} tag="p">
                {link_text}
            </Text>
            <IconExternalLink className={styles.arrowIcon} />
        </Link>
    );
}

interface SearchResultProps {
    label: string;
    title: string;
    titleLink: Link;
    mainText?: string;
    bottomLinkText?: string;
    bottomLink?: Link;
    thumbnailSrc?: string;
    variant: 'list' | 'card';
    labelPosition?: 'after_title';
    className?: string;
    titleClassName?: string;
    onClick?: React.MouseEventHandler;
}

export default function SearchResult({
    label,
    title,
    titleLink,
    mainText,
    bottomLink,
    bottomLinkText = 'Launch in player',
    thumbnailSrc,
    variant = 'list',
    labelPosition,
    className,
    titleClassName,
    onClick,
}: SearchResultProps): JSX.Element {
    const ariaLabel = `Launch ${title} in player: opens in new tab`;
    const isCardVariant = variant === 'card';

    const linkClasses = cn(styles.bottomlink, {
        [styles.linkWithIconWrapper]: isCardVariant,
    });

    const cardVariantStyling = cn(className, {
        [styles.cardMainContainer]: isCardVariant,
        [styles.mainContainer]: !isCardVariant,
    });

    return (
        <div className={cardVariantStyling}>
            <div className={styles.textContainer}>
                {'after_title' === labelPosition && (
                    <>
                        <Link
                            className={styles.titleLink}
                            href={titleLink?.href ?? ''}
                            aria-label={ariaLabel}
                            onClick={onClick}
                        >
                            <Text tag="h2" variant="h5" className={titleClassName ?? styles.title}>
                                {title}
                            </Text>
                        </Link>
                        <Text className={styles.label} tag="p">
                            {label}
                        </Text>
                    </>
                )}
                {'after_title' !== labelPosition && (
                    <>
                        <Text className={styles.label} tag="p">
                            {label}
                        </Text>
                        <Link
                            className={styles.titleLink}
                            href={titleLink?.href ?? ''}
                            aria-label={ariaLabel}
                            onClick={onClick}
                        >
                            <Text tag="h2" variant="h5" className={titleClassName ?? styles.title}>
                                {title}
                            </Text>
                        </Link>
                    </>
                )}

                {mainText && (
                    <Text className={isCardVariant ? styles.cardMainText : styles.mainText} tag="p">
                        {mainText}
                    </Text>
                )}
                {bottomLinkText && bottomLink?.href && (
                    <div className={linkClasses}>
                        <LinkWithIcon href={bottomLink?.href ?? ''} link_text={bottomLinkText} aria_label={ariaLabel} />
                    </div>
                )}
            </div>
            {!isCardVariant && thumbnailSrc && (
                <div className={styles.imageContainer}>
                    <ImageWrapper src={thumbnailSrc} alt={title} />
                </div>
            )}
        </div>
    );
}
