'use client';
import dynamic from 'next/dynamic';
import { useState, useEffect, ReactNode, useRef } from 'react';
import { PlanCard, PlanCardProps } from '@/components/atoms/PlanCard';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const InvalidFeedback = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/InvalidFeedback/InvalidFeedback'),
);
import useInheritParams from '@/hooks/useInheritParams';
import globalProperties from '../../../../properties/global';
import { VALIDITY } from '@/constants';
import styles from './planselector.module.scss';

const {
    mainWrapper,
    selectorWrapper,
    offerSection,
    planCardWrapper,
    inputStyles,
    offerTitleStyles,
    offerPriceStyles,
    errorMessageStyles,
    headlineStyles,
    offerDetailStyles,
    selectButtonStyles,
    inputWrapper,
    textContentWrapper,
} = styles;

type DefaultCtaData = {
    href: string;
    linkText: string;
    ariaLabel: string;
};

type PlanData = {
    cta: DefaultCtaData;
    subHeadline: ReactNode;
    creditCardText: ReactNode;
};

interface Plans extends PlanCardProps, PlanData {}

interface PlanSelectorProps {
    mainHeadline: ReactNode;
    offerTitle?: ReactNode;
    plans: Plans[];
    defaultCtaData: DefaultCtaData;
}

export default function PlanSelector({
    mainHeadline,
    plans,
    offerTitle,
    defaultCtaData,
}: PlanSelectorProps): JSX.Element {
    const defaultPlanData = {
        cta: {
            href: defaultCtaData.href,
            linkText: defaultCtaData.linkText,
            ariaLabel: defaultCtaData.ariaLabel,
        },
        subHeadline: '',
        creditCardText: '',
    };

    const [selectedPlan, setSelectedPlan] = useState<PlanData>(defaultPlanData);
    const [errorMessage, setErrorMessage] = useState(false);
    const [selectedInput, setSelectedInput] = useState(false);
    const inputRef = useRef<(HTMLInputElement | null)[]>([]);

    const href = useInheritParams(selectedPlan.cta.href || '#', globalProperties.urlInheritParams);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const target = (e.target as HTMLBaseElement).tagName.toLowerCase();
            if (target !== 'input' && target !== 'a') {
                setSelectedPlan(defaultPlanData);
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeHandler = (index: number) => {
        if (selectedPlan.cta.linkText === plans[index].cta.linkText && !selectedInput) {
            setSelectedInput(true);
            setSelectedPlan(defaultPlanData);
            inputRef.current?.[index]?.blur();
        } else if (selectedPlan.cta.linkText === plans[index].cta.linkText && selectedInput) {
            setSelectedInput(false);
            setErrorMessage(false);
            setSelectedPlan({
                cta: plans[index].cta,
                subHeadline: plans[index].subHeadline,
                creditCardText: plans[index].creditCardText,
            });
        } else {
            setSelectedInput(false);
            setErrorMessage(false);
            setSelectedPlan({
                cta: plans[index].cta,
                subHeadline: plans[index].subHeadline,
                creditCardText: plans[index].creditCardText,
            });
        }
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (selectedPlan.cta.href === '') {
            e.preventDefault();
            setErrorMessage(true);
            return;
        }

        setErrorMessage(false);
    };

    const content = plans?.map(({ headline, descriptionText, iconSource }, index: number) => {
        return (
            <div key={index} className={inputWrapper}>
                <input
                    ref={(ref) => {
                        inputRef.current[index] = ref;
                    }}
                    type="radio"
                    name="select"
                    className={inputStyles}
                    onClick={() => onChangeHandler(index)}
                    aria-label={`Listen on ${headline}`}
                    data-tracklinktext={headline}
                    checked={selectedPlan.cta.linkText === plans[index].cta.linkText}
                />
                <label>
                    <PlanCard headline={headline} descriptionText={descriptionText} iconSource={iconSource} />
                </label>
            </div>
        );
    });

    const selectedPlanText =
        selectedPlan.subHeadline !== '' || selectedPlan.creditCardText !== '' ? (
            <>
                <span>{selectedPlan.subHeadline}</span>
                <span>{selectedPlan.creditCardText}</span>
            </>
        ) : (
            <>
                Then current plan price. See <span className={offerDetailStyles}>Offer Details.</span>
            </>
        );

    return (
        <div className={mainWrapper}>
            <div className={selectorWrapper}>
                <span className={headlineStyles}>{mainHeadline}</span>
                <div className={errorMessageStyles}>
                    <InvalidFeedback validState={errorMessage ? VALIDITY.invalid : VALIDITY.valid}>
                        Please make a selection
                    </InvalidFeedback>
                </div>
                <div className={planCardWrapper}>{content}</div>
                <div className={offerSection}>
                    <div role="status" className={textContentWrapper}>
                        {offerTitle && <span className={offerTitleStyles}>{offerTitle}</span>}
                        <span className={offerPriceStyles}>{selectedPlanText}</span>
                    </div>
                    <Button
                        className={selectButtonStyles}
                        href={href}
                        theme="primary"
                        text={selectedPlan.cta.linkText}
                        type="button"
                        onClick={handleButtonClick}
                        ariaLabel={selectedPlan.cta.ariaLabel}
                    />
                </div>
            </div>
        </div>
    );
}
