'use client';
import dynamic from 'next/dynamic';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Checkbox = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Checkbox/Checkbox'));
const InvalidFeedback = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/InvalidFeedback/InvalidFeedback'),
);
import DisplayModal from 'de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal';
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
import { type SelectProps } from 'de-sxm-reactlib/dist/components/atoms/Inputs/Select';
import styles from './modal.module.scss';
import Link from '@/components/atoms/Link/Link';
import { useEffect, useRef, useState } from 'react';
import { YMM } from '..';
import AnimateHeight from 'react-animate-height';
import { YMMData } from '../Form/interfaces';
import { setYMMCookies } from '@/packages/sxm/ymm';
import { Platform } from '@/packages/sxm/ymm/types';
import { getYMMCookies } from '@/packages/sxm/ymm';
import { ObjectType } from 'typescript';
import { useClickOutside } from '@/hooks';
import FocusTrap from 'focus-trap-react';

interface Props {
    open: boolean;
    onClose: () => void;
    selectTheme?: SelectProps<ObjectType>['theme'];
}

interface PortableRadio {
    radio: string;
    platform: Platform;
}

export function Modal(props: Props) {
    const [carChecked, setCarChecked] = useState(false);
    const [onlineChecked, setOnlineChecked] = useState(false);
    const [radioChecked, setRadioChecked] = useState(false);
    const [selectionMade, setSelectionMade] = useState<FieldValidityState>('untouched');
    const [ymmValid, setYMMValid] = useState<FieldValidityState>('untouched');
    const [radioValid, setRadioValid] = useState<FieldValidityState>('untouched');
    const [portableRadio, setPortable] = useState<PortableRadio>({ radio: '', platform: 'siriusxm' });
    const [ymm, setYMMData] = useState<YMMData>({
        year: '',
        make: '',
        model: '',
        id: '',
    });
    const [focusPause, setFocusPause] = useState(false);

    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-consent-sdk', // One Trust cookie consent banner
        '.LPMcontainer', // Live Person chat invocation buttons
        '#lpChat', // Interactive Live Person chat window
    ].join(', ');

    useClickOutside(ref, {}, (_, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);
                if (closest) {
                    targetRef.current = target;
                }
                setFocusPause(!!closest);
            }
        }
    });

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    useEffect(() => {
        const ymmCookies = getYMMCookies();
        const carData = ymmCookies.sxm_radio.split('|');
        const ymmBar = ymmCookies.ymm_bar;

        if (carData.length > 1) {
            setCarChecked(true);
            setYMMData({
                year: carData[1],
                make: carData[2],
                model: carData[3],
                id: carData[4],
            });
        }

        setOnlineChecked(ymmBar.indexOf('streaming') >= 0);
        setRadioChecked(ymmBar.indexOf('radio') >= 0);

        ymmBar.split('|').forEach((cookie) => {
            if (cookie.indexOf('radio') >= 0) {
                setPortable({
                    radio: cookie.split('=')[1],
                    platform: (ymmCookies.sxm_platform as Platform) || 'siriusxm',
                });
            }
        });
    }, []);

    function carReady(ymm: YMMData) {
        setYMMData(ymm);
    }

    function saveCookies() {
        let platform: Platform = 'siriusxm';
        let isValid = false;
        const ymmBar = [];
        const userSelectedOption = carChecked || radioChecked || onlineChecked;

        isValid = userSelectedOption;

        if (radioChecked && !portableRadio.radio) {
            isValid = false;
            setRadioValid('invalid');
        } else {
            setRadioValid('valid');
        }

        if (carChecked && !ymm.id) {
            isValid = false;
            setYMMValid('invalid');
        } else {
            setYMMValid('valid');
        }

        setSelectionMade(userSelectedOption && isValid ? 'valid' : 'invalid');

        if (!isValid) {
            return;
        }

        if (radioChecked && portableRadio) {
            ymmBar.push(`radio=${portableRadio.radio}`);
            platform = portableRadio.platform;
        }

        if (onlineChecked) {
            ymmBar.push(`stream=streaming online`);
        }

        if (carChecked && ymm && ymm.capabilities) {
            if (ymm.capabilities.siriusRadio) {
                platform = 'sirius';
            } else if (ymm.capabilities.xmRadio) {
                platform = 'xm';
            }
        }

        setYMMCookies({
            sxm_radio: carChecked ? `YMM|${ymm.year}|${ymm.make}|${ymm.model}|${ymm.id}` : '',
            sxm_platform: platform,
            inft_ymm: carChecked ? ymm.capabilities?.infotainment.join('|') || '' : '',
            ymm_bar: onlineChecked || radioChecked ? ymmBar.join('|') : '',
            roadblockbypass: '',
        });

        props.onClose();

        if (window) {
            window.location.reload();
        }
    }

    function radioHandler(_: HTMLInputElement, { data }: { data?: PortableRadio }) {
        if (data) {
            setPortable(data);
        }
    }

    return (
        <>
            <FocusTrap active={props.open} paused={focusPause}>
                <DisplayModal
                    ref={ref}
                    className={styles.wrapper}
                    title="Listening Preferences"
                    onClose={props.onClose}
                    open={props.open}
                >
                    <Text tag="h4" variant="h4" className={styles.lpHeading}>
                        How do you want to listen?
                    </Text>

                    <Link href="https://care.siriusxm.com/login_view.action">Sign in</Link>

                    <Text tag="p" className={styles.p1}>
                        Picking a plan can be tricky and we&apos;re here to help.
                    </Text>

                    <Text tag="p">
                        Tell us how you want to listen to SiriusXM, and any hardware details you know. We&apos;ll show
                        you to the best plans and pricing for you.
                    </Text>

                    <InvalidFeedback validState={selectionMade} dataCy="molecules-ymm-modal-invalid-form">
                        At least one option below required
                    </InvalidFeedback>

                    <Checkbox
                        name="car"
                        value="car"
                        checked={carChecked}
                        onChange={(event) => setCarChecked(event.target.checked)}
                        dataCy="molecules-ymm-modal-car"
                    >
                        <b>I want to listen in my car</b>
                    </Checkbox>

                    <AnimateHeight duration={300} height={carChecked ? 'auto' : 0}>
                        <InvalidFeedback validState={ymmValid} dataCy="molecules-ymm-modal-invalid-ymm">
                            Year, make and model required
                        </InvalidFeedback>
                        <YMM.Form onCarReady={carReady} theme="standard" reset={props.open} />
                    </AnimateHeight>

                    <Checkbox
                        name="online"
                        value="online"
                        checked={onlineChecked}
                        onChange={(event) => setOnlineChecked(event.target.checked)}
                        dataCy="molecules-ymm-modal-online"
                    >
                        <b>I want to listen online</b>
                    </Checkbox>

                    <Text tag="p" className={styles.onlineDesc}>
                        You can listen on your connected device, on the app, or with our web player
                    </Text>

                    <Checkbox
                        name="radio"
                        value="radio"
                        checked={radioChecked}
                        onChange={(event) => setRadioChecked(event.target.checked)}
                        dataCy="molecules-ymm-modal-radio"
                    >
                        <b>I have a portable SXM Radio</b>
                    </Checkbox>

                    <AnimateHeight duration={300} height={radioChecked ? 'auto' : 0}>
                        <InvalidFeedback validState={radioValid} dataCy="molecules-ymm-modal-invalid-radio">
                            Radio required
                        </InvalidFeedback>
                        <Select<PortableRadio>
                            label="Select"
                            type="form"
                            theme="outline"
                            name="sxmradio"
                            value={portableRadio.radio}
                            placeholder={portableRadio.radio}
                            onChange={radioHandler}
                            dataCy="molecules-ymm-modal-portable-radio-select"
                        >
                            <OptionGroup>
                                <Option
                                    value="Onyx Series Radio"
                                    object={{ radio: 'Onyx Series Radio', platform: 'xm' }}
                                >
                                    Onyx Series Radio
                                </Option>
                                <Option
                                    value="Stratus Series Radio"
                                    object={{ radio: 'Stratus Series Radio', platform: 'sirius' }}
                                >
                                    Stratus Series Radio
                                </Option>
                                <Option value="Lynx Radio" object={{ radio: 'Lynx Radio', platform: 'siriusxm' }}>
                                    Lynx Radio
                                </Option>
                                <Option
                                    value="Other Sirius Radio"
                                    object={{ radio: 'Other Sirius Radio', platform: 'sirius' }}
                                >
                                    Other Sirius Radio
                                </Option>
                                <Option
                                    value="Other SiriusXM Radio"
                                    object={{ radio: 'Other SiriusXM Radio', platform: 'siriusxm' }}
                                >
                                    Other SiriusXM Radio
                                </Option>
                                <Option value="Other XM Radio" object={{ radio: 'Other XM Radio', platform: 'xm' }}>
                                    Other XM Radio
                                </Option>
                            </OptionGroup>
                        </Select>
                    </AnimateHeight>

                    <Button
                        theme="primary"
                        type="button"
                        text="SUBMIT"
                        buttonSize="full-width"
                        onClick={saveCookies}
                        dataCy="molecules-ymm-modal-submit-button"
                    />
                </DisplayModal>
            </FocusTrap>
        </>
    );
}
