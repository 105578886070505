import { useContext, useState } from 'react';
import { CGContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import { Genrelist } from '../interfaces';
import ChannelHeader from '../ChannelHeader';
import { useSearchParams } from 'next/navigation';
import { categoryMap } from '../ChannelGuide';

export default function Music(): JSX.Element {
    const searchParams = useSearchParams();
    const context = useContext(CGContext);
    const sortedChannels = context.channels;
    const upsellLink = searchParams.get('upsell') !== 'false' ? context.channelGuide.fields.upsellLink : undefined;
    const display_listen_live = context.channelGuide.fields.display_listen_live;
    const [genre, setGenre] = useState('All');

    const genreList: Genrelist = [
        [
            { value: 'All', label: 'All Music' },
            { value: 'Pop', label: 'Pop' },
            { value: 'Rock', label: 'Rock' },
            { value: 'Hip-Hop/R&B', label: 'Hip-Hop/R&B' },
            { value: 'Dance/Electronic', label: 'Dance/Electronic' },
            { value: 'Country', label: 'Country' },
            { value: 'Jazz/Standards/Classical', label: 'Jazz/Standards/Classical' },
            { value: 'Christian', label: 'Christian' },
            { value: 'Latino', label: 'Latino' },
            { value: 'Family', label: 'Family' },
        ],
        [
            { value: 'Top 100', label: 'Top 100' },
            { value: 'New Music Discovery', label: 'New Music Discovery' },
            { value: 'Music Mash', label: 'Music Mash' },
            { value: "Today's Hits", label: "Today's Hits" },
            { value: 'Party', label: 'Party' },
            { value: 'Workout', label: 'Workout' },
            { value: 'The 80s Decade', label: 'The 80s Decade' },
            { value: 'Cool Down', label: 'Cool Down' },
            { value: 'The 70s Decade', label: 'The 70s Decade' },
            { value: 'BBQ', label: 'BBQ' },
            { value: 'The 90s Decade', label: 'The 90s Decade' },
            { value: 'Instrumentals', label: 'Instrumentals' },
            { value: 'Just Music', label: 'Just Music' },
            { value: 'Deep Cuts', label: 'Deep Cuts' },
            { value: 'AllX', label: 'All Xtra Channels' },
        ],
    ];

    if (context.channelGuide.fields.displayHoliday) {
        genreList[0].push({ value: 'Holiday', label: 'Holiday' });
    }
    
    return (
        <>
            <ChannelHeader genreList={genreList} onGenreChange={setGenre} />
            {sortedChannels && sortedChannels[categoryMap.Music] && sortedChannels[categoryMap.Music][genre] && (
                <ChannelList
                    channels={sortedChannels[categoryMap.Music][genre]}
                    upsellLink={upsellLink}
                    display_listen_live={display_listen_live}
                />
            )}
        </>
    );
}
