import { Document } from '@contentful/rich-text-types';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { NodeRenderer, Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';
import InlineEmbed from './InlineEmbed';
import stringSplit from '@/packages/string/stringSplit';
import typographyStyles from 'de-sxm-reactlib-2/src/styles/global/typography.module.scss';

interface RenderRTEProps {
    contentfulDoc?: Document;
    pToSpan?: boolean;
    removePTag?: boolean;
    tokens?: {
        [index: string]: ReactNode;
    };
    className?: string;
    customTextSelector?: string;
    customTextStyling?: string;
    customRenderNodes?: Options['renderNode'];
    splitIntoChars?: boolean;
    asW2?: boolean;
}

export default function RenderRTE({
    contentfulDoc,
    pToSpan,
    removePTag,
    tokens,
    className = '',
    customTextSelector,
    customTextStyling,
    customRenderNodes,
    splitIntoChars = false,
    asW2 = false,
}: RenderRTEProps): ReactNode {
    function renderText(children: string): ReactNode {
        if (children) {
            const str = children.toString();
            let newString = [str] as ReactNode[];
            const anchoridRegEx = new RegExp('{anchorid=[a-zA-Z0-9-_]+}', 'g');
            const anchorIds = str.match(anchoridRegEx);

            if (splitIntoChars) {
                newString = stringSplit({ str: str });
            } else {
                const lines = str.split('\n');
                newString = lines.flatMap((line, index) => [index > 0 && <br key={index} />, line]) as ReactNode[];
            }

            if (anchorIds && anchorIds.length > 0) {
                anchorIds.forEach((anchorId) => {
                    const id = anchorId.replace(/{anchorid=|}/g, '');
                    newString = reactStringReplace(newString, anchorId, () => <span id={id}></span>);
                });
            }

            if (tokens) {
                for (const token in tokens) {
                    newString = reactStringReplace(newString, token, () => tokens[token]);
                }
            }

            if (customTextSelector) {
                newString = reactStringReplace(newString, customTextSelector, () => (
                    <span className={customTextStyling}>{customTextSelector}</span>
                ));
            }

            return newString;
        }

        return children;
    }

    const renderParagraph: NodeRenderer = (node, children) => {
        if (children?.toString().replace(/\s/g, '')) {
            if (pToSpan) {
                return (
                    <span className={asW2 ? `${typographyStyles.paragraph} ${className}` : `paragraph ${className}`}>
                        {children}
                    </span>
                );
            } else if (removePTag) {
                return children;
            } else {
                return <p className={asW2 ? `${typographyStyles.paragraph} ${className}` : className}>{children}</p>;
            }
        }

        return children;
    };

    if (contentfulDoc) {
        const options: Options = {
            renderText: renderText,
            renderNode: {
                [BLOCKS.HEADING_1]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h1} ${className}` : `h1 ${className}`}>{children}</span>
                ),
                [BLOCKS.HEADING_2]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h2} ${className}` : `h2 ${className}`}>{children}</span>
                ),
                [BLOCKS.HEADING_3]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h3} ${className}` : `h3 ${className}`}>{children}</span>
                ),
                [BLOCKS.HEADING_4]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h4} ${className}` : `h4 ${className}`}>{children}</span>
                ),
                [BLOCKS.HEADING_5]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h5} ${className}` : `h5 ${className}`}>{children}</span>
                ),
                [BLOCKS.HEADING_6]: (node, children) => (
                    <span className={asW2 ? `${typographyStyles.h6} ${className}` : `h6 ${className}`}>{children}</span>
                ),
                [BLOCKS.PARAGRAPH]: renderParagraph,
                [BLOCKS.TABLE]: (node, children) => <table className="rtetable"><tbody>{children}</tbody></table>,
                [INLINES.EMBEDDED_ENTRY]: (node) => <InlineEmbed node={node} />,
                ...customRenderNodes,
            },
        };

        return documentToReactComponents(contentfulDoc, options);
    }

    return undefined;
}
