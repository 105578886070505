'use client';
import { useSearchParams } from 'next/navigation';
import { useCallback, useState, useEffect, ReactNode } from 'react';
import { ucfirst } from '@/packages/string';
import { ChannelGuideProps, SortedChannels } from './interfaces';
import { ChannelGuideContext } from './ChannelGuideContext';
import ChannelHeader from './ChannelHeader';
import Grid from 'de-sxm-reactlib-2/dist/components/atoms/Layout/Grid/Grid';
import Link from 'de-sxm-reactlib-2/dist/components/atoms/Link/Link';
import ChannelStripSkeleton from 'de-sxm-reactlib-2/dist/components/molecules/ChannelStripSkeleton/ChannelStripSkeleton';
import All from './GenreTabs/All';
import Music from './GenreTabs/Music';
import TalkEntertainment from './GenreTabs/TalkEntertainment';
import Sports from './GenreTabs/Sports';
import NewsIssues from './GenreTabs/NewsIssues';
import IconChannelList from '@/assets/icons/icon-channel-list.svg';
import { DownloadIcon } from '@/assets/icons/DownloadIcon';
import { LockIcon } from '@/assets/icons/LockIcon';
import { sortChannels, categoryMap, XtraChannels } from './utils';
import { getGenreList } from './genres';
import cn from 'classnames';
import styles from './channelGuide.module.scss';
import IconDropDown from '@/assets/icons/icon-dropdown.svg';
import { useYmm20 } from '@/hooks/useYmm2.0';

// YMM web1
import Option from 'de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option';
import OptionGroup from 'de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup';
import Select from 'de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select';
import ShowMore from 'de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore';

const {
    downloadLinkMobile,
    downloadLinkDesktop,
    headerAreaWrapper,
    tabsSelectorWrapper,
    selectorsWrapper,
    selectorStyles,
    linkColor,
    downloadTextWrapper,
    ymmTriggerWrapper,
    selectOption,
    showMoreTextStyles,
    showMore,
    showMoreButton,
    show,
    hidden,
    showMoreAreaStyles,
    selectPlanHeaderSubTitleStyles,
    channelGuideContentWrapper,
    channelGuideSkeletonWrapper,
    iconStyle,
    openYmmStyles,
    legacyTopNavStyles,
} = styles;

function createSpaceHolder(): ReactNode[] {
    return Array.from(Array(8).keys()).map((num) => {
        return (
            <div className={channelGuideSkeletonWrapper} key={num}>
                <ChannelStripSkeleton />
            </div>
        );
    });
}

export default function ChannelGuide({ asset, pageContext }: ChannelGuideProps) {
    const searchParams = useSearchParams();
    const allPackages = asset.fields.allPackages;
    const lineupLink = asset.fields.channelLineup;
    const initialChannelGuideData = asset.fields.initialChannelGuideLoad;
    const [packageId, setPackageId] = useState('');
    const [channels, setChannels] = useState<SortedChannels | null>(initialChannelGuideData);
    const [openYmm, setOpenYmm] = useState(false);
    const [bodyComponent, setBodyComponent] = useState('All');
    const [genre, setGenre] = useState('All');
    const [showContent, setShowContent] = useState<boolean>(false);
    const [randomNumber, setRandom] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [label, setLabel] = useState('');

    const setOpenYmmFunc = useCallback((set: boolean) => setOpenYmm(set), []);

    const { ymmTextBar, modal } = useYmm20(setOpenYmmFunc, openYmm);

    useEffect(() => {
        if (!allPackages.length) return;
    
        const urlPackageId = searchParams.get('package') || '';
        if (urlPackageId) {
            setPackageId(urlPackageId);
        } else {
            setPackageId(allPackages[0].fields.sms_package_id || '');
        }
    }, [searchParams, allPackages]);

    useEffect(() => {
        async function fetchChannels() {
            let url: string = `/v2/channelfeed/${packageId}`;
            if (!asset.fields.display_upgraded_channels) {
                url = `/v2/channelfeed/package/${packageId}`;
            }

            const response = await fetch(url);
            const json = await response.json();

            if (json.errorCode) {
                const sortedChannels = sortChannels(
                    {
                        channels: [],
                        packageDisplayName: '',
                        packageTitle: '',
                        radioType: pageContext?.platform || '',
                    },
                    XtraChannels,
                    categoryMap,
                );
                setChannels(sortedChannels);
                setLoading(false);
            } else {
                const sortedChannels = sortChannels(json, XtraChannels, categoryMap);
                setChannels(sortedChannels);
                setLoading(false);
            }
        }

        if (packageId !== '') {
            if (!loading) {
                setLoading(true);
            }
            fetchChannels();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageId]);

    const channelButtons = [
        { title: 'All' },
        { title: 'Music' },
        { title: 'Sports' },
        { title: 'Talk' },
        { title: 'News' },
    ];
    const channelGuideBody = {
        All: All,
        Music: Music,
        Sports: Sports,
        Talk: TalkEntertainment,
        News: NewsIssues,
    };

    function getPackageById(packageId: string) {
        for (const platformPackage of allPackages) {
            if (platformPackage.fields.sms_package_id === packageId) {
                return platformPackage;
            }
        }
    }

    function handleShowMore() {
        setShowContent(!showContent);
        setTimeout(() => setRandom(Math.random()), 1);
    }

    function selectHeader(type: string) {
        return {
            title: `Select a ${type}`,
            body:
                type !== 'genre' ? (
                    <div className={selectPlanHeaderSubTitleStyles}>
                        <span>Channels not included in the plan will appear locked (</span>
                        <LockIcon />
                        <span>)</span>
                    </div>
                ) : null,
        };
    }

    function plansSelector() {
        if (!packageId && !channels) {
            return;
        }

        const mainPackages = allPackages.slice(0, allPackages.length - asset.fields.showMorePackageCount);
        const morePackages = allPackages.slice(allPackages.length - asset.fields.showMorePackageCount);

        const id = packageId || allPackages[0].fields.sms_package_id;
        const sxmSelectedPackage = id ? getPackageById(id) : null;

        return (
            <Select
                type="form"
                label="Plan"
                theme="grayLightWeb2"
                className={selectorStyles}
                value={id}
                placeholder={sxmSelectedPackage?.fields.package_display_name}
                name="siriusxm package"
                onChange={(e) => setPackageId(e.value)}
                dataCy="channel-guide-package-select"
                recalculateHeight={randomNumber}
                selectHeader={selectHeader('plan')}
            >
                <OptionGroup>
                    {mainPackages.map((sxmPackage, i) => {
                        if (sxmPackage.fields.package_display_name && sxmPackage.fields.sms_package_id) {
                            return (
                                <Option key={i} value={sxmPackage.fields.sms_package_id} className={selectOption}>
                                    {sxmPackage.fields.package_display_name}
                                </Option>
                            );
                        }

                        return <></>;
                    })}
                </OptionGroup>
                {morePackages.length > 0 ? (
                    <ShowMore
                        id="cgPackagesShowMore"
                        showText={<div className={showMoreTextStyles}>More plans</div>}
                        hideText={<div className={showMoreTextStyles}>Show less</div>}
                        onClickHandler={handleShowMore}
                        isContentShown={showContent}
                        activeToggleTextPosition="bottom"
                        className={showMore}
                        buttonClassName={showMoreButton}
                        shownClassName={show}
                        contentClassName={hidden}
                        showMoreAreaStyles={showMoreAreaStyles}
                    >
                        <OptionGroup>
                            {morePackages.map((sxmPackage, i) => {
                                if (sxmPackage.fields.package_display_name && sxmPackage.fields.sms_package_id) {
                                    return (
                                        <Option
                                            key={i}
                                            value={sxmPackage.fields.sms_package_id}
                                            className={selectOption}
                                        >
                                            {sxmPackage.fields.package_display_name}
                                        </Option>
                                    );
                                }

                                return <></>;
                            })}
                        </OptionGroup>
                    </ShowMore>
                ) : (
                    <></>
                )}
            </Select>
        );
    }

    function renderYMM() {
        if (!packageId && !channels) {
            return;
        }

        const id = packageId || allPackages[0].fields.sms_package_id;
        const sxmPackage = id ? getPackageById(id) : null;
        const platform = ucfirst(sxmPackage?.fields.platform.replace(/ip/gi, 'siriusxm') || 'siriusxm').replace(
            /xm/gi,
            'XM',
        );

        if (searchParams.get('package')) {
            return (
                <>
                    Showing channels for {platform} <b>{sxmPackage?.fields.package_display_name}</b>
                </>
            );
        } else {
            return (
                <div className={ymmTriggerWrapper}>
                    <button onClick={() => setOpenYmm(true)} className={styles.ymmButton}>
                        <span className={styles.ymmPreTextBar}>
                            <IconChannelList className={styles.channelIcon} />
                            Ch. numbers for:
                        </span>
                        <span className={styles.ymmTextBar}>
                            {ymmTextBar} <IconDropDown className={iconStyle} />
                        </span>
                    </button>
                </div>
            );
        }
    }

    function downloadLink(type: string) {
        if (lineupLink && lineupLink.fields.href) {
            const downloadLinkStyles = {
                mobile: downloadLinkMobile,
                desktop: downloadLinkDesktop,
            };

            return (
                <div className={downloadLinkStyles[type as keyof typeof downloadLinkStyles]}>
                    <Link href={lineupLink.fields.href} target="_blank" className={linkColor}>
                        <div className={downloadTextWrapper}>
                            <DownloadIcon />
                            {lineupLink.fields.link_text || 'Download PDF Guide'}
                        </div>
                    </Link>
                </div>
            );
        }

        return null;
    }

    function handleChannelSelection(component: string) {
        const Component = channelGuideBody[component as keyof typeof channelGuideBody];

        if (Component) {
            return <Component />;
        }

        return null;
    }

    type Option = {
        value: string;
        label: string;
    };

    function getLabelByValue(list: Option[], selectedValue: string) {
        const listItem = list.find((item) => item.value === selectedValue);

        return listItem ? listItem.label : '';
    }

    function genreSelector() {
        const genreList = getGenreList(bodyComponent);

        return genreList?.length > 0 ? (
            <Select
                type="form"
                label="Genre"
                theme="grayLightWeb2"
                value={genre}
                placeholder={genre === 'All' && bodyComponent !== 'All' ? genreList[0]?.label : label}
                name="Genres"
                onChange={(e) => {
                    setGenre(e.value);
                    setLabel(getLabelByValue(genreList, e.value));
                }}
                dataCy="channel-guide-genre-select"
                className={selectorStyles}
                selectHeader={selectHeader('genre')}
            >
                <OptionGroup>
                    {genreList.map((genreOption, i) => {
                        if (genreOption.label) {
                            return (
                                <Option key={i} value={genreOption.value} className={selectOption}>
                                    {genreOption.label}
                                </Option>
                            );
                        }

                        return <></>;
                    })}
                </OptionGroup>
            </Select>
        ) : null;
    }

    function changeBodyContent(data: string) {
        setBodyComponent(data);
        setGenre('All');
    }

    const headerAreaClassNames = cn(headerAreaWrapper, {
        [openYmmStyles]: openYmm,
        [legacyTopNavStyles]: pageContext?.legacyTopNavigation,
    });

    return (
        <>
            <Grid container className={styles.channelGuidePaddings}>
                <Grid col={{ sm: 12 }}>
                    <div className={headerAreaClassNames}>
                        <div className={tabsSelectorWrapper}>
                            <ChannelHeader onClick={changeBodyContent} channelButtons={channelButtons} />
                            {downloadLink('desktop')}
                        </div>
                        <div className={selectorsWrapper}>
                            {plansSelector()}
                            {genreSelector()}
                        </div>
                        {renderYMM()}
                    </div>
                    <div className={channelGuideContentWrapper}>
                        {loading ? (
                            <>{createSpaceHolder()}</>
                        ) : channels && !loading ? (
                            <ChannelGuideContext.Provider
                                value={{
                                    channels: channels,
                                    channelGuide: asset,
                                    platform: pageContext?.platform || '',
                                    genre: genre,
                                }}
                            >
                                {handleChannelSelection(bodyComponent)}
                            </ChannelGuideContext.Provider>
                        ) : null}
                    </div>
                    {downloadLink('mobile')}
                </Grid>
            </Grid>
            {openYmm && modal}
        </>
    );
}
