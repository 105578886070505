export function exact(value: string | number, criteria: string) {
    return value === criteria;
}

export function range(value: string | number, criteria: string) {
    const parts = criteria.split('-');
    const start = parts[0];
    const end = parts[1];

    return start <= value && value <= end;
}
