import { PRICING } from '@/constants/pulse/pricing';
import { ResponseData, outputMap } from './Offers';
import { ReadonlyURLSearchParams } from 'next/navigation';

interface Payload {
    priorRelationship: string; // FST_TRIAL, PRIOR_SFLPY, PRIOR_NON_CONV, UNK (paid media)
    journeyStage: string; // Onboarding, InTrial, Winback
    cohort: string; // sports, country, pop, news, general
}

export const generateOffer = (payload: Payload): ResponseData => {
    const { priorRelationship, journeyStage, cohort } = payload;
    const key = `${priorRelationship}_${journeyStage}_${cohort}`;

    const responseData = outputMap[key];
    if (responseData) {
        return responseData;
    }

    // Default offer if responseData not found - matches paid media
    return {
        offer: `${PRICING.offer2for3}`,
        terms: `${PRICING.terms2for3AllAccess}`,
        tagline: `${PRICING.taglineAllAccess}`,
        tier: `${PRICING.tierAllAccess}`,
        url: `${PRICING.url2for3AllAccess}`,
        offerName: `${PRICING.offerKey2for3AllAccess}`,
    };
};

export const createOfferPayload = (
    priorRelationship: string,
    journeyDaysParam: string,
    pageSlug: string,
): ResponseData => {
    const cohort = getCohortFromSlug(pageSlug);

    const journeyDays = journeyDaysParam ? Number(journeyDaysParam) : undefined;
    const journeyStage =
        journeyDays !== undefined
            ? journeyDays <= 30
                ? PRICING.onboarding
                : journeyDays <= 90
                  ? PRICING.inTrial
                  : PRICING.winback
            : PRICING.onboarding;

    return generateOffer({
        priorRelationship: priorRelationship ?? '',
        journeyStage: journeyStage ?? '',
        cohort: cohort,
    });
};

export const getCohortFromSlug = (slug: string): string => {
    const lastDashIndex = slug.lastIndexOf('-');
    return slug.substring(lastDashIndex + 1);
};

export const showConversionView = (searchParams: ReadonlyURLSearchParams): boolean => {
    const journeyDaysParam = searchParams.get(PRICING.journeyDaysParam);
    const engagedParam = searchParams.get(PRICING.engagedParam);
    const journeyDays = journeyDaysParam ? Number(journeyDaysParam) : undefined;
    const priorRelationshipParam = searchParams.get(PRICING.priorRelationshipParam);

    const showConversion =
        ((journeyDays !== undefined && journeyDays > 45) ||
            engagedParam === PRICING.engagedYes ||
            priorRelationshipParam === PRICING.unknownPriorRelationship) ? true : false;
    return showConversion;
};
