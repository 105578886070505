import { useSearchParams } from 'next/navigation';

export default function useInheritParams(path: string, inheritParams: string[]) {
    const searchParams = useSearchParams();
    const pathParts = path.split('?');
    const pathParams = new URLSearchParams(pathParts[1]);

    if (typeof window !== 'undefined') {
        const sessionParams = new URLSearchParams(window.sessionStorage.getItem('paramStr') || '');
        if (sessionParams) {
            const keys = sessionParams.keys();
            let key = keys.next().value;

            while (key) {
                if (inheritParams.indexOf(key) !== -1) {
                    const value = sessionParams.get(key);
                    if (value) {
                        pathParams.set(key, value);
                    }
                }
                key = keys.next().value;
            }
        }
    }

    if (searchParams) {
        inheritParams.forEach((param) => {
            const value = searchParams.get(param);
            if (value) {
                pathParams.set(param, value);
            }
        });
    }

    if (/\/subscribe\/checkout\/flepz/gi.test(path)) {
        const satCode = searchParams.get('satCode');

        if (satCode) {
            if (pathParams.has('promoCode') || pathParams.has('promocode')) {
                pathParams.delete('promoCode');
                pathParams.delete('promocode');
                pathParams.set('promoCode', satCode);
            } else {
                pathParams.delete('programCode');
                pathParams.delete('programcode');
                pathParams.set('programcode', satCode);
            }
        }
    } else if (/\/subscribe\/checkout\/purchase\/streaming/gi.test(path)) {
        const strCode = searchParams.get('strCode');
        if (strCode) {
            if (pathParams.has('programCode') || pathParams.has('programcode')) {
                pathParams.delete('programCode');
                pathParams.delete('programcode');
                pathParams.set('programcode', strCode);
            } else {
                pathParams.delete('promoCode');
                pathParams.delete('promocode');
                pathParams.set('promoCode', strCode);
            }
        }
    }

    return pathParts[0] + '?' + pathParams.toString();
}
