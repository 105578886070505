export default function Media(module: ContentfulMedia): SiteMedia {
    return {
        cms_id: module.sys.id,
        cms_type: module.sys.contentType.sys.id,
        fields: {
            name: module.fields.name,
            media: module.fields.media,
        },
    };
}
