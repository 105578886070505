import { ButtonProps } from 'de-sxm-reactlib-2/dist/components/atoms/Button';

export const siteLinkToWeb2ButtonProps = (linkFields: SiteLinkFields): ButtonProps => ({
    children: linkFields.link_text,
    href: linkFields.href || '',
    ariaLabel: linkFields.aria_label || linkFields.link_text,
    color: linkFields.ctaColor,
    linkTarget: linkFields.type === 'New Tab' ? '_blank' : '_self',
    size: linkFields.size === 'Small' ? 'small' : 'large',
    variant: linkFields.theme === 'text' ? 'tertiary' : linkFields.theme || 'primary',
    colorSet: linkFields.colorSet,
    tertiaryNoPadding: linkFields.theme === 'text',
    type: 'button',
    linkType: linkFields.link_type?.toLowerCase()
});
