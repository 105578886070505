import { useEffect, useState, useRef, RefObject } from 'react';

interface UseOnScreenOptions {
    threshold?: number | number[];
}

export default function useOnScreen(
    ref: RefObject<HTMLElement> | undefined,
    options: UseOnScreenOptions = {},
): { isOnScreen: boolean; isAbove: boolean } {
    const { threshold = 0 } = options;
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(false);
    const [isAbove, setIsAbove] = useState(false);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            ([entry]) => {
                setIsOnScreen(entry.isIntersecting);
                setIsAbove(entry.boundingClientRect.top < 0);
            },
            { threshold },
        );
    }, [threshold]);

    useEffect(() => {
        const current = observerRef.current!;
        const passed = ref?.current;

        if (passed) {
            current.observe(passed);
        }

        return () => {
            current.disconnect();
        };
    }, [ref]);

    return { isOnScreen, isAbove };
}
