export default function PhoneNumber(module: ContentfulPhoneNumber): SiteLink {
    return {
        cms_id: module.sys.id,
        cms_type: module.sys.contentType.sys.id,
        fields: {
            name: module.fields.name,
            phone: module.fields.phone,
            link_text: module.fields.link_text,
            link_caption: module.fields.description,
        },
    };
}
