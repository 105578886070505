import dynamic from 'next/dynamic';
import { ComponentProps, useRef, useState } from 'react';
import styles from './plandetailcardgroup.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { MarketingList } from '../MarketingList';

const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const {
    textContentWrapper,
    headlineStyles,
    descriptionTextStyles,
    iconWrapper,
    icon,
    descriptionPrice,
    iconAndText,
    headingDescription,
    cardHeading,
    plancardWrapper,
    planCard1,
    planCard2,
    titleHeadingDescription,
    optionalAddOns,
    optionalItems,
    optionalItemWrapper,
    optionalItemHeading,
    inputStyles,
    mainWrapper,
    headingAndListWrapper,
    planCardMarketingList,
    radioButtonWrapper,
    bestValue,
    planHeading,
    planCardParagraph,
} = styles;
export interface PlanDetailCardGroupProps {
    detailLink?: string;
    detailLinkText?: string;
    arialabel?: string;
    allAccessFeatures: ComponentProps<typeof MarketingList>['items'];
    buildaPlanFeatures: ComponentProps<typeof MarketingList>['items'];
    checkmarkColor?: string;
    radioArr: RadioButtonProps[];
    optionalAddonsArr: OptionalAddonsProps[];
    radioService: RadioButtonProps;
}
export interface RadioButtonProps {
    radioImage: string;
    radioAltText: string;
    radioButtonHeading?: string;
    radioButtonSubtext1?: string;
    radioButtonSubtext2?: string;
    buttonText?: string;
    buttonLink?: string;
}
export interface OptionalAddonsProps {
    item?: string;
    pricing?: string;
    perPricing?: string;
}
const PlanDetailCardGroup = ({
    arialabel,
    allAccessFeatures,
    buildaPlanFeatures,
    radioArr,
    optionalAddonsArr,
    radioService,
}: PlanDetailCardGroupProps): JSX.Element => {
    const inputRef = useRef<(HTMLInputElement | null)[]>([]);
    const [selectedInput, setSelectedInput] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState<RadioButtonProps>(radioArr[0]);
    const onChangeHandler = (index: number) => {
        setSelectedInput(index);
        setSelectedPlan(radioArr[index]);
    };
    return (
        <div className={mainWrapper}>
            <div>
                <h4 className={planHeading}>Plans in detail</h4>
                <span className={headingDescription}>
                    Get every channel we offer with All Access, or start with All Music and build your own plan.
                </span>
            </div>
            <div className={plancardWrapper}>
                <div className={planCard1}>
                    <h4 className={cardHeading}>All Access</h4>
                    <span className={titleHeadingDescription}>Choose where you want to listen</span>
                    {allAccessFeatures?.length && (
                        <MarketingList
                            items={allAccessFeatures}
                            visibleItems={allAccessFeatures.length}
                            className={planCardMarketingList}
                        />
                    )}
                    {radioArr?.map((rd, index) => (
                        <div className={iconAndText} key={index}>
                            <input
                                ref={(ref) => {
                                    inputRef.current[index] = ref;
                                }}
                                type="radio"
                                name="select"
                                className={inputStyles}
                                onClick={() => onChangeHandler(index)}
                                aria-label={`Get plan ${rd.radioButtonHeading} ${rd.radioButtonSubtext1} ${rd.radioButtonSubtext2} `}
                                defaultChecked={selectedInput === index}
                            />
                            <div className={radioButtonWrapper}>
                                <div aria-hidden="true" className={iconWrapper}>
                                    <ImageWrapper
                                        aria-hidden="true"
                                        src={rd?.radioImage}
                                        alt={rd?.radioAltText}
                                        className={icon}
                                    />
                                </div>
                                <div className={textContentWrapper}>
                                    <span className={headlineStyles}>
                                        {rd.radioButtonHeading}{' '}
                                        {index === 0 && <span className={bestValue}>Best Value</span>}
                                    </span>
                                    <span className={descriptionTextStyles}>
                                        <span className={descriptionPrice}> {rd.radioButtonSubtext1} </span>
                                        {rd.radioButtonSubtext2}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Button
                        type="button"
                        theme="primary"
                        text={'Get ' + selectedPlan?.buttonText}
                        href={selectedPlan?.buttonLink}
                        ariaLabel={arialabel}
                        className={styles.button}
                    />
                </div>
                <div className={planCard2}>
                    <div className={headingAndListWrapper}>
                        <span className={cardHeading}>Build a plan</span>
                        <span className={titleHeadingDescription}>Includes in car + app</span>
                        <span className={planCardParagraph}>
                            Starts with All Music. $1 for 3 months, then starting at $9.99/mo.
                        </span>
                        {buildaPlanFeatures?.length && (
                            <MarketingList
                                items={buildaPlanFeatures}
                                visibleItems={buildaPlanFeatures.length}
                                className={planCardMarketingList}
                            />
                        )}
                        <span className={optionalAddOns}>Optional add-ons</span>
                        <div className={optionalItemWrapper}>
                            {optionalAddonsArr?.map((op, index) => (
                                <div className={optionalItems} key={index}>
                                    <span className={optionalItemHeading}>{op.item}</span>
                                    <span>
                                        <span className={optionalItemHeading}>{op.pricing}</span>
                                        {op.perPricing}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className={iconAndText}>
                            <div aria-hidden="true" className={iconWrapper}>
                                <ImageWrapper
                                    aria-hidden="true"
                                    src={radioService.radioImage}
                                    alt={radioService.radioAltText ?? ''}
                                    className={icon}
                                />
                            </div>
                            <div className={textContentWrapper}>
                                <span className={headlineStyles}>{radioService.radioButtonHeading} </span>
                                <span className={descriptionTextStyles}>
                                    <span className={descriptionPrice}> {radioService.radioButtonSubtext1} </span>
                                    {radioService.radioButtonSubtext2}
                                </span>
                            </div>
                        </div>
                        <Button
                            type="button"
                            theme="primary"
                            buttonColor="0F63FF"
                            text={radioService.buttonText}
                            href={radioService.buttonLink}
                            ariaLabel={arialabel}
                            className={styles.button}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlanDetailCardGroup;
