import { NoGamesProps } from './interfaces';
import dynamic from 'next/dynamic';
import styles from './sportschedule.module.scss';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export default function NoGames({ logo, logoSize = 'large', title, children, classNames }: NoGamesProps) {
    return (
        <div className={`${styles.noGames} ${classNames}`}>
            {logo && logo.url && (
                <ImageWrapper
                    optimizedWidth={72}
                    optimizedHeight={72}
                    src={logo?.url || ''}
                    className={styles[logoSize]}
                    alt=""
                />
            )}
            <Text tag="div" variant="h5" className={styles.noGamesTitle}>
                {title}
            </Text>
            <div className={styles.noGamesMsg}>{children}</div>
        </div>
    );
}
