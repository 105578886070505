'use client';
// NOTE: We don't dynamically import components here to ensure `updateWidth`'s first call is accurate
import cn from 'classnames';
import React, { useEffect, useState, useRef, useCallback, useId } from 'react';
import styles from './sportsnavigation.module.scss';
import { PopoverWrapper } from '../PopoverWrapper';
import { useMediaQuery } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import { Link } from '@/components/atoms/Link';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import Tooltip from 'de-sxm-reactlib/dist/components/atoms/Tooltip/Tooltip';
import ShowMore from 'de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore';
import { TeamList } from '../Sports/TeamList';
import { TitledIconListProps } from '../TitledIconList/TitledIconList';

interface Sport {
    name: string;
    channelUrl: string;
    scheduleUrl: string;
    homePageUrl: string;
    teamsUrl?: string;
    visibleInPrimary: boolean;
    teamListProps: TitledIconListProps[];
    teamPageVanities?: { [index: string]: string };
}

interface SportsNavigationProps {
    sports: Sport[];
}

interface SportsListProps {
    sports: Sport[];
    isPrimary: boolean;
    onLinkHover?: () => void;
}

function SportsList({ sports, isPrimary, onLinkHover = () => {} }: SportsListProps): JSX.Element {
    return (
        <ul className={isPrimary ? styles.sportsMenuPrimaryList : styles.sportsMenuSecondaryList}>
            {sports.map((sport, index) => {
                const hasListProps = sport.teamListProps && Object.keys(sport.teamListProps).length > 0;
                const teamList =
                    hasListProps && sport.teamListProps ? (
                        <TeamList columnsMobile={4} leagueAbbr={sport.name} teamListProps={sport.teamListProps} />
                    ) : undefined;

                return (
                    <li
                        key={index}
                        className={isPrimary === sport.visibleInPrimary ? styles.visibile : styles.hidden}
                        onMouseEnter={onLinkHover}
                    >
                        {isPrimary ? (
                            <Tooltip
                                buttonText={sport.name}
                                contentShadowType="large"
                                position="bottom"
                                className={hasListProps ? styles.subMenuTitleTeam : undefined}
                                contentClassName={styles.subMenuWrapper}
                            >
                                <article
                                    className={cn(styles.subMenu, hasListProps ? styles.subMenuWithTeam : undefined)}
                                >
                                    <nav className={styles.subMenuSide}>
                                        <Text tag="h2" variant="h3">
                                            {sport.name}
                                        </Text>
                                        <ul className={styles.subMenuSideList}>
                                            <li>
                                                <Link href={sport.homePageUrl}>{sport.name} Home</Link>
                                            </li>
                                            <li>
                                                <Link href={sport.scheduleUrl} aria-label={`${sport.name} Schedule`}>
                                                    Schedule
                                                </Link>
                                            </li>
                                            {sport.teamsUrl && (
                                                <li>
                                                    <Link href={sport.teamsUrl} aria-label={`${sport.name} Teams`}>
                                                        Teams
                                                    </Link>
                                                </li>
                                            )}
                                            <li>
                                                <Link href={sport.channelUrl} aria-label={`${sport.name} Channels`}>
                                                    Channels
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    {hasListProps && <div className={styles.subMenuTeam}>{teamList}</div>}
                                </article>
                            </Tooltip>
                        ) : (
                            <Link href={sport.homePageUrl}>{sport.name}</Link>
                        )}
                    </li>
                );

                return <></>;
            })}
        </ul>
    );
}

export default function SportsNavigation({ sports }: SportsNavigationProps): JSX.Element {
    const isViewportDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.lg}px)`);
    const [sportsList, setSportsList] = useState<Sport[]>(
        sports.map((sport) => ({ ...sport, visibleInPrimary: isViewportDesktop })),
    );
    const [availableWidth, setAvailableWidth] = useState<number>(0);
    const primaryNavRef = useRef<HTMLDivElement>(null);
    const [showContent, setShowContent] = useState<boolean>(false);
    const contentId = useId();

    const updateWidth = useCallback(() => {
        if (primaryNavRef.current) {
            const BUFFER_WIDTH = 10; // Avoids content sometimes pushing past the width of the page
            setAvailableWidth(primaryNavRef.current.offsetWidth - BUFFER_WIDTH);
        }
    }, [primaryNavRef]);

    useEffect(() => {
        window.addEventListener('keyup', (event) => event.key === 'Escape' && setShowContent(false));
    }, []);

    // Update available width on resize
    useEffect(() => {
        // Update sportsList when isViewportMobile changes
        setSportsList(sports.map((sport) => ({ ...sport, visibleInPrimary: isViewportDesktop })));

        if (isViewportDesktop) {
            window.addEventListener('resize', updateWidth);
            updateWidth();

            return () => window.removeEventListener('resize', updateWidth);
        }
    }, [isViewportDesktop, sports, updateWidth]);

    // Handle visibility based on available width
    useEffect(() => {
        if (isViewportDesktop) {
            let cumulativeWidth = 0;
            const updatedSports = sports.map((sport) => {
                const listItem = primaryNavRef.current?.querySelector(
                    `li:nth-child(${sports.indexOf(sport) + 1})`,
                ) as HTMLElement | null;
                const sportWidth = listItem?.offsetWidth || 0;
                cumulativeWidth += sportWidth;

                return {
                    ...sport,
                    visibleInPrimary: cumulativeWidth <= availableWidth,
                };
            });

            setSportsList(updatedSports);
        }
    }, [availableWidth, sports, isViewportDesktop]);

    const secondaryMenuText = isViewportDesktop ? 'More Sports' : 'Choose your favorite sport';

    return (
        <div className={styles.sportsMenu}>
            {isViewportDesktop && (
                <nav
                    className={styles.sportsMenuPrimary}
                    aria-label="Primary Sports Menu Navigation"
                    ref={primaryNavRef}
                >
                    <SportsList sports={sportsList} isPrimary={true} onLinkHover={() => setShowContent(false)} />
                </nav>
            )}

            <nav className={styles.sportsMenuSecondary}>
                <ShowMore
                    className={styles.moreInfo}
                    showText={secondaryMenuText}
                    hideText={secondaryMenuText}
                    activeToggleTextPosition="top"
                    id={contentId}
                    shownClassName={styles.moreInfoActive}
                    contentClassName={styles.moreInfoContent}
                    onClickHandler={() => setShowContent(true)}
                    isContentShown={showContent}
                >
                    <PopoverWrapper
                        contentPadding="30px"
                        headerContent="Choose your favorite sport"
                        closeButtonColor="var(--purple)"
                        onClosePress={() => setShowContent(false)}
                        className={styles.sportsMenuSecondaryPopover}
                    >
                        <SportsList sports={sportsList} isPrimary={false} />
                    </PopoverWrapper>
                </ShowMore>
            </nav>
        </div>
    );
}
