import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useMediaQuery } from '@/hooks';
import styles from '../vegaplan.module.scss';
import IconCheckmarkSm from '@/assets/icons/icon-checkmark-sm.svg';
import { Link } from '@/components/atoms/Link';
import { DataRow, PlanDetails, PlansData } from '../data/interfaces';
import { responsiveBreakpointsPx } from '@/constants';

const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));

const FEES_TEXT: React.ReactNode = (
    <>
        <span className={styles.seeText}> See </span>
        <Link href="#pageOfferDetails" className={styles.offerText}>
            Offer Details.
        </Link>
    </>
);

export function PlanGrid({ plansData, className }: { plansData: PlansData; className?: string }) {
    const tableClasses = cn(styles.table, className);
    const viewportIsTablet = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const viewportIsSmallerPhone = useMediaQuery(`(max-width: 374px)`);
    const viewportIsBeforeXR = useMediaQuery(`(max-width: 411px)`);

    return (
        <table className={tableClasses}>
            <thead>
                <tr className={styles.rowHead}>
                    {plansData?.headers?.map((header, index) => (
                        <th
                            key={header}
                            className={cn({
                                [styles.plansAtaGlanceSpacing]: index === 0,
                                [styles.spacerCol]: index === 1,
                                [styles.bestPlanCol]: index > 0,
                                [styles.topBorderRadius]: index > 0,
                                [styles.smallerAndroidPhone]: viewportIsSmallerPhone && index === 0,
                                [styles.smallerPhonesBeforeXR]: viewportIsBeforeXR && index === 0,
                            })}
                        >
                            {plansData.best[header] && <div className={styles.bestPlan}>BEST VALUE</div>}
                            <div
                                className={cn(styles.planHeaderWrapper, {
                                    [styles.plansAtaGlance]: index === 0,
                                    [styles.smallerPhonesBeforeXRDiv]: viewportIsBeforeXR && index === 0,
                                })}
                            >
                                {index === 0 ? (
                                    <h4 className={styles.gridColumnTitle}>{header}</h4>
                                ) : (
                                    <h6 className={styles.gridColumnTitle}>{header}</h6>
                                )}
                                {index === 0 && (
                                    <p className={styles.colPrice}>{plansData.rows[0].plans['In-vehicle plans']}</p>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {plansData.rows.flatMap((row: DataRow, i: number) => {
                    const currentRow = (
                        <tr key={i} className={`${row.name === 'Price' ? styles.rowPrice : ''}`}>
                            {row.name === 'Price' ? (
                                <th
                                    className={cn(styles.firstColSpacing, {
                                        [styles.smallerAndroidPhone]: viewportIsSmallerPhone,
                                    })}
                                >
                                    <p>{FEES_TEXT}</p>
                                </th>
                            ) : (
                                <th
                                    scope="row"
                                    className={cn(styles.firstColSpacing, styles.headingwithDesc, {
                                        [styles.smallerAndroidPhone]: viewportIsSmallerPhone,
                                    })}
                                >
                                    {row.name}
                                    <p className={styles.headingDescription}>{row.description}</p>
                                </th>
                            )}
                            {plansData.headers.slice(1).map((planName, j) => (
                                <td
                                    key={j}
                                    className={cn({
                                        [styles.spacerCol]: j === 0,
                                        [styles.bestPlanCol]: true,
                                    })}
                                >
                                    {row.name === 'Price' ? (
                                        <>
                                            {!viewportIsTablet ? (
                                                <>
                                                    <Button
                                                        className={cn(styles.ctaButton, {
                                                            [styles.getButton]: viewportIsSmallerPhone,
                                                        })}
                                                        href={plansData.purchaseHrefs[planName]}
                                                        type="button"
                                                        theme="primary"
                                                        text={
                                                            !viewportIsTablet
                                                                ? `Get`
                                                                : planName === 'All Access'
                                                                  ? `Get ${planName}`
                                                                  : planName === 'Build a plan'
                                                                    ? 'Build your plan'
                                                                    : ''
                                                        }
                                                        buttonSize="narrow"
                                                        ariaLabel={`Get ${planName}`}
                                                    />
                                                    <p className={styles.colPrice}>
                                                        {row.plans[planName as keyof PlanDetails]}
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className={styles.colPrice}>
                                                        {row.plans[planName as keyof PlanDetails]}
                                                    </p>
                                                    <Button
                                                        className={styles.ctaButton}
                                                        href={plansData.purchaseHrefs[planName]}
                                                        type="button"
                                                        theme="primary"
                                                        text={
                                                            !viewportIsTablet
                                                                ? `Get`
                                                                : planName === 'All Access'
                                                                  ? `Get ${planName}`
                                                                  : planName === 'Build a plan'
                                                                    ? 'Build your plan'
                                                                    : ''
                                                        }
                                                        buttonSize="narrow"
                                                        ariaLabel={`Get ${planName}`}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : row.plans[planName as keyof PlanDetails] === 'yes' ? (
                                        <>
                                            <IconCheckmarkSm aria-hidden="true" />
                                            <span className="show-for-sr">Included</span>
                                        </>
                                    ) : (
                                        row.plans[planName as keyof PlanDetails] || <></>
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                    return currentRow;
                })}
            </tbody>
        </table>
    );
}
