import styles from './staticChannel.module.scss';
import StaticChannelCard from './StaticChannelCard/StaticChannelCard';
import StaticChannelCardStrip from './StaticChannelCardStrip/StaticChannelCardStrip';

interface StaticChannelProps {
    listenLink: string;
    listenLinkAriaLabel?: string;
    title: string;
    subTitle: string;
    borderColor: string;
    radioImageUrl: string;
    radioImageAlt?: string;
    albumImageSrc: string;
    albumImageAlt?: string;
}

export default function StaticChannel(props: StaticChannelProps) {
    return (
        <>
            <div className={styles.card}>
                <StaticChannelCard {...props} />
            </div>
            <div className={styles.strip}>
                <StaticChannelCardStrip {...props} />
            </div>
        </>
    );
}
