'use client';
import { Link } from '@/components/atoms/Link';
import { FormHeaderSection } from '../FormHeaderSection';
import FreeTrialForm from './FreeTrialForm';
import { ComponentProps } from 'react';

export default function FreeTrialFormSection(): JSX.Element {
    const headerProps: ComponentProps<typeof FormHeaderSection> = {
        eyebrow: 'getting started is easy',
        title: "ready for your free trial? fill out this form, and we'll reach out to you soon.",
        thinText: (
            <p>
                The following information will be subject to the SiriusXM Music for Business
                <Link
                    href="https://www.siriusxm.com/customer-agreement?intcmp=OTHER_NA_www:musicforbusiness:free-trial_CustomerAgreement"
                    target="_blank"
                >
                    Customer Agreement
                </Link>
                and
                <Link
                    href="https://www.siriusxm.com/content/dam/sxm-com/pdf/corporate-pdf/siriusxm_privacypolicy_eng.pdf"
                    target="_blank"
                >
                    Privacy Policy
                </Link>
            </p>
        ),
    };

    return (
        <>
            <FormHeaderSection {...headerProps} />
            <FreeTrialForm />
        </>
    );
}
