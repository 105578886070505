'use client';
import { RenderRTE } from '@/components/RenderRTE';
import { WidgetHero } from '@/components/molecules/Hero';
import styles from './hero.module.scss';
import { useState } from 'react';
import { useLiveChannel } from '@/hooks';
import { ChannelCard } from '@/components/molecules/ChannelCard';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import { SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import { Document } from '@contentful/rich-text-types';

interface Props {
    breadcrumbs?: Link[];
    channelColorLogo?: AEMImage;
    channelName?: string;
    channelShortDescription?: Document;
    showSchedulesUrl?: Link;
    deepLinkPlayerUrl?: string;
    channelPrimaryColor?: string;
    channelSocialText?: string;
    leagueHeroBackgroundImage: AEMImage;
    leagueBackgroundColor: string;
    leagueHeroHeadline?: string;
    leagueTitle: string;
    leagueShortDescription?: Document;
    channelPlayingInfo?: ChannelPlaying;
    channelId?: string;
    socialMedia: SocialMediaIconLinksProps;
    asW2?: boolean;
}

export default function Hero({
    breadcrumbs,
    channelColorLogo,
    channelName,
    channelShortDescription,
    showSchedulesUrl,
    deepLinkPlayerUrl,
    channelPrimaryColor,
    channelSocialText,
    leagueHeroBackgroundImage,
    leagueBackgroundColor,
    leagueHeroHeadline,
    leagueTitle,
    leagueShortDescription,
    channelPlayingInfo,
    channelId,
    socialMedia,
    asW2,
}: Props) {
    let card = <></>;
    let description = <></>;

    const [album, setAlbum] = useState({
        title: channelPlayingInfo?.content.title || '',
        artist: channelPlayingInfo?.content.artist || '',
        art: channelPlayingInfo?.album.art || channelColorLogo?.url || '',
    });
    const channelList = channelId ? [channelId] : [];
    useLiveChannel(channelList, (response: MountainResponse) => {
        const nowPlayingChannel = response.channels;

        if (nowPlayingChannel && channelId) {
            setAlbum({
                title: nowPlayingChannel[channelId].content.title || '',
                artist: nowPlayingChannel[channelId].content.artists
                    ? nowPlayingChannel[channelId].content.artists[0].name
                    : '',
                art: nowPlayingChannel[channelId].content.album?.art || channelColorLogo?.url || '',
            });
        }
    });

    if (channelId) {
        card = (
            <div className={styles.channelCardWrapper}>
                <ChannelCard
                    className={styles.channelCardSelector}
                    radioImageUrl={channelColorLogo?.url || ''}
                    radioImageAlt={channelColorLogo?.altText || channelName}
                    radioImageHeight={132}
                    radioImageWidth={132}
                    title={channelName || ''}
                    subTitle={<RenderRTE contentfulDoc={channelShortDescription} removePTag asW2={asW2} />}
                    showAndScheduleLink={showSchedulesUrl?.href}
                    showAndScheduleLinkText={showSchedulesUrl?.link_text}
                    showsAndScheduleLinkAriaLabel={showSchedulesUrl?.aria_label}
                    showAndScheduleLinkTarget={showSchedulesUrl?.link_target}
                    listenLink={deepLinkPlayerUrl || 'https://player.siriusxm.com'}
                    listenLinkAriaLabel={`Listen live to ${channelName}`}
                    borderColor={channelPrimaryColor || ''}
                    albumImageSrc={album.art}
                    albumImageAlt={album.title}
                    songTitle={album.title}
                    songArtist={album.artist}
                />
                <div className={styles.background}></div>
            </div>
        );

        description = (
            <>
                {socialMedia.items.length > 0 && (
                    <Text tag="span" variant="bold" className={styles.socialText}>
                        {channelSocialText}
                    </Text>
                )}
            </>
        );
    }
    socialMedia.className = styles.socialmedia;

    return (
        <>
            <WidgetHero
                heroTextClassName={styles.customHeroTextWrapper}
                backgroundImageTablet={leagueHeroBackgroundImage?.url}
                backgroundImageMobile={leagueHeroBackgroundImage?.url}
                backgroundColor={leagueBackgroundColor}
                widget={card}
                text={{
                    breadcrumbs: breadcrumbs,
                    header: <>{leagueHeroHeadline || leagueTitle}</>,
                    subhead: (
                        <RenderRTE
                            contentfulDoc={leagueShortDescription}
                            className={styles.subhead}
                            pToSpan
                            asW2={asW2}
                        />
                    ),
                    description: description,
                    socialMedia: socialMedia,
                }}
            />
        </>
    );
}
