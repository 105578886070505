'use client';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { default as AnnouncementBannerGroupUi } from 'de-sxm-reactlib-2/dist/components/organisms/AnnouncementBannerGroup/AnnouncementBannerGroup';
import { ComponentProps, useEffect, useState } from 'react';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { siteLinkToWeb2ButtonProps } from '@/components-2.0/contentful/Link/linkPropUtils';
import { RenderRTE } from '@/components-2.0/structure/RenderRTE';

dayjs.extend(isBetween);

type AnnouncementBannerGroupUiProps = ComponentProps<typeof AnnouncementBannerGroupUi>;

const BACKGROUND_COLOR_TO_COLOR_SET: {
    [backgroundColor: string]: AnnouncementBannerGroupUiProps['borderColorSet'];
} = Object.freeze({
    Black: 'inverse',
    White: 'base',
    'Brand Blue': 'sxmBlue',
});

const SESSION_STORAGE_KEY = 'closedAnnouncementIds';

interface AnnouncementBannersProps {
    announcementAssets: SiteAnnouncement[];
    hideGlobalAnnouncement?: boolean;
    forWeb1Nav?: boolean;
}

export function AnnouncementBanners({
    announcementAssets = [],
    hideGlobalAnnouncement,
    forWeb1Nav,
}: AnnouncementBannersProps): JSX.Element {
    const globalAnnouncement = announcementAssets.find((asset) => asset.fields.global_banner === 'Yes');
    const globalAnnouncementColorSet = BACKGROUND_COLOR_TO_COLOR_SET[globalAnnouncement?.fields.background_color || ''];

    const [banners, setBanners] = useState<AnnouncementBannerGroupUiProps['banners']>([]);
    const [previouslyClosedBanner, setPreviouslyClosedBanner] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!announcementAssets?.length) {
            return;
        }

        const closedAnnouncementIds = sessionStorage.getItem(SESSION_STORAGE_KEY)?.split(',') ?? [];
        const filteredSortedBanners = announcementAssets
            .filter(
                (asset) =>
                    !closedAnnouncementIds.includes(asset.cms_id) &&
                    dayjs().isBetween(asset.fields.begin_date, asset.fields.finish_date, 'minute', '[)') &&
                    (hideGlobalAnnouncement ? asset.fields.global_banner !== 'Yes' : true),
            )
            .sort((assetA, assetB) => {
                if (assetA.fields.global_banner === assetB.fields.global_banner) {
                    return 0;
                }

                return assetA.fields.global_banner === 'Yes' ? -1 : 1;
            })
            .map((asset, _, sortedFilteredAssets) => {
                const dismissButtonCallback = (closedBannerId: string) => {
                    sessionStorage.setItem(
                        SESSION_STORAGE_KEY,
                        // `Set` to remove duplicates:
                        Array.from(new Set(closedAnnouncementIds).add(closedBannerId)).join(','),
                    );

                    setPreviouslyClosedBanner(closedBannerId);
                };

                const filteredAssetsIncludeGlobalAnnouncement = sortedFilteredAssets.find(
                    (asset) => asset.cms_id === globalAnnouncement?.cms_id,
                );
                const colorSetOverride = filteredAssetsIncludeGlobalAnnouncement
                    ? globalAnnouncementColorSet
                    : undefined;

                return parseBannerData(asset, dismissButtonCallback, colorSetOverride, forWeb1Nav);
            });

        setBanners(filteredSortedBanners);
    }, [
        announcementAssets,
        forWeb1Nav,
        globalAnnouncement?.cms_id,
        globalAnnouncementColorSet,
        hideGlobalAnnouncement,
        previouslyClosedBanner, // This dep is only used to make this useEffect to run again
    ]);

    return <AnnouncementBannerGroupUi banners={banners} borderColorSet={banners[0]?.colorSet} />;
}

const parseBannerData = (
    { cms_id, fields }: SiteAnnouncement,
    dismissButtonCallback: (closedBannerId: string) => void,
    colorSetOverride?: AnnouncementBannerGroupUiProps['borderColorSet'],
    forWeb1Nav?: boolean,
): AnnouncementBannerGroupUiProps['banners'][0] => ({
    leadingIcon: fields.image?.url && (
        <ImageWrapper src={fields.image.url} alt={fields.image.altText ?? 'Announcement'} />
    ),
    text: <RenderRTE contentfulDoc={fields.description} />,
    onDismissButtonClick: fields.closeable_announcement ? () => dismissButtonCallback(cms_id) : undefined,
    colorSet: colorSetOverride ?? BACKGROUND_COLOR_TO_COLOR_SET[fields.background_color],
    ctas: fields.links?.map((link) => siteLinkToWeb2ButtonProps(link.fields)),
    forWeb1Nav,
});
