'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { Splide, SplideSlide, SplideTrack, Options } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import styles from './slider.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { responsiveBreakpointsPx } from '@/constants';
import { useMediaQuery } from '@/hooks';
const LinkWrapperIfHref = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/LinkWrapperIfHref/LinkWrapperIfHref'),
);

interface Slide {
    content: React.ReactNode;
    link?: Link;
}

interface SliderProps {
    slides: Slide[];
    isThemeDark?: boolean;
    className?: string;
    isArrowsTopAligned?: boolean;
    splideOptions: Options;
    type: 'ContentShowcase' | 'Sports' | 'EntityTileGrid' | 'EntityList' | 'Default';
    customArrows?: React.ReactNode;
    bottomArrows?: boolean;
}

const SPORTS_SLIDE_WIDTH = 238;

export default function Slider({
    slides,
    isThemeDark = true,
    className,
    isArrowsTopAligned = false,
    splideOptions,
    type,
    customArrows,
    bottomArrows = false,
}: SliderProps): JSX.Element {
    const sliderClassNames = cn(styles.slider, className, {
        [styles[type]]: type,
        [styles.darkTheme]: isThemeDark,
        [styles.arrowsTopAligned]: isArrowsTopAligned,
        [styles.showArrows]: slides.length > 3,
    });

    const sliderContainerRef = useRef<HTMLDivElement>(null);
    const [perMove, setPerMove] = useState(5);
    const isViewportDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.lg}px)`);

    const calculateVisibleSlides = useCallback(() => {
        if (sliderContainerRef.current) {
            const containerWidth = sliderContainerRef.current.offsetWidth;
            const slideWidth = SPORTS_SLIDE_WIDTH;
            const visibleSlides = Math.floor(containerWidth / slideWidth);
            setPerMove(visibleSlides);
        }
    }, [sliderContainerRef, setPerMove]);

    useEffect(() => {
        if (type === 'Sports' && isViewportDesktop) {
            calculateVisibleSlides();
            window.addEventListener('resize', calculateVisibleSlides);
        }
        return () => window.removeEventListener('resize', calculateVisibleSlides);
    }, [type, isViewportDesktop, calculateVisibleSlides]);

    const updatedSplideOptions = useMemo(
        () => ({
            ...splideOptions,
            ...(type === 'Sports' && { perMove: perMove }),
            ...(type === 'Sports' && { perPage: perMove }),
            ...(type === 'ContentShowcase' && slides.length < 3 && { type: 'slide', perPage: 2 }),
        }),
        [splideOptions, type, perMove, slides.length],
    );

    return (
        <div ref={sliderContainerRef}>
            <Splide className={sliderClassNames} hasTrack={false} options={updatedSplideOptions}>
                {!bottomArrows && customArrows}
                <SplideTrack className={styles.sliderTrack}>
                    {slides.map((slide, i) => {
                        return (
                            <SplideSlide key={i}>
                                <LinkWrapperIfHref link={slide.link} className={styles.linkWrapper}>
                                    {slide.content}
                                </LinkWrapperIfHref>
                            </SplideSlide>
                        );
                    })}
                </SplideTrack>
                {bottomArrows && customArrows}
            </Splide>
        </div>
    );
}
