import React from 'react';
import styles from './modalheader.module.scss';

interface ModalHeaderProps {
    titleText: string,
    subHeadText: string,
}

export const ModalHeader = ({
    titleText,
    subHeadText, 
}: ModalHeaderProps) => {
    return (
        <div className={styles.modalHeader}>
            <div className={styles.heading}>{titleText}</div>
            <div className={styles.subheading}>{subHeadText}</div>
        </div>
    );
}
