'use client';

import cn from 'classnames';
import { useEffect, useState } from 'react';
import { getShowScheduleData } from './scheduleUtilities';
import styles from './showschedule.module.scss';

interface ShowScheduleProps {
    displayTitles?: boolean;
    displayTimezones?: boolean;
    id?: string;
    shows: SiteChannelShow[];
}

export default function ShowSchedule({ shows, displayTitles, id, displayTimezones }: ShowScheduleProps): JSX.Element {
    const [scheduleDateRanges, setScheduleDateRanges] = useState<ReturnType<typeof getShowScheduleData>>();

    useEffect(() => {
        // Must be in useEffect to ensure no mismatch between server and client render
        // (https://github.com/vercel/next.js/discussions/17443#discussioncomment-87097):
        setScheduleDateRanges(getShowScheduleData(shows, displayTimezones));
    }, [displayTimezones, shows]);

    return (
        <div className={cn(styles.showSchedule)} id={id}>
            {scheduleDateRanges?.map((processedSchedules, programIndex) => (
                <div className={styles.scheduleTimeBlock} key={programIndex}>
                    {!!displayTitles && (
                        <span className={cn(styles.scheduleLabel, styles.scheduleTitle)}>
                            {processedSchedules?.[0]?.programName}
                        </span>
                    )}
                    {processedSchedules?.map((schedule, scheduleIndex) => (
                        <div key={scheduleIndex} className={styles.scheduleTimeRow}>
                            <span className={styles.scheduleLabel}>{schedule.dayRange}</span>
                            <span className={styles.scheduleTimeGroup}>
                                {schedule.showTimes.map((showTime, episodeIndex) => (
                                    <time key={episodeIndex} className={styles.scheduleSlot} dateTime={showTime}>
                                        {showTime}
                                    </time>
                                ))}
                            </span>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
