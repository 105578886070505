'use client';
import dynamic from 'next/dynamic';
import { MediaWithTextSingle } from '@/components/molecules/MediaWithText';
import type { MediaWithTextProps } from '@/components/molecules/MediaWithText/interfaces';
import { useState } from 'react';
import { range as rangeFilter } from '@/packages/array';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));

interface ClientPartnerGroupProps {
    defaultRange: string;
    unfilteredList: MediaWithTextProps[];
    listIsFilterable: boolean;
}

export default function ClientPartnerGroup({
    defaultRange,
    unfilteredList,
    listIsFilterable,
}: ClientPartnerGroupProps) {
    const [filteredList, setFilteredList] = useState<MediaWithTextProps[]>(filterList(defaultRange));

    function filterList(range: string) {
        if (!listIsFilterable) {
            return unfilteredList;
        }

        return unfilteredList.filter((item) => {
            return typeof item.headline === 'string' ? rangeFilter((item.headline || '')[0], range) : false;
        });
    }

    function onFilterChange(element: HTMLInputElement, params: { value: string }) {
        const newList = filterList(params.value);
        setFilteredList(newList);
    }

    if (filterList.length === 0) {
        return <></>;
    }

    return (
        <>
            {listIsFilterable && (
                <StyledContainer paddingBottom={40}>
                    <Grid container>
                        <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Select
                                label="Select"
                                type="bar"
                                theme="blueshort"
                                name="select"
                                value="A-K"
                                onChange={onFilterChange}
                            >
                                <OptionGroup>
                                    <Option value="A-K">A-K</Option>
                                    <Option value="L-Z">L-Z</Option>
                                </OptionGroup>
                            </Select>
                        </Grid>
                    </Grid>
                </StyledContainer>
            )}
            <MediaWithTextSingle isTwelveColumn={false} theme="partner" items={filteredList}></MediaWithTextSingle>
        </>
    );
}
