import { PRICING } from '@/constants/pulse/pricing';

export interface ResponseData {
    offer: string;
    terms: string;
    tagline: string;
    tier: string;
    url: string;
    offerName?: string;
}

const offer2for3AllAccess = {
    offer: `${PRICING.offer2for3}`,
    terms: `${PRICING.terms2for3AllAccess}`,
    tagline: `${PRICING.taglineAllAccess}`,
    tier: `${PRICING.tierAllAccess}`,
    url: `${PRICING.url2for3AllAccess}`,
    offerName: `${PRICING.offerKey2for3AllAccess}`,
};

const offer5for12AllAccess = {
    offer: `${PRICING.offer5for12}`,
    terms: `${PRICING.terms5for12}`,
    tagline: `${PRICING.taglineAllAccess}`,
    tier: `${PRICING.tierAllAccess}`,
    url: `${PRICING.url5for12AllAccess}`,
    offerName: `${PRICING.offerKey5for12AllAccess}`,
};

const offer4for12AllAccess = {
    offer: `${PRICING.offer4for12}`,
    terms: `${PRICING.terms4for12}`,
    tagline: `${PRICING.taglineAllAccess}`,
    tier: `${PRICING.tierAllAccess}`,
    url: `${PRICING.url4for12AllAccess}`,
    offerName: `${PRICING.offerKey4for12AllAccess}`,
};

export const outputMap: Record<string, ResponseData> = {
    FST_TRIAL_Onboarding_sports: offer2for3AllAccess,
    FST_TRIAL_Onboarding_general: offer2for3AllAccess,
    FST_TRIAL_Onboarding_pop: offer2for3AllAccess,
    FST_TRIAL_Onboarding_country: offer2for3AllAccess,
    FST_TRIAL_Onboarding_news: offer2for3AllAccess,
    FST_TRIAL_InTrial_sports: offer2for3AllAccess,
    FST_TRIAL_InTrial_general: offer2for3AllAccess,
    FST_TRIAL_InTrial_pop: offer2for3AllAccess,
    FST_TRIAL_InTrial_country: offer2for3AllAccess,
    FST_TRIAL_InTrial_news: offer2for3AllAccess,
    FST_TRIAL_Winback_sports: offer5for12AllAccess,
    FST_TRIAL_Winback_general: offer5for12AllAccess,
    FST_TRIAL_Winback_pop: offer5for12AllAccess,
    FST_TRIAL_Winback_country: offer5for12AllAccess,
    FST_TRIAL_Winback_news: offer5for12AllAccess,
    PRIOR_SLFPY_Onboarding_sports: offer2for3AllAccess,
    PRIOR_SLFPY_Onboarding_general: offer2for3AllAccess,
    PRIOR_SLFPY_Onboarding_pop: offer2for3AllAccess,
    PRIOR_SLFPY_Onboarding_country: offer2for3AllAccess,
    PRIOR_SLFPY_Onboarding_news: offer2for3AllAccess,
    PRIOR_SLFPY_InTrial_sports: offer2for3AllAccess,
    PRIOR_SLFPY_InTrial_general: offer2for3AllAccess,
    PRIOR_SLFPY_InTrial_pop: offer2for3AllAccess,
    PRIOR_SLFPY_InTrial_country: offer2for3AllAccess,
    PRIOR_SLFPY_InTrial_news: offer2for3AllAccess,
    PRIOR_SLFPY_Winback_sports: offer5for12AllAccess,
    PRIOR_SLFPY_Winback_general: offer5for12AllAccess,
    PRIOR_SLFPY_Winback_pop: offer5for12AllAccess,
    PRIOR_SLFPY_Winback_country: offer5for12AllAccess,
    PRIOR_SLFPY_Winback_news: offer5for12AllAccess,
    PRIOR_NON_CONV_Onboarding_sports: offer4for12AllAccess,
    PRIOR_NON_CONV_Onboarding_general: offer4for12AllAccess,
    PRIOR_NON_CONV_Onboarding_pop: offer4for12AllAccess,
    PRIOR_NON_CONV_Onboarding_country: offer4for12AllAccess,
    PRIOR_NON_CONV_Onboarding_news: offer4for12AllAccess,
    PRIOR_NON_CONV_InTrial_sports: offer4for12AllAccess,
    PRIOR_NON_CONV_InTrial_general: offer4for12AllAccess,
    PRIOR_NON_CONV_InTrial_pop: offer4for12AllAccess,
    PRIOR_NON_CONV_InTrial_country: offer4for12AllAccess,
    PRIOR_NON_CONV_InTrial_news: offer4for12AllAccess,
    PRIOR_NON_CONV_Winback_sports: offer4for12AllAccess,
    PRIOR_NON_CONV_Winback_general: offer4for12AllAccess,
    PRIOR_NON_CONV_Winback_pop: offer4for12AllAccess,
    PRIOR_NON_CONV_Winback_country: offer4for12AllAccess,
    PRIOR_NON_CONV_Winback_news: offer4for12AllAccess,
    UNK_Onboarding_sports: offer2for3AllAccess,
    UNK_Onboarding_general: offer2for3AllAccess,
    UNK_Onboarding_pop: offer2for3AllAccess,
    UNK_Onboarding_country: offer2for3AllAccess,
    UNK_Onboarding_news: offer2for3AllAccess,
    UNK_InTrial_sports: offer2for3AllAccess,
    UNK_InTrial_general: offer2for3AllAccess,
    UNK_InTrial_pop: offer2for3AllAccess,
    UNK_InTrial_country: offer2for3AllAccess,
    UNK_InTrial_news: offer2for3AllAccess,
    UNK_Winback_sports: offer2for3AllAccess,
    UNK_Winback_general: offer2for3AllAccess,
    UNK_Winback_pop: offer2for3AllAccess,
    UNK_Winback_country: offer2for3AllAccess,
    UNK_Winback_news: offer2for3AllAccess,
};
