'use client';
import IconCar from '@/assets/icons/icon-car.svg';
import dynamic from 'next/dynamic';
import IconDevices from '@/assets/icons/icon-devices.svg';
import cn from 'classnames';
import { PropsWithChildren, useState } from 'react';
import styles from '../comparisongrid.module.scss';
import { PlansData } from '../data/interfaces';
import { PlanGrid } from './PlanGrid';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
import { getBackgroundImage } from '@/shared-components/ImageWrapper/ImageWrapper';

interface PlanToggleProps {
    data: [PlansData, PlansData];
}

export default function PlanToggle({ data: [FIRST_TAB_DATA_SET, SECOND_TAB_DATA_SET] }: PlanToggleProps): JSX.Element {
    const [activeTab, setActiveTab] = useState<number>(0);

    // Temp markup to be updated soon
    return (
        <>
            <StyledContainer
                textColor="var(--white)"
                includeNavPadding={true}
                backgroundImageDesktop={getBackgroundImage({
                    src: 'https://www.siriusxm.com/content/dam/sxm-com/hero-background/graphics/comp-grid-bg-desktop.png',
                })}
                backgroundImageMobile={getBackgroundImage({
                    src: 'https://www.siriusxm.com/content/dam/sxm-com/hero-background/graphics/Plans-Hero-BG-D-DEX-58639-v1.jpg',
                })}
                backgroundGradientMobile="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 42.01%, rgba(0, 0, 0, 0.50) 91.84%)"
                backgroundGradientDesktop="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 42.01%, rgba(0, 0, 0, 0.50) 91.84%)"
                className={styles.hero}
            >
                <Grid container>
                    <Grid col={{ xs: 12 }}>
                        <Text tag="h1" variant="h1" className={styles.heroHeader}>
                            Popular Plans
                        </Text>
                        <Text tag="p" variant="subhead">
                            Enjoy the variety you&#39;re looking for, where and how you want to listen.
                        </Text>
                        <div
                            className={styles.tabsWrapper}
                            role="tablist"
                            aria-label="Toggle between satellite and streaming plans"
                        >
                            <button
                                onClick={() => setActiveTab(0)}
                                className={activeTab === 0 ? styles.buttonActive : ''}
                                id="satellite-tab"
                                role="tab"
                                aria-controls="satellite-panel"
                                aria-selected={activeTab === 0 ? true : false}
                            >
                                <IconCar />
                                <IconDevices />
                                Car + App
                            </button>
                            <button
                                onClick={() => setActiveTab(1)}
                                className={activeTab === 1 ? styles.buttonActive : ''}
                                id="streaming-tab"
                                role="tab"
                                aria-controls="streaming-panel"
                                aria-selected={activeTab === 1 ? true : false}
                            >
                                <IconDevices /> App Only
                            </button>
                            <div className={styles.tabAnimatedSwitch} />
                        </div>
                    </Grid>
                </Grid>
            </StyledContainer>

            <div className={styles.gridWrapper}>
                <div className={cn(styles.grids, { [styles.showRightItem]: activeTab === 0 })}>
                    <GridWrapper tab={1} activeTab={activeTab}>
                        <PlanGrid
                            plansData={FIRST_TAB_DATA_SET}
                            id={'streaming-panel'}
                            ariaLabelledby={'streaming-tab'}
                            activeTab={activeTab}
                        />
                    </GridWrapper>

                    <GridWrapper tab={0} activeTab={activeTab}>
                        <PlanGrid
                            plansData={SECOND_TAB_DATA_SET}
                            id={'satellite-panel'}
                            ariaLabelledby={'satellite-tab'}
                            activeTab={activeTab}
                        />
                    </GridWrapper>
                </div>
            </div>
        </>
    );
}

function GridWrapper({ children, tab, activeTab }: PropsWithChildren<{ tab: number; activeTab: number }>) {
    return (
        <Grid container className={cn(styles.gridItem, { [styles.active]: activeTab === tab })}>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <>{children}</>
            </Grid>
        </Grid>
    );
}
