import { ChannelFeedChannel } from './interfaces';
import dynamic from 'next/dynamic';
import styles from './channelguide.module.scss';
import { useContext } from 'react';
import { CGContext } from './ChannelGuideContext';
import { ChannelCardStripGroup } from '@/components/molecules/ChannelCardStripGroup';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

interface Props {
    channels: ChannelFeedChannel[];
    upsellLink?: SiteLink;
    display_listen_live: boolean;
}

export default function ChannelList({ channels, upsellLink, display_listen_live }: Props) {
    const context = useContext(CGContext);

    const cardStripChannelData = channels.map((channel) => {
        let listenLink = channel.deepLink || '';
        let listenText = 'listen live';
        let listenAria = `listen live to ${channel.displayName}`;

        if (!channel.availableToPackage && upsellLink) {
            listenLink = upsellLink.fields.href || '';
            listenText = upsellLink.fields.link_text || 'upgrade plan';
            listenAria = 'upgrade plan';
        }

        let shortDesc = channel.mediumDescription;
        if (channel.category === 'music' && channel.artistsYouHear.length > 0) {
            shortDesc = channel.artistsYouHear.join(', ');
        }

        let channelNumber = (
            <>{context.platform === 'sirius' ? channel.siriusChannelNumber : channel.xmChannelNumber}</>
        );
        if (channel.deliveryTypes.indexOf('satellite') === -1) {
            channelNumber = (
                <div className={styles.onlineOnly}>
                    Online
                    <br />
                    Only
                </div>
            );
        }

        return {
            radioImageUrl: channel.colorLogo,
            radioImageAlt: channel.displayName,
            title: channel.displayName,
            subTitle: channel.shortDescription,
            subTitleDetails: shortDesc,
            showAndScheduleLink: channel.vanityURL || '',
            showAndScheduleLinkText: 'Shows & Schedules',
            showsAndScheduleLinkAriaLabel: `shows and schedules for ${channel.displayName}`,
            listenLink: listenLink,
            listenText: listenText,
            listenLinkAriaLabel: listenAria,
            borderColor: channel.primaryColor,
            channelNumber: channelNumber,
            locked: !channel.availableToPackage,
            bannerText: channel.channelNotification,
            display_listen_live: display_listen_live,
        };
    });

    return (
        <Grid container>
            <Grid col={{ xs: 12 }}>
                <div className={styles.channelList}>
                    <div className={styles.groupWrapper}>
                        <ChannelCardStripGroup channels={cardStripChannelData} itemsClassName={styles.channelItem} />
                    </div>
                    <p className={styles.xlDescription}>
                        <span>XL</span>
                        May include frequent explicit language or mature programming. Call SiriusXM Listener Care at
                        1-800-967-2346 and ask about Family Friendly plans. All programming subject to change.
                    </p>
                </div>
            </Grid>
        </Grid>
    );
}
