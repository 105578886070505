'use client';
import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { Capabilities, Model, Platform } from '@/packages/sxm/ymm/types';
import YmmModal from 'de-sxm-reactlib-2/dist/components/molecules/YmmModal/YmmModal';
import { getCapabilities, getMakesForYear, getModelsForMake, getYMMCookies, setYMMCookies, getYearsFrom } from '@/packages/sxm/ymm';


interface YmmData {
    year: string;
    make: string;
    model: string;
    id: string;
    capabilities?: Capabilities;
}

interface PortableRadio {
    radio: string;
    platform: Platform;
}

interface YmmOptions {
    years: string[];
    makes: string[];
    models: Model[];
}

export const useYmm20 = (setShowYmm: (set: boolean) => void, openYmm: boolean) => {
    const [ymmOptions, setYmmOptions] = useState<YmmOptions>({
        years: [],
        makes: [],
        models: [],
    });
    const [ymm, setYmm] = useState<YmmData>({
        year: '',
        make: '',
        model: '',
        id: '',
    });
    const [ymmTextBar, setYmmTextBar] = useState<string>('Streaming & Most Radios');
    const [capabilities, setCapabilities] = useState<Capabilities>();
    const [portableRadio, setPortable] = useState<PortableRadio>({ radio: '', platform: 'siriusxm' });
    const [typeSelected, setTypeSelected] = useState<string>('online');


    useEffect(() => {
        const ymmCookies = getYMMCookies();
        const carData = ymmCookies.sxm_radio.split('|');
        const ymmBar = ymmCookies.ymm_bar.toLowerCase();

        if (carData.length > 1) {
            setYmm({
                year: carData[1],
                make: carData[2],
                model: carData[3],
                id: carData[4],
            });
            setTypeSelected('car');
        } else if (ymmBar.indexOf('streaming') >= 0) {
            setTypeSelected('online');
        } else if (ymmBar.indexOf('radio') >= 0) {
            setTypeSelected('radio');
        }

        ymmBar.split('|').forEach((cookie: string) => {
            if (cookie.includes('radio') && !cookie.includes('radios')) {
                setPortable({
                    radio: cookie,
                    platform: (ymmCookies.sxm_platform as Platform) || 'siriusxm',
                });
            }
        });
    }, []);

    useEffect(() => {
        if (ymm.id) {
            getCarCapabilities(ymm.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ymm.id]);

    useEffect(() => {
        const init = async () => {
            const ymmCookie = getYMMCookies();
            const car = ymmCookie.sxm_radio.split('|');
            await getYears();

            if (car[1]) {
                await getMakes(car[1]);
                await getModels(car[1], car[2]);
                setYmm({
                    year: car[1],
                    make: car[2],
                    model: car[3],
                    id: car[4],
                });
                setYmmTextBar(car[1] + ' ' + car[2] + ' ' + car[3]);
            } else {
                setYmmTextBar(ymmCookie.ymm_bar || 'Streaming & Most Radios');
            }
        };
        init();
    }, []);

    async function getYears() {
        const yearList = await getYearsFrom('2004');
        setYmmOptions((state) => ({
            ...state,
            years: yearList,
        }));
    }

    async function getMakes(customYear: string = ymm.year) {
        const makesList = await getMakesForYear(customYear);
        setYmmOptions((state) => ({
            ...state,
            makes: makesList,
        }));
    }

    async function getModels(customYear: string = ymm.year, customMake: string = ymm.make) {
        const modelsList = await getModelsForMake(customYear, customMake);
        setYmmOptions((state) => ({
            ...state,
            models: modelsList,
        }));
    }

    async function getCarCapabilities(customId: string = ymm.id) {
        const capabilities = await getCapabilities(customId);
        setCapabilities(capabilities);
    }

    const ymmModalProps: ComponentProps<typeof YmmModal> = useMemo(() => {
        const yearHandler = (_: HTMLInputElement | undefined, params: { value: string }) => {
            if (params.value && ymm.year !== params.value) {
                setYmm((state) => ({
                    ...state,
                    year: state.year !== '' ? state.year : params.value,
                    make: '',
                    model: '',
                    id: '',
                }));
                setYmmOptions((state) => ({
                    ...state,
                    makes: [],
                    models: [],
                }));
                getMakes(params.value);
            }
        };

        const makeHandler = (_: HTMLInputElement | undefined, params: { value: string }) => {
            if (params.value && ymm.make !== params.value) {
                setYmm((state) => ({
                    ...state,
                    make: params.value,
                    model: '',
                    id: '',
                }));
                setYmmOptions((state) => ({
                    ...state,
                    models: [],
                }));
                getModels(ymm.year, params.value);
            }
        };

        const modelHandler = (_: HTMLInputElement | undefined, params: { value: string; data?: Model }) => {
            if (params.value && params.data && ymm.model !== params.value) {
                setCapabilities(undefined);
                setYmm((state) => ({
                    ...state,
                    model: params.value,
                    id: params.data?.ymmComboId || '',
                }));
            }
        };

        const ymmFormProps = {
            yearHandler: yearHandler,
            makeHandler: makeHandler,
            modelHandler: modelHandler,
            ymmData: ymm,
            ymmOptions: ymmOptions,
        };

        const saveCookies: ComponentProps<typeof YmmModal>['onSubmit'] = (ymmData, radio, selectedType) => {
            let platform: Platform = 'siriusxm';
            let ymmText = '';

            if (selectedType === 'radio' && radio) {
                ymmText = radio.radio;
                platform = radio.platform;
            }

            if (selectedType === 'online') {
                ymmText = 'Streaming & Most Radios';
            }

            if (selectedType === 'car' && ymmData && capabilities) {
                if (capabilities.siriusRadio) {
                    platform = 'sirius';
                } else if (capabilities.xmRadio) {
                    platform = 'xm';
                }
                ymmText = `${ymmData.year} ${ymmData.make} ${ymmData.model}`;
            }

            const cookieValue = {
                sxm_radio: selectedType === 'car' ? `YMM|${ymm.year}|${ymm.make}|${ymm.model}|${ymm.id}` : '',
                sxm_platform: platform,
                inft_ymm: selectedType === 'car' ? ymm.capabilities?.infotainment.join('|') || '' : '',
                ymm_bar: ymmText,
                roadblockbypass: '',
            };

            setYMMCookies(cookieValue);
            setYmmTextBar(ymmText);

            if (window) {
                window.location.reload();
            }
            setShowYmm(false);
        };
        return {
            onClose: () => setShowYmm(false),
            portableValues: portableRadio,
            onSubmit: saveCookies,
            formProps: ymmFormProps,
            typeCheck: typeSelected,
        };
    }, [capabilities, portableRadio, setShowYmm, ymm, ymmOptions, typeSelected]);

    const keyUpListener = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                ymmModalProps.onClose?.();
            }
        },
        [ymmModalProps],
    );

    useEffect(() => {
        const page = document.getElementsByTagName('html').item(0);
        if (openYmm) {
            document.addEventListener('keyup', keyUpListener);
            if (page) {
                page.style.overflow = 'hidden';
            }
        } else {
            document.removeEventListener('keyup', keyUpListener);
            if (page) {
                page.style.overflow = 'auto';
            }
        }
    }, [keyUpListener, openYmm]);

    return {
        ymmTextBar,
        modal: <YmmModal {...ymmModalProps} />,
    };
};
