import { Genrelist } from './interfaces';
import dynamic from 'next/dynamic';
import styles from './channelguide.module.scss';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));

interface Props {
    genreList: Genrelist;
    onGenreChange: React.Dispatch<React.SetStateAction<string>>;
}

export default function ChannelHeader({ genreList, onGenreChange }: Props) {
    return (
        <div className={styles.stickySelect}>
            <Grid container>
                <Grid col={{ xs: 12 }}>
                    <Select
                        theme="purplefull"
                        type="bar"
                        name="genre"
                        value={genreList[0][0].value}
                        placeholder={genreList[0][0].label}
                        label="genre"
                        onChange={(e) => onGenreChange(e.value)}
                        dataCy="channel-guide-header-select"
                    >
                        <OptionGroup>
                            {genreList[0].map((genre) => (
                                <Option key={genre.value} value={genre.value}>
                                    {genre.label}
                                </Option>
                            ))}
                        </OptionGroup>
                        <>
                            {genreList[1] && (
                                <OptionGroup>
                                    {genreList[1].map((genre) => (
                                        <Option key={genre.value} value={genre.value}>
                                            {genre.label}
                                        </Option>
                                    ))}
                                </OptionGroup>
                            )}
                        </>
                    </Select>
                </Grid>
            </Grid>
        </div>
    );
}
