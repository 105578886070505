'use client';
import { useSearchParams, useParams } from 'next/navigation';
import styles from './simpleoffer.module.scss';
import OfferDetails from './OfferDetails';
import { PRICING } from '@/constants/pulse/pricing';
import { createOfferPayload, showConversionView } from '../GenerateOffer';

interface CallToActionProps {
    showShapes?: boolean;
    hasMobileViewOnConversion?: boolean;
    hasDesktopViewOnConversion?: boolean;
    isPrimaryCTA?: boolean;
}

const SimpleOffer = ({
    showShapes,
    hasMobileViewOnConversion,
    hasDesktopViewOnConversion,
    isPrimaryCTA,
}: CallToActionProps) => {
    const searchParams = useSearchParams();
    const priorRelationship = searchParams.get(PRICING.priorRelationshipParam);
    const journeyDaysParam = searchParams.get(PRICING.journeyDaysParam);

    const isConversion = showConversionView(searchParams);

    const styleClasses =
        isConversion && !isPrimaryCTA
            ? [
                  hasDesktopViewOnConversion && hasMobileViewOnConversion ? 'showOnConversionMobileAndDesktop' : '',
                  !hasDesktopViewOnConversion && !hasMobileViewOnConversion ? 'hideOnConversionMobileAndDesktop' : '',
                  hasDesktopViewOnConversion && !hasMobileViewOnConversion ? 'showOnConversionDesktopOnly' : '',
                  !hasDesktopViewOnConversion && hasMobileViewOnConversion ? 'showOnConversionMobileOnly' : '',
              ].filter(Boolean)
            : ['showOnConversionMobile', 'showOnConversionDesktop'];

    const params = useParams();
    const slug = params.slug[0];

    const offerData = createOfferPayload(priorRelationship ?? '', journeyDaysParam ?? '', slug);

    return (
        <>
            <div
                className={`
                ${styles.container} ${
                    showShapes !== undefined ? styles.hidden : null
                } ${styleClasses.map((className) => styles[className]).join(' ')} }
            `}
            >
                <div className={styles.uppershape}></div>
                <div className={styles.lowershape}></div>
                <OfferDetails offerData={offerData} isConversion={isConversion} pageSlug={slug} searchParams={searchParams} />
            </div>
        </>
    );
};

export default SimpleOffer;
