import { TimeZoneInfoHeader } from '@/components/atoms/TimeZoneInfoHeader';
import dynamic from 'next/dynamic';
import styles from './schedule.module.scss';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

interface ScheduleGroupProps {
    children: React.ReactElement | React.ReactElement[];
}

export default function ScheduleGroup(props: ScheduleGroupProps): JSX.Element {
    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ sm: 1, lg: 2 }}>
                <TimeZoneInfoHeader className={styles.scheduleLineupHeader} />
                <>{props.children}</>
            </Grid>
        </Grid>
    );
}
