import React from 'react';
import styles from './offermodalheromobile.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { PRICING } from '@/constants/pulse/pricing';

interface OfferModalHeroMobileProps {
    iconOptimizedWidth: number,
    iconOptimizedHeight: number,
    iconAlt: string,
    iconSrc: string,
    plan: string,
}

export const OfferModalHeroMobile = ({
    iconOptimizedWidth,
    iconOptimizedHeight,
    iconAlt,
    iconSrc,
    plan,   
}: OfferModalHeroMobileProps) => {
    return (
        <div className={styles.blueBackground}>
            <div className={styles.offerLegal}>
                <span className={styles.plan}>{plan}</span>
                <span><b>{PRICING.offerDetails}</b> {PRICING.apply}</span>
            </div>
            <div className={styles.modalOfferIcon}>
                <ImageWrapper
                    src={iconSrc}
                    alt={iconAlt}
                    className={styles.image}
                    optimizedWidth={iconOptimizedWidth}
                    optimizedHeight={iconOptimizedHeight}
                />
            </div>
        </div>
    );
}