const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
import dynamic from 'next/dynamic';
import { ButtonProps } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import { Dispatch, SetStateAction } from 'react';

interface ButtonToggleProps extends Partial<ButtonProps> {
    text: JSX.Element;
}

export default function getButtonToggle(
    isEnabled: boolean,
    setIsEnabled: Dispatch<SetStateAction<boolean>>,
    ariaControlsId?: string,
): (props: ButtonToggleProps) => JSX.Element {
    const ButtonToggle = (props: ButtonToggleProps) => (
        <Button
            onClick={() => setIsEnabled(!isEnabled)}
            theme="text"
            type="button"
            {...(!!ariaControlsId && isEnabled ? { ariaExpanded: true } : {})}
            ariaControls={ariaControlsId}
            {...props}
        />
    );

    return ButtonToggle;
}
