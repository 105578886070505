import { FilterTable } from '@/components/molecules/FilterTable';
import { metroAreaGroups, metroAreas } from './locationTrafficData';

export default function TrafficLocation(): JSX.Element {
    return (
        <FilterTable
            dataGroups={metroAreaGroups}
            dataList={metroAreas}
            headers={['Metro Area', 'State']}
            defaultFilter={Object.keys(metroAreaGroups)[0]}
        />
    );
}
