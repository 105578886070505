'use client';

import dynamic from 'next/dynamic';
import { PropsWithChildren } from 'react';
import styles from '../vegaplan.module.scss';
import { PlansData } from '../data/interfaces';
import { PlanGrid } from './PlanGrid';

const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
import { getBackgroundImage } from '@/shared-components/ImageWrapper/ImageWrapper';
import PlanDetailCard, { PlanDetailCardProps } from '@/components/atoms/PlanDetailCard/PlanDetailCard';
import PlanDetailCardGroup, {
    PlanDetailCardGroupProps,
} from '@/components/molecules/PlanDetailCardGroup/PlanDetailCardGroup';
import { Link } from '@/components/atoms/Link';

interface PlanOptionProps {
    data: PlansData;
    planDetailCardProps: PlanDetailCardProps;
    planDetailCardGroupProps: PlanDetailCardGroupProps;
}

const FEES_TEXT: React.ReactNode = (
    <>
        See{' '}
        <Link href="#pageOfferDetails" className={styles.spanOffer}>
            Offer Details.
        </Link>
    </>
);

export default function PlanOption({
    data,
    planDetailCardProps,
    planDetailCardGroupProps,
}: PlanOptionProps): JSX.Element {
    return (
        <>
            <StyledContainer
                textColor="var(--white)"
                includeNavPadding={true}
                backgroundImageDesktop={getBackgroundImage({
                    src: 'https://www.siriusxm.com/content/dam/sxm-com/sales-flows/vega-plans-page/1336_294_gradient.png',
                })}
                backgroundImageMobile={getBackgroundImage({
                    src: 'https://www.siriusxm.com/content/dam/sxm-com/sales-flows/vega-plans-page/3684_1864_gradient.png',
                })}
                className={styles.hero}
            >
                <Grid container className={styles.heroContainer}>
                    <Grid col={{ xs: 12 }} className={styles.textContainer}>
                        <Text tag="h3" variant="h3" className={styles.heroCopy}>
                            Listen anywhere
                        </Text>
                        <Text tag="p" variant="subhead" className={styles.heroOffer}>
                            Plans start at <span className={styles.price}>$9.99/month </span> after promotion.{' '}
                            <span className={styles.offerDetailsDesktop}>{FEES_TEXT}</span>
                        </Text>
                    </Grid>
                </Grid>
            </StyledContainer>

            <div className={styles.gridWrapper}>
                <div className={styles.grids}>
                    <GridWrapper>
                        <PlanGrid plansData={data} />
                        <PlanDetailCard {...planDetailCardProps} />
                        <PlanDetailCardGroup {...planDetailCardGroupProps} />
                    </GridWrapper>
                </div>
            </div>
        </>
    );
}

function GridWrapper({ children }: PropsWithChildren) {
    return (
        <Grid container className={styles.gridContainer}>
            <Grid col={{ xs: 12 }} className={styles.gridItem}>
                <>{children}</>
            </Grid>
        </Grid>
    );
}