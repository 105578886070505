import NodeCache, { Key, Stats } from 'node-cache';
import logger from '@/packages/logger';
export const nodeCache = new NodeCache({ useClones: false });
// const environment = process.env.npm_config_env || process.env.NEXT_PUBLIC_ENV || 'sandbox.preview';
// const isDeliveryEnvironment: boolean = environment.indexOf('.cdn') >= 0

const cache = {
    get: <T>(key: Key): T | undefined => {
        return nodeCache.get<T>(key);
    },
    set: <T>(key: Key, value: T, ttl: number | string): boolean => {
        logger.info(`Cache refreshed [${key}, ${ttl}Sec]`);
        return nodeCache.set<T>(key, deepFreeze(value), ttl);
    },
    flushAll: () => nodeCache.flushAll(),
    has: (key: Key): boolean => nodeCache.has(key),
    del: (keys: Key | Key[]): number => nodeCache.del(keys),
    getStats: (): Stats => nodeCache.getStats(),
    keys: (): string[] => nodeCache.keys(),
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deepFreeze = (obj: any) => {
    Object.keys(obj).forEach((property) => {
        if (typeof obj[property] === 'object' && obj[property] !== null && !Object.isFrozen(obj[property])) {
            deepFreeze(obj[property]);
        }
    });
    return Object.freeze(obj);
};

export default cache;
