import styles from './toastmessage.module.scss';
import { toast } from 'react-toastify';
import BlueCheck from '@/assets/icons/icon-checkmark-circle-blue.svg';
import { PULSE } from '@/constants/pulse/pulse';

interface ToastMessageProps {
    message: string;
}

export const ToastMessage = ({message}: ToastMessageProps) => {
    return (
        <> 
            <div className={styles.toastBar}></div>
            <div className={styles.toastContent}>
                <header className={styles.toastHeading}>
                    <BlueCheck className={styles.toastIcon} alt='blue checkmark icon' />
                    <h2>{message}</h2>
                </header>
                <div className={styles.toastBody}> 
                    <p>{PULSE.emailToastBody}</p>
                </div>
            </div>           
        </>
    );
}

export const toastConfig = {
    position: "bottom-center" as const,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: styles.toast
};

export const showToast = (toastMessage: React.ReactNode) => {
    toast(toastMessage, toastConfig);
};