'use client';
import dynamic from 'next/dynamic';
import dayjs from 'dayjs';
import { Filter } from '@/components/atoms/Display';
import Schedule from '@/components/molecules/Schedule/Schedule';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
import ScheduleGroup from '@/components/molecules/Schedule/ScheduleGroup';
import EmptyTabsMessage from '@/components/contentful/ContextualComponent/Sports/EmptyTabsMessage';
import { useState } from 'react';

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface Props {
    shows?: SiteChannelShow[];
}
export default function ChannelSchedule({ shows }: Props): JSX.Element {
    const currentDayOfWeek = dayjs().format('dddd');
    const [selectedDay, setSelectedDay] = useState(currentDayOfWeek);
    const tempSchedules: {
        filterDay: string;
        startTime: dayjs.Dayjs;
        showName: string;
        description: string;
        isNowPlaying: boolean;
    }[] = [];
    const scheduleNodes: React.ReactElement[] = [];
    const seenTimes = new Set<string>();
    shows?.forEach((show: SiteChannelShow) => {
        show.show_schedules.forEach((daySchedules) => {
            daySchedules.day_schedules.forEach((daySchedule) => {
                tempSchedules.push({
                    filterDay: dayjs(daySchedule.start_time).format('dddd'),
                    startTime: dayjs(daySchedule.start_time),
                    showName: show.show_name,
                    description: show.description,
                    isNowPlaying: dayjs().isBetween(daySchedule.start_time, daySchedule.end_time),
                });
            });
        });
    });

    // sort the schedule array based on daySchedule.start_time
    tempSchedules.sort((a, b) => {
        return a.startTime.valueOf() - b.startTime.valueOf();
    });

    tempSchedules.forEach((schedule) => {
        const formattedStartTime =
            schedule.startTime.minute() === 0 ? schedule.startTime.format('h a') : schedule.startTime.format('h:mm a');
        const uniqueKey = `${schedule.filterDay}-${formattedStartTime}-${schedule.showName}`;
        if (!seenTimes.has(formattedStartTime + schedule.filterDay)) {
            scheduleNodes.push(
                <Filter
                    key={uniqueKey}
                    conditions={{ day: { type: 'exact', value: schedule.filterDay } }}
                    criteria={{ day: selectedDay }}
                >
                    <Schedule
                        scheduleDate={schedule.startTime.toDate()}
                        title={schedule.showName}
                        description={schedule.description}
                        isNowPlaying={schedule.isNowPlaying}
                    />
                </Filter>,
            );
            seenTimes.add(formattedStartTime + schedule.filterDay);
        }
    });

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = parseInt(dayjs().format('d'));
    const printDays = days.slice(today).concat(days.slice(0, today));
    return scheduleNodes.length ? (
        <>
            <StyledContainer paddingBottom={16}>
                <Grid container>
                    <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                        <Select
                            label="Select"
                            type="bar"
                            theme="purple"
                            name="select"
                            value={currentDayOfWeek}
                            placeholder={currentDayOfWeek}
                            onChange={(element: HTMLInputElement, { value }: { value: string }) =>
                                setSelectedDay(value)
                            }
                        >
                            <OptionGroup>
                                {printDays.map((day) => (
                                    <Option key={day} value={day}>
                                        {day}
                                    </Option>
                                ))}
                            </OptionGroup>
                        </Select>
                    </Grid>
                </Grid>
            </StyledContainer>
            <ScheduleGroup>{scheduleNodes}</ScheduleGroup>
        </>
    ) : (
        <EmptyTabsMessage />
    );
}
