'use client';
import styles from './stickyTitleWithListSection.module.scss';
import { ComponentProps, LegacyRef, useEffect, useRef, useState } from 'react';
import { useBoundingBox, useOnScreen } from '@/hooks';
import cn from 'classnames';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import List from 'de-sxm-reactlib/dist/components/atoms/List/List';

type ListData = ComponentProps<typeof List>;

export interface WhatYouGetSectionProps {
    stickyTitleYOffset?: number;
    title: React.ReactNode;
    body: React.ReactNode;
    listData: ListData;
}

const getListDataIconSizeOnBp = (listData: ListData, dimension: number, top: number) => {
    listData.items.map((item) => {
        const link = item.link;
        return {
            ...item,
            link,
        };
    });
    return {
        ...listData,
        markerIcon: {
            ...listData.markerIcon,
            width: dimension,
            height: dimension,
            top,
        },
    };
};

export default function WhatYouGetSection({ title, body, listData }: WhatYouGetSectionProps) {
    const rectRef = useRef<HTMLDivElement>(null);
    const isOnScreen = useOnScreen(rectRef);
    const [listRect, listRectRef] = useBoundingBox(5);
    const [headerRect, headerRectRef] = useBoundingBox(5);

    const [stickyTitle, setStickyTitle] = useState(false);
    const [stickyEnd, setStickyEnd] = useState(false);
    const [colorChange, setColorChange] = useState(false);
    const [listDataWithSizing, setListDataWithSizing] = useState(getListDataIconSizeOnBp(listData, 20, 4));

    useEffect(() => {
        if (isOnScreen && headerRect && headerRect.width > 768) {
            setStickyTitle(calcStickyTitleBreakpoint(headerRect));
            setStickyEnd(calcStickyEndBreakpoint());
            setColorChange(headerRect.bottom <= 110);
        }
        if (headerRect && headerRect.width < 768) {
            setStickyTitle(false);
            setStickyEnd(false);
            setColorChange(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnScreen, headerRect, listRect]);

    useEffect(() => {
        if (isOnScreen && headerRect && headerRect.width > 768) {
            setListDataWithSizing(getListDataIconSizeOnBp(listData, 30, 11));
        }
        if (headerRect && headerRect.width < 768) {
            setListDataWithSizing(getListDataIconSizeOnBp(listData, 20, 4));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRect, headerRect]);

    const calcPercentage = (numerator: number, denominator: number) => (100 * numerator) / denominator;

    const calcStickyTitleBreakpoint = (headerRectangular: DOMRect) =>
        calcPercentage(headerRectangular.bottom, headerRectangular.height) <= 110 &&
        calcPercentage(headerRectangular.bottom, headerRectangular.height) >= -800;

    const calcStickyEndBreakpoint = () =>
        !listRect ? false : listRect.bottom <= listRect?.height - listRect?.height * 0.8;

    const stickyClasses = cn(styles.stickyHeader, {
        [styles.sticky]: stickyTitle,
        [styles.stickyEnd]: stickyEnd,
        [styles.colorChange]: colorChange,
    });

    const style: React.CSSProperties = {
        ...({ '--sticky-end-pixels': `-${(listRect?.height || 1) * 0.96}px` } as React.CSSProperties),
    };

    return (
        <div ref={rectRef}>
            <div style={style} ref={headerRectRef as LegacyRef<HTMLDivElement> | undefined} className={styles.header}>
                <Grid align="center">
                    <Grid col={{ sm: 12 }} container={true}>
                        <Grid col={{ sm: 12, md: 5 }}>
                            <Text tag="h2" variant="h2" className={stickyClasses}>
                                {title}
                            </Text>
                        </Grid>
                        <Grid col={{ sm: 12, md: 6 }}>
                            <Text tag="p">{body}</Text>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <div ref={listRectRef as LegacyRef<HTMLDivElement> | undefined} className={styles.listSection}>
                <Grid container={true}>
                    <Grid col={{ sm: 12, md: 6 }} start={{ md: 6 }}>
                        <List {...listDataWithSizing} />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
