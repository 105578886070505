import { useRef } from 'react';

function smoothHorizontalScrollTo(element: HTMLElement, targetX: number, duration: number = 300) {
    if (!element) return;

    const startLeft = element.scrollLeft;
    const changeInScroll = targetX - startLeft;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, startLeft, changeInScroll, duration);
        element.scrollLeft = val;
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };

    animateScroll();
}

function easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
}

export default function useScrollable(ref: React.RefObject<HTMLDivElement>) {
    const scrollData = useRef<{ startX: number; startLeft: number }>({
        startX: 0,
        startLeft: 0,
    });

    const scrollStart = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (ref.current) {
            scrollData.current.startX = 'touches' in e ? e.touches[0].clientX : e.pageX;
            scrollData.current.startLeft = ref.current.scrollLeft;
        }
    };

    const scrolling = (e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (scrollData.current.startX !== 0) {
            const currentX = 'touches' in e ? e.touches[0].clientX : e.pageX;
            if (ref.current) {
                ref.current.scrollLeft = scrollData.current.startLeft - (currentX - scrollData.current.startX);
            }
        }
    };

    const scrollEnd = () => {
        if (scrollData.current.startX !== 0) {
            if (ref.current) {
                scrollData.current.startX = 0;
            }
        }
    };

    const centerScroll = (element: HTMLElement | null) => {
        if (ref.current && element) {
            const targetScrollLeft = element.offsetLeft + element.offsetWidth / 2 - ref.current.offsetWidth / 2;
            smoothHorizontalScrollTo(ref.current, targetScrollLeft);
        }
    };

    return { scrollStart, scrolling, scrollEnd, centerScroll };
}
