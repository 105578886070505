'use client';
import dynamic from 'next/dynamic';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const InvalidFeedback = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/InvalidFeedback/InvalidFeedback'),
);
import DisplayModal from 'de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal';
import styles from './infotainmentmodal.module.scss';
import { useEffect, useRef, useState } from 'react';
import { YMM } from '..';
import { YMMData } from '../Form/interfaces';
import { setYMMCookies, getYMMCookies } from '@/packages/sxm/ymm';
import { Platform } from '@/packages/sxm/ymm/types';
import { useClickOutside } from '@/hooks';
import FocusTrap from 'focus-trap-react';

interface Props {
    open: boolean;
    onClose: () => void;
}

interface ModalStrings {
    titles: Record<string, string>;
    infoTexts: Record<string, string>;
    buttonTexts: Record<string, string>;
}

const modalStrings: ModalStrings = {
    titles: {
        default: 'What type of vehicle do you have?',
        onlyStreaming: 'You have streaming only.',
        noSupport: "Your vehicle doesn't support any of our Infotainment plans.",
    },
    infoTexts: {
        default:
            'We can show you relevant channel information, content and subscription options specific to the factory-equipped radio in your vehicle.',
        onlyStreaming:
            'Unfortunately, SiriusXM Infotainment is only available on vehicle hardware. You may preview all of our Infotainment offers, but you will be unable to purchase a package.',
        noSupport:
            "Unfortunately, SiriusXM Infotainment is not available on your vehicle's hardware. You may preview all of our Infotainment offers, but you will be unable to purchase a package.",
    },
    buttonTexts: {
        default: 'SHOW MY PACKAGES',
        onlyStreaming: 'SHOW ALL PACKAGES',
        noSupport: 'SHOW ALL PACKAGES',
    },
};

export function InfotainmentModal(props: Props) {
    const [modalPage, setModalPage] = useState('default');
    const [ymmValid, setYMMValid] = useState<FieldValidityState>('untouched');
    const [ymm, setYMMData] = useState<YMMData>({
        year: '',
        make: '',
        model: '',
        id: '',
    });
    const [focusPause, setFocusPause] = useState(false);

    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-consent-sdk', // One Trust cookie consent banner
        '.LPMcontainer', // Live Person chat invocation buttons
        '#lpChat', // Interactive Live Person chat window
    ].join(', ');

    useClickOutside(ref, {}, (state, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);
                if (closest) {
                    targetRef.current = target;
                }
                setFocusPause(!!closest);
            }
        }
    });

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    useEffect(() => {
        const ymmCookies = getYMMCookies();
        const carData = ymmCookies.sxm_radio.split('|');

        if (carData.length > 1) {
            setYMMData({
                year: carData[1],
                make: carData[2],
                model: carData[3],
                id: carData[4],
            });
            props.onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function carReady(ymm: YMMData) {
        setYMMData(ymm);
    }

    function saveCookies(event: React.MouseEvent<HTMLButtonElement>) {
        let platform: Platform = 'siriusxm';

        if (modalPage === 'noSupport') {
            props.onClose();

            return;
        }

        if (!ymm.id) {
            setYMMValid('invalid');
            return;
        } else {
            setYMMValid('valid');
        }

        if (ymm && ymm.capabilities) {
            if (ymm.capabilities.siriusRadio) {
                platform = 'sirius';
            } else if (ymm.capabilities.xmRadio) {
                platform = 'xm';
            }
        }

        setYMMCookies({
            sxm_radio: `YMM|${ymm.year}|${ymm.make}|${ymm.model}|${ymm.id}`,
            sxm_platform: platform,
            inft_ymm: ymm.capabilities?.infotainment.join('|') || '',
            ymm_bar: '', // TODO
            roadblockbypass: '', // TODO
        });

        if (
            ymm &&
            !ymm.capabilities?.travelLinkFuel &&
            !ymm.capabilities?.localForecast &&
            !ymm.capabilities?.travelLinkMovies &&
            !ymm.capabilities?.navTraffic &&
            !ymm.capabilities?.navWeather &&
            !ymm.capabilities?.travelLinkParking &&
            !ymm.capabilities?.siriusxmTraffic &&
            !ymm.capabilities?.travelLinkSports &&
            !ymm.capabilities?.travelLinkStocks &&
            !ymm.capabilities?.trafficPlus &&
            !ymm.capabilities?.travelLinkWeather
        ) {
            setModalPage('noSupport');
            event.currentTarget.blur();
            return;
        }

        if (window && ymm.capabilities) {
            window.location.reload();
        }
    }

    return (
        <>
            <FocusTrap active={props.open} paused={focusPause}>
                <DisplayModal
                    ref={ref}
                    title="Vehicle Lookup"
                    onClose={props.onClose}
                    open={props.open}
                    className={styles.modal}
                    pauseFocus
                >
                    <Text tag="h4" className={styles.lpHeading}>
                        {modalStrings.titles[modalPage]}
                    </Text>

                    {modalPage === 'noSupport' && (
                        <div className={styles.vehicleInfoWrapper}>
                            <Text tag="p">Your vehicle info:</Text>
                            <Text tag="p">{`${ymm.year} ${ymm.make} ${ymm.model}`}</Text>
                        </div>
                    )}

                    <Text tag="p">{modalStrings.infoTexts[modalPage]}</Text>

                    {modalPage === 'default' ? (
                        <div className={styles.ymmForm}>
                            <YMM.Form theme="standard" onCarReady={carReady} reset={props.open} />
                            <InvalidFeedback className={styles.invalidFeedback} validState={ymmValid}>
                                Year, make and model required
                            </InvalidFeedback>
                        </div>
                    ) : null}

                    <Button
                        theme="primary"
                        type="button"
                        text={modalStrings.buttonTexts[modalPage]}
                        buttonSize="full-width"
                        onClick={saveCookies}
                        className={styles.buttons}
                        disabled={!ymm.capabilities}
                        dataCy="molecules-ymm-infotainment-modal-show-packages-button"
                    />
                    {modalPage === 'onlyStreaming' && (
                        <Button
                            theme="secondary"
                            type="button"
                            text="CHANGE VEHICLE INFO"
                            buttonSize="full-width"
                            className={styles.buttons}
                            onClick={() => {
                                setModalPage('default');
                            }}
                        />
                    )}
                    {modalPage === 'default' && (
                        <Button
                            theme="text"
                            className={styles.textButton}
                            type="button"
                            text="Only have a streaming account?"
                            buttonSize="full-width"
                            onClick={() => {
                                setModalPage('onlyStreaming');
                            }}
                        />
                    )}
                    {modalPage === 'noSupport' && (
                        <Button
                            theme="text-button"
                            type="button"
                            text="CHANGE VEHICLE INFO"
                            buttonSize="full-width"
                            className={styles.noSupport}
                            onClick={() => {
                                setModalPage('default');
                            }}
                        />
                    )}
                </DisplayModal>
            </FocusTrap>
        </>
    );
}
