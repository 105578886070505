'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import IconAudioPlayButtonFilledShadow from '@/assets/icons/icon-audio-play-button-filled-shadow.svg';
import IconExternalLink from '@/assets/icons/icon-external-link.svg';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const ShadowBox = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/ShadowBox/ShadowBox'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const YoutubeVideo = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Media/YoutubeVideo/YoutubeVideo'));
import Modal from 'de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal';
import styles from './featuredcontent.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { useClickOutside } from '@/hooks';
import FocusTrap from 'focus-trap-react';

enum Variant {
    pingPong = 'pingpong',
}

export interface FeaturedContentItemProp {
    link?: Link;
    headerText?: string;
    bodyText: React.ReactNode | React.ReactNode[];
    image: ImageProps;
    video?: {
        name: string;
        youtubeId: string;
    };
    modalTitle?: string;
    variant?: Variant | string;
    itemIndex?: number;
}

interface FeaturedContentProps {
    items: FeaturedContentItemProp[];
    variant?: Variant | string;
}

export default function FeaturedContent({ items, variant }: FeaturedContentProps): JSX.Element {
    return (
        <div className={!Boolean(variant === Variant.pingPong) ? styles.clipContainer : styles.pingPongClipContainer}>
            {items.map((item, index) => (
                <FeaturedContentItem key={index} {...item} variant={variant} itemIndex={index} />
            ))}
        </div>
    );
}

function FeaturedContentItem({
    link,
    headerText,
    bodyText,
    image,
    video,
    modalTitle = '',
    variant,
    itemIndex = 0,
}: FeaturedContentItemProp) {
    const [modalOpen, setModalOpen] = useState(false);
    const [videoKey, setVideoKey] = useState(Date.now());
    const [focusPause, setFocusPause] = useState(false);

    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-consent-sdk', // One Trust cookie consent banner
        '.LPMcontainer', // Live Person chat invocation buttons
        '#lpChat', // Interactive Live Person chat window
    ].join(', ');

    useClickOutside(ref, {}, (state, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);
                if (closest) {
                    targetRef.current = target;
                }
                setFocusPause(!!closest);
            }
        }
    });

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    const isPingPong = Boolean(variant === Variant.pingPong);
    const ariaLabel = `Open ${video?.name || link?.aria_label || headerText || ''} video in ${
        video ? 'a dialog' : 'player'
    }`;
    const onButtonClick = () => setModalOpen(true);
    const actionData = video ? { onClick: onButtonClick } : { href: link?.href || '' };

    const onCloseModal = () => {
        setModalOpen(false);
        setVideoKey(Date.now());
    };

    const playButtonPingPongStyles = cn(styles.pingPongPlayButton, {
        [styles.leftAlign]: itemIndex % 2 !== 0,
    });

    const calculateImageDivOrder = (index: number) => {
        return index % 2 === 0 ? index * 2 : index * 2 + 1;
    };

    const calculateTextDivOrder = (index: number) => {
        return index % 2 !== 0 ? index * 2 : index * 2 + 1;
    };

    const content = !isPingPong ? (
        <ShadowBox targetClass={styles.item} animateOnHoverDesktop animateOnHoverMobile>
            <Button
                theme="text"
                type="button"
                className={styles.itemContainerbutton}
                ariaLabel={ariaLabel}
                targetBlank={link?.link_target === '_blank'}
                {...actionData}
                text={
                    <>
                        {(image.source || video) && (
                            <div className={styles.imageAbsolute}>
                                {image.source && (
                                    <ImageWrapper
                                        // optimizedWidth={image.width}
                                        // optimizedHeight={image.height}
                                        src={image.source}
                                        alt={image.altText}
                                    />
                                )}
                                {video && <IconAudioPlayButtonFilledShadow className={styles.icon} />}
                            </div>
                        )}
                        <div className={styles.clipContent}>
                            {!video && link && (
                                <>
                                    {link.link_text && (
                                        <Text className={cn(styles.linkLike, 'book')} tag="p">
                                            {link.link_text}
                                        </Text>
                                    )}
                                    <IconExternalLink className={styles.arrow} />
                                </>
                            )}

                            {headerText && (
                                <Text tag="p" variant="h5" className={styles.header}>
                                    {headerText}
                                </Text>
                            )}

                            {bodyText && (
                                <Text tag="p" className="text-color-gray-dark book">
                                    {bodyText}
                                </Text>
                            )}
                        </div>
                    </>
                }
            />
        </ShadowBox>
    ) : (
        <>
            <div
                className={styles.pingPongImageAbsolute}
                style={{ '--imageWrapperOrder': calculateImageDivOrder(itemIndex) } as CSSProperties}
            >
                {image.source && (
                    <ImageWrapper //optimizedWidth={image.width} optimizedHeight={image.height}
                        src={image.source}
                        alt={image.altText}
                        className={styles.imageStyles}
                    />
                )}

                {(video || link) && (
                    <Button
                        theme="text"
                        type="button"
                        className={playButtonPingPongStyles}
                        ariaLabel={ariaLabel}
                        targetBlank={link?.link_target === '_blank'}
                        {...actionData}
                        text={<IconAudioPlayButtonFilledShadow className={styles.pingPongPlayButtonIcon} />}
                        dataCy="molecules-featured-content-play-button"
                    />
                )}
            </div>
            <div
                className={styles.clipContentWrapper}
                style={{ '--textWrapperOrder': calculateTextDivOrder(itemIndex) } as CSSProperties}
            >
                <div className={styles.clipContent}>
                    {!video && link && (
                        <>
                            {link.link_text && (
                                <Text className={cn(styles.linkLike, 'book')} tag="p">
                                    {link.link_text}
                                </Text>
                            )}
                            <IconExternalLink className={styles.arrow} />
                        </>
                    )}

                    {headerText && (
                        <Text tag="p" variant="h4" className={styles.header}>
                            {headerText}
                        </Text>
                    )}

                    {bodyText && (
                        <Text tag="p" className={`text-color-black book ${styles.bodyText}`}>
                            {bodyText}
                        </Text>
                    )}
                </div>
            </div>
        </>
    );

    return (
        <>
            {video?.youtubeId && (
                <FocusTrap active={modalOpen} paused={focusPause}>
                    <Modal ref={ref} title={modalTitle} open={modalOpen} onClose={onCloseModal}>
                        <YoutubeVideo key={videoKey} videoId={video.youtubeId} title={video.name} />
                    </Modal>
                </FocusTrap>
            )}
            {content}
        </>
    );
}
