'use client';

import { useCallback, useState } from 'react';
import { YMM } from '../molecules/YMM';
import { YMMType } from '../molecules/YMM/interfaces';
import { ObjectType } from 'typescript';
import { SelectProps } from 'de-sxm-reactlib/dist/components/atoms/Inputs/Select';
import { useYmm20 } from '@/hooks/useYmm2.0';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import styles from './navYmm.module.scss';

interface Props {
    ymmType?: YMMType;
    selectTheme?: SelectProps<ObjectType>['theme'];
}

export default function NavYMM({ ymmType }: Props) {
    const [showYmm, setShowYmm] = useState(false);
    const { ymmTextBar, modal } = useYmm20(
        useCallback((set) => setShowYmm(set), []),
        showYmm,
    );

    const isInfotainment = ymmType && ymmType === YMMType.infotainment;
    const [open, setOpen] = useState(isInfotainment ?? false);
    const prefix = isInfotainment ? 'Showing infotainment options for ' : undefined;
    const initial = isInfotainment ? <b>vehicles with SiriusXM radios</b> : undefined;
    const content = <YMM.InfotainmentModal open={open} onClose={() => setOpen(false)} />;

    return (
        <>
            {ymmType === YMMType.infotainment && (
                <>
                    {ymmType && (
                        <YMM.Trigger onClick={() => setOpen(true)} prefixString={prefix} initialText={initial} />
                    )}
                    {content}
                </>
            )}
            {ymmType === YMMType.standard && (
                <>
                    <Grid container>
                        <Grid className={styles.container} col={{ sm: 12 }}>
                            <span className={styles.ymmTextWrapper}>
                                Showing channels for my
                                <span className={styles.selection}> {ymmTextBar}</span>.
                                <a role="button" onClick={() => setShowYmm(true)}>
                                    Edit
                                </a>
                            </span>
                        </Grid>
                    </Grid>

                    {showYmm && modal}
                </>
            )}
        </>
    );
}
