'use client';
import React from 'react';
import cn from 'classnames';
import { useParams, useSearchParams } from 'next/navigation';
import styles from './pricing.module.scss';
import { PRICING } from '@/constants/pulse/pricing';
import { createOfferPayload } from './GenerateOffer';
import Checkmark from '@/assets/icons/icon-checkmark-circle.svg';
import WhiteArrow from '@/assets/icons/icon-white-arrow.svg';
import StellaMobile from '@/assets/icons/icon-stella-play.svg';
import StellaDesktop from '@/assets/icons/icon-stella-face-right.svg';
import Link from '@/components/atoms/Link/Link';
import { buildAppLinkUrlAllParams } from '@/packages/pulse';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface PricingHeaderProps {
    title: string;
    subTitle: string;
}

const PricingHeader = ({ title, subTitle }: PricingHeaderProps) => (
    <div className={styles.pricing_header}>
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{subTitle}</span>
    </div>
);

interface PlanDetailsProps {
    planType: string;
    tier: string;
    offer: string;
    terms: string;
}

const PlanDetails = ({ planType, tier, offer, terms }: PlanDetailsProps) => (
    <div className={styles.details}>
        <div className={styles.title_box}>
            <span className={styles.title_first_line}>{planType}</span>
            <span className={styles.title_second_line}>{tier}</span>
        </div>
        <div className={styles.subtitle_box}>
            <div className={styles.pricing_box}>
                <span id="planDescription" className={styles.offer}>
                    {offer}
                </span>
                <span id="postTrialPricing" className={styles.extended_terms}>
                    {terms} {PRICING.see} <span className={styles.bold_text}>{PRICING.offerDetails}</span>{' '}
                    {PRICING.below}
                </span>
            </div>
            <div className={styles.best_plan_box}>
                <span className={styles.best_plan_text}>{PRICING.bestPlanText}</span>
            </div>
        </div>
    </div>
);

interface BenefitsProps {
    featuresList: { feature: string }[];
}

const Benefits = ({ featuresList }: BenefitsProps) => (
    <div className={styles.benefits_container}>
        {featuresList.map((feature, index) => (
            <div key={index} className={cn(styles.benefit_container, { [styles.benefit_extra_margin]: index === 0 })}>
                <Checkmark className={styles.benefit_icon} />
                <span className={styles.benefit}>{feature.feature}</span>
            </div>
        ))}
    </div>
);

const Pricing = () => {
    const searchParams = useSearchParams();
    const priorRelationship = searchParams.get(PRICING.priorRelationshipParam);
    const journeyDaysParam = searchParams.get(PRICING.journeyDaysParam);

    const params = useParams();
    const slug = params.slug[0];

    const {
        tierAllAccess,
        tierStandardVega,
        titleAllAccess,
        allAccessFeaturesList,
        standardVegaFeaturesList,
        standardVegaFootnote,
        titleStandardVega,
        descriptionAllAccess,
        descriptionStandardVega,
        linkTextAllAccess,
        linkTextStandardVega,
        planType,
        backgroundAltText,
        arrowAltText,
    } = PRICING;

    const offerData = createOfferPayload(priorRelationship ?? '', journeyDaysParam ?? '', slug);

    const { tier, offer, terms, url } = offerData;
    const featuresList = tier === tierAllAccess ? allAccessFeaturesList : standardVegaFeaturesList;
    const title = tier === tierAllAccess ? titleAllAccess : titleStandardVega;
    const subTitle = tier === tierAllAccess ? descriptionAllAccess : descriptionStandardVega;
    const linkText = tier === tierAllAccess ? linkTextAllAccess : linkTextStandardVega;

    const appLinkHref = buildAppLinkUrlAllParams(url, searchParams);

    return (
        <>
            <div className={styles.pricing_container}>
                <PricingHeader title={title} subTitle={subTitle} />
                <div id="wrapper" className={styles.pricing_wrapper}>
                    <div className={styles.sub_wrapper}>
                        <div className={styles.flex_center_stretch}>
                            <div className={styles.stella_desktop_container}>
                                <div className={styles.end_column}>
                                    <StellaDesktop className={styles.stella_desktop} />
                                </div>
                            </div>
                            <PlanDetails planType={planType} tier={tier} offer={offer} terms={terms} />
                            <div className={styles.stella_mobile_container}>
                                <StellaMobile className={styles.stella_mobile} />
                            </div>
                        </div>
                        <div className={styles.benefits_main_box}>
                            <div className={styles.crowd_mobile_container}>
                                <ImageWrapper
                                    src="/content/dam/sxm-com/pulse/Pricing-Mobile.png"
                                    alt={backgroundAltText}
                                    optimizedWidth={400}
                                    optimizedHeight={347}
                                    className={styles.crowd_mobile}
                                    loadingStrategy="lazy"
                                />
                            </div>
                            <ImageWrapper
                                src="/content/dam/sxm-com/pulse/Crowd.png"
                                alt={backgroundAltText}
                                optimizedWidth={1000}
                                optimizedHeight={400}
                                className={styles.crowd_desktop_container}
                                loadingStrategy="lazy"
                            />
                            <div className={styles.benefits_main_container}>
                                <Benefits featuresList={featuresList} />
                                {tier === tierStandardVega && (
                                    <div className={styles.footnote}>
                                        <span>*</span>
                                        {standardVegaFootnote}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.action}>
                    <Link
                        className={styles.appLinkHref}
                        href={appLinkHref}                     
                        inheritParams={true}
                    >
                        <span className={styles.action_text}>{linkText}</span>
                        <span className={styles.arrow}>
                            <WhiteArrow className={styles.arrow_image} alt={arrowAltText} />
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Pricing;
