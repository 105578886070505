import dynamic from 'next/dynamic';
import { switchCase } from '@/packages/switch';
import { PageContext } from '@/interfaces/types/PageContext';
const ComparisonGrid = dynamic(() => import('@/components/organisms/ComparisonGrid/ComparisonGrid'));
const FrequencyChannelFinder = dynamic(
    () => import('@/components/organisms/FrequencyChannelFinder/FrequencyChannelFinder'),
);
const TrafficCoverage = dynamic(() => import('@/components/contentful/StaticContent/TrafficCoverage/TrafficCoverage'));
const TrialEligibility = dynamic(() => import('@/components/organisms/TrialEligibility/TrialEligibility'));
const RenderRTE = dynamic(() => import('@/components/RenderRTE/RenderRTE'));
const ContactUsFormSection = dynamic(() => import('./ContactUsForm/ContactUsFormSection'));
const PartnerContactUsFormSection = dynamic(() => import('./PartnerContactUsForm/PartnerContactUsFormSection'));
const FreeTrialFormSection = dynamic(() => import('./FreeTrialForm/FreeTrialFormSection'));
const AutoDisclosure = dynamic(() => import('./AutoDisclosures'));
const ZagWidget = dynamic(() => import('./ZagWidget'));
const VegaPlan = dynamic(() => import('@/components/organisms/VegaPlan/VegaPlan'));
const Benefits = dynamic(() => import('@/components/contentful/StaticContent/pulse/Benefits'));
const Pricing = dynamic(() => import('@/components/contentful/StaticContent/pulse/Pricing'));
const SimpleOffer = dynamic(() => import('@/components/contentful/StaticContent/pulse/Pricing/SimpleOffer'));

interface Props {
    asset: SiteStaticContent;
    pageContext?: PageContext;
    asW2?: boolean;
}

type staticContentModuleNameType =
    | 'ComparisonGrid'
    | 'ContactUs Form'
    | 'Partner ContactUs Form'
    | 'Business Form'
    | 'FrequencyFinder'
    | 'Traffic Coverage'
    | 'Nav Traffic Coverage'
    | 'Traffic Plus Coverage'
    | 'Comparison Aviation Packages'
    | 'Comparison Marine Packages'
    | 'Comparison Garmin Aviation Packages'
    | 'Comparison Aviation Packages - ADS'
    | 'Comparison Garmin Aviation Packages - ADS'
    | 'AutoDisclosures'
    | 'ZagWidget'
    | 'Trial Eligibility'
    | 'VegaPlan'
    | 'Benefits'
    | 'Pricing'
    | 'SimpleOffer';

function matchStaticContentByModuleName(pageContext?: PageContext) {
    return switchCase<staticContentModuleNameType, JSX.Element>({
        ComparisonGrid: <ComparisonGrid variant="popular" />,
        'ContactUs Form': <ContactUsFormSection />,
        'Partner ContactUs Form': <PartnerContactUsFormSection />,
        'Business Form': <FreeTrialFormSection />,
        FrequencyFinder: <FrequencyChannelFinder />,
        'Traffic Coverage': <TrafficCoverage variant="TrafficCoverage" />,
        'Nav Traffic Coverage': <TrafficCoverage variant="NavTrafficCoverage" />,
        'Traffic Plus Coverage': <TrafficCoverage variant="TrafficPlusCoverage" />,
        'Comparison Aviation Packages': <ComparisonGrid variant="aviation" />,
        'Comparison Marine Packages': <ComparisonGrid variant="marine" />,
        'Comparison Garmin Aviation Packages': <ComparisonGrid variant="aviationGarmin" />,
        'Comparison Aviation Packages - ADS': <ComparisonGrid variant="aviationAdsB" />,
        'Comparison Garmin Aviation Packages - ADS': <ComparisonGrid variant="aviationGarminAdsB" />,
        AutoDisclosures: <AutoDisclosure />,
        ZagWidget: <ZagWidget />,
        'Trial Eligibility': (
            <TrialEligibility
                offerDetails={
                    <RenderRTE
                        pToSpan
                        contentfulDoc={pageContext?.offer_details}
                        asW2={pageContext?.properties?.web2styles === 'true'}
                    />
                }
            />
        ),
        VegaPlan: <VegaPlan />,
        Benefits: <Benefits />,
        Pricing: <Pricing />,
        SimpleOffer: <SimpleOffer />,
    })(<></>);
}

export default function StaticContent({ asset, pageContext }: Props): JSX.Element {
    const name = asset.fields.module_name as staticContentModuleNameType;
    return matchStaticContentByModuleName(pageContext)(name);
}
