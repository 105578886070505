'use client';
import dynamic from 'next/dynamic';

import { SportStrip } from '@/components/molecules/Sports/SportStrip';
import type { Details as Team } from '@/components/molecules/Sports/SportStrip';
import dayjs from 'dayjs';
import timeZoneExtension from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styles from './sportschedule.module.scss';
import { useMemo, useState } from 'react';
import { Game } from '@/packages/sxm/sports/games/GamesResponse';
import { DateOption, DateRange, GameList, GameProps } from './interfaces';
import filters from './league.config';
import { DynamicTableRow } from '@/components/molecules/DynamicTable/DynamicTable';
import { Link } from '@/components/atoms/Link';
import NoGames from './NoGames';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));

export default function LeagueSchedule({
    leagueAbbr,
    remind_me_logo,
    remind_me_title,
    reminder_url,
    games,
    dataCy,
}: GameProps) {
    dayjs.extend(advancedFormat);
    dayjs.extend(timeZoneExtension);
    dayjs.extend(isBetween);

    const today = dayjs();
    const sportType = leagueAbbr === 'soc' ? 'League' : 'Conference';

    const [dateOptions, conferenceOptions] = useMemo<[DateOption[], string[]]>(() => {
        const ranges: string[] = [];
        const dateOptions: DateOption[] = [];
        const conferenceOptions: string[] = [];

        games?.forEach((game: Game) => {
            if (game.homeLeagueAbbreviation) {
                const filterData = filters[leagueAbbr] || filters.default;

                if (filterData.conference) {
                    if (game.awayConfernceName && conferenceOptions.indexOf(game.awayConfernceName) === -1) {
                        conferenceOptions.push(game.awayConfernceName);
                    }

                    if (game.homeConfernceName && conferenceOptions.indexOf(game.homeConfernceName) === -1) {
                        conferenceOptions.push(game.homeConfernceName);
                    }

                    if (leagueAbbr === 'soc') {
                        if (game.awayTeamLeagueName && conferenceOptions.indexOf(game.awayTeamLeagueName) === -1) {
                            conferenceOptions.push(game.awayTeamLeagueName);
                        }

                        if (game.homeTeamLeagueName && conferenceOptions.indexOf(game.homeTeamLeagueName) === -1) {
                            conferenceOptions.push(game.homeTeamLeagueName);
                        }
                    }
                }

                const gameDate = dayjs(game.gameDate);
                let start = gameDate.startOf('day');
                let end = gameDate.endOf('day');
                let label = start.isSame(today, 'day') ? start.format('[Today], MMMM D') : start.format('dddd, MMMM D');

                if (filterData.schedule === 'monthly') {
                    start = gameDate.startOf('month');
                    end = gameDate.endOf('month');
                    label = start.format('MMMM');
                } else if (filterData.schedule === 'weekly') {
                    if (filterData.weekStart) {
                        if (start.day() < filterData.weekStart) {
                            start = start.day(-(filterData.weekStart - 1));
                        } else if (start.day() > filterData.weekStart) {
                            start = start.startOf('week');
                            start = start.day(filterData.weekStart);
                        }
                    }

                    start = start.startOf('day');
                    end = start.clone().add(6, 'day').endOf('day');
                    const endLabel = start.isSame(end, 'month') ? end.format('D') : end.format('MMM D');
                    label = start.format(`MMM D [- ${endLabel}]`);
                }

                const rangeIndex = `${start}${end}`;

                if (ranges.indexOf(rangeIndex) === -1) {
                    ranges.push(rangeIndex);

                    dateOptions.push({
                        range: {
                            start: start,
                            end: end,
                        },
                        value: label,
                        label: label,
                    });
                }
            }
        });

        return [dateOptions, conferenceOptions.sort()];
    }, [games, today, leagueAbbr]);

    const [dateRange, setDateRange] = useState<DateRange>(
        dateOptions[0] ? dateOptions[0].range : { start: dayjs().startOf('day'), end: dayjs().endOf('day') },
    );
    const [conference, setConference] = useState<string>('all');

    function renderChannelLink(
        deeplink: string | undefined,
        channelNumber: string | number | undefined,
        ariaLabel: string,
    ) {
        if (deeplink) {
            return (
                <Link href={deeplink} target="_blank" aria-label={ariaLabel}>
                    {channelNumber}
                </Link>
            );
        }
        return channelNumber || '-';
    }

    const [gameDates, dates] = useMemo<[GameList, string[]]>(() => {
        const gameDates: GameList = {};
        const dates: string[] = [];

        if (games) {
            for (let i = 0; i < games.length; i += 1) {
                const game = games[i] as Game;
                const index = game.gameDate || '';
                const gameDate = dayjs(game.gameDate);

                if (today.isAfter(game.endDateTime) || !gameDate.isBetween(dateRange.start, dateRange.end)) {
                    continue;
                }

                const filterData = filters[leagueAbbr] || filters.default;

                if (filterData.conference) {
                    if (
                        conference !== 'all' &&
                        conference !== game.awayConfernceName &&
                        conference !== game.homeConfernceName &&
                        conference !== game.awayTeamLeagueName &&
                        conference !== game.homeTeamLeagueName
                    ) {
                        continue;
                    }
                }

                if (!gameDates[index]) {
                    const dateStr = gameDate.format(`dddd, MMMM D • ${game.tbdTime ? '[TBD]' : 'h:mm A z'}`);
                    gameDates[index] = [
                        <Text
                            key={dateStr}
                            variant="small"
                            tag="h3"
                            className={styles.dateTitle}
                            data-cy="components-contextual-sports-date-title"
                        >
                            {dateStr}
                        </Text>,
                    ];
                    dates.push(index);
                }

                const homeTeam: Team = {
                    logo: game.homeLogo,
                    color: game.homeColor,
                    pageUrl: game.homeUrl,
                    playerUrl: game.homeStreamLink || game.nationalStreamLink,
                    name: `${game.homeCity} ${game.homeTeam}`,
                    location: game.homeCity || '',
                    channel:
                        game.homeStream ||
                        game.nationalStream ||
                        (game.homeInternetStream || game.nationalInternetStream ? 'ONLINE' : ''),
                };

                const awayTeam: Team = {
                    logo: game.awayLogo,
                    color: game.awayColor,
                    pageUrl: game.awayUrl,
                    playerUrl: game.awayStreamLink || game.nationalStreamLink,
                    name: `${game.awayCity} ${game.awayTeam}`,
                    location: game.awayCity || '',
                    channel:
                        game.awayStream ||
                        game.nationalStream ||
                        (game.awayInternetStream || game.nationalInternetStream ? 'ONLINE' : ''),
                };

                const moreWays: DynamicTableRow[] = [];

                if (game.awayStream || game.awayInternetStream) {
                    const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on away channel ${game.awayInternetStream}`;
                    moreWays.push({
                        rowLabel: 'Away',
                        items: [
                            game.awayStream || '-',
                            renderChannelLink(game.awayStreamLink, game.awayInternetStream, ariaLabel),
                        ],
                    });
                }

                if (game.homeStream || game.homeInternetStream) {
                    const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on home channel ${game.homeInternetStream}`;
                    moreWays.push({
                        rowLabel: 'Home',
                        items: [
                            game.homeStream || '-',
                            renderChannelLink(game.homeStreamLink, game.homeInternetStream, ariaLabel),
                        ],
                    });
                }

                if (game.nationalStream || game.nationalInternetStream) {
                    const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on national channel ${game.nationalInternetStream}`;
                    moreWays.push({
                        rowLabel: 'National',
                        items: [
                            game.nationalStream || '-',
                            renderChannelLink(game.nationalStreamLink, game.nationalInternetStream, ariaLabel),
                        ],
                    });
                }

                if (game.spanishStream || game.spanishInternetStream) {
                    const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on spanish channel ${game.spanishInternetStream}`;
                    moreWays.push({
                        rowLabel: 'Espanol',
                        items: [
                            game.spanishStream || '-',
                            renderChannelLink(game.spanishStreamLink, game.spanishInternetStream, ariaLabel),
                        ],
                    });
                }

                const isLive = dayjs().isBetween(game.gameDate, game.endDateTime);
                gameDates[index].push(
                    <li aria-label={`${homeTeam.name} vs ${awayTeam.name}`} key={i}>
                        <SportStrip
                            awayTeam={awayTeam}
                            homeTeam={homeTeam}
                            moreWays={moreWays}
                            isLive={isLive}
                            ariaLabel={`More Ways to listen ${awayTeam.name} @ ${homeTeam.name}`}
                        />
                    </li>,
                );
            }
        }

        return [gameDates, dates];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, dateRange, conference, leagueAbbr, today]);

    function dateSelectHandler(element: HTMLInputElement, { data }: { data?: DateRange }) {
        if (data) {
            setDateRange(data);
        }
    }

    function conferenceSelectHandler(element: HTMLInputElement) {
        setConference(element.value);
    }

    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <div className={styles.filterBar} data-cy={dataCy || 'games-grid'}>
                    {conferenceOptions.length > 0 && (
                        <Select
                            label={`filter games by ${sportType}`}
                            type="bar"
                            theme="purplemini"
                            value="all"
                            placeholder={`All ${sportType}s`}
                            name="confFilter"
                            onChange={conferenceSelectHandler}
                        >
                            <OptionGroup>
                                <Option value="all">{`All ${sportType}s`}</Option>
                                {conferenceOptions.map((option, i) => (
                                    <Option key={i} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </OptionGroup>
                        </Select>
                    )}
                    {dateOptions.length > 0 && (
                        <Select<DateRange>
                            label="filter games by date"
                            type="bar"
                            theme="purplemini"
                            value={dateOptions[0].value}
                            name="dateFilter"
                            onChange={dateSelectHandler}
                        >
                            <OptionGroup>
                                {dateOptions.map((option, i) => (
                                    <Option key={i} object={option.range} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                                <Option value="" disableOption className={styles.moreGames}>
                                    More events will be added as information becomes available.
                                </Option>
                            </OptionGroup>
                        </Select>
                    )}
                </div>

                {dates.sort().map((index) => (
                    <ul key={index} className={styles.listStylings}>
                        {gameDates[index]}
                    </ul>
                ))}

                <StyledContainer paddingTop={48}>
                    <NoGames logo={remind_me_logo} logoSize="small" title={remind_me_title || 'Never Miss a Moment'}>
                        <Link href={reminder_url} dataCy="contextualcomponent-league-addtocal-link">
                            Add to calender
                        </Link>
                    </NoGames>
                </StyledContainer>
            </Grid>
        </Grid>
    );
}
