'use client';
import { useSearchParams } from 'next/navigation';
import 'react-toastify/dist/ReactToastify.css';
import { useRef, useState, useEffect  } from 'react'
import { PRICING } from '@/constants/pulse/pricing';
import { useClickOutside } from '@/hooks';
import { ModalContentMap } from './ModalContentMap';
import styles from './pulsemodal.module.scss';
import WhiteArrowLarge from '@/assets/icons/icon-arrow-white-large.svg';

interface PulseModalProps {
    asset: SiteAssetGroup;
    displayType: 'AutomaticLaunch' | 'LaunchOnClick' | 'Fixed';
}

const PulseModal = ({ asset, displayType }: PulseModalProps) => {
    const modalData: SiteAsset[] = asset.fields.assets;

    const { 
        modalParam, 
        priorRelationshipParam, 
        offerParamValue,
        appParamValue,
    } = PRICING;

    const searchParams = useSearchParams();
    const modalValue = searchParams.get(modalParam);
    const priorRelationship = searchParams.get(priorRelationshipParam);
    const shouldCaptureEmail = (modalValue === offerParamValue || modalValue === appParamValue) && priorRelationship === 'UNK' ? true : false;

    const modalAsset = modalData.find(
        asset => asset.fields.name === modalValue
    );
   
    const [openModal, setOpenModal] = useState(false);
    const [focusPause, setFocusPause] = useState(false);

    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (displayType === 'AutomaticLaunch') {
            const timer = setTimeout(() => {
                setOpenModal(shouldCaptureEmail);
            }, 100);

            return () => clearTimeout(timer);
        }
    }, [displayType, shouldCaptureEmail]);

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-banner-sdk',
        '#onetrust-consent-sdk',
        '#onetrust-reject-all-handler',
        '.LPMcontainer',
        '.onetrust-close-btn-handler',
        '#lpChat',
        'nextjs-portal',
        '[class*="QSIWebResponsiveDialog"]',
        '#qualtricsModalContent'
    ].join(', ');

    useClickOutside(ref, {}, (_state, event) => {
        if (!event?.target) return;

        const target = event.target as HTMLElement;
        
        const isException = (element: HTMLElement): boolean => [
            element.matches(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR),
            element.tagName.toLowerCase() === 'nextjs-portal',
            element.className.includes('QSI'),
            element.parentElement ? isException(element.parentElement) : false
        ].some(Boolean);
    
        const shouldPause = isException(target);        
        setFocusPause(shouldPause);
    });
    
    const closeModal = async () => {
        await Promise.all([
            new Promise<void>((resolve) => {
                setOpenModal(false);
                resolve();
            }),
            new Promise<void>((resolve) => {
                setFocusPause(false);
                resolve();
            })
        ]);
    };

    // Lookup content and options from ModalContentMap using modalValue
    const captureContent = ModalContentMap[modalValue || 'default'];
    // If no content, query param, or modal asset show nothing
    if (!captureContent || !modalAsset) return null

    return (
        <>
            {/* Show a button which launches modal on click */}
            {displayType === 'LaunchOnClick' && (
                <div className={styles.buttonContainer} >
                    <button type="button" onClick={() => setOpenModal(shouldCaptureEmail)}>
                        <span className={styles.buttonText}>Provide an Email Address?</span>
                        <WhiteArrowLarge className={styles.buttonArrow} alt={'white arrow'} />
                    </button>
                </div>
            )}

            {/* 
                If Fixed, show an inline form (in the page and not modal)
                Else create a modal dialog for LaunchOnClick and AutomaticLaunch
            */}
            {displayType === 'Fixed' ? (
                captureContent.modalContent({
                    modalAsset: modalAsset,
                    isModal: false,
                    openModal,
                    setOpenModal,
                    focusPause,
                    closeModal,
                }, ref)
            ) : (
                captureContent.modalContent({
                    modalAsset: modalAsset,
                    isModal: true,
                    openModal,
                    setOpenModal,
                    focusPause,
                    closeModal,
                }, ref)
            )}
        </>
    );
}

export default PulseModal;