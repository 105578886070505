'use client';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import globalProperties from '../../../../properties/global';
import { jsonToQuery } from '@/packages/json';
import { useSearchParams } from 'next/navigation';
import styles from './search.module.scss';
import SearchRecord from './SearchRecord';
import cs from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const SearchBar = dynamic(() => import('de-sxm-reactlib/dist/components/molecules/SearchBar/SearchBar'));
const Tabs = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs'));
import { type TabItem } from 'de-sxm-reactlib/dist/components/atoms/Layout/Tabs';

interface TabsIndex {
    [index: string]: {
        label: string;
        count: number;
        displayLimit: number;
        results: React.ReactNode[];
    };
}

interface ShowResults {
    [index: string]: number;
}

const defaultResults = {
    errors: {},
    info: {
        page: {
            current_page: 1,
            num_pages: 1,
            per_page: 0,
            query: '',
            total_result_count: 0,
        },
    },
    record_count: -1,
    records: {
        page: [],
    },
};

const tabOrder = ['all', 'dotcom', 'blog', 'shop', 'support'];

export default function SearchSwiftpe(): JSX.Element {
    const [searchSubmitEvent, setSearchSubmitEvent] = useState<CustomEvent>();
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const [kg, setKG] = useState<React.ReactNode | undefined>(undefined);
    const [query, setQuery] = useState(searchParams.get('q') || '');
    const [predictiveValue, setPredictiveValue] = useState(searchParams.get('q') || '');
    const [predictiveResults, setPredictive] = useState<Link[]>([]);
    const [results, setResults] = useState<SwiftypeResult>(defaultResults);
    const [tabItems, setTabItems] = useState<TabItem[]>([]);
    const [showResults, setShowResults] = useState<ShowResults>({
        shop: 10,
        support: 10,
        blog: 10,
        dotcom: 10,
        all: 10,
    });

    function onSubmit(query: string) {
        router.push(`${pathname}?q=${query}`);
        setQuery(query);
    }

    async function getPredictive(newQuery: string) {
        setPredictive([]);
        setPredictiveValue(newQuery);

        if (query !== newQuery && newQuery.replace(/ /g, '').length >= globalProperties.search.minQueryLength) {
            globalProperties.search.predictive.q = newQuery;
            const queryString = jsonToQuery(globalProperties.search.predictive);
            const response = await fetch(`https://www.siriusxm.com/search.json?${queryString}`);
            const json = (await response.json()) as SwiftypeResult;
            const links: Link[] = [];

            json.records.page?.forEach((page) => {
                const title: string = page.title.replace(' | SiriusXM', '').trim();
                links.push({
                    href: encodeURI(
                        page.link ||
                            page.url ||
                            page.keymatchurl ||
                            page.blogUrl ||
                            `${globalProperties.domain}/search?q=${title}&predictive=true`,
                    ),
                    link_text: title,
                });
            });

            // commenting this out for now, they don't want predictive on search page
            // will remove after a couple of sprints, once we know they don't want to roll back
            // setPredictive(links);
        }
    }

    function loadMore(tab: string) {
        setShowResults({ ...showResults, [tab]: (showResults[tab] += 10) });
    }

    function resetResults(tab: string) {
        setShowResults({ ...showResults, [tab]: 10 });
    }

    useEffect(() => {
        if (!searchSubmitEvent) {
            setSearchSubmitEvent(new CustomEvent('searchSubmit'));
            return;
        }

        document.body.dispatchEvent(searchSubmitEvent);
    }, [searchParams, searchSubmitEvent]);

    useEffect(() => {
        const kg: React.ReactNode | undefined = undefined;

        const tabs: TabsIndex = {
            shop: {
                label: 'Shop',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            support: {
                label: 'Support',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            blog: {
                label: 'Blog',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            dotcom: {
                label: 'SiriusXM.com',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            all: {
                label: 'All Results',
                count: 0,
                displayLimit: 10,
                results: [],
            },
        };

        function recordClick(record: SwiftypePage) {
            const data = {
                engine_key: globalProperties.search.organic.engine_key,
                doc_id: record.id,
                q: query,
                t: Math.floor(new Date().getTime() / 1000),
            };
            fetch(`https://api.swiftype.com/api/v1/public/analytics/pc.json?${jsonToQuery(data)}`);
        }

        results.records.page?.forEach((record, i) => {
            if (record) {
                const link = record.keymatchurl || record.blogUrl || record.url || record.link || '';
                const recordNode = <SearchRecord key={i} record={record} onClick={() => recordClick(record)} />;

                if (link.indexOf('shop.siriusxm.com') !== -1) {
                    tabs.shop.count += 1;

                    if (tabs.shop.results.length < showResults.shop) {
                        tabs.shop.results.push(recordNode);
                    }
                } else if (link.indexOf('care.siriusxm.com') !== -1) {
                    tabs.support.count += 1;

                    if (tabs.support.results.length < showResults.support) {
                        tabs.support.results.push(recordNode);
                    }
                } else if (link.indexOf('blog.siriusxm.com') !== -1 || link.indexOf('/blog') !== -1) {
                    tabs.blog.count += 1;

                    if (tabs.blog.results.length < showResults.blog) {
                        tabs.blog.results.push(recordNode);
                    }
                } else {
                    tabs.dotcom.count += 1;

                    if (tabs.dotcom.results.length < showResults.dotcom) {
                        tabs.dotcom.results.push(recordNode);
                    }
                }

                tabs.all.count += 1;

                if (tabs.all.results.length < showResults.all) {
                    tabs.all.results.push(recordNode);
                }

                // Leaving this snippedcommented to quickly enable should business change their mind - Attilio
                // if (!kg && i < 4 && ['channelpage', 'channel'].indexOf(record.doctype.toLowerCase()) > -1) {
                //     kg = <ChannelKnowledgeGraph channelId={record.contentid || ''} />;
                // }
            }
        });

        setKG(kg);

        const resultsOrder = tabOrder.filter((tab) => {
            return tabs[tab].count > 0;
        });

        setTabItems(
            resultsOrder.map((tab) => {
                return {
                    title: `${tabs[tab].label}`,
                    body: (
                        <Grid container>
                            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                                <>{tabs[tab].results}</>

                                <StyledContainer className={styles.loadWrapper}>
                                    {tabs[tab].count > tabs[tab].displayLimit &&
                                        (showResults[tab] < tabs[tab].count ? (
                                            <Button
                                                type="button"
                                                theme="secondary"
                                                text="LOAD MORE"
                                                onClick={() => loadMore(tab)}
                                            />
                                        ) : (
                                            <Button
                                                type="button"
                                                theme="secondary"
                                                text="LOAD LESS"
                                                onClick={() => resetResults(tab)}
                                            />
                                        ))}
                                </StyledContainer>
                            </Grid>
                        </Grid>
                    ),
                };
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, showResults]);

    useEffect(() => {
        async function getResults() {
            if (query.replace(/ /g, '').length >= globalProperties.search.minQueryLength) {
                setResults(defaultResults);

                globalProperties.search.organic.q = query;
                const organicQuery = jsonToQuery(globalProperties.search.organic);
                const organicResponse = await fetch(`https://www.siriusxm.com/search.json?${organicQuery}`);
                const organicJson = (await organicResponse.json()) as SwiftypeResult;

                // Leaving this for now for quick rollback - Attilio

                // if (properties.envName !== 'DEV-PREVIEW') {
                //     properties.search.docs.q = query;
                //     const docsQuery = jsonToQuery(properties.search.docs);
                //     const docsResponse = await fetch(`https://www.siriusxm.com/search.json?${docsQuery}`);
                //     const docsJson = (await docsResponse.json()) as SwiftypeResult;

                //     organicJson.record_count = organicJson.record_count + docsJson.record_count;
                //     organicJson.records.page = organicJson.records.page?.concat(
                //         docsJson.records.page || [],
                //         docsJson.records.keymatchdata || [],
                //         docsJson.records.spkeywordsphoenix || [],
                //         docsJson.records.sxmblogs || [],
                //         docsJson.records.sxmshop || [],
                //     );
                // }

                setPredictive([]);
                setResults(organicJson);
            }
        }

        getResults();
    }, [query]);

    function resultsStatus(): JSX.Element {
        if (query) {
            if (results.record_count > 0 && results.info.page.query !== query) {
                return (
                    <>
                        <Text tag="div" className={styles.resultStatusLine1}>
                            Showing results for &quot;{results.info.page.query}&quot;.
                        </Text>
                        <Text tag="div" className={styles.resultStatusLine2}>
                            No results were found for &quot;{query}&quot;.
                        </Text>
                    </>
                );
            } else if (results.record_count === 0 && !results.info.page.spelling_suggestion) {
                return (
                    <>
                        <Text tag="div" className={styles.resultStatusLine1}>
                            Sorry, your search &quot;{query}&quot; did not have any matches.
                        </Text>
                        <Text tag="div" className={styles.resultStatusLine2}>
                            Check for any spelling errors or try different keywords.
                        </Text>
                    </>
                );
            } else if (results.info.page.spelling_suggestion) {
                return (
                    <Text tag="div" className={styles.resultStatusLine2}>
                        Did you mean to search for{' '}
                        <a href={`/search?q=${results.info.page.spelling_suggestion.text}`}>
                            {results.info.page.spelling_suggestion.text}
                        </a>
                        ?
                    </Text>
                );
            }
        }

        return <></>;
    }

    const barContainerCS = cs(styles.barContainer, {
        [styles.extendBarHeight]: !!kg,
    });

    return (
        <>
            <StyledContainer backgroundColor="var(--purple)" className={barContainerCS} includeNavPadding>
                <Grid container>
                    <Grid col={{ xs: 12 }}>
                        <SearchBar
                            initialValue={query}
                            searchResults={predictiveResults}
                            onChange={getPredictive}
                            onSubmit={onSubmit}
                            allResultsUrl={predictiveValue ? `${globalProperties.domain}/search?q=${query}` : undefined}
                            label="Search SiriusXM…"
                            keepSearchQuery
                        />
                        {resultsStatus()}
                    </Grid>
                </Grid>
            </StyledContainer>

            <StyledContainer standardMoleculeSpacing>
                {Boolean(tabItems.length) && <Tabs items={tabItems} />}
            </StyledContainer>
        </>
    );
}
