'use client';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { Link } from '@/components/atoms/Link';
import { useValidator } from '@/hooks';
import { VALIDITY } from '@/constants';
import styles from './frequencychannelfinder.module.scss';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Input = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Input/Input'));
const Form = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Form/Form'));

const ZIP_CODE_JSON_URL = '/ns/json/zip-frequencies.json';

const LINKS = [
    {
        href: 'https://shop.siriusxm.com/professional-prepaid-install-card.html',
        linkText: 'Professional Pre-Paid XM Installation Card',
    },
    {
        href: 'https://shop.siriusxm.com/professional-prepaid-install-card.html',
        linkText: 'Professional Pre-Paid Sirius Installation Card',
    },
    {
        href: 'https://shop.siriusxm.com/support/cassette-adapter.html',
        linkText: 'Cassette Adapter',
    },
    {
        href: 'https://shop.siriusxm.com/aux-in-cable-reconditioned.html',
        linkText: 'Direct Audio Cable',
    },
    {
        href: 'https://shop.siriusxm.com/fm-direct-adapter-reconditioned.html',
        linkText: 'FM Direct Adapter for Sirius',
    },
    {
        href: 'https://shop.siriusxm.com/fm-direct-adapter-reconditioned.html',
        linkText: 'FM Direct Adapter for XM',
    },
];

interface ZipCodeJson {
    [x: string]: string[];
}

export default function FrequencyChannelFinder(): JSX.Element {
    const validator = useValidator({
        fields: {
            zip: [{ type: 'minlength', config: 4, helperText: 'Valid zip code required' }],
        },
    });
    const [zipCodeData, setZipCodeData] = useState<ZipCodeJson | null>();
    const [validation, setValidation] = useState(validator.getDefault);
    const [foundFrequency, setFoundFrequency] = useState<string[] | null>();

    useEffect(() => {
        const getZipCodes = async () => {
            try {
                const response = await fetch(ZIP_CODE_JSON_URL);
                const data = await response.json();
                if (data) {
                    setZipCodeData(data);
                }
            } catch (err) {
                setZipCodeData(null);
            }
        };

        getZipCodes();
    }, []);

    const handleValidation = (event: React.SyntheticEvent) => {
        const newValidations = validator.validate(event);
        const updated = { ...validation, ...newValidations };

        setValidation(updated);
        return updated.form.invalid === VALIDITY.valid;
    };

    const checkForFrequencies = (value?: string) => {
        if (!zipCodeData) {
            return false;
        }
        const zip = value?.replace(/^0+/, '');
        const [frequency] = Object.keys(zipCodeData).filter((key) => key === zip);

        if (frequency) {
            setFoundFrequency(zipCodeData[frequency]);
        } else {
            setFoundFrequency(null);
        }

        return true;
    };

    return (
        <StyledContainer includeNavPadding>
            <Grid container className={styles.container}>
                <Grid col={{ sm: 12, md: 12, lg: 6 }} start={{ lg: 2 }}>
                    <Text tag="h3" variant="h3">
                        FM Channel Finder
                    </Text>
                    <Text tag="p">
                        Need to find the best FM frequency for your SiriusXM Satellite Radio? Use our FM Channel Finder
                        to help you to get the best listening experience from your radio.
                    </Text>
                    <Text tag="p">
                        SiriusXM FM Channel Finder searches a FCC-provided database of licensed FM broadcasters to
                        determine the best FM frequencies to use in your car and around your home for your SiriusXM
                        Satellite Radio.
                    </Text>
                    <Text tag="p">
                        SiriusXM FM Channel Finder is a free resource that you can use any time to find one or more
                        potentially vacant FM frequencies. In most areas, we will give you a choice of up to five
                        frequencies to try, ranked by how far away they are from other radio stations. SiriusXM FM
                        Channel Finder can be used in the 48 contiguous states served by SIRIUS Satellite Radio.
                    </Text>
                    <Grid col={{ sm: 12, md: 8, lg: 6 }} className={styles.frequencyFinderWrapper}>
                        <Form
                            action=""
                            method="GET"
                            contentType="json"
                            onChange={handleValidation}
                            onSubmit={() => checkForFrequencies(validation?.zip?.value)}
                        >
                            <Text tag="h2" variant="h5">
                                Enter Your ZIP Code
                            </Text>
                            <Text tag="p" className={styles.formInfoText}>
                                This search will help you find the most suitable FM frequencies to use for your SiriusXM
                                Satellite Radio.
                            </Text>
                            <Input
                                type="zip"
                                name="zip"
                                label="Enter your zip code"
                                invalid={validation.zip.invalid}
                                required
                                invalidMessage={validation.zip.helperText.minlength}
                            />
                            <Button type="submit" theme="primary" text="FIND FREQUENCY" />
                            {foundFrequency && (
                                <div className={styles.hasFrequencyBox}>
                                    <Text tag="h2" variant="h5">
                                        {`FM Frequencies for ${validation.zip.value}`}
                                    </Text>
                                    <Text tag="p">
                                        {`For ZIP code ${validation.zip.value}, try using ${foundFrequency[0]}. Tune both your FM radio and SiriusXM Satellite radio to this frequency first. If you feel you need to try another frequency, select any of the ones listed below in the order suggested.`}
                                    </Text>
                                    <ul className={styles.list}>
                                        {foundFrequency.map((frequency, index) => {
                                            if (Number(frequency) !== 0) {
                                                return (
                                                    <li key={index}>
                                                        <span>{frequency}</span>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            )}
                            {foundFrequency === null && (
                                <div className={styles.hasFrequencyBox}>
                                    <Text
                                        tag="h2"
                                        variant="h5"
                                    >{`No frequency found for ${validation.zip.value}`}</Text>
                                    <Text tag="p">Please try another ZIP code.</Text>
                                </div>
                            )}
                        </Form>
                    </Grid>
                    <Text tag="p">
                        Should you continue to have interference from local radio stations, the following accessories
                        can help improve your SiriusXM experience.
                    </Text>
                    <ul className={styles.links}>
                        {LINKS.map(({ href, linkText }, index) => (
                            <li key={index}>
                                <Link href={href}>{linkText}</Link>
                            </li>
                        ))}
                    </ul>
                </Grid>
            </Grid>
        </StyledContainer>
    );
}
