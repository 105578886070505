import { useEffect, useState } from 'react';

const EMULATED_MOUSEMOVE_IGNORE_DURATION_MS = 500;

/**
 * Used for elements that have highly noticeable hover states that can potentially get stuck when tapped with touch
 * devices. Works with hybrid touch/pointer devices.
 * Based off of https://stackoverflow.com/a/30303898
 */
export const useTouchOrHoverDetector = () => {
    const [hoverEnabled, setHoverEnabled] = useState(true);
    const [lastTouchTime, setLastTouchTime] = useState(EMULATED_MOUSEMOVE_IGNORE_DURATION_MS + 100);

    useEffect(() => {
        const handleMouseMoveEvent = () => {
            if (+new Date() - lastTouchTime < EMULATED_MOUSEMOVE_IGNORE_DURATION_MS) {
                return;
            }

            setHoverEnabled(true);
        };

        const handleTouchEvent = () => {
            setLastTouchTime(+new Date());
            setHoverEnabled(false);
        };

        document.addEventListener('mousemove', handleMouseMoveEvent, true);
        document.addEventListener('touchstart', handleTouchEvent, true);

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveEvent, true);
            document.removeEventListener('touchstart', handleTouchEvent, true);
        };
    }, [lastTouchTime]);

    return hoverEnabled;
};
