import styles from './channelherocard.module.scss';
import dynamic from 'next/dynamic';
import { ComponentProps } from 'react';
import { StaticChannel } from '../StaticChannel';
const AudioIcon = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/AnimatedIcons/AudioIcon/AudioIcon'));

export interface ChannelHeroProps {
    color: string;
    channelCardProps: ComponentProps<typeof StaticChannel>;
}

export default function ChannelHeroCard({ channelCardProps, color }: ChannelHeroProps) {
    return (
        <div className={styles.channelWrapper}>
            <div className={styles.ChannelTextWrapper}>
                <div className={styles.audioIconWrapper}>
                    <span>Now Playing</span> <AudioIcon fillColor={color} />
                </div>
                <StaticChannel {...channelCardProps} />
            </div>
        </div>
    );
}
