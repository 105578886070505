import React from 'react';
import styles from './offermodalhero.module.scss';
import Image from 'next/image';
import { PRICING } from '@/constants/pulse/pricing';

interface OfferModalHeroProps {
    imageSrc: string,
    imageAlt: string,
    width: number,
    height: number,
    plan: string,    
}

export const OfferModalHero = ({
    imageSrc,
    imageAlt,
    width,
    height,
    plan,    
}: OfferModalHeroProps) => {
    return (
        <div className={styles.modalImage}>
            <div className={styles.offerLegal}>
                <span className={styles.plan}>{plan}</span>
                <span><b>{PRICING.offerDetails}</b> {PRICING.apply}</span>
            </div>
            <Image
                src={imageSrc}
                alt={imageAlt}
                width={width}
                height={height}
            />
        </div>
    );
}
