import styles from './formHeaderSection.module.scss';
import dynamic from 'next/dynamic';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));

export interface FormHeaderSectionProps {
    eyebrow: string;
    title: string;
    thinText: React.ReactNode;
}

export default function FormHeaderSection({ eyebrow, thinText, title }: FormHeaderSectionProps) {
    return (
        <Grid className={styles.container} container>
            <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                <Text tag="p" variant="eyebrowLg">
                    {eyebrow}
                </Text>
                <Text tag="h2" variant="h2">
                    {title}
                </Text>
                <>{thinText}</>
            </Grid>
        </Grid>
    );
}
