'use client';

import { useId, useRef, useState, useCallback, useEffect, MouseEventHandler } from 'react';
import properties from '@/../properties/global';
import styles from './navsearch.module.scss';
import Search from 'de-sxm-reactlib-2/dist/components/organisms/Search/Search';
import { type EntityListItemProps } from 'de-sxm-reactlib-2/dist/components/molecules/EntityListItem/EntityListItem';
import { SearchResponse } from 'algoliasearch';
import { SafeHtml } from '@/components/atoms/Display';
import Grid from 'de-sxm-reactlib-2/dist/components/atoms/Layout/Grid/Grid';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import SearchSuggestions, {
    type SearchChip,
} from 'de-sxm-reactlib-2/dist/components/molecules/SearchSuggestions/SearchSuggestions';
import ShowMore from 'de-sxm-reactlib-2/dist/components/molecules/ShowMore/ShowMore';
import {
    ItemVariant,
    LayoutGrid,
    TileVariant,
} from 'de-sxm-reactlib-2/dist/components/organisms/ContentCategory/ContentCategory';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { type EntityCategoryItemProps } from 'de-sxm-reactlib-2/dist/components/molecules/EntityCategoryItem/EntityCategoryItem';

interface Props {
    searchTerm: string;
    open?: boolean;
    minSearchLength?: number;
    searchSuggestions?: SearchChip[];
    searchSuggestionsLabel?: string;
    searchQuickLinks?: EntityCategoryItemProps[];
    userTypingDelay?: number;
    onCancelClick?: MouseEventHandler;
    quickLinksHeader?: string;
}

export default function NavSearch({
    searchTerm = '',
    open,
    minSearchLength = 3,
    searchSuggestions = [],
    searchSuggestionsLabel,
    searchQuickLinks = [],
    userTypingDelay = 1000,
    onCancelClick,
    quickLinksHeader,
}: Props) {
    const domain = properties.domain;

    const navSearchInputRef = useRef<HTMLInputElement>(null);
    const navSearchRef = useRef<HTMLDivElement>(null);

    const [predictiveResults, setPredictive] = useState<EntityListItemProps[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const navSearchId = useId();

    const hasResults = predictiveResults.length > 0;
    const showSuggestions =
        (searchQuery.trim().length < minSearchLength || isLoading) && !hasResults && searchSuggestions.length > 0;
    const showQuickLinks = !hasResults && searchQuickLinks.length > 0;

    const getPredictive = useCallback(
        async (newQuery: string) => {
            if (newQuery.length < properties.search.minQueryLength) {
                setSearchQuery(newQuery);
                setPredictive([]);
                setIsLoading(false);
                return;
            }

            if (searchTerm === newQuery) {
                return;
            }

            const hadResults = predictiveResults.length > 0;
            const oldQueryWasShort = searchQuery.length < properties.search.minQueryLength;

            if (hadResults || oldQueryWasShort) {
                setIsLoading(true);
            }

            setSearchQuery(newQuery);

            try {
                const searchParams = new URLSearchParams({
                    'query': newQuery,
                    'hitsPerPage': '20',
                    'attributesToHighlight': JSON.stringify(['title', 'description']),
                });

                const response = await fetch('/rest/search?' + searchParams);

                const searchIndex: SearchResponse<AlgoliaResult> = await response.json();

                const results: EntityListItemProps[] = searchIndex.hits.map((page) => ({
                    title: <SafeHtml tag="span" print={page._highlightResult.title.value} />,
                    shortDescription: <SafeHtml tag="span" print={page._highlightResult.description.value} />,
                    image: page.image ? (
                        <ImageWrapper src={page.image} alt="" optimizedHeight={100} optimizedWidth={100} />
                    ) : undefined,
                    href: encodeURI(page.url || `${domain}/search?q=${page.title}&predictive=true`),
                }));

                setPredictive(results);
            } catch (error) {
                console.error('Error fetching predictive results:', error);
                setPredictive([]);
            } finally {
                if (hadResults || oldQueryWasShort) {
                    setIsLoading(false);
                }
            }
        },
        [domain, searchTerm, predictiveResults, searchQuery],
    );

    // Focus on the input field when the search drawer is opened
    useEffect(() => {
        if (open && navSearchInputRef.current) {
            navSearchInputRef.current.focus();
        }
    }, [open]);

    // Clear the search input and results when the search drawer is closed
    useEffect(() => {
        if (!open) {
            setSearchQuery('');
            setPredictive([]);
            setIsLoading(false);
        }
    }, [open]);

    const handleSuggestionClick = (suggestion: string) => {
        setSearchQuery(suggestion);
        getPredictive(suggestion);
        if (navSearchInputRef.current) {
            navSearchInputRef.current.focus();
        }
    };

    const contentCategoryProps = {
        items: searchQuickLinks,
        layoutGrid: 'threeInRow' as LayoutGrid,
        itemVariant: 'list' as ItemVariant,
        tileVariant: 'single' as TileVariant,
    };

    return (
        <div className={styles.searchContainer} ref={navSearchRef} id={navSearchId}>
            <Search
                ref={navSearchInputRef}
                value={searchQuery}
                searchResults={predictiveResults}
                onChange={getPredictive}
                isLoading={isLoading}
                minSearchLength={minSearchLength}
                userTypingDelay={userTypingDelay}
                onCancelClick={onCancelClick}
            ></Search>

            {showSuggestions && (
                <Grid container className={styles.suggestionsWrapper}>
                    <Grid col={{ lg: 10 }} start={{ lg: 2 }}>
                        <SearchSuggestions
                            title={searchSuggestionsLabel || ''}
                            searchChips={searchSuggestions}
                            onChipSelect={handleSuggestionClick}
                        />
                    </Grid>
                </Grid>
            )}

            {showQuickLinks && (
                <Grid container className={styles.linksWrapper}>
                    <Grid col={{ lg: 10 }} start={{ lg: 2 }}>
                        {quickLinksHeader && (
                            <Text tag="p" className={styles.searchQuickLinksHeading} variant="h4">
                                {quickLinksHeader}
                            </Text>
                        )}
                        <ShowMore content={contentCategoryProps} />
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
