'use client';
// For an unknown reason, TitledIconListGroup -> List styles sometimes don't load without this being a client component,
// e.g. in ContextualComponent.

import { TitledIconListGroup, TitledIconListProps } from '../../TitledIconList';

interface Props {
    columnsMobile: number;
    leagueAbbr: string;
    teamListProps: TitledIconListProps[];
}

interface DivisionColumns {
    [index: string]: number;
}

const divisionColumns: DivisionColumns = {
    MLB: 3,
    NFL: 4,
    NHL: 4,
    NBA: 3,
};

export default function TeamList(props: Props): JSX.Element {
    return (
        <TitledIconListGroup
            iconLists={props.teamListProps}
            columnCountDesktop={divisionColumns[props.leagueAbbr]}
            columnCountMobile={props.columnsMobile}
        />
    );
}
