const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import dynamic from 'next/dynamic';
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import { RenderRTE } from '@/components/RenderRTE';

interface Props {
    sweepsFields: Pick<SiteSweeps['fields'], 'closed_description' | 'closed_headline' | 'closed_message'>;
    asW2?: boolean;
}

export default function Ended({ sweepsFields, asW2 }: Props) {
    function SweepsClosedDescription() {
        if (sweepsFields.closed_message) {
            return <RenderRTE contentfulDoc={sweepsFields.closed_message} asW2={asW2} />;
        } else {
            return <>{sweepsFields.closed_description && sweepsFields.closed_description}</>;
        }
    }

    return (
        <Grid container>
            <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                <StyledContainer paddingTop={40} paddingBottom={40}>
                    <Text tag="h2" variant="h2" style={{ marginBottom: 30 }}>
                        {sweepsFields.closed_headline || 'Sorry this sweepstakes has ended.'}
                    </Text>
                    <SweepsClosedDescription />
                </StyledContainer>
            </Grid>
        </Grid>
    );
}
