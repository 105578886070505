'use client';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import globalProperties from '@/../properties/global';
import { useSearchParams } from 'next/navigation';
import styles from './search.module.scss';
import cs from 'classnames';
import { usePathname, useRouter } from 'next/navigation';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const SearchBar = dynamic(() => import('de-sxm-reactlib/dist/components/molecules/SearchBar/SearchBar'));
const Tabs = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs'));
import { type TabItem } from 'de-sxm-reactlib/dist/components/atoms/Layout/Tabs';
import { getCookie } from 'cookies-next';
import { SearchResponse } from 'algoliasearch';
import { EventsItems, insightsClient } from '@algolia/client-insights';
import { SearchResult } from '@/components/molecules/SearchResult';

import properties from '@/../properties/global';
const algoliaInsights = insightsClient(properties.search.algolia.appID, properties.search.algolia.apiKey);

interface TabsIndex {
    [index: string]: {
        label: string;
        count: number;
        displayLimit: number;
        results: React.ReactNode[];
    };
}

interface ShowResults {
    [index: string]: number;
}

const defaultResults: SearchResponse<AlgoliaResult> = {
    hits: [],
    hitsPerPage: 20,
    page: 0,
    query: '',
    processingTimeMS: 0,
    params: '',
    nbHits: 0,
    nbPages: 0,
};

const tabOrder = ['all', 'dotcom', 'blog', 'shop', 'support'];

export default function Search(): JSX.Element {
    const userToken = getCookie('suserid');
    const [searchSubmitEvent, setSearchSubmitEvent] = useState<CustomEvent>();
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const router = useRouter();
    const [kg, setKG] = useState<React.ReactNode | undefined>(undefined);
    const [query, setQuery] = useState(searchParams.get('q') || '');
    const [results, setResults] = useState<SearchResponse<AlgoliaResult>>(defaultResults);
    const [tabItems, setTabItems] = useState<TabItem[]>([]);
    const [showResults, setShowResults] = useState<ShowResults>({
        shop: 10,
        support: 10,
        blog: 10,
        dotcom: 10,
        all: 10,
    });

    function onSubmit(query: string) {
        router.push(`${pathname}?q=${query}`);
        setQuery(query);
    }

    function loadMore(tab: string) {
        setShowResults({ ...showResults, [tab]: (showResults[tab] += 10) });
    }

    function resetResults(tab: string) {
        setShowResults({ ...showResults, [tab]: 10 });
    }

    useEffect(() => {
        if (!searchSubmitEvent) {
            setSearchSubmitEvent(new CustomEvent('searchSubmit'));
            return;
        }

        document.body.dispatchEvent(searchSubmitEvent);
    }, [searchParams, searchSubmitEvent]);

    useEffect(() => {
        const kg: React.ReactNode | undefined = undefined;

        const tabs: TabsIndex = {
            shop: {
                label: 'Shop',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            support: {
                label: 'Support',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            blog: {
                label: 'Blog',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            dotcom: {
                label: 'SiriusXM.com',
                count: 0,
                displayLimit: 10,
                results: [],
            },
            all: {
                label: 'All Results',
                count: 0,
                displayLimit: 10,
                results: [],
            },
        };

        results.hits.forEach((record: AlgoliaResult) => {
            if (record) {
                const link = record.url;

                const algoliaClickEvent: EventsItems = {
                    eventType: 'click',
                    eventName: 'Product Clicked',
                    index: 'products',
                    userToken: `${userToken}`,
                    timestamp: Date.now(),
                    objectIDs: [record.objectID],
                    queryID: results.queryID,
                };

                const recordNode = (
                    <SearchResult
                        label={record.title}
                        title={record.title}
                        titleLink={{ href: record.url }}
                        thumbnailSrc=""
                        mainText={record.description}
                        variant="list"
                        onClick={() => {
                            algoliaInsights.pushEvents({
                                events: [algoliaClickEvent],
                            });
                        }}
                    />
                );

                if (link.indexOf('shop.siriusxm.com') !== -1) {
                    tabs.shop.count += 1;

                    if (tabs.shop.results.length < showResults.shop) {
                        tabs.shop.results.push(recordNode);
                    }
                } else if (link.indexOf('care.siriusxm.com') !== -1) {
                    tabs.support.count += 1;

                    if (tabs.support.results.length < showResults.support) {
                        tabs.support.results.push(recordNode);
                    }
                } else if (link.indexOf('blog.siriusxm.com') !== -1 || link.indexOf('/blog') !== -1) {
                    tabs.blog.count += 1;

                    if (tabs.blog.results.length < showResults.blog) {
                        tabs.blog.results.push(recordNode);
                    }
                } else {
                    tabs.dotcom.count += 1;

                    if (tabs.dotcom.results.length < showResults.dotcom) {
                        tabs.dotcom.results.push(recordNode);
                    }
                }

                tabs.all.count += 1;

                if (tabs.all.results.length < showResults.all) {
                    tabs.all.results.push(recordNode);
                }
            }
        });

        setKG(kg);

        const resultsOrder = tabOrder.filter((tab) => {
            return tabs[tab].count > 0;
        });

        setTabItems(
            resultsOrder.map((tab) => {
                return {
                    title: `${tabs[tab].label}`,
                    body: (
                        <Grid container>
                            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                                <>{tabs[tab].results}</>

                                <StyledContainer className={styles.loadWrapper}>
                                    {tabs[tab].count > tabs[tab].displayLimit &&
                                        (showResults[tab] < tabs[tab].count ? (
                                            <Button
                                                type="button"
                                                theme="secondary"
                                                text="LOAD MORE"
                                                onClick={() => loadMore(tab)}
                                            />
                                        ) : (
                                            <Button
                                                type="button"
                                                theme="secondary"
                                                text="LOAD LESS"
                                                onClick={() => resetResults(tab)}
                                            />
                                        ))}
                                </StyledContainer>
                            </Grid>
                        </Grid>
                    ),
                };
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results, showResults]);

    useEffect(() => {
        async function getResults() {
            if (query.replace(/ /g, '').length >= globalProperties.search.minQueryLength) {
                setResults(defaultResults);

                const response = await fetch(
                    '/rest/search?' +
                        new URLSearchParams({
                            query: query,
                            hitsPerPage: '20',
                        }),
                );

                const searchIndex: SearchResponse<AlgoliaResult> = await response.json();

                setResults(searchIndex);
            }
        }

        getResults();
    }, [query]);

    function resultsStatus(): JSX.Element {
        if (query && results.info) {
            if (results.hits.length > 0 && results.query && results.query !== query) {
                return (
                    <>
                        <Text tag="div" className={styles.resultStatusLine1}>
                            Showing results for &quot;{results.info?.page?.query}&quot;.
                        </Text>
                        <Text tag="div" className={styles.resultStatusLine2}>
                            No results were found for &quot;{query}&quot;.
                        </Text>
                    </>
                );
            } else if (results.hits.length === 0 && !results.exhaustive?.typo) {
                return (
                    <>
                        <Text tag="div" className={styles.resultStatusLine1}>
                            Sorry, your search &quot;{query}&quot; did not have any matches.
                        </Text>
                        <Text tag="div" className={styles.resultStatusLine2}>
                            Check for any spelling errors or try different keywords.
                        </Text>
                    </>
                );
            }
        }

        return <></>;
    }

    const barContainerCS = cs(styles.barContainer, {
        [styles.extendBarHeight]: !!kg,
    });

    return (
        <>
            <StyledContainer backgroundColor="var(--purple)" className={barContainerCS} includeNavPadding>
                <Grid container>
                    <Grid col={{ xs: 12 }}>
                        <SearchBar initialValue={query} onSubmit={onSubmit} label="Search SiriusXM…" keepSearchQuery />
                        {resultsStatus()}
                    </Grid>
                </Grid>
            </StyledContainer>

            <StyledContainer standardMoleculeSpacing>
                {Boolean(tabItems.length) && <Tabs items={tabItems} />}
            </StyledContainer>
        </>
    );
}
