'use client';
import dynamic from 'next/dynamic';
import styles from './channelCardGroup.module.scss';
import { Link } from '@/components/atoms/Link';
import { ChannelCard, ChannelCardProps } from '@/components/molecules/ChannelCard';
import { ComponentProps, useState } from 'react';
const TitleAndLink = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/TitleAndLink/TitleAndLink'));
import cn from 'classnames';

export interface ChannelCardGroupProps {
    headline?: string;
    linkUrl?: string;
    linkAria?: string;
    linkText?: string;
    channels: ChannelCardProps[];
}

type TitleAndLinkProps = ComponentProps<typeof TitleAndLink>;

export function ChannelCardGroup({ channels, headline, linkUrl, linkAria, linkText }: ChannelCardGroupProps) {
    const [activeItem, setActiveItem] = useState<number>();
    const headlineProps: TitleAndLinkProps = {
        text: headline,
        link: {
            children: linkText,
            href: linkUrl,
            ['aria-label']: linkAria,
        },
    };

    return (
        <>
            {headline && (
                <div className={styles.headline}>
                    <TitleAndLink {...headlineProps} />
                </div>
            )}

            <div className={styles.group}>
                {channels.map((args, i) => (
                    <div className={styles.cardWrapper} key={i}>
                        <ChannelCard
                            {...args}
                            onKebabClick={() => setActiveItem(i)}
                            index={i}
                            activeItem={activeItem}
                            wrapperClass={cn({
                                [styles.lastCard]: i + 1 === channels?.length,
                            })}
                        ></ChannelCard>
                    </div>
                ))}
            </div>

            {linkUrl && (
                <Link href={linkUrl} aria-label={linkAria} className={styles.mobileLink}>
                    {linkText}
                </Link>
            )}
        </>
    );
}
