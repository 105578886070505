'use client';
import dynamic from 'next/dynamic';
import { VALIDITY } from '@/constants';
import { useValidator } from '@/hooks';
import { Config, Fields } from '@/hooks/useValidator/interfaces';
import { useEffect, useRef, useState } from 'react';
import { Headline } from '@/components/molecules/Headline';
import globalProperties from '@/../properties/global';
import { validateForm, validateInput } from '@/packages/form';
import useErrorCount from '@/hooks/useErrorCount';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Fieldset = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Fieldset/Fieldset'));
import Input from 'de-sxm-reactlib/dist/components/atoms/Inputs/Input/Input';
const Textarea = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Input/Textarea'));
const Form = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Form/Form'));
const FormErrorDisplay = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/FormErrorDisplay/FormErrorDisplay'),
);

type SubmitState = 'untouched' | 'failed' | 'success' | 'error';

interface ServerFields {
    error: string;
    message: string;
    name: string;
}

export default function ContactUsForm(): JSX.Element {
    const emailRef = useRef<HTMLInputElement>(null);
    const confirmEmailRef = useRef<HTMLInputElement>(null);

    const validationConfig: Config = {
        fields: {
            FirstName: [{ type: 'required', helperText: 'First name required' }],
            LastName: [{ type: 'required', helperText: 'Last name required' }],
            Email: [
                {
                    type: 'email',
                    helperText: 'Valid email address required',
                    validateAlso: [confirmEmailRef.current],
                },
                {
                    type: 'required',
                },
            ],
            confirmEmail: [
                { type: 'matchesCaseInsensitive', config: emailRef.current, helperText: 'Your email must match' },
                { type: 'email' },
            ],
            Phone: [{ type: 'minlength', config: 14, helperText: 'Valid phone number required' }],
            Company: [{ type: 'required', helperText: 'Company name required' }],
            comments: [{ type: 'required', helperText: 'Description required' }],
        },
    };

    const validator = useValidator(validationConfig);
    const [submitState, setSubmitState] = useState<SubmitState>('untouched');
    const [validations, setValidations] = useState(validator.getDefault);
    const errorCount: number = useErrorCount(validations);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow._sxmDataLayerPush) {
            if (submitState === 'success') {
                browserWindow._sxmDataLayerPush('contact-us-success');
            } else if (submitState === 'failed') {
                browserWindow._sxmDataLayerPush('contact-us-fail');
            }
        }
    }, [submitState]);

    function handleValidateForm(event: React.SyntheticEvent) {
        return validateForm(event, validations, setValidations, validator);
    }

    function handleValidateInput(event: React.SyntheticEvent) {
        validateInput(event, validations, setValidations, validator);
    }

    function beforeSubmit(data: FormData) {
        const phone = data.get('Phone') as string;
        data.set('Phone', phone.replace(/\D/g, ''));
        data.set('languagePreference', 'en-US');

        return data;
    }

    async function formSuccess(response: Response) {
        const json = await response.json();

        if (json.formSubmitResponse.status.message === 'ERROR') {
            const serverValidations: Fields = {};

            json.formSubmitResponse.errors.fields.forEach((field: ServerFields) => {
                serverValidations[field.name] = {
                    value: validations[field.name].value,
                    invalid: VALIDITY.invalid,
                    validations: { ...validations[field.name].validations },
                    helperText: { ...validations[field.name].helperText },
                };

                if (field.name === 'form') {
                    if (field.error === 'unknown') {
                        setSubmitState('failed');
                    }
                } else {
                    delete serverValidations.form;
                }
            });

            setValidations({ ...validations, ...serverValidations });
        } else if (json.formSubmitResponse.status.message === 'SUCCESS') {
            setSubmitState('success');
        }
    }

    function formFail() {
        setSubmitState('failed');
    }

    function renderSubmitMessage() {
        if (submitState === 'success') {
            return (
                <>
                    <Headline desktopCols={10} tag="h3" className="h4">
                        Thanks for your inquiry, we will get back to you as soon as possible.
                    </Headline>
                </>
            );
        }

        if (submitState === 'failed') {
            return (
                <>
                    <Headline desktopCols={10} tag="h3" className="h4">
                        There is something wrong :(
                    </Headline>
                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Text tag="p" style={{ paddingTop: 30 }}>
                                Please check and try submitting again
                            </Text>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    return (
        <>
            <div aria-live="polite">{renderSubmitMessage()}</div>

            {submitState === 'untouched' ? (
                <Form
                    action="/p/formsubmit/SaveDataServlet"
                    method="POST"
                    contentType="urlencoded"
                    onSubmit={handleValidateForm}
                    onBeforeSubmit={beforeSubmit}
                    onSuccess={formSuccess}
                    onFail={formFail}
                    onChange={handleValidateForm}
                    novalidate={true}
                    data-form-name="bussiness-contact-us"
                    data-cy="contact-us-form-form"
                >
                    <input type="hidden" name="formId" value="3" />
                    <input type="hidden" name="environment" value={globalProperties.pii.environment} />
                    <input type="hidden" name="evflag" value="" />
                    <input type="hidden" name="guid" value="" />

                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <FormErrorDisplay errorCount={errorCount} />

                            <Fieldset theme="themeGrey" legend="Contact Information">
                                <Grid container>
                                    <Grid col={{ xs: 12, lg: 6 }}>
                                        <Input
                                            type="text"
                                            name="FirstName"
                                            label="First Name"
                                            invalid={validations.FirstName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.FirstName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="LastName"
                                            label="Last Name"
                                            invalid={validations.LastName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.LastName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="Email"
                                            label="Account Email"
                                            ref={emailRef}
                                            invalid={validations.Email.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.Email.helperText.email}
                                        />

                                        <Input
                                            type="text"
                                            name="confirmEmail"
                                            label="Confirm Account Email"
                                            ref={confirmEmailRef}
                                            invalid={validations.confirmEmail.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.confirmEmail.helperText.matchesCaseInsensitive}
                                        />
                                    </Grid>

                                    <Grid col={{ xs: 12, lg: 6 }}>
                                        <Input
                                            type="tel"
                                            name="Phone"
                                            label="Phone"
                                            invalid={validations.Phone.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.Phone.helperText.minlength}
                                        />

                                        <Input
                                            type="text"
                                            name="Company"
                                            label="Company Name"
                                            invalid={validations.Company.invalid}
                                            onBlur={handleValidateInput}
                                            required={true}
                                            invalidMessage={validations.Company.helperText.required}
                                        />

                                        <Textarea
                                            name="comments"
                                            label="How can we help you?"
                                            required={true}
                                            invalid={validations.comments.invalid}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.comments.helperText.required}
                                        />
                                    </Grid>
                                </Grid>
                            </Fieldset>
                        </Grid>

                        <Grid col={{ xs: 12, lg: 6 }} start={{ lg: 4 }}>
                            {validations.form && validations.form.validations.formerror && (
                                <StyledContainer paddingTop={40}>
                                    <Text tag="div" style={{ color: 'var(--red)', fontWeight: 'bold' }} role="alert">
                                        {validations.form.helperText.formerror}
                                    </Text>
                                </StyledContainer>
                            )}

                            <Button
                                type="submit"
                                text="SUBMIT"
                                ariaLabel="submit contact us"
                                theme="primary"
                                buttonSize="full-width"
                            />
                        </Grid>
                    </Grid>
                </Form>
            ) : undefined}
        </>
    );
}
