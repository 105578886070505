'use client';

import dynamic from 'next/dynamic';
import { ChannelCardProps } from '../ChannelCard/ChannelCard';
import { ChannelCardGroup } from './ChannelCardGroup';
import { useLiveChannel } from '@/hooks';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import { useState } from 'react';

export interface ChannelCardGroupWrapperProps {
    channels: ChannelCardProps[];
    channelIds: string[];
    title?: string;
    link?: SiteLink;
}

export default function ChannelCardGroupWrapper({ channels, channelIds, title, link }: ChannelCardGroupWrapperProps) {
    const [channelCards, setChannelCards] = useState<ChannelCardProps[]>(channels);

    useLiveChannel(channelIds, (response: MountainResponse) => {
        const cards = channels.map((channel, index) => {
            const nowPlaying = response && response.channels && response.channels[channelIds[index]];

            if (nowPlaying) {
                return {
                    ...channel,
                    albumImageSrc: nowPlaying.content.album?.art || channel.albumImageSrc,
                    albumImageAlt: nowPlaying.content.album?.title || channel.albumImageAlt,
                    songTitle: nowPlaying.content.title || channel.songTitle,
                    songArtist: nowPlaying.content.artists ? nowPlaying.content.artists[0].name : channel.songArtist,
                };
            }

            return channel;
        });

        setChannelCards(cards);
    });

    const groupProps = {
        headline: title,
        linkUrl: link?.fields.href,
        linkAria: `click here to listen to ${link?.fields.link_text}`,
        linkText: link?.fields.link_text,
        channels: channelCards,
    };

    return channelCards.length <= 0 ? (
        <></>
    ) : (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <ChannelCardGroup {...groupProps}></ChannelCardGroup>
            </Grid>
        </Grid>
    );
}
