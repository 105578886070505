import React from 'react';
import styles from './appmodalhero.module.scss';
import Image from 'next/image';

interface AppModalHeroProps {
    imageSrc: string,
    imageAlt: string,
    mainOptimizedWidth: number,
    mainOptimizedHeight: number,    
}

export const AppModalHero = ({
    imageSrc,
    imageAlt,
    mainOptimizedWidth,
    mainOptimizedHeight,    
}: AppModalHeroProps) => {
    return (
        <div className={styles.modalImage}>
            <Image
                src={imageSrc}
                alt={imageAlt}
                width={mainOptimizedWidth}
                height={mainOptimizedHeight}
            />
            <div className={styles.colorBlock}>
                <div className={styles.red}></div>
                <div className={styles.orange}></div>
                <div className={styles.blue}></div>
            </div>
        </div>
    );
}
