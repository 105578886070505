import globalProperties from '../../../properties/global';

interface Args {
    src: string;
    width?: number;
    height?: number;
    skipOptimization?: boolean;
}

interface ImgoPayload {
    key: string;
    edits: (FormatEdit | ResizeEdit)[];
}
interface FormatEdit {
    format: { type: string };
}
interface ResizeEdit {
    resize: {
        width: number;
        height: number;
    };
}
const optimizationAllowedFileTypes = ['jpeg', 'jpg', 'png'];

export default function ImageOptimization({ src, width, height, skipOptimization }: Args): string {
    if (!src) {
        // Some content assets have blank or undefined `src` fields:
        return src;
    }

    function optimizeImage() {
        if (src.charAt(0) === '/') {
            src = src.substring(1);
        }

        const json: ImgoPayload = {
            key: src,
            edits: [{ format: { type: 'webp' } }],
        };

        if (width && height && width > 0 && height > 0) {
            json.edits.push({ resize: { width: width, height: height } });
        }

        const base64 = btoa(JSON.stringify(json));
        return `/imgo/${base64}`;
    }

    const enableOptimization = globalProperties.optimizeImage && !skipOptimization;
    if ((enableOptimization || src.includes('playerimage')) && !src.includes('https') && !src.includes('/vod/image')) {
        src = src.split('?')[0];
        const filetype = src.split('.')?.pop();
        if (filetype && optimizationAllowedFileTypes.includes(filetype.toLowerCase())) {
            src = optimizeImage();
        }
    }

    return src;
}
