import { useEffect, useState } from 'react';

interface NavigatorProps extends Navigator {
    msMaxTouchPoints: number;
}

export enum DeviceTypes {
    ios = 'iOS',
    android = 'android',
    desktop = 'desktop',
    unknown = 'unknown',
}

function getUserDeviceType(userAgent: string) {
    if (/\b(iPad|iPhone)\b/i.test(userAgent)) {
        return DeviceTypes.ios;
    }
    if (/\b(iPad)\b/i.test(userAgent) || (navigator.maxTouchPoints && /Macintosh/i.test(userAgent))) {
        return DeviceTypes.ios;
    }
    if (/\b(android)\b/i.test(userAgent)) {
        return DeviceTypes.android;
    }

    return DeviceTypes.unknown;
}

export const useUserDeviceType = () => {
    const [mobileDevice, setMobileDevice] = useState<string>();

    useEffect(() => {
        const checkForTouchDevice = () => {
            if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
                const { userAgent } = navigator;
                setMobileDevice(getUserDeviceType(userAgent));
            } else if ('msMaxTouchPoints' in navigator && (navigator as NavigatorProps).msMaxTouchPoints > 0) {
                const { userAgent } = navigator;
                setMobileDevice(getUserDeviceType(userAgent));
            } else if (window.matchMedia('(pointer: coarse)').matches) {
                const { userAgent } = navigator;
                setMobileDevice(getUserDeviceType(userAgent));
            } else {
                setMobileDevice(DeviceTypes.desktop);
            }
        };

        checkForTouchDevice();

        window.addEventListener('resize', checkForTouchDevice);

        return () => window.removeEventListener('resize', checkForTouchDevice);
    }, [mobileDevice]);

    return mobileDevice;
};
