import React, { useState } from 'react';
import styles from './emailform.module.scss';
import { RenderRTE } from '@/components/RenderRTE';
import WhiteArrowLarge from '@/assets/icons/icon-arrow-white-large.svg';
import RedExclamation from '@/assets/icons/icon-exclamation-red.svg';
import { PULSE } from '@/constants/pulse/pulse';

interface EmailFormProps {
    showClose: boolean,
    placeHolderText: string,
    modalAsset: SiteAsset,
    submitHandler: () => void,
    closeHandler: () => void,
    asW2?: boolean;
}

export const EmailForm = ({
    showClose,
    placeHolderText,
    modalAsset,
    submitHandler,
    closeHandler,
    asW2 = false
}: EmailFormProps) => {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const isDisabled = !email;

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    };

    const validateEmail = (email: string) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(String(email).toLowerCase());
    };

    const runValidation = (callback: () => void) => {
        const valid = validateEmail(email);
        setIsValid(valid);
        if (valid) {
            callback();
        }
    }

    return (
        <main className={styles.validationContainer}>
            <div className={styles.inputWrapper} aria-label="Email form">
            {isValid ? (
                <label htmlFor="email">{PULSE.emailFieldLabel}</label>
            ) : (
                <div className={styles.errorSection}>
                    <header className={styles.errorHeader}>
                        <RedExclamation className={styles.errorIcon} alt={'red error icon'} />
                        <h1 className={styles.errorTitle}>{PULSE.emailErrorTitle}</h1>
                    </header>
                    <p className={styles.errorMessage}>{PULSE.emailErrorMessage}</p>
                </div>
            )}
                <input
                    className={`${(isDisabled && email) && styles.errorBorder}`}  
                    type="email" 
                    id={PULSE.emailFieldLabel} 
                    name={PULSE.emailFieldLabel}
                    value={email}
                    onChange={handleEmailChange} 
                    placeholder={placeHolderText} 
                    aria-label="Email input field" 
                />
            </div>
            <div className={styles.subscribe}>
                <RenderRTE contentfulDoc={modalAsset.fields.pulse_offer_details} pToSpan asW2={asW2} />
            </div>
            <div className={styles.buttonWrapper}>
                <button 
                    className={`${styles.buttonWrapper} ${isDisabled ? styles.disabled : ''}`} 
                    type="button" 
                    onClick={() => {
                        runValidation(submitHandler);
                    }}
                    disabled={isDisabled}
                >
                    <span className={styles.buttonText}>Continue</span>
                    <WhiteArrowLarge className={styles.buttonArrow} alt={'white arrow'} />
                </button>
            </div>
            {showClose ? (
                <a onClick={closeHandler} className={styles.modalClose}>{PULSE.emailCloseLinkText}</a>
            ) : (
                <div className={styles.modalClose} />
            )}
        </main>
    );
}
