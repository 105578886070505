const executeIfFunction = <Wrapped extends Unwrapped | (() => Unwrapped), Unwrapped>(f: Wrapped): Unwrapped =>
    typeof f === 'function' ? f() : f;

const switchf =
    <ValueType>(cases: { [key: PropertyKey]: ValueType }) =>
    (defaultCase: ValueType) =>
    (key: PropertyKey) =>
        cases.hasOwnProperty(key) ? cases[key] : defaultCase;

export const switchCase =
    <KeyType extends PropertyKey, ValueType>(cases: { [key in KeyType]: ValueType }) =>
    (defaultCase: ValueType) =>
    (key: KeyType) =>
        executeIfFunction<ValueType | (() => ValueType), ValueType>(switchf<ValueType>(cases)(defaultCase)(key));
