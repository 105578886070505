'use client';

import cn from 'classnames';
import { ComponentProps, Ref, forwardRef } from 'react';
import { Hero } from '../Hero';
import styles from '../hero.module.scss';
import HeroText from '../slots/HeroText';
import { responsiveBreakpointsPx } from '@/constants';
import { useMediaQuery } from '@/hooks';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface Props {
    text: ComponentProps<typeof HeroText>;
    mobileOnlyForegroundImage?: Partial<ImageProps> & { stickToBottom?: boolean };
    widget?: React.ReactNode | React.ReactNode[];
    widgetPhoneNumberLink?: React.ReactNode;
    mobileFlatBackground?: boolean;
    darkTheme?: boolean;
    heroTextClassName?: string;
    widgetBackground?: boolean;
}

const WidgetHero = forwardRef(function (props: Props & ComponentProps<typeof Hero>, ref: Ref<HTMLElement>) {
    const { mobileOnlyForegroundImage: imageProps, text: textProps, ...heroProps } = props;
    const viewportIsTabletSized = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const imageClassNames = cn(styles.foregroundImageWrapper, styles.hiddenDesktop, styles.stretchImage, {
        [styles.imageToBottom]: props.mobileOnlyForegroundImage?.stickToBottom,
    });

    const navPadding = viewportIsTabletSized || (imageProps && imageProps.source ? false : true);

    const mobileFlatClass = cn({
        [styles.mobileFlatBackground]: props.mobileFlatBackground === undefined ? true : props.mobileFlatBackground,
        [styles.noWidgetBackground]: props.widgetBackground === false ? true : false,
    });

    return (
        <>
            <div className={imageClassNames}>
                {imageProps && imageProps.source && (
                    <ImageWrapper
                        optimizedWidth={imageProps.width}
                        optimizedHeight={imageProps.height}
                        src={imageProps.source}
                        alt={imageProps.altText ?? ''}
                        loadingStrategy="eager"
                    />
                )}
            </div>
            <Hero
                ref={ref}
                includeNavPadding={navPadding}
                {...heroProps}
                leftSlot={{
                    mobileSlot: 'top',
                    content: (
                        <HeroText
                            {...textProps}
                            mobileFlatBackground={imageProps && imageProps.source ? true : false}
                            darkTheme={props.darkTheme}
                            className={props.heroTextClassName}
                        />
                    ),
                }}
                rightSlot={{
                    content: props.widget && (
                        <>
                            <div className={`${styles.widgetRightColumn} ${mobileFlatClass}`}>{props.widget}</div>
                            {props.widgetPhoneNumberLink && (
                                <div className={`${styles.widgetPhoneNumber} ${mobileFlatClass}`}>
                                    {props.widgetPhoneNumberLink}
                                </div>
                            )}
                        </>
                    ),
                }}
            />
        </>
    );
});

WidgetHero.displayName = 'WidgetHero';
export default WidgetHero;
