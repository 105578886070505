import React from 'react';
import styles from './modalcontent.module.scss';
import { EmailForm } from './EmailForm';
import { ModalHeader } from './ModalHeader';

interface ModalContentTemplateProps { 
    modalAsset: SiteAsset,
    isModal: boolean,
    ModalHero: React.ComponentType,
    ModalHeroMobile: React.ComponentType,
    placeHolderText: string,
    submitHandler: () => void,
    closeHandler: () => void
}

const ModalContentTemplate = ({
    modalAsset,
    isModal,
    ModalHero,
    ModalHeroMobile,
    placeHolderText,
    submitHandler,
    closeHandler
}: ModalContentTemplateProps) => {
    const { title, description } = modalAsset.fields;
   
    return (
        isModal ? ( 
            <div>
                <ModalHero />  
                <div className={styles.modalDialog}>
                    <ModalHeroMobile />
                    <ModalHeader titleText={title} subHeadText={description} />
                    <EmailForm 
                        placeHolderText={placeHolderText}
                        modalAsset={modalAsset}
                        showClose={isModal} 
                        submitHandler={submitHandler} 
                        closeHandler={closeHandler} 
                    />
                </div>
            </div>
        ) : (
            <div className={styles.fixedForm}>
                <ModalHeader titleText={title} subHeadText={description} />
                <EmailForm 
                    placeHolderText={placeHolderText}
                    modalAsset={modalAsset}
                    showClose={isModal} 
                    submitHandler={submitHandler} 
                    closeHandler={closeHandler} 
                />
            </div>
        )
    );
};

export default ModalContentTemplate;