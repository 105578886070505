import type { Config, FieldData, FieldHelperTexts, FieldValidations, Fields } from './interfaces';
import { VALIDITY } from '@/constants';
import { validators } from '@/packages/string';

export interface ValidatorObject {
    getDefault: () => Fields;
    validate: (event: React.SyntheticEvent) => Fields;
    validateField: (field: HTMLInputElement) => Fields;
    validateForm: (form: HTMLFormElement) => Fields;
}

export default function useValidator(config: Config): ValidatorObject {
    function validateForm(form: HTMLFormElement) {
        const fields = form.querySelectorAll<HTMLInputElement>('textarea:not([name=""]),input:not([name=""])');
        const data: FieldData = {};

        fields?.forEach((field) => {
            const name = field.getAttribute('name');
            const type = field.getAttribute('type') || '';
            const value = ['radio', 'checkbox'].indexOf(type) >= 0 ? field.checked : field.value;

            if (name) {
                if (data[name]) {
                    data[name] = data[name] || value;
                } else {
                    data[name] = value;
                }
            }
        });

        let formIsValid = true;
        const validations: Fields = {};

        Object.keys(config.fields)?.forEach((name) => {
            let fieldIsValid = true;
            const value: string = data[name]?.toString();
            const fieldValidations: FieldValidations = {};
            const fieldHelperTexts: FieldHelperTexts = {};

            config.fields[name]?.forEach((validation) => {
                let configValue = validation.config;
                if (validation.config instanceof HTMLInputElement) {
                    configValue = validation.config.value;
                }
                fieldIsValid = fieldIsValid && validators[validation.type](value, configValue);
                fieldValidations[validation.type] = fieldIsValid;
                fieldHelperTexts[validation.type] = validation.helperText || '';
            });

            validations[name] = {
                value: value,
                invalid: fieldIsValid ? VALIDITY.valid : VALIDITY.invalid,
                validations: fieldValidations,
                helperText: fieldHelperTexts,
            };

            formIsValid = formIsValid && fieldIsValid;
        });

        validations.form = {
            value: undefined,
            invalid: formIsValid ? VALIDITY.valid : VALIDITY.invalid,
            validations: {},
            helperText: {},
        };

        return validations;
    }

    function validateField(field: HTMLInputElement) {
        const name = field.getAttribute('name') || '';
        const type: string = field.getAttribute('type') as string;
        const value = ['radio', 'checkbox'].indexOf(type) >= 0 ? field.checked.toString() : field.value;
        let fieldIsValid = true;
        const fieldValidations: FieldValidations = {};
        const fieldHelperTexts: FieldHelperTexts = {};
        let validations: Fields = {};

        config.fields[name]?.forEach((validation) => {
            let configValue = validation.config;
            if (validation.config instanceof HTMLInputElement) {
                configValue = validation.config.value;
            }
            fieldIsValid = fieldIsValid && validators[validation.type](value, configValue);
            fieldValidations[validation.type] = fieldIsValid;
            fieldHelperTexts[validation.type] = validation.helperText || '';

            if (Array.isArray(validation.validateAlso)) {
                validation.validateAlso?.forEach((element) => {
                    if (element) {
                        const fieldValidations = validateField(element);
                        validations = { ...validations, ...fieldValidations };
                    }
                });
            }
        });

        validations[name] = {
            value: field.value,
            invalid: fieldIsValid ? VALIDITY.valid : VALIDITY.invalid,
            validations: fieldValidations,
            helperText: fieldHelperTexts,
        };

        return validations;
    }

    function validate(event: React.SyntheticEvent) {
        const target = event.target as HTMLElement;
        let validations: Fields = {};

        if (target.nodeName === 'FORM') {
            validations = validateForm(target as HTMLFormElement);
        } else {
            validations = validateField(target as HTMLInputElement);
        }

        return validations;
    }

    function getDefault() {
        const defaultValidations: Fields = {
            form: {
                value: undefined,
                invalid: VALIDITY.invalid,
                validations: {},
                helperText: {},
            },
        };

        Object.keys(config.fields)?.forEach((name) => {
            defaultValidations[name] = {
                value: undefined,
                invalid: VALIDITY.untouched,
                validations: {},
                helperText: {},
            };
        });

        return defaultValidations;
    }

    return {
        getDefault: getDefault,
        validate: validate,
        validateField: validateField,
        validateForm: validateForm,
    };
}
