import { getUnbrokenEpisodeDateRangeGroups } from '@/packages/dateTime/scheduling';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timeZoneExtension from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timeZoneExtension);
dayjs.extend(advancedFormat);

export function getShowScheduleData(shows: SiteChannelShow[], displayTimezones?: boolean) {
    return shows.map((show) => {
        const unbrokenDateRangeGroupedEpisodes = getUnbrokenEpisodeDateRangeGroups(show);
        return getScheduleData(show.show_name, unbrokenDateRangeGroupedEpisodes, displayTimezones);
    });
}

function getScheduleData(
    programName: string,
    unbrokenDateRangeGroupedEpisodes: DaySchedules[][][],
    displayTimezones?: boolean,
) {
    return unbrokenDateRangeGroupedEpisodes.map((groupedEpisodes) => {
        const firstEpisodeStartTime = dayjs(groupedEpisodes[0][0].start_time);
        const lastGroup = groupedEpisodes[groupedEpisodes.length - 1];
        const lastEpisodeStartTime = dayjs(lastGroup[lastGroup.length - 1].start_time);

        return {
            programName: programName,
            dayRange: `${formatDayName(firstEpisodeStartTime)}${
                firstEpisodeStartTime.isSame(lastEpisodeStartTime, 'day')
                    ? ''
                    : '-' + formatDayName(lastEpisodeStartTime)
            }`,
            showTimes: groupedEpisodes[0].map((episode) => {
                const episodeTime = dayjs(episode.start_time);
                return formatEpisodeShowTimes(episodeTime, displayTimezones);
            }),
        };
    });
}

function formatDayName(date: Dayjs) {
    return date.format('ddd');
}

function formatEpisodeShowTimes(episodeTime: Dayjs, displayTimezones?: boolean) {
    const timeFormat = episodeTime.minute() === 0 ? 'h a' : 'h:mm a';
    const timeZoneFormat = displayTimezones ? ` z` : '';
    return episodeTime.format(timeFormat + timeZoneFormat);
}
