import { getComponent } from '@/components/contentful';
import cn from 'classnames';
import { CSSProperties } from 'react';
import { PageContext } from '@/interfaces/types/PageContext';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import styles from './renderasset.module.scss';

interface Props {
    pageLevel?: boolean;
    inline?: boolean;
    index?: number;
    asset: SiteAsset;
    presentation?: SiteEntryPresentation;
    pageContext?: PageContext;
    isInAssetGroup?: boolean;
    isActionBeltAfterHero?: boolean;
}

const fullWidthTypes: string[] = [
    'Header',
    'Hero',
    'Youtube',
    'TextContent',
    'Action',
    'StaticContent',
    'ContentShowcase',
    'PromoOffer',
    'channelGuide',
    'NavigationFooter',
    'contextualComponent',
    'OfferComparison',
    'MotionGraphicModule',
    'landingtitle',
];

const standardMoleculeSpacingTypes: string[] = [
    'AssetGroup:MediaWithText',
    'AssetGroup:FAQ',
    'AssetGroup:DataPackage',
    'AssetGroup:Device',
    'AssetGroup:Channel',
    'TextContent',
    'Header',
    'Youtube',
    'FeaturedShow',
    'NavigationFooter',
    'AssetGroup:AssetGroup',
    'Blog',
    'AssetGroup:FeaturedContent',
    'AssetGroup:Feature',
];

const assetGroupItemsSpacing: string[] = ['Action'];

// This should apply only on components that are used in the AssetGroup AND outside of the AssetGroup.
function addMoleculeSpacingIfInAssetGroup(contentType: string, isInAssetGroup?: boolean): boolean {
    return Boolean(isInAssetGroup && assetGroupItemsSpacing.includes(contentType));
}

function shouldAddMoleculeSpacing(contentFullType: string): boolean {
    return standardMoleculeSpacingTypes.includes(contentFullType);
}

function shouldHideOnMobile(presentation?: SiteEntryPresentation): boolean {
    return presentation?.styles.Display?.toLowerCase() === 'hideonmobile';
}

function shouldAddSectionSpacing(isInAssetGroup = false, type: string) {
    return Boolean(isInAssetGroup && type === 'AssetGroup:Any');
}

export default function RenderAsset(props: Props): JSX.Element {
    const contentType = props.asset.cms_type;
    if (!contentType) {
        return <></>;
    }

    const contentSubType = contentType === 'AssetGroup' ? `:${props.asset.fields.type}` : '';
    const contentFullType = contentType + contentSubType;
    const contentId = props.asset.cms_id;

    const Component = getComponent(contentType);
    let moleculeSpacingClassName =
        shouldAddMoleculeSpacing(contentFullType) || addMoleculeSpacingIfInAssetGroup(contentType, props.isInAssetGroup)
            ? 'standard-molecule-spacing'
            : '';

    if (props.pageContext?.type === 'Pulse') {
        moleculeSpacingClassName = '';
    }

    const hideOnMobileClassName = shouldHideOnMobile(props.presentation) ? 'hide-on-mobile' : '';

    const isSectionSpacing = shouldAddSectionSpacing(props.isInAssetGroup, contentFullType);
    const nestedSectionStyles = isSectionSpacing ? 'nested-section-styles' : '';
    const Tag = isSectionSpacing ? 'section' : 'div';

    const calculateBackgroundColor = (initialBackground?: string) => {
        if (!initialBackground) {
            return {};
        }

        let colorToStartWith = 'var(--white)';
        let alternativeColor = 'var(--off-white)';

        if (initialBackground === 'Transparent') {
            colorToStartWith = 'transparent';
            alternativeColor = 'transparent';
        }

        return {
            '--background-color': colorToStartWith,
            '--alternative-color': alternativeColor,
        } as CSSProperties;
    };

    if (Component) {
        if (props.pageLevel && fullWidthTypes.indexOf(contentFullType) === -1 && contentType !== 'AssetGroup') {
            return (
                <div
                    className={cn(moleculeSpacingClassName, hideOnMobileClassName)}
                    data-componenttype={contentType + contentSubType}
                    data-componentid={contentId}
                    id={contentType + contentSubType + contentId}
                >
                    <Grid container>
                        <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Component
                                key={contentId}
                                index={props.index}
                                asset={props.asset}
                                pageContext={props.pageContext}
                                presentation={props.presentation}
                                asW2={props?.pageContext?.properties?.web2styles === 'true'}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        } else if (props.inline) {
            return (
                <>
                    <span
                        className={cn(moleculeSpacingClassName, hideOnMobileClassName)}
                        data-componenttype={contentType + contentSubType}
                        data-componentid={contentId}
                        id={(contentType + contentId).toLowerCase()}
                    ></span>
                    <Component
                        index={props.index}
                        asset={props.asset}
                        presentation={props.presentation}
                        pageContext={props.pageContext}
                        asW2={props?.pageContext?.properties?.web2styles === 'true'}
                    />
                </>
            );
        } else {
            return (
                <Tag
                    style={isSectionSpacing ? calculateBackgroundColor(props.asset.initialBackground) : {}}
                    className={cn(
                        moleculeSpacingClassName,
                        hideOnMobileClassName,
                        nestedSectionStyles,
                        props.isActionBeltAfterHero && styles.actionBeltPadding,
                    )}
                    data-componenttype={contentType + contentSubType}
                    data-componentid={contentId}
                    id={(contentType + contentId).toLowerCase()}
                >
                    <Component
                        index={props.index}
                        asset={props.asset}
                        presentation={props.presentation}
                        pageContext={props.pageContext}
                        asW2={props?.pageContext?.properties?.web2styles === 'true'}
                    />
                </Tag>
            );
        }
    } else if (process.env.NODE_ENV === 'development') {
        return (
            <Grid container>
                <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                    <h5 style={{ color: 'red' }}>
                        <b>Warning!</b>
                    </h5>
                    <p>
                        Contentful is expecting a <b>{contentType}</b> component to be displayed here, but a Contentful
                        React Component has not been created yet. Please create one under{' '}
                        <b>
                            <i>src/components/contentful/{contentType}</i>
                        </b>{' '}
                        to render it on the page.
                    </p>
                    <p>
                        <i>This warning will not appear in production builds.</i>
                    </p>
                </Grid>
            </Grid>
        );
    }

    return <></>;
}
