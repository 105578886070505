'use client';

import { useEffect, useState } from 'react';
import { useRegisterServiceWorker } from '@/hooks';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import globalProperties from '@/../properties/global';

export default function ClientPageHead(): JSX.Element {
    const [ddLoaded, setDdLoaded] = useState(false);
    const searchParams = useSearchParams();

    useRegisterServiceWorker('/service-worker.js');

    useEffect(() => {
        window.dispatchEvent(new Event('hydrate'));
    }, []);

    useEffect(() => {
        const paramStr = searchParams.toString();
        if (paramStr) {
            window.sessionStorage.setItem('paramStr', paramStr);
        }
    }, [searchParams]);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow.DD_RUM) {
            browserWindow.DD_RUM.init({
                clientToken: 'pub2efebd86de973d3161e20023ac07e68a',
                applicationId: 'c27edd08-fd30-4d05-ace8-21ad46980f08',
                site: 'datadoghq.com',
                service: 'sxm-cecm-web-www',
                env: globalProperties.ddenv,
                version: process.env.NEXT_PUBLIC_DD_VERSION || '1.0.0',
                sessionSampleRate: 45,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
            });

            browserWindow.DD_RUM.startSessionReplayRecording();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ddLoaded]);

    return (
        <>
            <Script
                src="https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js"
                onLoad={() => {
                    setDdLoaded(true);
                }}
            />

            <Script
                src={globalProperties.adobe.launchScript}
                async
                onLoad={() => {
                    const script = document.createElement('script');
                    script.setAttribute('src', '/resources/js/analytics.js');
                    document.body.appendChild(script);
                }}
            />

            {process.env.NODE_ENV !== 'development' && (
                <Script
                    src={globalProperties.onetrust.script}
                    async
                    defer
                    rel="prefetch"
                    data-domain-script={globalProperties.onetrust.domainScript}
                    onLoad={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        function OptanonWrapper() {}
                    }}
                />
            )}
        </>
    );
}
