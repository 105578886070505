import { Link as NextLink } from '@/components/atoms/Link';
import dynamic from 'next/dynamic';
import styles from './link.module.scss';
import CallCenterWrapper from '@/components/www/CallCenterWrapper';
import { LinkInfoItem } from '@/components/molecules/LinkInfoCard';
import ChatLink from './ChatLink';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const ShowMore = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore'));

interface LinkProps {
    asset: SiteLink;
    children?: React.ReactNode;
}

export default function Link({ asset, children }: LinkProps): JSX.Element {
    const theme = asset.fields.theme || 'primary';
    const bgColor = asset.fields.ctaColor;

    if (asset.fields.type === 'Chat') {
        return (
            <ChatLink
                type={asset.fields.link_type}
                theme={theme}
                bgColor={bgColor}
                text={asset.fields.link_text}
                iconUrl={asset.fields.media?.url}
            />
        );
    } else if (asset.fields.type === 'Manage Cookies') {
        return (
            <NextLink href="#" className="ot-sdk-show-settings">
                {asset.fields.link_text}
            </NextLink>
        );
    } else if (asset.fields.type === 'Call Center') {
        let online = <></>;
        let offline = <></>;

        if (asset.fields.link_type === 'LinkInfoCard') {
            online = <LinkInfoItem link={asset.fields as Link} disabled={false} icon={asset.fields.media?.url} />;
            offline = <LinkInfoItem link={asset.fields as Link} disabled={true} icon={asset.fields.media?.url} />;
        } else {
            online = (
                <ShowMore
                    id="callcenter"
                    showText="Our Call Center is Open"
                    hideText="Our Call Center is Open"
                    activeToggleTextPosition="top"
                    contentClassName={styles.callCenter}
                    shownClassName={styles.show}
                    TagName='span'
                >
                    <NextLink href={asset.fields.href || ''}>{asset.fields.link_text}</NextLink>
                </ShowMore>
            );
            offline = <b>Our Call Center is Closed</b>;
        }

        return <CallCenterWrapper online={online} offline={offline} />;
    } else if (asset.fields.link_type === 'Text') {
        const target = asset.fields.type === 'New Tab' ? '_blank' : '_self';
        return (
            <NextLink
                href={asset.fields.href || ''}
                target={target}
                aria-label={asset.fields.aria_label || asset.fields.link_text}
                inheritParams={asset.fields.inheritParams}
            >
                {asset.fields.media?.url ? (
                    <ImageWrapper
                        src={asset.fields.media.url}
                        alt={asset.fields.media.altText}
                        className={styles.imageWithLink}
                    />
                ) : (
                    asset.fields.link_text
                )}
                {children}
            </NextLink>
        );
    } else if (asset.fields.link_type && asset.fields.link_type.indexOf('CTA') >= 0) {
        return (
            <Button
                href={asset.fields.href || ''}
                targetBlank={asset.fields.type === 'New Tab'}
                type="button"
                theme={theme}
                buttonColor={bgColor}
                text={asset.fields.link_text}
                ariaLabel={asset.fields.aria_label || asset.fields.link_text}
                inheritParams={asset.fields.inheritParams}
            />
        );
    } else if (asset.fields.link_type === 'LinkInfoCard') {
        return <LinkInfoItem link={asset.fields as Link} disabled={false} icon={asset.fields.media?.url} />;
    }

    return <></>;
}
