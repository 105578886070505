export function keyBy<ObjectType extends { [key: string]: ObjectType[keyof ObjectType] }>(
    key: keyof ObjectType,
    array: ObjectType[],
): { [key: string]: ObjectType } {
    return Object.fromEntries(Array.from(new Map(array.map((item) => [item[key], item]))));
}

export function groupBy<ObjectType extends { [key: string]: ObjectType[keyof ObjectType] }>(
    key: keyof ObjectType,
    array: ObjectType[],
) {
    return array.reduce<{ [key: string]: ObjectType[] }>(
        (accumulated, current: ObjectType) => ({
            ...accumulated,
            [current[key]]: [...(accumulated[current[key]] ?? []), current],
        }),
        {},
    );
}
