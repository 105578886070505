import { useContext, useState } from 'react';
import { CGContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import { Genrelist } from '../interfaces';
import ChannelHeader from '../ChannelHeader';
import { useSearchParams } from 'next/navigation';
import { categoryMap } from '../ChannelGuide';

export default function NewsIssues(): JSX.Element {
    const searchParams = useSearchParams();
    const context = useContext(CGContext);
    const sortedChannels = context.channels;
    const upsellLink = searchParams.get('upsell') !== 'false' ? context.channelGuide.fields.upsellLink : undefined;
    const display_listen_live = context.channelGuide.fields.display_listen_live;
    const [genre, setGenre] = useState('All');

    const genreList: Genrelist = [
        [
            { value: 'All', label: 'All News' },
            { value: 'News/Public Radio', label: 'News/Public Radio' },
            { value: 'Politics/Issues', label: 'Politics/Issues' },
        ],
    ];

    return (
        <>
            <ChannelHeader genreList={genreList} onGenreChange={setGenre} />
            {sortedChannels && sortedChannels[categoryMap['News & Issues']] && sortedChannels[categoryMap['News & Issues']][genre] && (
                <ChannelList
                    channels={sortedChannels[categoryMap['News & Issues']][genre]}
                    upsellLink={upsellLink}
                    display_listen_live={display_listen_live}
                />
            )}
        </>
    );
}
