export function required(value: string): boolean {
    return !!value;
}

export function matches(value: string, match: string | number): boolean {
    return value === `${match}`;
}

export function matchesCaseInsensitive(value: string, match: string | number): boolean {
    return value.toLowerCase() === `${match}`.toLowerCase();
}

export function istrue(value: string): boolean {
    return value === 'true';
}

export function min(value: string, minimum: number): boolean {
    return parseInt(value) >= minimum;
}

export function max(value: string, maximum: number): boolean {
    return parseInt(value) <= maximum;
}

export function minlength(value: string, length: number): boolean {
    return `${value}`.length >= length;
}

export function maxlength(value: string, length: number): boolean {
    return `${value}`.length <= length;
}

export function email(value: string): boolean {
    const rx = /^.+@.+\..{2,3}$/;
    return rx.test(value);
}

type ifValueHandler = (value: string) => boolean;
export function ifValue(value: string, handler: ifValueHandler) {
    if (!value) {
        return true;
    }

    return handler(value);
}
