'use client';
import dynamic from 'next/dynamic';
import { RenderRTE } from '@/components/RenderRTE';
import { WidgetHero } from '@/components/molecules/Hero';
import styles from './hero.module.scss';
import { ChannelHeroCard } from '@/components/molecules/ChannelHeroCard';
import { Link } from '@/components/atoms/Link';
import { CSSProperties, ComponentProps, useRef, useState, useEffect, Fragment } from 'react';
import { useLiveChannel } from '@/hooks';
import { StickyHeaderFooterCta } from '@/components/molecules/StickyHeaderFooterCta';
import { convertContentToLinkTarget as setTarget } from '@/packages/linkHandler';
import { getCookie } from 'cookies-next';
import { getXMChannelSchedules } from '@/packages/sxm/epg';
const AudioIcon = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/AnimatedIcons/AudioIcon/AudioIcon'));
const HeroBanner = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/HeroBanner/HeroBanner'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import { SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import { Document } from '@contentful/rich-text-types';

interface Props {
    breadcrumbs?: Link[];
    channelContent?: ChannelContentProps;
    channelArt?: string;
    channelEmail?: SiteLink[];
    channelEmailAddress?: string;
    channelPollLinks?: SiteLink[];
    channelName?: string;
    channelSiriusNumber?: number;
    channelXmNumber?: number;
    channelId: string;
    channelGenreKey?: string;
    channelSocialText?: string;
    channelPrimaryColor?: string;
    channelSecondaryColor?: string;
    channelShortDescription?: Document;
    channelLongDescription?: Document;
    channelNotification?: string;
    channelDeepLinkToPlayerUrl?: string;
    channelLogoUrl?: string;
    channelDisplayName?: string;
    channelExplicitContent?: boolean;
    channelPhone?: SiteLink[];
    socialIcons?: SocialIconProps;
    asW2?: boolean;
}
interface ChannelContentProps {
    title: string;
    artist: string;
}

interface SocialIconProps {
    x?: string;
    facebook?: string;
    instagram?: string;
    youtube?: string;
    tiktok?: string;
}

export default function Hero({
    breadcrumbs,
    channelContent,
    channelArt,
    channelEmail,
    channelEmailAddress,
    channelPollLinks,
    channelId,
    channelGenreKey,
    socialIcons,
    channelSocialText,
    channelName,
    channelSiriusNumber,
    channelXmNumber,
    channelLogoUrl,
    channelPrimaryColor,
    channelSecondaryColor,
    channelShortDescription,
    channelLongDescription,
    channelNotification,
    channelDeepLinkToPlayerUrl,
    channelDisplayName,
    channelExplicitContent,
    channelPhone,
    asW2,
}: Props) {
    const [album, setAlbum] = useState({
        title: channelContent?.title || '',
        artist: channelContent?.artist || '',
        art: channelArt || '',
    });

    const [sxmPlatformCookie, setSxmPlatformCookie] = useState<string>();

    const stickyRef = useRef<HTMLDivElement>(null);

    const email = channelEmail && channelEmail[0] ? channelEmail[0].fields.email : channelEmailAddress;

    const pollLinks: JSX.Element[] = [];
    const mobilePollLinks: JSX.Element[] = [];
    if (channelPollLinks && channelPollLinks.length) {
        for (const link of channelPollLinks) {
            if (link.fields.href) {
                pollLinks.push(
                    <Text tag="div" key={link.cms_id} variant="small" className={styles.bannerText}>
                        {link.fields.link_caption}{' '}
                        <Link
                            href={link.fields.href}
                            target={setTarget(link.fields?.link_target)}
                            aria-label={link.fields?.aria_label}
                        >
                            {link.fields?.link_text}
                        </Link>
                    </Text>,
                );
                mobilePollLinks.push(
                    <Text tag="div" key={link.cms_id} variant="small" className={styles.mobileDetailText}>
                        {link.fields.link_caption} <br />
                        <Link
                            href={link.fields.href}
                            target={setTarget(link.fields?.link_target)}
                            aria-label={link.fields?.aria_label}
                        >
                            {link.fields?.link_text}
                        </Link>
                    </Text>,
                );
            }
        }
    }

    useLiveChannel([channelId], (response: MountainResponse) => {
        const nowPlayingChannel = response.channels;
        const firstKey = response?.channels ? Object.keys(response.channels)[0] : undefined;
        const category = (firstKey && response.channels?.[firstKey].category) || channelGenreKey;

        if (response?.channels && firstKey && category !== 'music') {
            getChannelEpg(response.channels[firstKey].channel_id);
        } else if (nowPlayingChannel) {
            setAlbum({
                title: nowPlayingChannel[channelId].content.title || '',
                artist:
                    (nowPlayingChannel[channelId].content.artists &&
                        nowPlayingChannel[channelId].content.artists[0]?.name) ||
                    '',
                art: nowPlayingChannel[channelId].content.album?.art || '',
            });
        }
    });

    const getChannelEpg = async (channelId: string) => {
        const channelEpg = await getXMChannelSchedules(channelId);
        setAlbum({
            title: channelEpg?.nowplaying?.[0]?.name || '',
            artist: '',
            art: channelEpg?.nowplaying?.[0]?.logo,
        });
    };

    const socialMedia: SocialMediaIconLinksProps = {
        className: styles.socialmedia,
        items: [],
    };

    ['x', 'facebook', 'instagram', 'youtube', 'tiktok'].forEach((socialName) => {
        if (socialIcons?.[socialName as keyof typeof socialIcons]) {
            socialMedia.items.push({
                name: socialName as SocialMediaNames,
                link: {
                    ariaLabel: `${channelName} ${socialName}`,
                    href: socialIcons[socialName as keyof typeof socialIcons] as string,
                },
            });
        }
    });

    const stickyHeaderProps: ComponentProps<typeof StickyHeaderFooterCta> = {
        ctaOption: 'button',
        cta: {
            href: channelDeepLinkToPlayerUrl,
            theme: 'primary',
            targetBlank: true,
            text: 'listen live',
            type: 'button',
        },
        planName: <h3 className={styles.stickyChannelName}>{channelName}</h3>,
        highlight: channelSiriusNumber ? (
            <h3 className={styles.stickyChannelNumber}>Channel {channelSiriusNumber}</h3>
        ) : (
            <></>
        ),
        logoImageUrl: channelLogoUrl,
        triggerRef: stickyRef,
        borderColor: channelPrimaryColor,
        alwaysVisibleInMobile: true,
    };

    useEffect(() => {
        const getPlatformCookie = () => {
            const sxmCookie = getCookie('sxm_platform');

            if (sxmCookie) {
                setSxmPlatformCookie(sxmCookie);
            }
        };

        getPlatformCookie();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSirius = sxmPlatformCookie === 'sirius';
    const channelNumber = isSirius ? channelSiriusNumber : channelXmNumber;

    let channelMessage = `Channel ${channelNumber}`;
    if (isSirius && !channelNumber) {
        channelMessage = 'Channel not available for Sirius Radio';
    } else if (!isSirius && !channelNumber) {
        channelMessage = 'Channel not available for XM Radio';
    }

    const description = (
        <>
            <Text tag="span" variant="bold" className={styles.channelNumber}>
                {channelMessage}
            </Text>
            <RenderRTE contentfulDoc={channelLongDescription} removePTag />
            {channelNotification && (
                <Text tag="span" className={styles.channelNotification}>
                    {channelNotification}
                </Text>
            )}
            {socialMedia?.items?.length > 0 && (
                <Text tag="span" variant="bold" className={styles.socialText}>
                    {channelSocialText}
                </Text>
            )}
        </>
    );

    socialMedia.className = styles.socialmedia;

    const card = (
        <div className={styles.channelHeroCardWrapper}>
            <ChannelHeroCard
                color={channelPrimaryColor || 'var(--black)'}
                channelCardProps={{
                    listenLink: channelDeepLinkToPlayerUrl ?? '',
                    listenLinkAriaLabel: `listen live to ${channelName}`,
                    title: album.title,
                    subTitle: album.artist,
                    borderColor: channelPrimaryColor || 'var(--black)',
                    radioImageUrl: channelLogoUrl || '',
                    radioImageAlt: '',
                    albumImageSrc: album.art,
                    albumImageAlt: '',
                }}
            />
        </div>
    );

    const mobileDetails = (
        <>
            <div className={styles.mobileDetails}>
                {email && (
                    <Text tag="div" variant="small" className={styles.mobileDetailText}>
                        <Link href={`mailto:${email}`}>{email}</Link>
                    </Text>
                )}
                {channelPhone?.map((phone, i) => (
                    <Text key={i} tag="div" variant="small" className={styles.mobileDetailText}>
                        <Link href={`tel:${phone.fields.phone}`}>{phone.fields.link_text}</Link>
                    </Text>
                ))}
                {mobilePollLinks}
                <div className={styles.audioIconWrapper}>
                    <span>Now Playing</span> <AudioIcon fillColor={channelPrimaryColor || 'var(--white)'} />
                    <div className={styles.radioTextInfo}>
                        <Text tag="p">{album.title}</Text>
                        <Text tag="p">{album.artist}</Text>
                    </div>
                </div>
            </div>
        </>
    );

    const heroCustomProperties = {
        '--channelPrimaryColor': channelPrimaryColor,
    } as CSSProperties;

    return (
        <>
            <div style={heroCustomProperties}>
                <WidgetHero
                    ref={stickyRef}
                    backgroundColor={channelSecondaryColor || 'var(--bg-blue)'}
                    className={styles.channelsHeroWrapper}
                    widget={card}
                    text={{
                        breadcrumbs: breadcrumbs,
                        description: description,
                        header: <>{channelDisplayName}</>,
                        subhead: (
                            <div className={styles.subhead}>
                                <RenderRTE contentfulDoc={channelShortDescription} pToSpan asW2={asW2} />
                                {channelExplicitContent ? (
                                    <div role="img" aria-label="Explicit language" className={styles.explicitContent}>
                                        XL
                                    </div>
                                ) : null}
                            </div>
                        ),
                        socialMedia: socialMedia,
                        customComponent: mobileDetails,
                    }}
                />
            </div>
            <HeroBanner className={styles.banner}>
                {email && (
                    <Text tag="div" variant="small" className={styles.bannerText}>
                        <Link href={`mailto:${email}`}>{email}</Link>
                    </Text>
                )}
                {Boolean(channelPhone) && (
                    <Text tag="div" variant="small" className={styles.bannerText}>
                        {channelPhone?.map((phone, i) => {
                            const pipe = i < channelPhone.length - 1 ? <span className={styles.delimeter}></span> : '';
                            return (
                                <Fragment key={i}>
                                    <Link href={`tel:${phone.fields.phone}`} className={styles.phoneLink}>
                                        {phone.fields.link_text}
                                    </Link>
                                    {pipe}
                                </Fragment>
                            );
                        })}
                    </Text>
                )}
                {pollLinks}
            </HeroBanner>
            <StickyHeaderFooterCta {...stickyHeaderProps} />
        </>
    );
}
