interface Props {
    str: string;
}

export default function stringSplit({ str = ''}:Props) : React.ReactNode[] {
    const words = str.split(' ');
    
    return words.flatMap((word, index) => {
        const chars = word.split('').flatMap((char, index) => {
            if(char !== '\n') {
                return [<span className='char' key={index}>{char}</span>]
            } else {
                return [<br key={index} />]
            }
        });

        return <>
            <span className='word' key={index}>{chars}</span>
            { (index < words.length-1) && <span className='space'> </span> }
        </>
    }) as React.ReactNode[];
}