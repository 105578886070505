import { Form } from './Form';
import { Trigger } from './Trigger';
import { Modal } from './Modal';
import { InfotainmentModal } from './InfotainmentModal';

export const YMM = {
    Form: Form,
    Trigger: Trigger,
    Modal: Modal,
    InfotainmentModal
};
