'use client';

import useCallCenter from '@/hooks/useCallCenter';

interface Props {
    online: React.ReactNode;
    offline: React.ReactNode;
}

export default function CallCenterWrapper({ online, offline }: Props): JSX.Element {
    const isOnline = useCallCenter();

    if (isOnline) {
        return <>{online}</>;
    }

    return <>{offline}</>;
}
