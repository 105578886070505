import cn from 'classnames';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import styles from './offerdetails.module.scss';

interface OfferDetailsProps {
    shortDescription?: string | React.ReactNode;
    mediumDescription?: string | React.ReactNode;
    mediumDescriptionSmall?: string | React.ReactNode;
    longDescription?: string | React.ReactNode;
    isHighlighted?: boolean;
    isNarrow?: boolean;
    className?: string;
}

export default function OfferDetails({
    shortDescription,
    mediumDescription,
    mediumDescriptionSmall,
    longDescription,
    isHighlighted = false,
    isNarrow = false,
    className,
}: OfferDetailsProps): JSX.Element {
    const wrapperCn = cn(styles.planDetails, className, { [styles.narrow]: isNarrow });
    const mediumDescCn = cn(styles.medDesc, { [styles.highlighted]: isHighlighted });

    return (
        <div className={wrapperCn}>
            {shortDescription && (
                <Text tag="p" variant="medium" className={styles.shortDesc}>
                    {shortDescription}
                </Text>
            )}
            {mediumDescription && (
                <Text
                    tag="p"
                    variant="h3"
                    className={mediumDescriptionSmall ? styles.mediumDescriptionHeadline : mediumDescCn}
                >
                    {mediumDescription}
                </Text>
            )}
            {mediumDescriptionSmall && (
                <Text tag="p" className={styles.mediumDescSmall}>
                    {mediumDescriptionSmall}
                </Text>
            )}
            {longDescription && (
                <Text tag="p" variant="small">
                    {longDescription}
                </Text>
            )}
        </div>
    );
}
