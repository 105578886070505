'use client';
import dynamic from 'next/dynamic';
import { NFL_TEAMS, US_STATES, VALIDITY } from '@/constants';
import { useValidator } from '@/hooks';
import { Config, Fields } from '@/hooks/useValidator/interfaces';
import { useEffect, useState } from 'react';
import { Headline } from '@/components/molecules/Headline';
import { Link } from '@/components/atoms/Link';
import styles from './nflOptInForm.module.scss';
import { validateForm, validateInput } from '@/packages/form';
import useErrorCount from '@/hooks/useErrorCount';
import IconCheckmarkCircle from '@/assets/icons/icon-checkmark-circle.svg';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const FormErrorDisplay = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/FormErrorDisplay/FormErrorDisplay'),
);
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Fieldset = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Fieldset/Fieldset'));
const Form = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Form/Form'));
const Input = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Input/Input'));

type SubmitState = 'untouched' | 'failed' | 'success' | 'error';

interface ServerFields {
    error: string;
    message: string;
    name: string;
}

export default function NflOptInForm(): JSX.Element {
    const validationConfig: Config = {
        fields: {
            firstName: [{ type: 'required', helperText: 'First name required' }],
            lastName: [{ type: 'required', helperText: 'Last name required' }],
            email: [
                {
                    type: 'email',
                    helperText: 'Valid email address required',
                },
            ],
            streetAddress: [{ type: 'required', helperText: 'Valid street address required' }],
            city: [{ type: 'required', helperText: 'City required' }],
            state: [{ type: 'required', helperText: 'State required' }],
            zip: [{ type: 'minlength', config: 5, helperText: 'Valid zip code required' }],
            favoriteTeam: [],
        },
    };

    const validator = useValidator(validationConfig);
    const [submitState, setSubmitState] = useState<SubmitState>('untouched');
    const [validations, setValidations] = useState(validator.getDefault);
    const errorCount: number = useErrorCount(validations);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow._sxmDataLayerPush) {
            if (submitState === 'success') {
                browserWindow._sxmDataLayerPush('nfl-opt-in-success');
            } else if (submitState === 'failed') {
                browserWindow._sxmDataLayerPush('nfl-opt-in-fail');
            }
        }
    }, [submitState]);

    function handleValidateForm(event: React.SyntheticEvent) {
        return validateForm(event, validations, setValidations, validator);
    }

    function handleValidateInput(event: React.SyntheticEvent) {
        validateInput(event, validations, setValidations, validator);
    }

    function validateSelect(input: HTMLInputElement) {
        if (input.value !== '') {
            const newValidations = validator.validateField(input);
            const updatedValidations = { ...validations, ...newValidations };
            setValidations(updatedValidations);
            return updatedValidations.form.invalid === VALIDITY.valid;
        }
    }

    function beforeSubmit(data: FormData) {
        const zip = data.get('zip') as string;
        data.set('zip', zip.replace(/\D/g, '').substring(0, 5));
        data.set('languagePreference', 'en-US');

        return data;
    }

    async function formSuccess(response: Response) {
        const json = await response.json();

        if (json?.status === 'ERROR') {
            const serverValidations: Fields = {};

            json.formSubmitResponse.errors.fields.forEach((field: ServerFields) => {
                serverValidations[field.name] = {
                    value: validations[field.name].value,
                    invalid: VALIDITY.invalid,
                    validations: { ...validations[field.name].validations },
                    helperText: { ...validations[field.name].helperText },
                };

                if (field.name === 'form') {
                    if (field.error === 'unknown') {
                        setSubmitState('failed');
                    }
                } else {
                    delete serverValidations.form;
                }
            });

            setValidations({ ...validations, ...serverValidations });
        } else if (json?.status === 'SUCCESS') {
            setSubmitState('success');
        }
    }

    function formFail() {
        setSubmitState('failed');
    }

    function renderSubmitMessage() {
        if (submitState === 'success') {
            return (
                <div className={styles.success}>
                    <IconCheckmarkCircle />
                    <Text tag="h2" variant="h4">
                        Thanks for signing up.
                    </Text>
                    <Text tag="p">You are now signed up to get all the latest league news.</Text>
                </div>
            );
        }

        if (submitState === 'failed') {
            return (
                <>
                    <Headline desktopCols={10} tag="h2" className="h2">
                        There is something wrong :(
                    </Headline>
                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Text tag="p" style={{ paddingTop: 30 }}>
                                Please check and try submitting again
                            </Text>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    return (
        <>
            <div aria-live="polite">{renderSubmitMessage()}</div>

            {submitState === 'untouched' ? (
                <Form
                    action="/phx/services/slam/utility/nfl-opt-in"
                    method="POST"
                    contentType="json"
                    onSubmit={handleValidateForm}
                    onBeforeSubmit={beforeSubmit}
                    onSuccess={formSuccess}
                    onFail={formFail}
                    onChange={handleValidateForm}
                    novalidate={true}
                    data-form-name="FormNFLOptIn"
                    data-cy="nfl-opt-in-form-form"
                >
                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Fieldset theme="themeGrey" legend="">
                                <Grid container>
                                    <Grid col={{ xs: 12 }}>
                                        <FormErrorDisplay errorCount={errorCount} />

                                        <Input
                                            type="text"
                                            name="firstName"
                                            label="First Name"
                                            invalid={validations.firstName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.firstName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            invalid={validations.lastName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.lastName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="email"
                                            label="Account Email"
                                            invalid={validations.email.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.email.helperText.email}
                                        />

                                        <Input
                                            type="text"
                                            name="streetAddress"
                                            label="Address"
                                            invalid={validations.streetAddress.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.streetAddress.helperText.required}
                                        />
                                    </Grid>

                                    <Grid col={{ xs: 12 }}>
                                        <Input
                                            type="text"
                                            name="city"
                                            label="City"
                                            invalid={validations.city.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.city.helperText.required}
                                        />

                                        <Grid container>
                                            <Grid col={{ xs: 12, lg: 6 }}>
                                                <Select
                                                    name="state"
                                                    type="form"
                                                    theme="outline"
                                                    label="State"
                                                    onChange={validateSelect}
                                                    invalid={validations.state.invalid}
                                                    onBlur={handleValidateInput}
                                                    invalidMessage={validations.state.helperText.required}
                                                    className={styles.select}
                                                >
                                                    <OptionGroup>
                                                        {US_STATES.map((state, index) => (
                                                            <Option key={index} value={state.abbreviation}>
                                                                {state.name}
                                                            </Option>
                                                        ))}
                                                    </OptionGroup>
                                                </Select>
                                            </Grid>

                                            <Grid col={{ xs: 12, lg: 6 }}>
                                                <Input
                                                    type="zip"
                                                    name="zip"
                                                    label="Zip Code"
                                                    invalid={validations.zip.invalid}
                                                    required={true}
                                                    onBlur={handleValidateInput}
                                                    invalidMessage={validations.zip.helperText.minlength}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid col={{ xs: 12 }}>
                                        <Select
                                            name="favoriteTeam"
                                            type="form"
                                            theme="outline"
                                            label="What is your favorite NFL team? (Optional)"
                                            ariaLabel="Optional: What is your favorite NFL team?"
                                        >
                                            <OptionGroup>
                                                {NFL_TEAMS.map((team, index) => (
                                                    <Option key={index} value={team}>
                                                        {team}
                                                    </Option>
                                                ))}
                                            </OptionGroup>
                                        </Select>

                                        <div className={styles.agreementContainer}>
                                            <div>
                                                <Link href="https://www.nfl.com/legal/privacy/">Privacy Policy</Link>.
                                            </div>

                                            <p>
                                                By clicking sign up, I consent to SiriusXM sharing my name, email and
                                                contact information with the NFL (
                                                <Link href="http://www.nfl.com/">www.nfl.com</Link>
                                                ). I acknowledge that use of my personal information by the NFL will be
                                                governed by the{' '}
                                                <Link href="https://www.nfl.com/legal/privacy/">
                                                    NFL Privacy Policy
                                                </Link>
                                                .
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Fieldset>
                        </Grid>

                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            {validations.form && validations.form.validations.formerror && (
                                <StyledContainer paddingTop={40}>
                                    <Text tag="div" style={{ color: 'var(--red)', fontWeight: 'bold' }} role="alert">
                                        {validations.form.helperText.formerror}
                                    </Text>
                                </StyledContainer>
                            )}

                            <Button
                                type="submit"
                                text="sign up"
                                ariaLabel="submit contact us"
                                theme="primary"
                                buttonSize="full-width"
                            />
                        </Grid>
                    </Grid>
                </Form>
            ) : undefined}
        </>
    );
}
