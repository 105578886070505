import cn from 'classnames';
import { ComponentProps, Ref, forwardRef } from 'react';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import { Hero } from '../Hero';
import styles from '../hero.module.scss';
import HeroText from '../slots/HeroText';

interface Props {
    text: ComponentProps<typeof HeroText>;
    foregroundImage: {
        tablet?: Partial<ImageProps>;
        mobile?: Partial<ImageProps>;
        stickToBottom?: boolean;
    };
    darkTheme?: boolean;
}

const ImageHero = forwardRef(function (props: Props & ComponentProps<typeof Hero>, ref: Ref<HTMLElement>) {
    const { foregroundImage, text: textProps, ...heroProps } = props;
    const imageClassNames = cn(styles.foregroundImageWrapper, {
        [styles.imageToBottom]: foregroundImage?.stickToBottom,
    });

    return (
        <Hero
            ref={ref}
            {...heroProps}
            leftSlot={{
                content: (
                    <HeroText
                        {...textProps}
                        mobileFlatBackground={!!foregroundImage?.mobile?.source}
                        darkTheme={props.darkTheme}
                    />
                ),
            }}
            rightSlot={
                !!foregroundImage?.tablet?.source
                    ? {
                          content: (
                              <div className={imageClassNames}>
                                  <ImageWrapper
                                      src={foregroundImage.tablet.source}
                                      alt={foregroundImage?.tablet?.altText || ''}
                                      optimizedHeight={foregroundImage?.tablet?.height}
                                      optimizedWidth={foregroundImage?.tablet?.width}
                                      loadingStrategy="eager"
                                  />
                              </div>
                          ),
                      }
                    : undefined
            }
        />
    );
});

ImageHero.displayName = 'ImageHero';
export default ImageHero;
