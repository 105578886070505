'use client';
import dynamic from 'next/dynamic';
import { US_STATES, VALIDITY } from '@/constants';
import { useValidator } from '@/hooks';
import { Config, Fields } from '@/hooks/useValidator/interfaces';
import { useEffect, useRef, useState } from 'react';
import { Headline } from '@/components/molecules/Headline';
import globalProperties from '@/../properties/global';
import { Link } from '@/components/atoms/Link';
import { validateForm, validateInput } from '@/packages/form';
import useErrorCount from '@/hooks/useErrorCount';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const Checkbox = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Checkbox/Checkbox'));
const FormErrorDisplay = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/FormErrorDisplay/FormErrorDisplay'),
);
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Fieldset = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Fieldset/Fieldset'));
const Form = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Form/Form'));
import Input from 'de-sxm-reactlib/dist/components/atoms/Inputs/Input/Input';
type SubmitState = 'untouched' | 'failed' | 'success' | 'error' | 'duplicate';

interface ServerFields {
    error: string;
    message: string;
    name: string;
}

export default function FreeTrialForm(): JSX.Element {
    const emailRef = useRef<HTMLInputElement>(null);
    const confirmEmailRef = useRef<HTMLInputElement>(null);

    const validationConfig: Config = {
        fields: {
            FirstName: [{ type: 'required', helperText: 'First name required' }],
            LastName: [{ type: 'required', helperText: 'Last name required' }],
            Email: [
                {
                    type: 'email',
                    helperText: 'Valid email address required',
                    validateAlso: [confirmEmailRef.current],
                },
            ],
            confirmEmail: [
                { type: 'matchesCaseInsensitive', config: emailRef.current, helperText: 'Your email must match' },
                { type: 'email', helperText: 'Your email must match' },
            ],
            Phone: [{ type: 'minlength', config: 14, helperText: 'Valid phone number required' }],
            Company: [{ type: 'required', helperText: 'Company name required' }],
            agree: [{ type: 'istrue', helperText: 'Please accept the terms' }],
            BusinessAddress: [],
            City: [],
            State: [],
            ZipCode: [],
        },
    };

    const validator = useValidator(validationConfig);
    const [submitState, setSubmitState] = useState<SubmitState>('untouched');
    const [validations, setValidations] = useState(validator.getDefault);
    const errorCount: number = useErrorCount(validations);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow._sxmDataLayerPush) {
            if (submitState === 'success') {
                browserWindow._sxmDataLayerPush('contact-us-success');
            } else if (submitState === 'failed') {
                browserWindow._sxmDataLayerPush('contact-us-fail');
            }
        }
    }, [submitState]);

    function handleValidateForm(event: React.SyntheticEvent) {
        return validateForm(event, validations, setValidations, validator);
    }

    function handleValidateInput(event: React.SyntheticEvent) {
        validateInput(event, validations, setValidations, validator);
    }

    function beforeSubmit(data: FormData) {
        const phone = data.get('Phone') as string;
        data.set('Phone', phone.replace(/\D/g, ''));
        data.set('languagePreference', 'en-US');

        return data;
    }

    async function formSuccess(response: Response) {
        const json = await response.json();

        if (json.formSubmitResponse.errors?.fields[0]?.message === 'duplicate') {
            setSubmitState('duplicate');
        }

        if (json.formSubmitResponse.status.message === 'ERROR') {
            const serverValidations: Fields = {};

            json.formSubmitResponse.errors.fields.forEach((field: ServerFields) => {
                serverValidations[field.name] = {
                    value: validations[field.name].value,
                    invalid: VALIDITY.invalid,
                    validations: { ...validations[field.name].validations },
                    helperText: { ...validations[field.name].helperText },
                };

                if (field.name === 'form') {
                    if (field.error === 'unknown') {
                        setSubmitState('failed');
                    }
                } else {
                    delete serverValidations.form;
                }
            });

            setValidations({ ...validations, ...serverValidations });
        } else if (json.formSubmitResponse.status.message === 'SUCCESS') {
            setSubmitState('success');
        }
    }

    function formFail() {
        setSubmitState('failed');
    }

    function renderSubmitMessage() {
        if (submitState === 'success') {
            return (
                <>
                    <Headline desktopCols={10} tag="h3" className="h4">
                        Thanks for your inquiry, we will get back to you as soon as possible.
                    </Headline>
                </>
            );
        }

        if (submitState === 'failed') {
            return (
                <>
                    <Headline desktopCols={10} tag="h3" className="h4">
                        There is something wrong :(
                    </Headline>
                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Text tag="p" style={{ paddingTop: 30 }}>
                                Please check and try submitting again
                            </Text>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    return (
        <>
            <div aria-live="polite">{renderSubmitMessage()}</div>

            {submitState === 'untouched' || submitState === 'duplicate' ? (
                <Form
                    action="/p/formsubmit/SaveDataServlet"
                    method="POST"
                    contentType="urlencoded"
                    onSubmit={handleValidateForm}
                    onBeforeSubmit={beforeSubmit}
                    onSuccess={formSuccess}
                    onFail={formFail}
                    onChange={handleValidateForm}
                    novalidate={true}
                    data-form-name="bussiness-contact-us"
                >
                    <input type="hidden" name="formId" value="4" />
                    <input type="hidden" name="environment" value={globalProperties.pii.environment} />
                    <input type="hidden" name="evflag" value="" />
                    <input type="hidden" name="guid" value="" />

                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <FormErrorDisplay errorCount={errorCount} />

                            <Fieldset theme="themeGrey" legend="Contact Information">
                                <Grid container>
                                    <Grid col={{ xs: 12, lg: 6 }}>
                                        <Input
                                            type="text"
                                            name="FirstName"
                                            label="First Name"
                                            invalid={validations.FirstName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.FirstName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="LastName"
                                            label="Last Name"
                                            invalid={validations.LastName.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.LastName.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="Email"
                                            label="Account Email"
                                            ref={emailRef}
                                            invalid={validations.Email.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.Email.helperText.email}
                                        />

                                        <Input
                                            type="text"
                                            name="confirmEmail"
                                            label="Confirm Account Email"
                                            ref={confirmEmailRef}
                                            invalid={validations.confirmEmail.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.confirmEmail.helperText.matchesCaseInsensitive}
                                        />

                                        <Input
                                            type="tel"
                                            name="Phone"
                                            label="Phone"
                                            invalid={validations.Phone.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.Phone.helperText.minlength}
                                        />
                                    </Grid>

                                    <Grid col={{ xs: 12, lg: 6 }}>
                                        <Input
                                            type="text"
                                            name="Company"
                                            label="Company Name"
                                            invalid={validations.Company.invalid}
                                            onBlur={handleValidateInput}
                                            required={false}
                                            invalidMessage={validations.Company.helperText.required}
                                        />

                                        <Input
                                            type="text"
                                            name="BusinessAddress"
                                            label="Business Address (Optional)"
                                            required={false}
                                            ariaLabel="Optional: Business Address"
                                        />

                                        <Input
                                            type="text"
                                            name="City"
                                            label="City (Optional)"
                                            required={false}
                                            ariaLabel="Optional: City"
                                        />

                                        <Grid container>
                                            <Grid col={{ xs: 6 }}>
                                                <Select
                                                    name="state"
                                                    type="form"
                                                    theme="outline"
                                                    label="State (Optional)"
                                                    ariaLabel="Optional: State"
                                                >
                                                    <OptionGroup>
                                                        {US_STATES.map((state, index) => (
                                                            <Option key={index} value={state.abbreviation}>
                                                                {state.name}
                                                            </Option>
                                                        ))}
                                                    </OptionGroup>
                                                </Select>
                                            </Grid>

                                            <Grid col={{ xs: 6 }}>
                                                <Input
                                                    type="zip"
                                                    name="ZipCode"
                                                    label="Zip Code (Optional)"
                                                    required={false}
                                                    ariaLabel="Optional: Zip Code"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Checkbox
                                            name="agree"
                                            value="Y"
                                            invalid={validations.agree.invalid}
                                            required={true}
                                            onBlur={handleValidateInput}
                                            invalidMessage={validations.agree.helperText.istrue}
                                        >
                                            <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                                I have read and agree to SiriusXM Music for Business Trial&nbsp;
                                                <Link href="https://www.siriusxm.com/content/dam/sxm-com/pdf/corporate-pdf/SXMWebTermsOfUse_english1.pdf?intcmp=Search%20Result_NA_sxm:search-phx_LegalLegacyPDF">
                                                    Terms
                                                </Link>
                                            </Text>
                                        </Checkbox>
                                    </Grid>
                                </Grid>
                            </Fieldset>
                        </Grid>

                        <Grid col={{ xs: 12, lg: 6 }} start={{ lg: 4 }}>
                            {validations.form && validations.form.validations.formerror && (
                                <div role="alert">
                                    <StyledContainer paddingTop={40} textAlign="center">
                                        <Text tag="div" style={{ color: 'var(--red)', fontWeight: 'bold' }}>
                                            {validations.form.helperText.formerror}
                                        </Text>
                                    </StyledContainer>
                                </div>
                            )}
                            {submitState === 'duplicate' && (
                                <div role="alert">
                                    <StyledContainer paddingTop={40} textAlign="center">
                                        <Text
                                            tag="div"
                                            style={{ color: 'var(--red)', fontWeight: 'bold' }}
                                            role="alert"
                                        >
                                            Sorry, only people who have not signed up for a trial before are eligible.
                                        </Text>
                                    </StyledContainer>
                                </div>
                            )}
                            <Button
                                type="submit"
                                text="SUBMIT"
                                ariaLabel="submit contact us"
                                theme="primary"
                                buttonSize="full-width"
                            />
                        </Grid>
                    </Grid>
                </Form>
            ) : undefined}
        </>
    );
}
