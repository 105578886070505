'use client';
import dynamic from 'next/dynamic';
import { Link as NextLink } from '@/components/atoms/Link';
import { LinkInfoItem } from '@/components/molecules/LinkInfoCard';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
import { ButtonTheme } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import { useEffect, useState } from 'react';

interface Props {
    type: LinkType | undefined;
    theme?: ButtonTheme;
    bgColor?: string;
    text: string | undefined;
    iconUrl?: string;
}

export default function ChatLink({ type, theme = 'text', bgColor, text, iconUrl }: Props): JSX.Element {
    const [component, setComponent] = useState(<></>);

    useEffect(() => {
        const clientWindow = window as BrowserWindow;

        function openChat(e: React.MouseEvent) {
            e.preventDefault();
            if (clientWindow && clientWindow.sierraSXM) {
                clientWindow.sierraSXM.openChatModal();
            }
        }

        if (type) {
            if (type.indexOf('CTA') >= 0) {
                setComponent(
                    <Button type="button" theme={theme} buttonColor={bgColor} text={text} onClick={openChat} />,
                );
            } else if (type === 'Text') {
                setComponent(
                    <NextLink href="" onClick={openChat}>
                        {text}
                    </NextLink>,
                );
            } else if (type === 'LinkInfoCard') {
                setComponent(
                    <LinkInfoItem
                        link={{
                            link_text: text,
                        }}
                        disabled={false}
                        onClick={openChat}
                        icon={iconUrl}
                    />,
                );
            }
        }
    }, [bgColor, iconUrl, text, theme, type]);

    return component;
}
