import { LeagueFilters } from './interfaces';

const filters: LeagueFilters = {
    default: { schedule: 'daily', conference: false },
    mlb: { schedule: 'monthly', conference: false },
    nba: { schedule: 'daily', conference: false },
    nhl: { schedule: 'daily', conference: false },
    nfl: { schedule: 'weekly', conference: false, weekStart: 4 },
    ncaaf: { schedule: 'weekly', conference: true, weekStart: 4 },
    ncaab: { schedule: 'daily', conference: true },
    wncaab: { schedule: 'daily', conference: true },
    ncaabb: { schedule: 'daily', conference: true },
    ncaah: { schedule: 'daily', conference: true },
    soc: { schedule: 'monthly', conference: true },
    nascar: { schedule: 'monthly', conference: false },
    yachting: { schedule: 'monthly', conference: false },
    lemans: { schedule: 'monthly', conference: false },
    boxing: { schedule: 'monthly', conference: false },
    f1: { schedule: 'monthly', conference: false },
    golf: { schedule: 'monthly', conference: false },
    horseracing: { schedule: 'monthly', conference: false },
    imsa: { schedule: 'monthly', conference: false },
    lpga: { schedule: 'monthly', conference: false },
    motogp: { schedule: 'monthly', conference: false },
    nascarsprint: { schedule: 'monthly', conference: false },
    nascarcamping: { schedule: 'monthly', conference: false },
    nascarnationwide: { schedule: 'monthly', conference: false },
    pga: { schedule: 'monthly', conference: false },
    poker: { schedule: 'monthly', conference: false },
    skiing: { schedule: 'monthly', conference: false },
    summergames: { schedule: 'monthly', conference: false },
    tennis: { schedule: 'monthly', conference: false },
    indycar: { schedule: 'monthly', conference: false },
};

export default filters;
