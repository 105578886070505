'use client';
import { useEffect, useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import { MediaWithTextProps } from '../MediaWithText/interfaces';
import styles from './pulsecarousel.module.scss';
import { useSearchParams } from 'next/navigation';
import { getPulseAnalyticsAttributes } from '@/packages/pulse';
import Link from 'next/link';
import { PULSE } from '@/constants/pulse/pulse';
import WhiteArrow from '@/assets/icons/icon-white-arrow.svg';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

interface PulseCarouselProps {
    mediaWithTextProps: MediaWithTextProps[];
}

const PulseCarousel = ({ mediaWithTextProps }: PulseCarouselProps) => {
    const [activeTab, setActiveTab] = useState(0);
    const [initCarousel, setInitCarousel] = useState(true);
    const searchParams = useSearchParams();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(0);
    const [dragOffset, setDragOffset] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);

    const updateCarousel = (index: number) => {
        //Set offset and transformation effect to animate mobile carousel
        if (carouselRef.current) {
            const offset = -index * 100;
            carouselRef.current.style.transition = 'transform 0.5s ease-in-out';
            carouselRef.current.style.transform = `translateX(${offset}vw)`;
            setActiveTab(index);
        }
    };

    const handleThumbnailClick = (index: number, item: MediaWithTextProps) => {
        //Selecting thumbnail tab on mobile carousel, set carousel active tile & send anayltics with handlePanelClick
        setCurrentIndex(index);
        updateCarousel(index);
        handlePanelClick(index, item);
    };

    const handleTouchStart = (event: React.TouchEvent) => {
        //On mobile swipe start, set isDragging to true to handle swipe in handleTouchMove
        setIsDragging(true);
        setDragStartX(event.touches[0].clientX);
        if (carouselRef.current) {
            carouselRef.current.style.transition = 'none';
        }
    };

    const handleTouchMove = (event: React.TouchEvent) => {
        //On mobile user swipe, offset element to give user feedback on swiped carousel
        if (isDragging) {
            const currentX = event.touches[0].clientX;
            const offset = currentX - dragStartX;
            setDragOffset(offset);
            if (carouselRef.current) {
                const baseOffset = -currentIndex * 100;
                const dragPercentage = (offset / carouselRef.current.clientWidth) * 100;
                carouselRef.current.style.transform = `translateX(${baseOffset + dragPercentage}vw)`;
            }
        }
    };

    const handleTouchEnd = () => {
        //On mobile swipe end, set isDragging to false and snap to desired tile based on swiped direction
        setIsDragging(false);
        if (dragOffset > 50 && currentIndex > 0) {
            // Swipe right
            setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
            handlePanelClick(currentIndex - 1, mediaWithTextProps[currentIndex - 1]);
        } else if (dragOffset < -50 && currentIndex < mediaWithTextProps.length - 1) {
            // Swipe left
            setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, mediaWithTextProps.length - 1));
            handlePanelClick(currentIndex + 1, mediaWithTextProps[currentIndex + 1]);
        } else {
            // Snap back to the current item
            updateCarousel(currentIndex);
        }
        setDragOffset(0);
    };

    useEffect(() => {
        updateCarousel(currentIndex);
    }, [currentIndex]);

    useEffect(() => {
        const initTimeout = setTimeout(() => {
            setInitCarousel(false);
        }, 1500);
        return () => {
            clearTimeout(initTimeout);
        };
    }, []);

    const handlePanelClick = (index: number, item: MediaWithTextProps) => {
        const clickInfo: { [key: string]: string } = {
            name: item.link?.link_text || '',
            pageName: document.querySelector('meta[name="sxm:analyticsPageName"]')?.getAttribute('content') || '',
            position: 'PulseCarousel',
            linkKey: 'PulseCarousel',
            type: 'ui',
        };

        if (Array.isArray(item.link?.analytics_tags)) {
            item.link?.analytics_tags.forEach((tag: { key: string; value: string }) => {
                clickInfo[tag.key] = tag.value;
            });
        }

        if (window._sxmDataLayerPush) {
            window._sxmDataLayerPush('user-click', { clickInfo });
        }

        setActiveTab(index);
    };

    if (mediaWithTextProps.length !== 4) {
        return (
            <Grid container>
                <Grid col={{ xs: 12, lg: 12 }}>
                    <p>The Pulse Carousel requires exactly 4 items.</p>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            <div className={styles.gridContainer}>
                <Grid container className={styles.gridContainer}>
                    <Grid col={{ xs: 12, lg: 12 }}>
                        <div className={`${styles.carouselContainer} ${initCarousel ? styles.initCarousel : ''}`}>
                            {mediaWithTextProps.map((item, index) => {
                                const channelNumber = item.channelAssociation
                                    ? item.channelAssociation.fields.channel_number?.toString()
                                    : null;
                                let logo = {
                                    url: '',
                                    altText: '',
                                };
                                if (item.link?.media) {
                                    logo = {
                                        url: item.link.media.url,
                                        altText: item.link.media.altText,
                                    };
                                } else if (item.channelAssociation && item.channelAssociation.fields.color_logo) {
                                    logo = {
                                        url: item.channelAssociation.fields.color_logo?.url,
                                        altText: item.channelAssociation.fields.color_logo.altText,
                                    };
                                }
                                const analyticsTags = getPulseAnalyticsAttributes(item.link?.analytics_tags || []);
                                return (
                                    <div
                                        className={`${styles.item} ${activeTab === index ? styles.active : ''}`}
                                        key={index}
                                    >
                                        <div className={styles.bgImage} onClick={() => handlePanelClick(index, item)}>
                                            <ImageWrapper
                                                src={item.image?.source || ''}
                                                alt={item.image?.altText || ''}
                                            />
                                        </div>
                                        <div className={styles.footer}>
                                            <div className={styles.footerText}>
                                                <div>
                                                    <h3>{item.headline}</h3>
                                                    <p>{item.body}</p>
                                                </div>
                                                <div className={styles.logoContainer}>
                                                    <ImageWrapper
                                                        className={styles.logo}
                                                        src={logo.url}
                                                        alt={logo.altText}
                                                    />
                                                </div>
                                            </div>
                                            <div className={styles.linkContainer}>
                                                <div className={styles.linkWrapper}>
                                                    <div className={styles.link}>
                                                        <Link
                                                            href={`${item.link?.href}?${searchParams.toString()}`}
                                                            legacyBehavior
                                                        >
                                                            <a {...analyticsTags}>{item.link?.link_text}</a>
                                                        </Link>
                                                        <span className={styles.arrow}>
                                                            <WhiteArrow
                                                                className={styles.arrow_image}
                                                                alt={PULSE.whiteArrowAltText}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={styles.channelNumber}>
                                                    {channelNumber ? `Ch. ${channelNumber}` : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/* Mobile Carousel */}
                        <div className={`${styles.mobileCarousel} ${initCarousel ? styles.initCarousel : ''}`}>
                            <div
                                className={styles.mobileCarouselContainer}
                                ref={carouselRef}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                            >
                                {mediaWithTextProps.map((item, index) => {
                                    const channelNumber = item.channelAssociation
                                        ? item.channelAssociation.fields.channel_number?.toString()
                                        : null;
                                    let logo = {
                                        url: '',
                                        altText: '',
                                    };
                                    if (item.link?.media) {
                                        logo = {
                                            url: item.link.media.url,
                                            altText: item.link.media.altText,
                                        };
                                    } else if (item.channelAssociation && item.channelAssociation.fields.color_logo) {
                                        logo = {
                                            url: item.channelAssociation.fields.color_logo?.url,
                                            altText: item.channelAssociation.fields.color_logo.altText,
                                        };
                                    }
                                    const analyticsTags = getPulseAnalyticsAttributes(item.link?.analytics_tags || []);
                                    return (
                                        <div
                                            className={`${styles.item} ${activeTab === index ? styles.active : ''}`}
                                            key={index}
                                        >
                                            <div className={styles.bgImage}>
                                                <ImageWrapper
                                                    src={item.image?.source || ''}
                                                    alt={item.image?.altText || ''}
                                                />
                                            </div>
                                            <div className={styles.footer}>
                                                <div className={styles.footerText}>
                                                    <div>
                                                        <h3>{item.headline}</h3>
                                                        <p>{item.body}</p>
                                                    </div>
                                                    <div className={styles.logoContainer}>
                                                        <ImageWrapper
                                                            className={styles.logo}
                                                            src={logo.url}
                                                            alt={logo.altText}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.linkContainer}>
                                                    <div className={styles.linkWrapper}>
                                                        <div className={styles.link}>
                                                            <Link
                                                                href={`${item.link?.href}?${searchParams.toString()}`}
                                                                legacyBehavior
                                                            >
                                                                <a {...analyticsTags}>{item.link?.link_text}</a>
                                                            </Link>
                                                            <span className={styles.arrow}>
                                                                <WhiteArrow
                                                                    className={styles.arrow_image}
                                                                    alt={PULSE.whiteArrowAltText}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className={styles.channelNumber}>
                                                        {channelNumber ? `Ch. ${channelNumber}` : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={styles.mobileCarouselTabs}>
                            {mediaWithTextProps.map((item, index) => {
                                return (
                                    <div
                                        className={`${styles.item} ${activeTab === index ? styles.active : ''}`}
                                        onClick={() => handleThumbnailClick(index, item)}
                                        key={index}
                                    >
                                        <div className={styles.thumbnailImage}>
                                            <ImageWrapper
                                                src={item.image?.source || ''}
                                                alt={item.image?.altText || ''}
                                            />
                                        </div>
                                        <div className={styles.tabIndicator}></div>
                                    </div>
                                );
                            })}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default PulseCarousel;
