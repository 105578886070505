'use client';
import { CSSProperties, RefObject, useEffect, useState } from 'react';
import styles from './stickyBox.module.scss';
import { useOnScreen, useMediaQuery } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import cn from 'classnames';

export type StickyBoxPosition = 'top' | 'bottom' | 'topDesktopBottomMobile';

export interface StickyBoxProps {
    children: React.ReactNode;
    triggerRef?: RefObject<HTMLElement>;
    scrollStartPosition?: number;
    alwaysVisibleInMobile?: boolean;
    alwaysVisibleInDesktop?: boolean;
    position?: StickyBoxPosition;
    initialTopOffset?: number;
    className?: string;
    triggerThreshold?: number;
    showClassName?: string;
    dataCy?: string;
}

export default function StickyBox({
    children,
    triggerRef,
    scrollStartPosition = undefined,
    alwaysVisibleInMobile = true,
    alwaysVisibleInDesktop = false,
    position = 'topDesktopBottomMobile',
    initialTopOffset = 0,
    className = '',
    triggerThreshold = 0,
    showClassName,
    dataCy = 'atoms-sticky-box',
}: StickyBoxProps) {
    const { isOnScreen, isAbove } = useOnScreen(triggerRef, { threshold: triggerThreshold });
    const isViewportDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const [showBox, setShowBox] = useState<boolean>(false);
    const clientWindowScrollY = typeof window !== 'undefined' ? window.scrollY : undefined;

    useEffect(() => {
        const showOnScroll = !!scrollStartPosition && isViewportDesktop;

        const handleScroll = () => !!scrollStartPosition && setShowBox(window.scrollY > scrollStartPosition);

        if (alwaysVisibleInDesktop) {
            setShowBox(true);
        } else if (!isViewportDesktop && alwaysVisibleInMobile) {
            setShowBox(true);
        } else if (showOnScroll && !!scrollStartPosition) {
            window.addEventListener('scroll', handleScroll);
            handleScroll();
        } else if (!!triggerRef && scrollStartPosition === undefined) {
            const shouldShow = isAbove && !isOnScreen;
            setShowBox(!!triggerRef && shouldShow);
        }

        // this function only works with the viewport that the page loads on.
        // currently not listening to resize, not needed for feature
        return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnScreen, isViewportDesktop, triggerRef, clientWindowScrollY]);

    const style = {
        ...{ '--offset': `${initialTopOffset}px` },
    } as CSSProperties;

    const classNames = cn(styles.box, className, {
        // shows the stickybox when the ref is off screen
        [styles.show]: showBox,
        ...(showClassName && showBox ? { [showClassName]: true } : {}),
        [styles.top]: position === 'top',
        [styles.bottom]: position === 'bottom',
        [styles.topDesktopBottomMobile]: position === 'topDesktopBottomMobile',
    });

    return (
        <div className={classNames} style={style} data-cy={dataCy}>
            {children}
        </div>
    );
}
