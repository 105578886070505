export function getLast120Years() {
    const currentYear = new Date().getFullYear();
    const years = [];

    // Loop to push the last 100 years into the array
    for (let i = 0; i < 120; i++) {
        years.push(currentYear - i);
    }

    return years.sort().reverse();
}

export function hasRequiredAge(dateString: string, restrictedAge: number): boolean {
    // Split the input string to extract year and month
    const [year, month] = dateString.split('-');

    // Convert month names to numbers
    const monthMap: { [key: string]: number } = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12,
    };
    // Extract the numeric value of the month
    const monthNumber = monthMap[month.trim()];

    // Create a date object for the given date
    const birthDate = new Date(parseInt(year), monthNumber - 1, 1); // Month is zero-indexed

    // Calculate the person's age
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the person's birthday is later this year
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    // Return true if the person's birthday has passed and they meet the restricted age
    return age >= restrictedAge;
}
