import { getContentfulComponent } from '@/components-2.0/contentful';
import { PageContext } from '@/interfaces/types/PageContext';
import Grid from 'de-sxm-reactlib-2/dist/components/atoms/Layout/Grid/Grid';
import StyledContainer from 'de-sxm-reactlib-2/dist/components/atoms/Layout/StyledContainer/StyledContainer';
import { default as RenderAssetv1 } from '@/components/RenderAsset';

interface Props {
    pageLevel?: boolean;
    inline?: boolean;
    index?: number;
    asset: SiteAsset;
    presentation?: SiteEntryPresentation;
    pageContext?: PageContext;
    isInAssetGroup?: boolean;
    fullWidth?: boolean;
    noAnimation?: boolean;
    inNavHeader?: boolean;
    spacing?: GroupSpacing;
    pageItemsLength?: number;
}

const web1FullWidthTypes = [
    'Hero',
    'YouTube',
    'TextContent',
    'Action',
    'StaticContent',
    'ContentShowcase',
    'PromoOffer',
    'channelGuide',
    'contextualComponent',
    'OfferComparison',
    'MotionGraphicModule',
];
const fullWidthTypes = [
    ...web1FullWidthTypes,
    'Header',
    'PromoBanner',
    'AssetGroup:Any',
    'EntityHero',
    'AssetGroup:EntityVideoTile',
    'AssetGroup:EntityHero',
    'Hero_2_0',
];
const noVerticalSpacingTypes = ['PromoBanner', 'Hero_2_0'];

function shouldBeFullWidth(contentType: string, contentSubType: string, asset: SiteAsset | SiteAssetGroup): boolean {
    const fullContentType = contentType + contentSubType;

    // If we start to accumulate a lot of these special cases, a refactor will be needed:

    if (fullContentType === 'AssetGroup:EntityList') {
        return (asset as SiteAssetGroup).presentation?.presentations?.EntityList?.template === 'Scroll';
    }

    if (fullContentType === 'PromoBanner') {
        return (asset as SitePromoBanner).fields.theme === 'Expanded';
    }

    return fullWidthTypes.indexOf(fullContentType) >= 0;
}

function shouldBeTenCol(contentType: string, contentSubType: string, asset: SiteAsset | SiteAssetGroup): boolean {
    const fullContentType = contentType + contentSubType;
    let isTenCol = false;
    if (fullContentType === 'AssetGroup:EntityList') {
        isTenCol = (asset as SiteAssetGroup).presentation?.presentations?.EntityList?.template === 'Generic';
    } else if (fullContentType === 'AssetGroup:EntityCategory') {
        isTenCol = (asset as SiteAssetGroup).presentation?.presentations?.EntityCategory?.template === 'Tile';
    } else if (fullContentType === 'AssetGroup:DeviceGroup' || fullContentType === 'SundaySkyVideo') {
        isTenCol = true;
    }
    return isTenCol;
}

function shouldRemoveMobilePadding(
    contentType: string,
    contentSubType: string,
    asset: SiteAsset | SiteAssetGroup,
    spacing: string,
): boolean {
    const contentFullType = contentType + contentSubType;

    return (
        (contentFullType === 'AssetGroup:EntityList' &&
            (asset as SiteAssetGroup).presentation?.presentations?.EntityList?.template === 'Mixtape') ||
        (contentFullType === 'AssetGroup:GridLayout' &&
            asset.fields.horizontal_mobile_layout?.toLowerCase() === 'swipeable') ||
        (contentFullType === 'UpcomingSports' && spacing === 'mixtape')
    );
}

export default function RenderAsset(props: Props): JSX.Element {
    const contentType = props.asset.cms_type;
    if (!contentType) {
        return <></>;
    }

    const contentSubType = contentType === 'AssetGroup' ? `:${props.asset?.fields.type}` : '';
    const contentId = props.asset?.cms_id;
    const contentFullType = contentType + contentSubType;
    const Component = getContentfulComponent(contentType);

    if (!Component) {
        return <RenderAssetv1 {...props} />;
    }

    const asset: SiteAssetGroup = props.asset as SiteAssetGroup;

    const useTenCol = shouldBeTenCol(contentType, contentSubType, asset);
    const isFullWidth = props.fullWidth || shouldBeFullWidth(contentType, contentSubType, asset);
    const noMobilePadding = shouldRemoveMobilePadding(contentType, contentSubType, asset, props.spacing ?? '');

    // We avoid the spacing around the PromoBanner if it's the last item on the page.
    const avoidSpacing =
        contentFullType === 'PromoBanner' && props?.pageItemsLength ? props.index === props.pageItemsLength - 1 : false;

    const moleculeSpacingClassName =
        contentType === 'Hero_2_0' || avoidSpacing
            ? ''
            : props.spacing === 'mixtape'
              ? 'standard-mixtape-spacing'
              : 'standard-molecule-spacing';

    const renderedComponent = (
        <Component
            index={props.index}
            asset={props.asset}
            presentation={props.presentation}
            pageContext={props.pageContext}
            noAnimation={props.noAnimation}
            inNavHeader={props.inNavHeader}
            inMixtape={props.spacing === 'mixtape'}
        />
    );

    function ComponentWrap({ children }: { children: React.ReactNode }) {
        return (
            <div
                className={moleculeSpacingClassName}
                data-componenttype={contentType + contentSubType}
                data-componentid={contentId}
                id={(contentType + contentId).toLowerCase()}
            >
                {children}
            </div>
        );
    }

    if (props.inline) {
        return renderedComponent;
    }

    if (isFullWidth) {
        if (props.pageLevel && !noVerticalSpacingTypes.includes(contentType)) {
            return (
                <StyledContainer tag="section" includeNavPadding={props.index === 0}>
                    <ComponentWrap>{renderedComponent}</ComponentWrap>
                </StyledContainer>
            );
        }
        return <ComponentWrap>{renderedComponent}</ComponentWrap>;
    }

    const isPageLevelEntityPromoItem = contentFullType === 'EntityPromoItem';

    if (props.pageLevel) {
        return (
            <StyledContainer
                tag={isPageLevelEntityPromoItem ? 'div' : 'section'}
                className={isPageLevelEntityPromoItem ? 'page-level-margins' : ''}
                includeNavPadding={props.index === 0}
            >
                <ComponentWrap>
                    <Grid container noMobilePadding={noMobilePadding}>
                        <Grid col={{ lg: useTenCol ? 10 : 12 }} start={{ lg: useTenCol ? 2 : 1 }}>
                            {renderedComponent}
                        </Grid>
                    </Grid>
                </ComponentWrap>
            </StyledContainer>
        );
    } else {
        return (
            <ComponentWrap>
                <Grid container noMobilePadding={noMobilePadding}>
                    <Grid col={{ lg: useTenCol ? 10 : 12 }} start={{ lg: useTenCol ? 2 : 1 }}>
                        {renderedComponent}
                    </Grid>
                </Grid>
            </ComponentWrap>
        );
    }
}
