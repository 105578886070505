import { Schedules, ChannelFeedResponse, SortedChannels, CategoryMap } from './interfaces';

export const categoryMap: CategoryMap = {
    Music: 'Music',
    Sports: 'Sports',
    'Talk & Entertainment': 'Talk',
    'News & Issues': 'News',
    Comedy: 'Talk',
    'Howard Stern': 'Talk',
};

export const XtraChannels = [
    'Top 100',
    'New Music Discovery',
    'Music Mash',
    "Today's Hits",
    'Party',
    'Workout',
    'The 80s Decade',
    'Cool Down',
    'The 70s Decade',
    'BBQ',
    'The 90s Decade',
    'Instrumentals',
    'Just Music',
    'Deep Cuts',
];

export function isLiveWithTimeLeft(timeRanges: Schedules[]) {
    if (!timeRanges) {
        return { isLive: false, timeLeft: '' };
    }
    const currentTime = new Date();

    for (const range of timeRanges) {
        const startDate = new Date(range.startTime);
        const endDate = new Date(range.endTime);

        if (currentTime >= startDate && currentTime <= endDate) {
            // Calculate time left till the end
            const timeLeftMs = endDate.getTime() - currentTime.getTime();
            const timeLeftMinutes = Math.floor(timeLeftMs / 60000); // Convert ms to minutes
            const hours = Math.floor(timeLeftMinutes / 60);
            const minutes = timeLeftMinutes % 60;

            return {
                isLive: true,
                timeLeft: `${hours}h ${minutes}m`,
                liveShowID: range.showId,
            };
        }
    }

    return {
        isLive: false,
        timeLeft: '',
        liveShowID: '',
    };
}

export function sortChannels(data: ChannelFeedResponse, xtraChannels: string[], categoryMap: CategoryMap) {
    const sortedChannels: SortedChannels = {};
    data?.channels?.forEach((channel) => {
        const category: string | undefined = categoryMap[channel.categoryTitle as keyof CategoryMap];

        if (category) {
            if (!sortedChannels[category]) {
                sortedChannels[category] = { All: [], AllX: [] };
            }

            if (!sortedChannels[category][channel.genreTitle]) {
                sortedChannels[category][channel.genreTitle] = [];
            }

            sortedChannels[category].All.push(channel);
            sortedChannels[category][channel.genreTitle].push(channel);

            if (xtraChannels.indexOf(channel.genreTitle) >= 0) {
                sortedChannels[category].AllX.push(channel);
            }
        }
    });

    return sortedChannels;
}
