'use client';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import NoGames from '@/components/contentful/ContextualComponent/Sports/NoGames';
import sportsStyles from '@/components/contentful/ContextualComponent/Sports/sportschedule.module.scss';
import { YMM } from '@/components/molecules/YMM';
import { useState } from 'react';

export default function EmptyTabsMessage(): JSX.Element {
    const [openYmm, setOpenYmm] = useState(false);
    return (
        <Grid container>
            <Grid col={{ xs: 12 }}>
                <NoGames
                    logo={{
                        url: 'https://www.siriusxm.com/content/dam/sxm-com/lottie/stella-animated-png/stella-05-digs_grey_72x72_trans_backdrop.png',
                        altText: '',
                        filetype: 'image/png',
                        format: 'image',
                    }}
                    title="This channel will only display if it's included in your plan"
                    classNames={sportsStyles.noGamesWrapper}
                >
                    <YMM.Trigger
                        initialText="Verify your car's year, make and model"
                        variant="static"
                        onClick={() => setOpenYmm(true)}
                        className={sportsStyles.ymmTrigger}
                    >
                        <Text className={sportsStyles.textStyling} tag="p">
                            {' '}
                            (at the very top of this page) to ensure the correct programming is displaying for your
                            vehicle.
                        </Text>
                    </YMM.Trigger>
                    <YMM.Modal open={openYmm} onClose={() => setOpenYmm(false)} />
                </NoGames>
            </Grid>
        </Grid>
    );
}
