import { SearchResult } from '@/components/molecules/SearchResult';

interface Props {
    record: SwiftypePage;
    variant?: 'list' | 'card';
    onClick?: React.MouseEventHandler;
}

export default function SearchRecord({ record, variant = 'list', onClick }: Props): JSX.Element {
    let label = record.keymatch || record.blgCategory || 'SiriusXM';
    let title = record.keymatch || record.blgtitle || record.title;
    const link = record.keymatchurl || record.blogUrl || record.url || record.link || '';
    let description = record.keymatchHeadline || record.description || record.blgExcert || record.desc || record.title;
    let playerLink = '';
    let img = '';

    if (['ChannelPage', 'channel'].indexOf(record.doctype) > -1) {
        title = record.displayname || '';
        description = record.channeldescription ? record.channeldescription : description;
        playerLink = record.deeplink ? record.deeplink : 'https://player.siriusxm.com';
        img = record.icon || img;
    }

    if (link.indexOf('shop.siriusxm.com') !== -1) {
        label = 'Shop';
        img = record.image || record.img || '';
    } else if (link.indexOf('care.siriusxm.com') !== -1) {
        label = 'Support';
    } else if (link.indexOf('blog.siriusxm.com') !== -1 || link.indexOf('/blog') !== -1) {
        label = 'Blog';
        img = record.image;
    }

    return (
        <SearchResult
            label={label}
            title={title}
            titleLink={{ href: link }}
            bottomLink={{ href: playerLink }}
            thumbnailSrc={img}
            bottomLinkText="Launch in player"
            mainText={description}
            variant={variant}
            onClick={onClick}
        />
    );
}
