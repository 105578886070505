/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export default function useClickOutside(
    ref: { [index: string]: any },
    state: { [index: string]: any },
    callback: (state?: any, event?: Event) => void,
) {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        function handleClickOutside(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(state, event);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchend', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchend', handleClickOutside);
        };
    }, [ref, state]);
    /* eslint-enable react-hooks/exhaustive-deps */
}
