import globalProperties from '@/../properties/global';
import { YMMType, Model, ResponseCapabilities, Capabilities, Platform, YMMCookies } from './types';
import cache from '@/packages/cache';
import { getCookie, setCookie } from 'cookies-next';
import dayjs from 'dayjs';

const ymmCacheTTL = 43200;

async function getYMM(type: YMMType, body: object) {
    const response = await fetch(globalProperties.ymm.path + type, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            transactionid: `${new Date().getTime()}`,
            sourcename: 'SXMCOMDE',
            timestamp: new Date().toISOString(),
            'content-type': 'application/json',
        },
    });
    return await response.json();
}

export async function getYearsFrom(startYear: string) {
    const cacheName = `vehiclemodelyears${startYear}`;
    const yearsImmutableObj: string[] = cache.get(cacheName) as string[];
    let years: string[];
    if (!yearsImmutableObj) {
        const response = await getYMM('vehiclemodelyears', { startVehicleModelYear: startYear });
        years = response.vehicleModelYearList.sort().reverse() as string[];
        cache.set(cacheName, JSON.parse(JSON.stringify(years)), ymmCacheTTL);
    } else {
        years = JSON.parse(JSON.stringify(yearsImmutableObj));
    }

    return years;
}

export async function getMakesForYear(year: string) {
    const cacheName = `vehiclemake${year}`;
    const makesImmutableObj: string[] = cache.get(cacheName) as string[];
    let makes: string[];
    if (!makesImmutableObj) {
        const response = await getYMM('vehiclemake', { vehicleModelYear: year });
        makes = response.vehicleMakeList.sort() as string[];
        cache.set(cacheName, JSON.parse(JSON.stringify(makes)), ymmCacheTTL);
    } else {
        makes = JSON.parse(JSON.stringify(makesImmutableObj));
    }

    return makes;
}

export async function getModelsForMake(year: string, make: string) {
    const cacheName = `vehiclemodel${year}${make}`;
    const modelsImmutableObj: Array<Model> = cache.get(cacheName) as Array<Model>;
    let models: Array<Model>;
    if (!modelsImmutableObj) {
        const response = await getYMM('vehiclemodel', { vehicleModelYear: year, vehicleMake: make });
        models = response.vehicleModelList.sort() as Array<Model>;
        cache.set(cacheName, JSON.parse(JSON.stringify(models)), ymmCacheTTL);
    } else {
        models = JSON.parse(JSON.stringify(modelsImmutableObj));
    }

    return models;
}

export async function getCapabilities(ymmComboId: string) {
    const cacheName = `vehiclecapabilities${ymmComboId}`;
    const capabilitiesImmutableObj: Capabilities = cache.get(cacheName) as Capabilities;
    let capabilities: Capabilities;
    if (!capabilitiesImmutableObj) {
        const fullCapabilities: ResponseCapabilities = await getYMM('vehiclecapabilities', {ymmComboId: ymmComboId});

        capabilities = {
            localForecast: fullCapabilities.localForecast === 'Y',
            make: fullCapabilities.make,
            model: fullCapabilities.model,
            year: parseInt(`${fullCapabilities.modelYear}`, 10),
            navTraffic: fullCapabilities.navTraffic === 'Y',
            navWeather: fullCapabilities.navWeather === 'Y',
            siriusRadio: fullCapabilities.siriusRadio === 'Y',
            siriusxmRadio: fullCapabilities.siriusxmRadio === 'Y',
            siriusxmTraffic: fullCapabilities.siriusxmTraffic === 'Y',
            trafficPlus: fullCapabilities.trafficPlus === 'Y',
            travelLinkFuel: fullCapabilities.travelLinkFuel === 'Y',
            travelLinkMovies: fullCapabilities.travelLinkMovies === 'Y',
            travelLinkParking: fullCapabilities.travelLinkParking === 'Y',
            travelLinkSports: fullCapabilities.travelLinkSports === 'Y',
            travelLinkStocks: fullCapabilities.travelLinkStocks === 'Y',
            travelLinkWeather: fullCapabilities.travelLinkWeather === 'Y',
            xmRadio: fullCapabilities.xmRadio === 'Y',
            ymmComboId: fullCapabilities.ymmComboId,
            l360: fullCapabilities.TaData?.DeviceInfo.RadioModelTypeLegacyFeatures.l360 === 'Y',
            infotainment: [],
        };

        for (const capability in capabilities) {
            if (capabilities[capability as keyof Capabilities] === true) {
                capabilities.infotainment.push(capability);

                if (capabilities.infotainment.indexOf('Travel Link') === -1 && capability.indexOf('travelLink') >= 0) {
                    capabilities.infotainment.push('Travel Link');
                }
            }
        }

        const hasNavTraffic = capabilities.infotainment.indexOf('navTraffic') > -1;
        const hasNavWeather = capabilities.infotainment.indexOf('navWeather') > -1;
        const hasLocalForecast = capabilities.infotainment.indexOf('localForecast') > -1;
        const hasTravelLink = capabilities.infotainment.indexOf('Travel Link') > -1;
        const hasTraffic = capabilities.infotainment.indexOf('siriusxmTraffic') > -1;
        const hasTrafficPlus = capabilities.infotainment.indexOf('trafficPlus') > -1;

        if (hasNavTraffic && hasNavWeather && hasTravelLink) {
            capabilities.infotainment.push('NavTraffic_NavWeather_TravelLink');
        } else if (hasNavTraffic && hasNavWeather) {
            capabilities.infotainment.push('NavTraffic_NavWeather');
        } else if (hasNavTraffic && hasLocalForecast) {
            capabilities.infotainment.push('NavTraffic_LocalForecast');
        } else if (hasNavTraffic && hasTravelLink) {
            capabilities.infotainment.push('NavTraffic_TravelLink');
        } else if (hasTraffic && hasTravelLink) {
            capabilities.infotainment.push('Traffic_TravelLink');
        } else if (hasTrafficPlus && hasTravelLink) {
            capabilities.infotainment.push('TrafficPlus_TravelLink');
        }

        cache.set(cacheName, JSON.parse(JSON.stringify(capabilities)), ymmCacheTTL);
    } else {
        capabilities = JSON.parse(JSON.stringify(capabilitiesImmutableObj));
    }

    return capabilities;
}

export function setYMMCookies(cookieValues: YMMCookies) {
    const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.siriusxm.com';
    const expires = dayjs().add(1, 'day').toDate();

    setCookie('sxm_radio', cookieValues.sxm_radio, {
        domain: domain,
        expires: expires,
        path: '/',
    });
    setCookie('sxm_platform', cookieValues.sxm_platform, {
        domain: domain,
        expires: expires,
        path: '/',
    });
    setCookie('inft_ymm', cookieValues.inft_ymm, {
        domain: domain,
        expires: expires,
        path: '/',
    });
    setCookie('ymm_bar', cookieValues.ymm_bar, {
        domain: domain,
        expires: expires,
        path: '/',
    });
    setCookie('roadblockbypass', cookieValues.roadblockbypass, {
        domain: domain,
        expires: expires,
        path: '/',
    });
}

export function getYMMCookies(): YMMCookies {
    return {
        sxm_radio: getCookie('sxm_radio') || '',
        sxm_platform: (getCookie('sxm_platform') as Platform) || 'siriusxm',
        inft_ymm: getCookie('inft_ymm') || '',
        ymm_bar: getCookie('ymm_bar') || '',
        roadblockbypass: getCookie('roadblockbypass') || '',
    };
}
