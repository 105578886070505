import { Block, Inline } from '@contentful/rich-text-types';
import RenderAsset from '../RenderAsset';
import Context from '@/services/contentful/pageContext';

import Link from '@/services/contentful/contentTypes/Link';
import Media from '@/services/contentful/contentTypes/Media';

interface Props {
    node: Block | Inline;
}

const validEmbedEntry = ['Link', 'Media'];
type ValidEmbedTypes = (typeof validEmbedEntry)[number];

export default function InlineEmbed({ node }: Props) {
    const asset = node.data.target;
    const type: ValidEmbedTypes = asset.sys.contentType?.sys.id;

    let parsed: SiteAsset = {
        cms_id: '',
        cms_type: '',
        fields: {},
    };

    if (type === 'Link') {
        parsed = Link(asset, {} as Context);
    } else if (type === 'Media') {
        parsed = Media(asset);
    }

    if (parsed && validEmbedEntry.includes(parsed.cms_type)) {
        return <RenderAsset asset={parsed} inline={true} />;
    }

    return <></>;
}
