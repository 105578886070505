import { VALIDITY } from '@/constants';
import { Fields } from '@/hooks/useValidator/interfaces';
import { ValidatorObject } from '@/hooks/useValidator/useValidator';
import { Dispatch, SetStateAction } from 'react';

export const validateForm = (
    event: React.SyntheticEvent,
    validationState: Fields,
    setValidationState: Dispatch<SetStateAction<Fields>>,
    validator: ValidatorObject,
): boolean => {
    const newValidations = validator.validate(event);
    const updatedValidations = { ...validationState, ...newValidations };
    setValidationState(updatedValidations);
    return updatedValidations.form.invalid === VALIDITY.valid;
};
