import mountain from '@/packages/sxm/mountain';
import { useEffect, useState } from 'react';

export default function useLiveChannel(channelList: string[], callback: (arg0: MountainResponse) => void) {
    const [timer, setTimer] = useState(0);

    async function getChannels() {
        const channels = await mountain(channelList);
        return channels;
    }

    useEffect(() => {
        if (timer === 0 && channelList.length > 0) {
            getChannels().then((channelData) => {
                setTimer(channelData.nextCallAfter);

                const timeout = setTimeout(() => {
                    setTimer(0);
                }, channelData.nextCallAfter);

                callback(channelData);

                return () => clearTimeout(timeout);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer]);
}
