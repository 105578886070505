'use client';

import styles from './sundayskyvideo.module.scss';
import { useEffect } from 'react';

interface Props {
    index?: number;
    asset: SiteSundaySky;
}

export default function SundaySkyVideo({ asset }: Props): JSX.Element {

    const programID = asset.fields.program_id;
    const posterImageUrl = asset.fields.poster_image?.url;

    useEffect(() => {
        const sskyProgramId = programID;
        let sskyplayer: HTMLElement | null;
    
        function createPlayer() {
            const existingPlayer = document.getElementById("sskyplayer");
            if (existingPlayer) {
                return;
            }

            sskyplayer = document.createElement("sundaysky-video");
            sskyplayer.setAttribute("id", "sskyplayer");
            sskyplayer.setAttribute("analytics-token", sskyProgramId);
            sskyplayer.setAttribute("poster", posterImageUrl || "https://smartvideo-hub-assets.sundaysky.com/cc-entities/d916a00b-2c5a-439a-9ac4-4bd6fec10d95/media/445473db-ded4-44c5-9e5e-63f8f397430e/Default_Poster_Image_16_9.png");
            sskyplayer.setAttribute("aspect-ratio", "horizontal");
            sskyplayer.setAttribute("play-button-style-config", "true");
            sskyplayer.setAttribute("play-button-color", "#000000ff");
            sskyplayer.setAttribute(
            "endpoint-url",
            `https://apis.sundaysky.com/get-video/player-session/${sskyProgramId}${window.location.search}`
            );
            sskyplayer.setAttribute("control-bar-lock", "normal fullscreen");
            sskyplayer.setAttribute("transcript-button", "show");
            sskyplayer.setAttribute("cc-on-by-default", "true");
    
            if (navigator.userAgent.includes("Windows")) {
                sskyplayer.classList.add("ssky-play-windows");
            }
    
            document.getElementById("sskyPlayerWrapper")?.appendChild(sskyplayer);
        }
    
        createPlayer();
    }, []);

    
    return (<>
        <div id="sskyElementsWrapper" className={styles.elementWrapper} data-name={programID}>
            <div id="sskyPlayerWrapper" className={styles.playerWrapper}></div>
        </div>

        <script src="https://play.sundaysky.com/main/sundaysky-player.grey.en-us.min.js" async></script>
    </>);
};
