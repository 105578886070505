'use client';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import { SportStrip } from '@/components/molecules/Sports/SportStrip';
import type { Details as Team } from '@/components/molecules/Sports/SportStrip';
import dayjs from 'dayjs';
import timeZoneExtension from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styles from './sportschedule.module.scss';
import { Recirculation } from '@/components/molecules/Recirculation';
import { TeamProps, GameList } from './interfaces';
import { RenderRTE } from '@/components/RenderRTE';
import NoGames from './NoGames';
import { Game } from '@/packages/sxm/sports/games/GamesResponse';
import { DynamicTableRow } from '@/components/molecules/DynamicTable/DynamicTable';
import { Link } from '@/components/atoms/Link';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));

export default function TeamSchedule({
    league_logo,
    off_season_message_title,
    off_season_message_description,
    remind_me_logo,
    remind_me_title,
    reminder_url,
    games,
    asW2,
}: TeamProps) {
    const [showAllGames, setShowAllGames] = useState(false);

    if (games?.length === 0) {
        return (
            <Grid container>
                <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                    <NoGames logo={league_logo} title={off_season_message_title || ''}>
                        <RenderRTE
                            className={styles.noGamesMsg}
                            contentfulDoc={off_season_message_description}
                            asW2={asW2}
                        />
                    </NoGames>
                </Grid>
            </Grid>
        );
    }

    dayjs.extend(advancedFormat);
    dayjs.extend(timeZoneExtension);

    function renderChannelLink(
        deeplink: string | undefined,
        channelNumber: string | number | undefined,
        ariaLabel: string,
    ) {
        if (deeplink) {
            return (
                <Link href={deeplink} target="_blank" aria-label={ariaLabel}>
                    {channelNumber}
                </Link>
            );
        }
        return channelNumber || '-';
    }

    if (games && games.length) {
        const gameDates: GameList = {};
        const dates: string[] = [];

        const gamesToShow = showAllGames ? games : games.slice(0, 6);

        for (let i = 0; i < gamesToShow.length; i += 1) {
            const game = gamesToShow[i] as Game;
            const index = game.gameDate || '';
            const gameDate = dayjs(game.gameDate);

            if (!gameDates[index]) {
                const dateStr = gameDate.format(`dddd, MMMM D • ${game.tbdTime ? '[TBD]' : 'h:mm A z'}`);
                gameDates[index] = [
                    <Text
                        key={dateStr}
                        variant="small"
                        tag="h3"
                        className={styles.dateTitle}
                        data-cy="components-contextual-sports-date-title"
                    >
                        {dateStr}
                    </Text>,
                ];
                dates.push(index);
            }

            const homeTeam: Team = {
                logo: game.homeLogo,
                color: game.homeColor,
                pageUrl: game.homeUrl,
                playerUrl: game.homeStreamLink || game.nationalStreamLink,
                name: `${game.homeCity} ${game.homeTeam}` || '',
                location: game.homeCity || '',
                channel:
                    game.homeStream ||
                    game.nationalStream ||
                    (game.homeInternetStream || game.nationalInternetStream ? 'ONLINE' : ''),
            };

            const awayTeam: Team = {
                logo: game.awayLogo,
                color: game.awayColor,
                pageUrl: game.awayUrl,
                playerUrl: game.awayStreamLink || game.nationalStreamLink,
                name: `${game.awayCity} ${game.awayTeam}` || '',
                location: game.awayCity || '',
                channel:
                    game.awayStream ||
                    game.nationalStream ||
                    (game.awayInternetStream || game.nationalInternetStream ? 'ONLINE' : ''),
            };

            const moreWays: DynamicTableRow[] = [];

            if (game.awayStream || game.awayInternetStream) {
                const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on away channel ${game.awayInternetStream}`;
                moreWays.push({
                    rowLabel: 'Away',
                    items: [
                        game.awayStream || '-',
                        renderChannelLink(game.awayStreamLink, game.awayInternetStream, ariaLabel),
                    ],
                });
            }

            if (game.homeStream || game.homeInternetStream) {
                const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on home channel ${game.homeInternetStream}`;
                moreWays.push({
                    rowLabel: 'Home',
                    items: [
                        game.homeStream || '-',
                        renderChannelLink(game.homeStreamLink, game.homeInternetStream, ariaLabel),
                    ],
                });
            }

            if (game.nationalStream || game.nationalInternetStream) {
                const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on national channel ${game.nationalInternetStream}`;
                moreWays.push({
                    rowLabel: 'National',
                    items: [
                        game.nationalStream || '-',
                        renderChannelLink(game.nationalStreamLink, game.nationalInternetStream, ariaLabel),
                    ],
                });
            }

            if (game.spanishStream || game.spanishInternetStream) {
                const ariaLabel = `Listen to ${game.awayTeamCity} @ ${game.homeTeamCity} on spanish channel ${game.spanishInternetStream}`;
                moreWays.push({
                    rowLabel: 'Spanish',
                    items: [
                        game.spanishStream || '-',
                        renderChannelLink(game.spanishStreamLink, game.spanishInternetStream, ariaLabel),
                    ],
                });
            }

            const isLive = dayjs().isBetween(game.gameDate, game.endDateTime);
            gameDates[index].push(
                <li key={i} aria-label={`${homeTeam.name} vs ${awayTeam.name}`}>
                    <SportStrip
                        awayTeam={awayTeam}
                        homeTeam={homeTeam}
                        moreWays={moreWays}
                        isLive={isLive}
                        ariaLabel={`More Ways to listen ${awayTeam.name} @ ${homeTeam.name}`}
                    />
                </li>,
            );
        }

        return (
            <Grid container>
                <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                    {dates.sort().map((index) => (
                        <ul
                            key={index}
                            className={styles.listStylings}
                            data-cy="components-contentful-contextual-component-sports-dates"
                        >
                            {gameDates[index]}
                        </ul>
                    ))}
                    {!showAllGames && games.length > 6 && (
                        <button className={styles.loadMoreGames} onClick={() => setShowAllGames(true)}>
                            <span className={styles.loadMoreMessage}>Load More Games</span>
                        </button>
                    )}
                    {(showAllGames || games.length <= 6) && (
                        <Recirculation className={styles.allGamesMessage}>
                            All available games have been loaded. Check back soon, we&apos;re still adding more.
                        </Recirculation>
                    )}

                    {reminder_url && (
                        <NoGames
                            logo={remind_me_logo}
                            logoSize="small"
                            title={remind_me_title || 'Never Miss a Moment'}
                        >
                            <Link href={reminder_url} dataCy="contextualcomponent-league-addtocal-link">
                                Add to calender
                            </Link>
                        </NoGames>
                    )}
                </Grid>
            </Grid>
        );
    }

    return <></>;
}
