import cn from 'classnames';
import dynamic from 'next/dynamic';
import { Fragment, useId } from 'react';
import { ShowSchedule } from '@/components/molecules/ShowSchedule';
import styles from './host.module.scss';
import { HostChannel } from '@/services/cache/ChannelCache';
import { Link } from '@/components/atoms/Link';
const ShowMore = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore'));
const SocialMediaIconLinks = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks/SocialMediaIconLinks'),
);
import { SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface HostProps {
    className: string;
    name: string;
    description: React.ReactNode | React.ReactNode[];
    image: ImageProps;
    shows: SiteChannelShow[];
    socialMedia: SocialMediaIconLinksProps;
    channels: HostChannel[];
}

export function Host({ className, name, description, image, shows, socialMedia, channels }: HostProps): JSX.Element {
    const contentId = useId();
    const alsoOn: React.ReactNode[] = [];

    channels?.forEach((channel, i) => {
        if (channel.pageUrl) {
            alsoOn.push(
                <Fragment key={i}>
                    <Link href={channel.pageUrl} key={i} aria-label={channel.displayName}>
                        {channel.displayName}
                    </Link>
                    &nbsp;
                </Fragment>,
            );
        } else {
            alsoOn.push(<Fragment key={i}>{channel.displayName}&nbsp;</Fragment>);
        }
    });

    return (
        <div className={cn(className, styles.item)}>
            <div className={styles.imageContainer}>
                {image.source && (
                    <ImageWrapper
                        optimizedWidth={100}
                        optimizedHeight={100}
                        className={styles.image}
                        src={image.source}
                        alt={image.altText}
                    />
                )}
            </div>
            <ShowMore
                className={styles.showMore}
                color={'var(--black)'}
                showText="View more"
                hideText="View less"
                contentClassName="flex flex-column"
                shownClassName={styles.showHideables}
                id={contentId}
            >
                <>
                    <p className={cn('bold', styles.name)}>{name}</p>

                    <div className={styles.info}>
                        <div className={cn(styles.infoLeft)}>
                            <div className="small">{description}</div>
                            {alsoOn.length > 0 && <p className="small">Also appears on {alsoOn}</p>}

                            <div className={cn(styles.hideable, styles.socialMedia)}>
                                <SocialMediaIconLinks {...socialMedia} />
                            </div>
                        </div>

                        <div className={cn(styles.hideable, styles.infoRight)}>
                            <ShowSchedule displayTitles shows={shows} />
                        </div>
                    </div>
                </>
            </ShowMore>
        </div>
    );
}
