import { useEffect, useState, useRef } from 'react';
import { ChannelFeedChannel } from './interfaces';
import styles from './channelGuide.module.scss';
import { isLiveWithTimeLeft } from './utils';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import ChannelCardStrip from 'de-sxm-reactlib-2/dist/components/molecules/ChannelCardStrip/ChannelCardStrip';
import ChannelCardStripBadge from 'de-sxm-reactlib-2/dist/components/molecules/ChannelCardStrip/ChannelCardStripBadge';
import Grid from 'de-sxm-reactlib-2/dist/components/atoms/Layout/Grid/Grid';

const { channelList, channelCardStripWrapper, xlDescription, badgeStyles, explicitContainerWrapper } = styles;

const REFERENCE_POSITION_INDEX = 10;
const NEXT_BATCH_COUNT = 15;

interface Props {
    channels: ChannelFeedChannel[];
    upsellLink?: SiteLink;
    display_listen_live: boolean;
    platform?: string;
    defaultChannelImage: {
        url: string;
        altText: string;
    };
}

export default function ChannelList({ channels, upsellLink, platform, defaultChannelImage }: Props) {
    const triggerElementReference = useRef<HTMLDivElement>(null);
    const [referencePosition, setReferencePosition] = useState(REFERENCE_POSITION_INDEX);
    const [channelListStrips, setChannelList] = useState<ChannelFeedChannel[] | undefined>();

    useEffect(() => {
        function onScroll() {
            if (triggerElementReference.current) {
                const rect = triggerElementReference.current.getBoundingClientRect();

                // We check if the referenced element is entering the viewport in order to load the next batch
                if (rect.top < window.innerHeight && rect.bottom >= 0) {
                    // We change the referenced element
                    setReferencePosition((prevState) => prevState + REFERENCE_POSITION_INDEX);
                    setChannelList((prevState) => {
                        if (prevState?.length) {
                            return [
                                ...prevState,
                                ...channels.slice(prevState.length, prevState.length + NEXT_BATCH_COUNT),
                            ];
                        }
                    });
                }
            }
        }

        setChannelList(channels.slice(0, NEXT_BATCH_COUNT));
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [channels]);

    const cardStripChannelData = channelListStrips?.map((channel) => {
        let listenLink = channel.deepLink || '';
        let listenAria = `listen live to ${channel.displayName}`;
        const airNowAria = `On Air Now ${channel.displayName}`;

        if (!channel.availableToPackage && upsellLink) {
            listenLink = upsellLink.fields.href || '';
            listenAria = 'upgrade plan';
        }

        let subTitleDetails = channel.mediumDescription;
        if (channel.category === 'music' && channel.artistsYouHear.length > 0) {
            subTitleDetails = channel.artistsYouHear.join(', ');
        }

        let channelNumber = <>{platform === 'sirius' ? channel.siriusChannelNumber : channel.xmChannelNumber}</>;
        if (channel.deliveryTypes.indexOf('satellite') === -1) {
            channelNumber = <></>;
        }

        let showLogo;
        let showTitle;
        const { isLive, timeLeft, liveShowID } = isLiveWithTimeLeft(channel?.showSchedules?.schedules);

        channel?.showSchedules?.shows?.forEach((show) => {
            if (show.showId === liveShowID) {
                showLogo = show.showLogo;
                showTitle = show.showName;
            }
        });

        return {
            title: channel.displayName,
            live: isLive,
            subTitle: channel.shortDescription,
            subTitleDetails: subTitleDetails,
            showAndScheduleLink: `${channel.vanityURL}#web2-channel-guide` || '',
            showsAndScheduleLinkAriaLabel: `shows and schedules for ${channel.displayName}`,
            listenLink: listenLink,
            listenLinkAriaLabel: listenAria,
            onAirNowArialLabel: airNowAria,
            showAndScheduleLinkText: 'Show information & Schedules',
            locked: !channel.availableToPackage,
            onAirTime: !channel.xtra_channel ? timeLeft : '',
            onAirImage: (
                <ImageWrapper
                    src={showLogo || channel?.web_2_0_image?.url || defaultChannelImage?.url}
                    optimizedHeight={96}
                    optimizedWidth={96}
                />
            ),
            showName: showTitle || channel.displayName,
            channelImage: (
                <ImageWrapper
                    src={channel?.web_2_0_image?.url || defaultChannelImage?.url}
                    alt={channel?.web_2_0_image?.altText || defaultChannelImage?.altText}
                    optimizedHeight={112}
                    optimizedWidth={112}
                />
            ),
            plansLink: 'https://www.siriusxm.com/plans',
            plansLinkAriaLabel: 'upgrade plan',
            titleLink: channel.vanityURL || '',
            titleAriaLabel: 'channels',
            channelNumber: channelNumber,
            id: channel.assetId,
            bannerText: channel.channelNotification,
            isXLBadge: channel.explicitContentIndicator === 'Yes',
            isOnlyOnBadge: channel.exclusiveContent,
            isAppOnlyBadge: !channel.deliveryTypes.includes('satellite'),
        };
    });

    return (
        <Grid container>
            <Grid col={{ sm: 12 }}>
                <div className={channelList}>
                    {cardStripChannelData && cardStripChannelData.length > 0
                        ? cardStripChannelData.map((channelData, index) => (
                              <div
                                  key={channelData.id}
                                  className={channelCardStripWrapper}
                                  ref={index === referencePosition ? triggerElementReference : null}
                              >
                                  <ChannelCardStrip {...channelData} />
                              </div>
                          ))
                        : null}
                    <div className={explicitContainerWrapper}>
                        <ChannelCardStripBadge badgeText="XL" className={badgeStyles} />
                        <p className={xlDescription}>
                            May include frequent explicit language or mature programming. Call SiriusXM Listener Care at
                            1-800-967-2346 and ask about Family Friendly plans. All programming subject to change.
                        </p>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
