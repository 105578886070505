import { Fields } from '@/hooks/useValidator/interfaces';
import { ValidatorObject } from '@/hooks/useValidator/useValidator';
import { Dispatch, SetStateAction } from 'react';

export const validateInput = (
    event: React.SyntheticEvent,
    validationState: Fields,
    setValidationState: Dispatch<SetStateAction<Fields>>,
    validator: ValidatorObject,
): void => {
    const newValidations = validator.validate(event);
    setValidationState({ ...validationState, ...newValidations });
};
