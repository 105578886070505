import { useEffect } from 'react';
import { Workbox } from 'workbox-window';

export const useRegisterServiceWorker = (path: string) => {
    useEffect(() => {
        if ('serviceWorker' in window.navigator) {
            const workBox = new Workbox(path);
            workBox.register();
        }
    }, [path]);
};
