import { groupBy, inChronologicalOrder, keyBy } from '@/packages/array';
import dayjs, { Dayjs } from 'dayjs';
import { toLocalTimezone } from './timezones';

type EpisodeWorkingData = {
    showSchedules: DaySchedules;
    startTimeDayjs: Dayjs;
    startTimeIso: string;
    day: number;
};

export function getUnbrokenEpisodeDateRangeGroups(show: SiteChannelShow) {
    const workingFlattenedLocalTimeEpisodes =
        show.show_schedules
            ?.map((schedule) =>
                schedule.day_schedules.map<EpisodeWorkingData>((episode) => {
                    const startTimeDayjs = dayjs(toLocalTimezone(episode.start_time));

                    return {
                        showSchedules: episode,
                        startTimeDayjs,
                        startTimeIso: startTimeDayjs.toISOString(),
                        day: startTimeDayjs.date(),
                    };
                }),
            )
            .flat() ?? [];

    const uniqueEpisodes = Object.values(keyBy('startTimeIso', workingFlattenedLocalTimeEpisodes));

    const dayGroupedSortedEpisodes = Object.values(groupBy('day', uniqueEpisodes)).sort((groupA, groupB) =>
        inChronologicalOrder('startTimeDayjs')(groupA[0], groupB[0]),
    );

    return removeWorkingData(groupUnbrokenEpisodeDateRanges(dayGroupedSortedEpisodes));
}

function groupUnbrokenEpisodeDateRanges(dayGroupedEpisodes: EpisodeWorkingData[][]) {
    return dayGroupedEpisodes.reduce<EpisodeWorkingData[][][]>((unbrokenDateRangesSoFar, currentDayGroup) => {
        if (!unbrokenDateRangesSoFar?.length) {
            return [[currentDayGroup]];
        }

        const completedUnbrokenRanges = [...unbrokenDateRangesSoFar];
        const inProgressUnbrokenRange = completedUnbrokenRanges.pop() ?? [];
        const inProgressRangeDaySample = inProgressUnbrokenRange[0];

        const episodesMatchPreviousDayTimes =
            currentDayGroup.length === inProgressRangeDaySample.length &&
            currentDayGroup.reduce((rangeIsUnbrokenSoFar, { startTimeDayjs: currentDayStartTime }, currentIndex) => {
                const inProgressGroupDaySampleEpisode = inProgressRangeDaySample[currentIndex];

                return (
                    rangeIsUnbrokenSoFar &&
                    currentDayStartTime
                        .year(inProgressGroupDaySampleEpisode.startTimeDayjs.year())
                        .month(inProgressGroupDaySampleEpisode.startTimeDayjs.month())
                        .date(inProgressGroupDaySampleEpisode.startTimeDayjs.date())
                        .isSame(inProgressGroupDaySampleEpisode.startTimeDayjs, 'hour')
                );
            }, true);

        return [
            ...completedUnbrokenRanges,
            [...inProgressUnbrokenRange, ...(episodesMatchPreviousDayTimes ? [currentDayGroup] : [])],
            ...(episodesMatchPreviousDayTimes ? [] : [[currentDayGroup]]),
        ];
    }, []);
}

function removeWorkingData(unbrokenDateRanges: EpisodeWorkingData[][][]) {
    return unbrokenDateRanges.map((unbrokenDateRange) =>
        unbrokenDateRange.map((dayGroup) => dayGroup.map(({ showSchedules: episode }) => episode)),
    );
}
