'use client';
import dynamic from 'next/dynamic';
import SxmLogo from '@/assets/icons/icon-sxm-logo.svg';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Modal = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal'));
import cn from 'classnames';
import Link from 'next/link';
import { CSSProperties, ComponentProps, ReactNode, useEffect, useRef, useState } from 'react';
import globalProperties from '../../../../properties/global';
import styles from './trialeligibilitymodal.module.scss';
import { TrialEligibilityStickyBoxOfferDetails } from '@/components/atoms/Display';
import { useClickOutside } from '@/hooks';
import FocusTrap from 'focus-trap-react';

interface ModalProps extends Omit<ComponentProps<typeof Modal>, 'maxWidth' | 'children' | 'padding'> {
    widget: ReactNode;
    offerDetails: ReactNode;
    subheadingBeforeButton: string;
    subheadingAfterButton: string;
    subheadingButtonText: string;
    id?: string;
}

const MODAL_ANIMATION_DURATION_MS = 400;

export default function TrialEligibilityModal({
    subheadingBeforeButton,
    subheadingAfterButton,
    subheadingButtonText,
    widget,
    offerDetails,
    ...modalProps
}: ModalProps): JSX.Element {
    const [animateToBaseState, setAnimateToBaseState] = useState(false);
    const [prepareModalClose, setPrepareModalClose] = useState(false);
    const [focusPause, setFocusPause] = useState(modalProps.pauseFocus);

    const modalContentRef = useRef<HTMLDivElement>(null);
    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-consent-sdk', // One Trust cookie consent banner
        '.LPMcontainer', // Live Person chat invocation buttons
        '#lpChat', // Interactive Live Person chat window
    ].join(', ');

    useClickOutside(ref, {}, (state, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);
                if (closest) {
                    targetRef.current = target;
                }
                setFocusPause(!!closest);
            }
        }
    });

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    useEffect(() => {
        setAnimateToBaseState(modalProps.open);

        if (modalProps.open) {
            setTimeout(() =>
                modalContentRef.current?.scrollTo({
                    top: 0,
                }),
            );
        }
    }, [modalProps.open]);

    useEffect(() => {
        const timeoutId = prepareModalClose
            ? setTimeout(() => {
                  modalProps?.onClose?.();
                  setAnimateToBaseState(false);
                  setPrepareModalClose(false);
              }, MODAL_ANIMATION_DURATION_MS)
            : undefined;

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [prepareModalClose, modalProps]);

    const modalCustomProps = {
        '--te-transition-duration': `${MODAL_ANIMATION_DURATION_MS}ms`,
        // Values from CARE team for form widget (CECM-24100):
        '--size-rounding-small': '16px',
        '--size-rounding-input-field': '20px',
        '--brand-border-radius-button': '2.4rem',
        '--color-default-surface-primary': 'var(--button-primary-surface, #0f63ff)',
        '--color-default-contrast-primary': 'var(--white, #ffffff)',
        '--color-default-surface-primary-border': 'var(--button-primary-surface, #0f63ff)',
        '--color-default-contrast-primary-border': 'var(--white, #ffffff)',
        '--color-contrast-critical': 'var(--red, #eb0a0a)',
    } as CSSProperties;

    const [showOfferDetails, setShowOfferDetails] = useState<boolean>(false);

    const handleShowOfferDetails = () => {
        setShowOfferDetails(true);
    };

    const handleHideOfferDetails = () => {
        setShowOfferDetails(false);
    };

    return (
        <div className={styles.modalWrapper} style={modalCustomProps}>
            <FocusTrap active={modalProps.open} paused={focusPause}>
                <Modal
                    {...modalProps}
                    padding="none"
                    maxWidth="none"
                    className={cn(styles.modal, modalProps.className, {
                        [styles.baseAnimation]: animateToBaseState,
                        [styles.exitAnimation]: prepareModalClose,
                    })}
                    hideTitleBar
                >
                    <Grid container>
                        <Grid col={{ xs: 12 }}>
                            <div className={styles.logoContainer}>
                                <Link className={styles.logo} href={globalProperties.domain} aria-label="SiriusXM Home">
                                    <SxmLogo />
                                </Link>
                            </div>

                            <div ref={modalContentRef} className={styles.modalContent}>
                                <div className={styles.closeButton}>
                                    <button
                                        onClick={() => setPrepareModalClose(true)}
                                        aria-label="Close the eligibility form dialog"
                                    >
                                        {/* Add this icon to the asset lib if we decide to make TE permanent */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
                                            <path
                                                fillRule="evenodd"
                                                d="M4.543 4.543a1 1 0 0 1 1.414 0L12 10.586l6.043-6.043a1 1 0 1 1 1.414 1.414L13.414 12l6.043 6.043a1 1 0 0 1-1.414 1.414L12 13.414l-6.043 6.043a1 1 0 0 1-1.414-1.414L10.586 12 4.543 5.957a1 1 0 0 1 0-1.414Z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <div className={styles.animatedSection}>
                                    <Text tag="h1" variant="h1" className={cn(styles.modalText, styles.title)}>
                                        {modalProps.title}
                                    </Text>

                                    <Text tag="p" variant="medium" className={cn(styles.modalText, 'bold text-center')}>
                                        {subheadingBeforeButton}{' '}
                                        <span className="text-nowrap">
                                            <button
                                                className={styles.textButton}
                                                onClick={() => handleShowOfferDetails()}
                                            >
                                                {subheadingButtonText}
                                            </button>{' '}
                                            {subheadingAfterButton}
                                        </span>
                                    </Text>

                                    <div className={styles.widgetContainer}>{widget}</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <TrialEligibilityStickyBoxOfferDetails
                        stickyBoxProps={{ position: 'bottom', alwaysVisibleInMobile: true }}
                        visible={showOfferDetails}
                        onClose={handleHideOfferDetails}
                        closeButtonAriaLabel="Close Offer Details"
                    >
                        {offerDetails}
                    </TrialEligibilityStickyBoxOfferDetails>
                </Modal>
            </FocusTrap>
        </div>
    );
}
