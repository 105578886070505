'use client'
import React from 'react';
import { useSearchParams } from 'next/navigation';
import dynamic from 'next/dynamic';
import { PULSE } from '@/constants/pulse/pulse';
import { LiveNowItem } from '@/interfaces/types/LiveNowItem';
import styles from './pulselivenow.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

interface PulseLiveNowClientProps {
    liveNowItems: LiveNowItem[];
}

const PulseLiveNowClient = ({ liveNowItems }: PulseLiveNowClientProps) => {
    const searchParams = useSearchParams();
    const queryString = searchParams.toString();

    const limitDescription = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const renderNowPlaying = (liveNow: LiveNowItem) => {
        // redirect with pulse app link - sal url
        const url = `${PULSE.appLinkUrl}?${queryString}`;

        return (
            <div className={styles.liveNowItem}>
                <div
                    className={`${styles.channelLogo} ${liveNow.roundStyle ? styles.roundImage : styles.roundBackground}`}
                >
                    <ImageWrapper src={liveNow.image} alt="" aria-hidden />
                </div>
                <div className={styles.showName}>
                    <span className={styles.title}>{limitDescription(liveNow.title ?? '', 40)}</span>
                    <span className={styles.artist}>{limitDescription(liveNow.artist ?? '', 30)}</span>
                </div>
                <div className={styles.channelLink}>
                    <a
                        href={url}
                        data-channel={liveNow.channelNumber}
                        data-show={liveNow.title}
                        data-component="pulse_live_now"
                        data-talent={liveNow.artist}
                        data-category={liveNow.category}
                        data-subcategory={liveNow.genre}
                    >
                        <span>Channel {liveNow.channelNumber}</span>
                        <span className={styles.rightTriangle}></span>
                    </a>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.liveNowContainer}>
            <Grid container>
                <Grid col={{ xs: 12, lg: 12 }}>
                    <div className={styles.liveNowInner}>
                        <h4 className={styles.heading}>Live Now</h4>
                        <div className={styles.liveNowListOuter}>
                            <div className={styles.liveNowList}>
                                {liveNowItems.map((item, index) => {
                                    return <React.Fragment key={`channel-${item.channelNumber}-${index}`}>
                                        {renderNowPlaying(item)}
                                    </React.Fragment>;
                                })}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PulseLiveNowClient;

