export const LockIcon = ({ className }: { className?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.86865 3.85602C10.4292 3.31101 11.1954 3 12 3C12.8046 3 13.5708 3.31101 14.1313 3.85602C14.691 4.40011 15 5.13194 15 5.88889V9H9V5.88889C9 5.13194 9.30902 4.40011 9.86865 3.85602ZM7 9V5.88889C7 4.58304 7.53383 3.33657 8.47449 2.42203C9.4142 1.50843 10.6829 1 12 1C13.3171 1 14.5858 1.50843 15.5255 2.42203C16.4662 3.33657 17 4.58304 17 5.88889V9H18.3636C19.1478 9 20 9.64055 20 10.7045V19.2955C20 20.3595 19.1478 21 18.3636 21H5.63636C4.85215 21 4 20.3595 4 19.2955V10.7045C4 9.64055 4.85215 9 5.63636 9H7Z"
            />
        </svg>
    );
};
