'use client';
import dynamic from 'next/dynamic';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);

const AutoDisclosure = () => {
    const pegaScript = `<script src="https://sirsxm-cepl-dt2.pegacloud.net/prweb/IAC?pyActivity=pzIncludeMashupScripts"></script>
    <div id='PegaGadget#1' style = "height:calc(100vh - 80px);" data-pega-gadgetname ='PegaGadget'
        data-pega-action ='openWorkByURL'
        data-pega-applicationname ='CEPUS_PCIChat_IAC'
        data-pega-threadname ='STANDARD'
        data-pega-resizetype ='fixed'
        data-pega-url ='https://sirsxm-cepl-dt2.pegacloud.net/prweb/IAC'
        data-pega-redirectguests='true'
        data-pega-isdeferloaded ='false'
        data-pega-channelID ='MASHUP2c7c27b7045b475e9f9a67887e41162a'
        data-pega-action-param-parameters ='{"uid":"IACUser","token":"U29sdXRpb25UZWFtRmluYWxfMUBA","pzSkinName":"SXMEnterprise","pyMashupSkeletonName":"pyDefaultMashupSkeleton"}' >
    </div>
    <script>
      let userAgentString = navigator.userAgent;
      let IExplorerAgent = userAgentString.indexOf('MSIE') > -1;
      if (!IExplorerAgent) {
          const params = new URLSearchParams(window.location.search);
          const keyparam = params.get('assignkey');
          const ActivityStr = 'pyActivity=Assign-.OpenExternalDisclosureWork&AssignmentID=' + keyparam;

          var gadget = document.getElementById('PegaGadget#1');
          gadget?.setAttribute('data-pega-action-param-query', ActivityStr);
      } else {
          const ActivityStr = 'pyActivity=Assign-.OpenExternalDisclosureWork&ActionType=display';
          var gadget = document.getElementById('PegaGadget#1');
          gadget?.setAttribute('data-pega-action-param-query', ActivityStr);
      }
    </script>`;

    return (
        <StyledContainer includeNavPadding>
            <Grid container>
                <Grid col={{ lg: 12 }}>
                    <div dangerouslySetInnerHTML={{ __html: pegaScript }} />
                </Grid>
            </Grid>
        </StyledContainer>
    );
};

export default AutoDisclosure;
