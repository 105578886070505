import React, { forwardRef } from 'react';
import styles from './modalcontent.module.scss';
import FocusTrap from 'focus-trap-react';
import Modal from 'de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal';
import { Flip, ToastContainer } from 'react-toastify';
import ModalContentTemplate from './ModalContentTemplate';
import { useForwardedRef } from '@/hooks';
import { ToastMessage, showToast } from './ToastMessage';
import { AppModalHero } from './AppModal/AppModalHero';
import { OfferModalHero } from './OfferModal/OfferModalHero';
import { AppModalHeroMobile } from './AppModal/AppModalHeroMobile';
import { OfferModalHeroMobile } from './OfferModal/OfferModalHeroMobile';
import { PULSE } from '@/constants/pulse/pulse';

interface ContentProps {
    submitHandler: () => void;
    closeHandler: () => void;
}

interface ModalTemplateProps {
    modalAsset: SiteAsset,
    isModal: boolean;
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;    
    focusPause: boolean;
    closeModal: () => void;
    focus?: string;
    content?: (props: ContentProps) => React.ReactNode;
    successMessage?: React.ReactNode;
    submitForm?: (toastMessage: React.ReactNode) => void;
}

const ModalTemplate = forwardRef<HTMLDivElement, ModalTemplateProps>(({
    isModal,
    openModal, 
    setOpenModal, 
    focusPause,
    closeModal,
    focus, 
    content, 
    successMessage, 
    submitForm, 
}: ModalTemplateProps, ref) => {
    const forwardedRef = useForwardedRef(ref);
    return (
        !isModal ? (
            <div>
                <div className={styles.modalContainer}>
                    {content && content({
                        submitHandler: (): void => {
                            setOpenModal(false);
                            submitForm && submitForm(successMessage);
                        },
                        closeHandler: (): void => {
                            setOpenModal(false);
                            closeModal();
                        }
                    } satisfies ContentProps)}
                </div>
                <div style={{ position: 'absolute' }}>
                    <ToastContainer transition={Flip} />
                </div>
            </div>
        ) : (
        <div id='dialog' tabIndex={-1}>
            <FocusTrap
                active={openModal}
                paused={focusPause}
                focusTrapOptions={{
                    initialFocus: focus,
                    fallbackFocus: '#dialog',
                }}
            >
                <Modal
                    ref={forwardedRef}
                    className={styles.modalWrapper}
                    onClose={() => {closeModal()}}
                    open={openModal}
                    hideTitleBar={true}
                    maxWidth={1000}
                    padding={'none'}
                    title=""
                >
                    <div className={styles.modalContainer}>
                        {content && content({
                            submitHandler: (): void => {
                                setOpenModal(false);
                                submitForm && submitForm(successMessage);
                            },
                            closeHandler: (): void => {
                                setOpenModal(false);
                                closeModal();
                            }
                        } satisfies ContentProps)}
                    </div>
                </Modal>
            </FocusTrap>
            <div style={{ position: 'absolute' }}>
                <ToastContainer transition={Flip} />
            </div>
        </div>
        )
    );
});

ModalTemplate.displayName = 'ModalTemplate';
export default ModalTemplate;

export const ModalContentMap: { [key: string]: {
    modalContent: (
        props: ModalTemplateProps, 
        ref: React.Ref<HTMLDivElement>
    ) => React.ReactNode,
}} = {
    app: {
        modalContent: (props: ModalTemplateProps, ref) => (
            <ModalTemplate
                ref={ref}
                modalAsset={props.modalAsset}
                isModal={props.isModal}
                openModal={props.openModal}
                setOpenModal={props.setOpenModal}
                focusPause={props.focusPause}
                closeModal={props.closeModal}
                focus={`#${PULSE.emailInputId}`}
                content={({ submitHandler, closeHandler }: ContentProps) => (
                    <ModalContentTemplate
                        modalAsset={props.modalAsset}
                        isModal={props.isModal}
                        ModalHero={() => (
                            <AppModalHero
                                imageSrc="/content/dam/sxm-com/pulse/iphone-app-download-cropped.png"
                                imageAlt="App page in app store"
                                mainOptimizedWidth={280}
                                mainOptimizedHeight={500}
                            />
                        )}
                        ModalHeroMobile={() => (
                            <AppModalHeroMobile
                                iconSrc="/content/dam/sxm-com/pulse/SXMAppTile.png"
                                iconAlt="SiriusXM app icon"
                                iconOptimizedWidth={100}
                                iconOptimizedHeight={100}
                            />
                        )}
                        placeHolderText={PULSE.emailPlaceholder}
                        submitHandler={submitHandler}
                        closeHandler={closeHandler}
                    />
                )}
                successMessage={<ToastMessage message={PULSE.emailToastMessage} />}
                submitForm={showToast}
            />
        ),
    },
    off: {
        modalContent: (props: ModalTemplateProps, ref) => (
            <ModalTemplate
                ref={ref}
                modalAsset={props.modalAsset}
                isModal={props.isModal}
                openModal={props.openModal}
                setOpenModal={props.setOpenModal}
                focusPause={props.focusPause}
                closeModal={props.closeModal}
                focus={`#${PULSE.emailInputId}`}
                content={({ submitHandler, closeHandler }: ContentProps) => props.modalAsset && (
                    <ModalContentTemplate
                        modalAsset={props.modalAsset}   
                        isModal={props.isModal}
                        ModalHero={() => (
                            <OfferModalHero
                                imageSrc="/content/dam/sxm-com/pulse/stella-barking-icon.png"
                                imageAlt="Stella"
                                width={280}
                                height={500}
                                plan={props.modalAsset.fields.additional_description}
                            />
                        )}
                        ModalHeroMobile={() => (
                            <OfferModalHeroMobile
                                iconSrc="/content/dam/sxm-com/pulse/stella-barking-icon.png"
                                iconAlt="Stella icon"
                                iconOptimizedWidth={100}
                                iconOptimizedHeight={100}
                                plan={props.modalAsset.fields.additional_description}
                            />
                        )}
                        placeHolderText={PULSE.emailPlaceholder}
                        submitHandler={submitHandler}
                        closeHandler={closeHandler}
                    />
                )}
                successMessage={<ToastMessage message={PULSE.emailToastMessage} />}
                submitForm={showToast}
            />
        )
    }      
};
