export const PRICING = Object.freeze({
    planType: 'CAR + APP',
    bestPlanText: 'RECOMMENDED PLAN',
    see: 'See',
    offerDetails: 'Offer Details',
    below: 'below.',
    apply: 'apply.',
    backgroundAltText: 'Immersive Listening in a Crowd',
    arrowAltText: 'arrow icon for link',
    offer4for12: '$4/mo for 12 months',
    offer2for3: '$2 for 3 months',
    offer5for12: '$5/mo for 12 months',
    terms4for12: 'then $9.99/mo.',
    terms2for3AllAccess: 'then $9.99/mo.',
    terms2for3StandardVega: 'then $9.99/mo.',
    terms5for12: 'then $9.99/mo.',
    taglineAllAccess: 'Enjoy music, news, sports, talk & more',
    taglineStandardVega: 'Start with All Music or bundle with news, sports or talk*',
    tierAllAccess: 'All Access Next Plan',
    tierStandardVega: 'Build Your Plan',
    titleAllAccess: 'Subscribe Now',
    titleStandardVega: 'Build Your Plan',
    descriptionAllAccess: 'Enjoy music, news, sports, talk & more',
    descriptionStandardVega: 'Start with All Music or bundle with news, sports & talk',
    linkTextAllAccess: 'Get SiriusXM',
    linkTextStandardVega: 'Subscribe Now',
    url4for12AllAccess:
        'https://care.siriusxm.com/subscribe/checkout/purchase/satellite/targeted/new?programcode=AANMCP12MO4TA',
    url2for3AllAccess:
        'https://care.siriusxm.com/subscribe/checkout/purchase/satellite/targeted/new?programcode=AAN3MO2FEETA',
     url5for12AllAccess:
        'https://care.siriusxm.com/subscribe/checkout/purchase/satellite/targeted/new?programcode=AANMCP12MO5TA',
    url2for3StandardVega:
        'https://care.siriusxm.com/subscribe/checkout/purchase/satellite/vega?programCode=VGA3MO2FEEALL',
    url5for12StandardVega:
        'https://care.siriusxm.com/subscribe/checkout/purchase/satellite/vega?programCode=VGAMCP12MO5ALL',
    offerKey4for12AllAccess: '4for12AllAccess',
    offerKey2for3AllAccess: '2for3AllAccess',
    offerKey5for12AllAccess: '5for12AllAccess',
    offerKey2for3StandardVega: '2for3StandardVega',
    offerKey5for12StandardVega: '5for12StandardVega',
    allAccessFeaturesList: [
        {
            feature: '425+ Channels',
        },
        {
            feature: 'Ad-free Music',
        },
        {
            feature: '24/7 Breaking News',
        },
        {
            feature: 'Live Sports & Analysis',
        },
        {
            feature: 'Talk & Entertainment',
        },
        {
            feature: 'Howard Stern',
        },
    ],
    standardVegaFeaturesList: [
        {
            feature: 'Ad-free Music',
        },
        {
            feature: 'Artist Stations',
        },
        {
            feature: 'Music-only Channels',
        },
        {
            feature: 'Exclusive video',
        },
        {
            feature: 'News, sports and talk add-ons*',
        },
    ],
    inTrial: 'InTrial',
    onboarding: 'Onboarding',
    winback: 'Winback',
    journeyDaysParam: 'jDay',
    priorRelationshipParam: 'PRF',
    engagedParam: 'eng',
    cohortGeneral: 'general',
    engagedYes: 'Y',
    unknownPriorRelationship: 'UNK',
    standardVegaFootnote: 'Additional fees apply for add-ons after promotional term.',
    modalParam: 'Mod', // off (offer) app (app download) undefined (no modal shown)
    offerParamValue: 'off',
    appParamValue: 'app',
});
