'use client';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import SearchResult from './SearchResult';
import { NewsroomCardGroup } from '../NewsroomCardGroup';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Pagination = dynamic(() => import('de-sxm-reactlib/dist/components/molecules/Pagination/Pagination'));
import IconSimpleArrow from '@/assets/icons/icon-simple-arrow.svg';
import { useUserDeviceType, DeviceTypes } from '@/hooks';
import styles from './searchresult.module.scss';

const { paginationNavigationWrapper, articleWrapperStyles, linkWrapper, textLinkStyles } = styles;

type Result = {
    label: string;
    title: string;
    titleLink: string;
};

interface SearchResultWithPaginationProps {
    results: Result[];
    resultsPerPage: number;
    headline?: string;
    cardsVersion?: boolean;
    groupLink?: SiteLink;
}

const SearchResultWithPagination = ({
    results,
    resultsPerPage,
    cardsVersion,
    groupLink,
    headline,
}: SearchResultWithPaginationProps) => {
    const userDeviceType = useUserDeviceType();
    const isMobileDevice = userDeviceType === DeviceTypes.android || userDeviceType === DeviceTypes.ios;
    const [pageToShow, setPageToShow] = useState(0);

    if (cardsVersion && headline) {
        const articles = results.slice(0, 3);

        return <NewsroomCardGroup articles={articles} headline={headline} groupLink={groupLink} />;
    }

    const paginatedResults: Result[][] = [];
    let page: Result[] = [];
    results.forEach((result, index) => {
        if ((index + 1) % resultsPerPage !== 0) {
            page.push(result);
            if (index + 1 === results.length) {
                paginatedResults.push(page);
            }
        } else if ((index + 1) % resultsPerPage === 0) {
            page.push(result);
            paginatedResults.push(page);
            page = [];
        }
    });

    return (
        <>
            {paginatedResults[pageToShow].map((element, index) => (
                <SearchResult
                    key={index}
                    className={articleWrapperStyles}
                    label={element.label}
                    title={element.title}
                    titleLink={{ href: element.titleLink }}
                    variant="list"
                />
            ))}
            <div className={paginationNavigationWrapper}>
                <Pagination
                    showPage={setPageToShow}
                    paginatedResults={paginatedResults}
                    leftIcon={<IconSimpleArrow />}
                    rightIcon={<IconSimpleArrow />}
                    isMobileDevice={isMobileDevice}
                />
            </div>
            {groupLink && (
                <div className={linkWrapper}>
                    <Button
                        className={groupLink.fields?.theme === 'text' ? textLinkStyles : undefined}
                        type="button"
                        href={groupLink.fields?.href}
                        buttonColor={groupLink.fields?.ctaColor}
                        text={groupLink.fields?.link_text}
                        theme={groupLink.fields?.theme}
                        ariaLabel={groupLink.fields?.aria_label}
                    />
                </div>
            )}
        </>
    );
};

export default SearchResultWithPagination;
