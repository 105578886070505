'use client';
import dynamic from 'next/dynamic';
import { DriverStrip, SportStrip } from '@/components/molecules/Sports/SportStrip';
import type { Details as Team } from '@/components/molecules/Sports/SportStrip';
import dayjs from 'dayjs';
import timeZoneExtension from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styles from './sportschedule.module.scss';
import { useMemo, useState } from 'react';
import { Race } from '@/packages/sxm/sports/races/RacesResponse';
import { DateOption, DateRange, GameList, RaceProps } from './interfaces';
import filters from './league.config';
import { DynamicTableRow } from '@/components/molecules/DynamicTable/DynamicTable';
import { Link } from '@/components/atoms/Link';
import NoGames from './NoGames';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));

export default function RaceSchedule({ leagueAbbr, remind_me_logo, remind_me_title, reminder_url, races }: RaceProps) {
    dayjs.extend(advancedFormat);
    dayjs.extend(timeZoneExtension);
    dayjs.extend(isBetween);

    const today = dayjs();

    const dateOptions = useMemo<DateOption[]>(() => {
        const ranges: string[] = [];
        const dateOptions: DateOption[] = [];

        races?.forEach((race: Race) => {
            if (race.seriesName) {
                const filterData = filters[leagueAbbr] || filters.default;

                const raceDate = dayjs(race.startDateTime);
                let start = raceDate.startOf('day');
                let end = raceDate.endOf('day');
                let label = start.isSame(today, 'day') ? start.format('[Today], MMMM D') : start.format('dddd, MMMM D');

                if (filterData.schedule === 'monthly') {
                    start = raceDate.startOf('month');
                    end = raceDate.endOf('month');
                    label = start.format('MMMM');
                } else if (filterData.schedule === 'weekly') {
                    if (filterData.weekStart) {
                        if (start.day() < filterData.weekStart) {
                            start = start.day(-(filterData.weekStart - 1));
                        } else if (start.day() > filterData.weekStart) {
                            start = start.startOf('week');
                            start = start.day(filterData.weekStart);
                        }
                    }

                    start = start.startOf('day');
                    end = start.clone().add(6, 'day').endOf('day');
                    const endLabel = start.isSame(end, 'month') ? end.format('D') : end.format('MMM D');
                    label = start.format(`MMM D [- ${endLabel}]`);
                }

                const rangeIndex = `${start}${end}`;

                if (ranges.indexOf(rangeIndex) === -1) {
                    ranges.push(rangeIndex);

                    dateOptions.push({
                        range: {
                            start: start,
                            end: end,
                        },
                        value: label,
                        label: label,
                    });
                }
            }
        });

        return dateOptions;
    }, [races, today, leagueAbbr]);

    const [dateRange, setDateRange] = useState<DateRange>(
        dateOptions[0] ? dateOptions[0].range : { start: dayjs(), end: dayjs() },
    );

    function renderChannelLink(
        deeplink: string | undefined,
        channelNumer: string | number | undefined,
        ariaLabel: string,
    ) {
        if (deeplink) {
            return (
                <Link href={deeplink} target="_blank" aria-label={ariaLabel}>
                    {channelNumer}
                </Link>
            );
        }
        return channelNumer || '-';
    }

    const [raceDates, dates] = useMemo<[GameList, string[]]>(() => {
        const raceDates: GameList = {};
        const dates: string[] = [];

        if (races) {
            for (let i = 0; i < races.length; i += 1) {
                const race = races[i] as Race;
                const index = race.startDateTime || '';
                const raceDate = dayjs(race.startDateTime);

                if (!raceDate.isBetween(dateRange.start, dateRange.end)) {
                    continue;
                }

                if (!raceDates[index]) {
                    const dateStr = raceDate.format('dddd, MMMM D • h:mm A z');
                    raceDates[index] = [
                        <Text
                            key={dateStr}
                            variant="small"
                            tag="h3"
                            className={styles.dateTitle}
                            dataCy="components-contextual-sports-date-title"
                        >
                            {dateStr}
                        </Text>,
                    ];
                    dates.push(index);
                }

                const leagueRace: Team = {
                    logo: race.homeLogo,
                    pageUrl: race.homeUrl,
                    name: race.raceOrDriverName || race.seriesName || '',
                    location: race.venueOrTeamName || '',
                    channel: race.nationalStream,
                };

                const moreWays: DynamicTableRow[] = [];

                if (race.nationalStream || race.nationalInternetStream) {
                    const ariaLabel = `Listen to ${race.raceOrDriverName} on channel ${race.nationalStream}`;
                    moreWays.push({
                        rowLabel: 'National',
                        items: [
                            race.nationalStream || '-',
                            renderChannelLink(race.nationalStreamLink, race.nationalInternetStream, ariaLabel),
                        ],
                    });
                }

                if (race.spanishStream || race.spanishInternetStream) {
                    const ariaLabel = `Listen to ${race.raceOrDriverName} on channel ${race.spanishStream}`;
                    moreWays.push({
                        rowLabel: 'Espanol',
                        items: [
                            race.spanishStream || '-',
                            renderChannelLink(race.spanishStreamLink, race.spanishInternetStream, ariaLabel),
                        ],
                    });
                }

                const isLive = dayjs().isBetween(race.startDateTime, race.endDateTime);
                const drivers =
                    race.raceDrivers && race.raceDrivers.length > 0 ? <DriverStrip drivers={race.raceDrivers} /> : '';
                const isNascar = leagueAbbr.toLowerCase() === 'nascar';

                raceDates[index].push(
                    <li key={i}>
                        <SportStrip
                            league={leagueRace}
                            moreWays={moreWays}
                            isLive={isLive}
                            subStrip={drivers}
                            tabletContentLayout={isNascar ? 'column' : 'row'}
                            ariaLabel={`Listen to ${race.raceOrDriverName} on channel ${race.nationalStream}`}
                        />
                    </li>,
                );
            }
        }

        return [raceDates, dates];
    }, [races, dateRange.start, dateRange.end, leagueAbbr]);

    function dateSelectHandler(element: HTMLInputElement, { data }: { data?: DateRange }) {
        if (data) {
            setDateRange(data);
        }
    }

    if (dates.length > 0) {
        return (
            <Grid container>
                <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                    <div className={styles.filterBar}>
                        <Select<DateRange>
                            label="filter races by date"
                            type="bar"
                            theme="purplemini"
                            value={dateOptions[0].value}
                            name="dateFilter"
                            onChange={dateSelectHandler}
                        >
                            <OptionGroup>
                                {dateOptions.map((option, i) => (
                                    <Option key={i} object={option.range} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                                <Option value="" disableOption className={styles.moreGames}>
                                    More events will be added as information becomes available.
                                </Option>
                            </OptionGroup>
                        </Select>
                    </div>

                    {dates.sort().map((index) => (
                        <ul key={index} className={styles.listStylings}>
                            {raceDates[index]}
                        </ul>
                    ))}

                    <StyledContainer paddingTop={48}>
                        <NoGames
                            logo={remind_me_logo}
                            logoSize="small"
                            title={remind_me_title || 'Never Miss a Moment'}
                        >
                            <Link href={reminder_url} dataCy="contextualcomponent-race-addtocal-link">
                                Add to calender
                            </Link>
                        </NoGames>
                    </StyledContainer>
                </Grid>
            </Grid>
        );
    }

    return <></>;
}
