import { useContext, useState } from 'react';
import { CGContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import ChannelHeader from '../ChannelHeader';
import { Genrelist } from '../interfaces';
import { useSearchParams } from 'next/navigation';
import { categoryMap } from '../ChannelGuide';

export default function TalkEntertainment(): JSX.Element {
    const searchParams = useSearchParams();
    const context = useContext(CGContext);
    const sortedChannels = context.channels;
    const upsellLink = searchParams.get('upsell') !== 'false' ? context.channelGuide.fields.upsellLink : undefined;
    const display_listen_live = context.channelGuide.fields.display_listen_live;
    const [genre, setGenre] = useState('All');

    const genreList: Genrelist = [
        [
            { value: 'All', label: 'All Talk' },
            { value: 'Entertainment', label: 'Entertainment' },
            { value: 'Religion', label: 'Religion' },
            { value: 'Canadian', label: 'Canadian' },
            { value: 'Comedy', label: 'Comedy' },
            { value: 'Howard Stern', label: 'Howard Stern' },
        ],
    ];

    return (
        <>
            <ChannelHeader genreList={genreList} onGenreChange={setGenre} />
            {sortedChannels &&
                sortedChannels[categoryMap['Talk & Entertainment']] &&
                sortedChannels[categoryMap['Talk & Entertainment']][genre] && (
                    <ChannelList
                        channels={sortedChannels[categoryMap['Talk & Entertainment']][genre]}
                        upsellLink={upsellLink}
                        display_listen_live={display_listen_live}
                    />
                )}
        </>
    );
}
