import dayjs from 'dayjs';
import logger from '../logger';

export default async function mountain(channelList: string[]): Promise<MountainResponse> {
    const domain = typeof window === 'undefined' ? 'https://www.siriusxm.com' : '';
    const mountainUrl = `${domain}/api/mountain/`;

    let nextCallAfter: number = 0;
    let responseData: MountainResponse = { nextCallAfter: 30000 };

    const url = mountainUrl + channelList.join(',');

    try {
        if (channelList.length > 0) {
            const response = await fetch(url, {
                cache: 'no-store',
            });
            responseData = await response.json();
            const now = dayjs().valueOf();
            const expireTime = dayjs(responseData.utcExpireTime).valueOf();
            nextCallAfter = expireTime - now + 30000;
            nextCallAfter = nextCallAfter < 30000 ? 30000 : nextCallAfter;

            responseData.nextCallAfter = nextCallAfter;
        }
    } catch (error) {
        logger.error(`Error fetching from mountain: ${error}`);
    }

    return responseData;
}
