export const DownloadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.66406 14.0007C4.66406 13.6325 4.96254 13.334 5.33073 13.334H10.6641C11.0323 13.334 11.3307 13.6325 11.3307 14.0007C11.3307 14.3688 11.0323 14.6673 10.6641 14.6673H5.33073C4.96254 14.6673 4.66406 14.3688 4.66406 14.0007Z"
                fill="#202020"
            />
            <path
                d="M7.73865 10.6152C7.81821 10.6488 7.90564 10.6673 7.9974 10.6673C8.17466 10.6673 8.33576 10.5981 8.45518 10.4853C8.46009 10.4807 8.46494 10.4759 8.46971 10.4711L10.8235 8.12313C11.0842 7.8631 11.0847 7.44099 10.8247 7.18032C10.5647 6.91965 10.1425 6.91913 9.88188 7.17916L8.66406 8.39397V2.00065C8.66406 1.63246 8.36559 1.33398 7.9974 1.33398C7.62921 1.33398 7.33073 1.63246 7.33073 2.00065V8.39397L6.11291 7.17916C5.85224 6.91913 5.43013 6.91965 5.1701 7.18032C4.91008 7.44099 4.9106 7.8631 5.17127 8.12313L7.5251 10.4712C7.52987 10.4759 7.53471 10.4807 7.53961 10.4853C7.59931 10.5417 7.66688 10.5851 7.73865 10.6152Z"
                fill="#202020"
            />
        </svg>
    );
};
