import { inheritQueryString } from '@/packages/string';
import globalProperties from '../../../../properties/global';
import Context from '../pageContext';
import { switchCase } from '@/packages/switch';
import { ButtonTheme } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import PhoneNumber from './PhoneNumber';

type ThemeType = 'Primary CTA' | 'Secondary CTA' | 'White CTA' | 'Custom CTA' | 'Tertiary';
type ButtonThemesWithWeb2 = ButtonTheme | 'tertiary';

const cases: { [key in ThemeType]: ButtonThemesWithWeb2 } = {
    ['Primary CTA']: 'primary',
    ['Secondary CTA']: 'secondary',
    ['White CTA']: 'dark',
    ['Custom CTA']: 'custom',
    ['Tertiary']: 'tertiary',
};

const themeSwitch = () => switchCase<ThemeType, ButtonThemesWithWeb2>(cases)('text');

export default function Link(module: ContentfulLink, pageContext: Context): SiteLink {
    let href = module.fields.href || '';
    const inheritRegex = /(subscribe\/checkout)|(\/onboarding\/setup\-credentials)/gi;
    const shouldInherit = !!module.fields.inheritUrlParameters || inheritRegex.test(href);

    if (shouldInherit && typeof pageContext?.get === 'function') {
        const searchParams = pageContext.get<StringObject>('searchParams') || {};
        href = inheritQueryString(href, searchParams, globalProperties.urlInheritParams);
    }

    let sitePhoneNumber: SiteLink | undefined;
    if (module.fields.phone_number) {
        sitePhoneNumber = PhoneNumber(module.fields.phone_number);
    }

    let ctaColor: string = 'transparent';
    if (module.fields.ctaColor) {
        ctaColor = module.fields.ctaColor[0] !== '#' ? `#${module.fields.ctaColor}` : module.fields.ctaColor;
    }

    const target = module.fields.link_target === 'New Tab' ? '_blank' : '';

    // The figma source's color name includes the "subset-" prefix:
    const colorSet =
        module.fields.cta_color_set === 'sxm-blue' ? 'subset-sxm-blue' : module.fields.cta_color_set || 'base';

    return {
        cms_id: module.sys.id,
        cms_type: module.sys.contentType.sys.id,
        fields: {
            name: module.fields.name,
            href: href,
            link_text: module.fields.link_text,
            secondaryText: module.fields.secondaryText,
            aria_label: module.fields.aria_label,
            link_type: module.fields.link_type,
            ctaColor: ctaColor,
            media: module.fields.media,
            link_target: target,
            type: module.fields.link_target,
            link_caption: module.fields.link_caption,
            inheritParams: shouldInherit,
            phoneNumber: sitePhoneNumber,
            qr_code: module.fields.qr_code,
            theme: themeSwitch()(module.fields.link_type as ThemeType),
            size: module.fields.size ? module.fields.size : 'Large',
            colorSet: colorSet,
            analytics_tags: module.fields.analytics_tags,
        },
    };
}
