'use client';
import styles from './pulseofferdetails.module.scss'
import cn from 'classnames';
import { RenderRTE } from '@/components/RenderRTE'
import { useSearchParams, useParams } from 'next/navigation';
import { createOfferPayload } from '../StaticContent/pulse/Pricing/GenerateOffer';
import { PRICING } from '@/constants/pulse/pricing';

interface PulseOfferDetailsProps {
    index?: number;
    asset: SiteAssetGroup; 
    asW2?: boolean;
}

const PulseOfferDetails = ({ asset, asW2 = false  }: PulseOfferDetailsProps) => {
    const offerDetails: SiteAsset[] = asset.fields.assets;

    const searchParams = useSearchParams();
    const priorRelationship = searchParams.get(PRICING.priorRelationshipParam);
    const journeyDaysParam = searchParams.get(PRICING.journeyDaysParam);

    const params = useParams();
    const slug = params.slug[0];

    const offerData = createOfferPayload(
        priorRelationship ?? '', 
        journeyDaysParam ?? '', 
        slug
    );

    return (
        <div className={cn(styles.offerDetails, 'styledContainer-module_styledContainer')}>
            { offerDetails.map((offerDetail: SiteAsset, index: number) => (
                offerDetail.fields.title === offerData.offerName && (
                    <div className="grid-container" id={offerDetail.fields.title} key={index} >
                        <div className="legal-copy col col-sm-12" id={offerDetail.fields.title} key={index} >
                            <RenderRTE contentfulDoc={offerDetail.fields.pulse_offer_details} pToSpan asW2={asW2} />
                        </div>
                    </div>
                )
            )) }
        </div>
    )
}

export default PulseOfferDetails
