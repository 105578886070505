'use client';

import styles from '../linkInfoCard.module.scss';
import { Link } from '@/components/atoms/Link';
import { convertContentToLinkTarget as setTarget } from '@/packages/linkHandler';
import ArrowRightIcon from '@/assets/icons/icon-arrow-right.svg';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export interface LinkInfoItemProps {
    link: Link;
    disabled: boolean;
    icon?: string;
    onClick?: React.MouseEventHandler;
}

export default function LinkInfoItem({ link, icon, disabled, onClick }: LinkInfoItemProps) {
    const ref = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (ref.current && disabled) {
            ref.current.addEventListener('keydown', handleKeyDown);
        }
    }, [ref, disabled]);

    const handleKeyDown = (e: KeyboardEvent) => e.key === 'Enter' && e.preventDefault();

    const classes = cn(styles.row, {
        [styles.disabled]: disabled,
    });

    if (link.href) {
        return (
            <Link
                ref={ref}
                className={classes}
                href={link.href}
                aria-label={link.aria_label}
                target={setTarget(link.link_target)}
                aria-disabled={disabled}
                onClick={onClick}
            >
                {icon && <ImageWrapper src={icon} alt="" />}
                {link.link_text}
                <span className={styles.arrow}>
                    <ArrowRightIcon />
                </span>
            </Link>
        );
    }

    return (
        <button ref={ref} className={classes} aria-label={link.aria_label} aria-disabled={disabled} onClick={onClick}>
            {icon && <ImageWrapper src={icon} alt="" />}
            {link.link_text}
            <span className={styles.arrow}>
                <ArrowRightIcon />
            </span>
        </button>
    );
}
