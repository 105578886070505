'use client';

import { useEffect, useState } from 'react';
import styles from './pageloading.module.scss';

export default function PageLoading() {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        let counter = 0;
        const interval = 100;
        const maxCounter = 3000 / interval;

        const sfInterval = setInterval(() => {
            const clientWindow = window as BrowserWindow;
            const campaigns = clientWindow.SalesforceInteractions?.mcis?.getCampaignResponses();

            if (campaigns) {
                document.body.classList.remove('loading');
                setHidden(true);
                clearInterval(sfInterval);
            } else if (counter >= maxCounter) {
                document.body.classList.remove('loading');
                setHidden(true);
                clearInterval(sfInterval);
            }

            counter += 1;
        }, 100);
    });

    return (
        <>
            <div
                className={styles.pageLoadOverlay}
                role="alert"
                aria-busy={!hidden}
                aria-label="Loading"
                aria-hidden={hidden}
            ></div>
        </>
    );
}
