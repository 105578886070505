import EPGResponse, { Episode as EPGEpisode, Program as EPGProgram } from './EPGResponse';
import ChannelPrograms, { Episode, Program } from './ChannelPrograms';
import globalProperties from '../../../../properties/global';

function mapEpisode(episode: EPGEpisode): Episode {
    return {
        logo: episode.pr.logo,
        logoLarge: episode.pr.lrgLogo,
        longDesc: episode.pr.longDesc,
        shortDesc: episode.pr.shortDesc,
        name: episode.pr.pName,
        startTime: new Date(episode.sc.sTimeStr),
        endTime: new Date(episode.sc.eTimeStr),
        duration: episode.sc.duration,
        episodeId: episode.pr.prId,
        type: episode.pgType,
        programId: episode.pgid,
    };
}

function mapPrograms(program: EPGProgram): Program {
    return {
        description: program.desc,
        logo: program.logo,
        logoLarge: program.lrgLogo,
        name: program.name,
        id: program.pgid,
        type: program.type,
    };
}

export async function getChannelProgramSchedules(channelId: string, programid: number) {
    let program: Program | undefined;
    const channelPrograms: ChannelPrograms | undefined = await getChannelSchedules(channelId);
    if (channelPrograms) {
        program = channelPrograms.programs.find((program) => program.id === programid);
    }
    return program;
}
export async function getXMChannelSchedules(channelId: string): Promise<ChannelPrograms> {
    const epgDomain: string = globalProperties.domain ? globalProperties.domain : 'https://www.siriusxm.com';
    const url = `${epgDomain}/sxmepg/epg.sxmchepginfo.xmc?channelKeys=${channelId}&distribution=XMDCOM&tzone=Eastern`;
    return getChannelSchedules(url);
}

export async function getSiriusChannelSchedules(channelId: string): Promise<ChannelPrograms> {
    const epgDomain: string = globalProperties.domain ? globalProperties.domain : 'https://www.siriusxm.com';
    const url = `${epgDomain}/sxmepg/epg.sxmchepginfo.xmc?channelKeys=${channelId}&distribution=SIRIUSDCOM&tzone=Eastern`;
    return getChannelSchedules(url);
}
async function getChannelSchedules(url: string): Promise<ChannelPrograms> {
    const response = await fetch(url);
    const epgJson: EPGResponse = await response.json();

    const json: ChannelPrograms = {
        schedule: [],
        nowplaying: epgJson.chEpgInfo.nowplaying.episode.map(mapEpisode),
        programs: epgJson.chEpgInfo.pg.map(mapPrograms),
    };

    epgJson.chEpgInfo.dayChSchedules.forEach((daySchedule) => {
        json.schedule.push({
            date: new Date(daySchedule.groupdate),
            episodes: daySchedule.episode.map(mapEpisode),
        });
    });

    json.programs.forEach((program) => {
        program.schedules = [];
        json.schedule.forEach((schedule) => {
            const programDayEpisodes = schedule.episodes.filter((episode) => episode.programId === program.id);
            program.schedules?.push({ date: schedule.date, episodes: programDayEpisodes });
        });
    });

    return json;
}
