'use client';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { useValidator } from '@/hooks';
import { NFL_TEAMS, US_STATES, VALIDITY } from '@/constants';
import type { Config, Fields } from '@/hooks/useValidator/interfaces';
import { Headline } from '@/components/molecules/Headline';
import globalProperties from '@/../properties/global';
import { RenderRTE } from '@/components/RenderRTE';
import dayjs from 'dayjs';
import Ended from './Ended';
import styles from './form.module.scss';
import useErrorCount from '@/hooks/useErrorCount';
import { ChannelCardGroupWrapper } from '@/components/molecules/ChannelCardGroup';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Checkbox = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Checkbox/Checkbox'));
const FormErrorDisplay = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Inputs/FormErrorDisplay/FormErrorDisplay'),
);
const Radio = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Radio/Radio'));
const RadioGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/RadioGroup/RadioGroup'));
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Fieldset = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Fieldset/Fieldset'));
import Input from 'de-sxm-reactlib/dist/components/atoms/Inputs/Input/Input';
const Textarea = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Input/Textarea'));
const Form = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Form/Form'));
import submitJson from 'de-sxm-reactlib/dist/components/atoms/Inputs/Form/contentTypes/json';
import type { SweepsFormData } from './SweepsFormData';
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));

interface Props {
    sweepsFields: SweepsFormData;
    asW2?: boolean;
}

interface ServerFields {
    error: string;
    message: string;
    name: string;
}

type SubmitState = 'untouched' | 'failed' | 'success' | 'error' | 'duplicate';

export default function SweepsForm({ sweepsFields: { formFields, featuredChannelFields }, asW2 }: Props): JSX.Element {
    const [ended, setEnded] = useState(false);
    const emailRef = useRef<HTMLInputElement>(null);
    const confirmEmailRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (dayjs().isBefore(formFields.begin_date)) {
            setEnded(true);
        }
    }, [formFields.begin_date]);

    const validationConfig: Config = {
        fields: {
            firstName: [{ type: 'required', helperText: 'First name required' }],
            lastName: [{ type: 'required', helperText: 'Last name required' }],
            email: [
                {
                    type: 'email',
                    helperText: 'Valid email address required',
                    validateAlso: [confirmEmailRef.current],
                },
            ],
            confirmEmail: [
                { type: 'matchesCaseInsensitive', config: emailRef.current, helperText: 'Your email must match' },
            ],
            phone: [{ type: 'minlength', config: 14, helperText: 'Valid phone number required' }],
            address1: [{ type: 'required', helperText: 'Valid street address required' }],
            city: [{ type: 'required', helperText: 'City required' }],
            state: [{ type: 'required', helperText: 'State required' }],
            zip: [{ type: 'minlength', config: 5, helperText: 'Valid zip code required' }],
            acceptLegal: [{ type: 'istrue', helperText: 'Please accept the Official Rules' }],
        },
    };

    if (formFields.legal_agreement) {
        validationConfig.fields['partner_optin2'] = [
            { type: 'istrue', helperText: 'Please accept the Content Release Agreement' },
        ];
    }

    if (formFields.list_item && formFields.list_item_answers) {
        validationConfig.fields['options'] = [{ type: 'required', helperText: 'Select a location is required' }];
    }

    if (formFields.trivia_question && formFields.trivia_answers) {
        validationConfig.fields['answer1'] = [{ type: 'istrue', helperText: 'Answer required' }];
    }

    if (formFields.text_area) {
        validationConfig.fields['ans'] = [{ type: 'required', helperText: 'Required' }];
    }

    const validator = useValidator(validationConfig);
    const [submitState, setSubmitState] = useState<SubmitState>('untouched');
    const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
    const [validations, setValidations] = useState(validator.getDefault);
    const errorCount: number = useErrorCount(validations);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow._sxmDataLayerPush) {
            if (submitState === 'success') {
                browserWindow._sxmDataLayerPush('sweeps-success');
            } else if (submitState === 'failed') {
                browserWindow._sxmDataLayerPush('sweeps-fail');
            }
        }
    }, [submitState]);

    if (ended) {
        const endedFields = {
            closed_headline: formFields.closed_headline,
            closed_description: formFields.closed_description,
            closed_message: formFields.closed_message,
        };
        return <Ended sweepsFields={endedFields} asW2={asW2} />;
    }

    function validateForm(event: React.SyntheticEvent) {
        const newValidations = validator.validate(event);
        const updatedValidations = { ...validations, ...newValidations };
        setValidations(updatedValidations);
        return updatedValidations.form.invalid === VALIDITY.valid;
    }

    function validateInput(input: HTMLInputElement) {
        const newValidations = validator.validateField(input);
        const updatedValidations = { ...validations, ...newValidations };
        setValidations(updatedValidations);
        return updatedValidations.form.invalid === VALIDITY.valid;
    }

    function onSubmit(event: React.SyntheticEvent, data: FormData) {
        setSubmitAttempted(true);
        const isValid = validateForm(event);

        if (isValid) {
            if (data.get('nfl_optIn')) {
                const nflFormData = new FormData();
                nflFormData.set('languagePreference', 'en-US');
                nflFormData.set('sourceName', 'DE_Sweepstakes');
                nflFormData.set('email', data.get('email') as string);
                nflFormData.set('firstName', data.get('firstName') as string);
                nflFormData.set('lastName', data.get('lastName') as string);
                nflFormData.set('streetAddress', data.get('address1') as string);
                nflFormData.set('city', data.get('city') as string);
                nflFormData.set('state', data.get('state') as string);
                nflFormData.set('zip', data.get('zip') as string);
                nflFormData.set('favoriteTeam', data.get('favoriteTeam') as string);

                submitJson('/phx/services/slam/utility/nfl-opt-in', 'POST', nflFormData);
            }
        }

        return isValid;
    }

    function beforeSubmit(data: FormData) {
        const phone = data.get('phone') as string;
        data.set('phone', phone.replace(/\D/g, ''));

        const zip = data.get('zip') as string;
        data.set('zip', zip.replace(/\D/g, '').substring(0, 5));

        data.set('languagePreference', 'en-US');
        data.set('sourceName', 'DE_Sweepstakes');

        return data;
    }

    async function formSuccess(response: Response) {
        const json = await response.json();

        if (json.formSubmitResponse.status.message === 'ERROR') {
            const serverValidations: Fields = {};

            json.formSubmitResponse.errors.fields.forEach((field: ServerFields) => {
                serverValidations[field.name] = {
                    value: validations[field.name].value,
                    invalid: VALIDITY.invalid,
                    validations: { ...validations[field.name].validations },
                    helperText: { ...validations[field.name].helperText },
                };

                if (field.name === 'form') {
                    if (field.error === 'unknown') {
                        setSubmitState('failed');
                    } else if (field.error === 'invalid') {
                        if (field.message === 'duplicate') {
                            serverValidations.form = {
                                value: '',
                                invalid: VALIDITY.invalid,
                                validations: { formerror: true },
                                helperText: { formerror: 'Sorry, only one entry per person allowed.' },
                            };

                            setSubmitState('duplicate');
                        } else if (field.message === 'ended') {
                            setEnded(true);
                        }
                    }
                } else {
                    delete serverValidations.form;
                }
            });

            setValidations({ ...validations, ...serverValidations });
        } else if (json.formSubmitResponse.status.message === 'SUCCESS') {
            setSubmitState('success');
        }
    }

    function formFail() {
        setSubmitState('failed');
    }

    function renderSubmitMessage() {
        if (submitState === 'success') {
            const [channelIds, channelCards] = featuredChannelFields;

            return (
                <>
                    <Headline desktopCols={10} tag="h2" className="h2">
                        <div data-cy="sweeps-form-submit-success-message">{formFields.thankyou_header}</div>
                    </Headline>
                    {channelCards.length && (
                        <div className={styles.channelCardsWrapper}>
                            <ChannelCardGroupWrapper
                                channels={channelCards}
                                channelIds={channelIds}
                                title={formFields.channel_card_intro_text}
                                link={formFields.channel_group_link}
                            />
                        </div>
                    )}
                </>
            );
        }

        if (submitState === 'failed') {
            return (
                <>
                    <Headline desktopCols={10} tag="h2" className="h2" data-cy="sweeps-form-submit-error-message">
                        There is something wrong :(
                    </Headline>
                    <Grid container>
                        <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                            <Text tag="p" style={{ paddingTop: 30 }}>
                                Please check and try submitting again
                            </Text>
                        </Grid>
                    </Grid>
                </>
            );
        }
    }

    return (
        <>
            <div aria-live="polite">{renderSubmitMessage()}</div>

            {submitState === 'untouched' || submitState === 'duplicate' ? (
                <div data-componenttype="SweepEntryForm">
                    <Form
                        action="/p/formsubmit/ContestController"
                        method="POST"
                        contentType="urlencoded"
                        onSubmit={onSubmit}
                        onBeforeSubmit={beforeSubmit}
                        onSuccess={formSuccess}
                        onFail={formFail}
                        onChange={validateForm}
                        novalidate={true}
                        dataCy="sweeps-form-form"
                    >
                        <input type="hidden" name="contestID" value={formFields.id} />
                        <input type="hidden" name="formId" value="2" />
                        <input type="hidden" name="environment" value={globalProperties.pii.environment} />
                        <input type="hidden" name="evflag" value="" />
                        <input type="hidden" id="optin" name="optin" value="Y" />
                        <input type="hidden" id="dob" name="dob" value="" />
                        <input type="hidden" id="favprogramming" name="favprogramming" value="Yes" />
                        <input type="hidden" name="guid" value="" />

                        <input type="hidden" name="subscriberStatus" value="Not-Applicable" />

                        <Grid container>
                            <Grid col={{ xs: 12, lg: 10 }} start={{ lg: 2 }}>
                                <FormErrorDisplay errorCount={submitAttempted ? errorCount : 0} />
                            </Grid>

                            <Grid col={{ xs: 12, lg: 5 }} start={{ lg: 2 }}>
                                <Fieldset theme="themeGrey" legend="Contact Information">
                                    <Input
                                        type="text"
                                        name="firstName"
                                        label="First Name"
                                        invalid={validations.firstName.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.firstName.helperText.required}
                                        dataCy="sweeps-form-first-name"
                                        errorDataCy="sweeps-form-first-name-err"
                                    />

                                    <Input
                                        type="text"
                                        name="lastName"
                                        label="Last Name"
                                        invalid={validations.lastName.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.lastName.helperText.required}
                                        dataCy="sweeps-form-last-name"
                                        errorDataCy="sweeps-form-last-name-err"
                                    />

                                    <Input
                                        type="text"
                                        name="email"
                                        label="Email"
                                        ref={emailRef}
                                        invalid={validations.email.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.email.helperText.email}
                                        dataCy="sweeps-form-email"
                                        errorDataCy="sweeps-form-email-err"
                                    />

                                    <Input
                                        type="text"
                                        name="confirmEmail"
                                        label="Confirm Email"
                                        ref={confirmEmailRef}
                                        invalid={validations.confirmEmail.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.confirmEmail.helperText.matchesCaseInsensitive}
                                        dataCy="sweeps-form-confirm-email"
                                        errorDataCy="sweeps-form-confirm-email-err"
                                    />

                                    <Input
                                        type="tel"
                                        name="phone"
                                        label="Phone"
                                        invalid={validations.phone.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.phone.helperText.minlength}
                                        dataCy="sweeps-form-phone"
                                        errorDataCy="sweeps-form-phone-err"
                                    />

                                    <Input
                                        type="text"
                                        name="address1"
                                        label="Address (include Apt/Suite/Unit)"
                                        required={true}
                                        invalid={validations.address1.invalid}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.address1.helperText.required}
                                        dataCy="sweeps-form-address"
                                        errorDataCy="sweeps-form-address-err"
                                    />

                                    <Input
                                        type="text"
                                        name="city"
                                        label="City"
                                        invalid={validations.city.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.city.helperText.required}
                                        dataCy="sweeps-form-city"
                                        errorDataCy="sweeps-form-city-err"
                                    />

                                    <Grid container>
                                        <Grid col={{ xs: 6 }}>
                                            <Select
                                                name="state"
                                                type="form"
                                                theme="outline"
                                                label="State"
                                                onChange={validateInput}
                                                invalid={validations.state.invalid}
                                                required
                                                onBlur={(event) => validateInput(event.currentTarget)}
                                                invalidMessage={validations.state.helperText.required}
                                                dataCy="sweeps-form-select-state"
                                                errorDataCy="sweeps-form-select-state-err"
                                            >
                                                <OptionGroup>
                                                    {US_STATES.map((state, index) => (
                                                        <Option key={index} value={state.abbreviation}>
                                                            {state.name}
                                                        </Option>
                                                    ))}
                                                </OptionGroup>
                                            </Select>
                                        </Grid>
                                        <Grid col={{ xs: 6 }}>
                                            <Input
                                                type="zip"
                                                name="zip"
                                                label="Zip Code"
                                                invalid={validations.zip.invalid}
                                                required={true}
                                                onBlur={(event) => validateInput(event.currentTarget)}
                                                invalidMessage={validations.zip.helperText.minlength}
                                                dataCy="sweeps-form-zip"
                                                errorDataCy="sweeps-form-zip-err"
                                            />
                                        </Grid>
                                    </Grid>
                                </Fieldset>
                            </Grid>

                            <Grid col={{ xs: 12, lg: 5 }}>
                                <Fieldset theme="themeGrey" legend="Tell us about yourself">
                                    {formFields.how_did_you_hear && formFields.how_did_you_hear.length > 0 && (
                                        <Select
                                            name="howHeard"
                                            type="form"
                                            theme="outline"
                                            label="How did you hear about this promotion? (Optional)"
                                            ariaLabel="Optional: How did you hear about this promotion?"
                                            dataCy="sweeps-form-select-how-heard"
                                            errorDataCy="sweeps-form-select-how-heard-err"
                                        >
                                            <OptionGroup>
                                                {formFields.how_did_you_hear.map((value: string, index) => (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                ))}
                                            </OptionGroup>
                                        </Select>
                                    )}
                                </Fieldset>

                                {formFields.list_item && formFields.list_item_answers && (
                                    <Fieldset theme="themeGrey" legend={formFields.list_item}>
                                        <Select
                                            name="options"
                                            type="form"
                                            theme="outline"
                                            label="Choose an option"
                                            ariaLabel="Choose an option"
                                            onChange={validateInput}
                                            invalid={validations.options.invalid}
                                            required
                                            onBlur={(event) => validateInput(event.currentTarget)}
                                            invalidMessage={validations.options.helperText.required}
                                            dataCy="sweeps-form-select-options"
                                            errorDataCy="sweeps-form-select-options-err"
                                        >
                                            <OptionGroup>
                                                {formFields.list_item_answers.map((value: string, index) => (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                ))}
                                            </OptionGroup>
                                        </Select>
                                    </Fieldset>
                                )}

                                {formFields.nfl_opt_in_label && (
                                    <Fieldset theme="themeHidden" legend="NFL">
                                        <Select
                                            name="favoriteTeam"
                                            type="form"
                                            theme="outline"
                                            label="What is your favorite NFL team? (Optional)"
                                            ariaLabel="Optional: What is your favorite NFL team?"
                                            dataCy="sweeps-form-select-favorite-team"
                                        >
                                            <OptionGroup>
                                                {NFL_TEAMS.map((value: string, index) => (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                ))}
                                            </OptionGroup>
                                        </Select>
                                    </Fieldset>
                                )}

                                {formFields.trivia_question && formFields.trivia_answers && (
                                    <RadioGroup
                                        theme="themeGrey"
                                        legend="Trivia"
                                        invalidMessage={validations.answer1.helperText.istrue}
                                        invalid={validations.answer1.invalid}
                                    >
                                        <StyledContainer>{formFields.trivia_question}</StyledContainer>

                                        <div>
                                            {formFields.trivia_answers.map((answer, index) => (
                                                <Radio
                                                    key={index}
                                                    name="answer1"
                                                    label={answer}
                                                    value={answer}
                                                    onBlur={(event) => validateInput(event.currentTarget)}
                                                    required
                                                    dataCy="sweeps-form-radio-answer1"
                                                />
                                            ))}
                                        </div>
                                    </RadioGroup>
                                )}

                                {formFields.text_area && (
                                    <Fieldset theme="themeGrey" legend={formFields.text_area}>
                                        <Textarea
                                            name="ans"
                                            label="Enter description"
                                            invalid={validations.ans.invalid}
                                            maxLength={
                                                formFields.text_area_maxlength ? formFields.text_area_maxlength : 524288
                                            }
                                            showCount={formFields.text_area_maxlength ? true : false}
                                            required
                                            onBlur={(event: React.FocusEvent<HTMLTextAreaElement, Element>) =>
                                                validateInput(event.currentTarget as unknown as HTMLInputElement)
                                            }
                                            invalidMessage={validations.ans.helperText.required}
                                            dataCy="sweeps-form-textarea-ans"
                                        />
                                    </Fieldset>
                                )}

                                <Fieldset theme="themeHidden" legend="Agreements">
                                    {formFields.legal_agreement && (
                                        <>
                                            <Checkbox
                                                name="partner_optin2"
                                                value="Y"
                                                invalid={validations['partner_optin2'].invalid}
                                                onBlur={(event) => validateInput(event.currentTarget)}
                                                invalidMessage={validations['partner_optin2'].helperText.istrue}
                                                dataCy="sweeps-form-checkbox-partner-opt-in-2"
                                            >
                                                <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                                    <RenderRTE contentfulDoc={formFields.legal_agreement} asW2={asW2} />
                                                </Text>
                                            </Checkbox>
                                        </>
                                    )}

                                    <Checkbox
                                        name="acceptLegal"
                                        value="Y"
                                        invalid={validations.acceptLegal.invalid}
                                        required={true}
                                        onBlur={(event) => validateInput(event.currentTarget)}
                                        invalidMessage={validations.acceptLegal.helperText.istrue}
                                        dataCy="sweeps-form-checkbox-accept-legal"
                                        errorDataCy="sweeps-form-checkbox-accept-legal-err"
                                    >
                                        <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                            <RenderRTE contentfulDoc={formFields.official_rule} asW2={asW2} />
                                        </Text>
                                    </Checkbox>

                                    {formFields.partner_opt_in_label && (
                                        <Checkbox
                                            name="partner_optin"
                                            value="Y"
                                            ariaLabel={`Optional: ${documentToPlainTextString(
                                                formFields.partner_opt_in_label,
                                            )}`}
                                            dataCy="sweeps-form-checkbox-partner-opt-in"
                                        >
                                            <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                                <RenderRTE
                                                    contentfulDoc={formFields.partner_opt_in_label}
                                                    removePTag
                                                    asW2={asW2}
                                                />
                                                &nbsp;(Optional)
                                            </Text>
                                        </Checkbox>
                                    )}

                                    {formFields.partner_opt_in_label2 && (
                                        <Checkbox
                                            name="partner_optin_label2"
                                            value="Y"
                                            ariaLabel={`Optional: ${documentToPlainTextString(
                                                formFields.partner_opt_in_label2,
                                            )}`}
                                            dataCy="sweeps-form-checkbox-partner-opt-in-2"
                                        >
                                            <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                                <RenderRTE
                                                    contentfulDoc={formFields.partner_opt_in_label2}
                                                    removePTag
                                                    asW2={asW2}
                                                />
                                                &nbsp;(Optional)
                                            </Text>
                                        </Checkbox>
                                    )}

                                    {formFields.nfl_opt_in_label && (
                                        <Checkbox
                                            name="nfl_optIn"
                                            value="Y"
                                            ariaLabel={`Optional: ${documentToPlainTextString(
                                                formFields.nfl_opt_in_label,
                                            )}`}
                                            dataCy="sweeps-form-checkbox-nfl-opt-in"
                                        >
                                            <Text tag="div" variant="caption" style={{ color: 'var(--gray-dark)' }}>
                                                <RenderRTE
                                                    contentfulDoc={formFields.nfl_opt_in_label}
                                                    removePTag
                                                    asW2={asW2}
                                                />
                                                &nbsp;(Optional)
                                            </Text>
                                        </Checkbox>
                                    )}
                                </Fieldset>
                            </Grid>

                            <Grid col={{ xs: 12, lg: 6 }} start={{ lg: 4 }}>
                                {validations.form && validations.form.validations.formerror && (
                                    <StyledContainer>
                                        <Text
                                            tag="div"
                                            style={{ color: 'var(--red)', fontWeight: 'bold' }}
                                            role="alert"
                                        >
                                            {validations.form.helperText.formerror}
                                        </Text>
                                    </StyledContainer>
                                )}

                                <Button
                                    type="submit"
                                    text={formFields.cta_label ? formFields.cta_label : 'REGISTER'}
                                    ariaLabel="register for sweeps"
                                    theme="primary"
                                    buttonSize="full-width"
                                    dataCy="sweeps-form-submit-button"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                </div>
            ) : undefined}
        </>
    );
}
