'use client';

import RenderAsset from '@/components/RenderAsset';
import { LinkInfoCard } from '@/components/molecules/LinkInfoCard';
import useCallCenter from '@/hooks/useCallCenter';
import styles from './hero.module.scss';

interface Props {
    linkList: SiteLink[];
}

export default function HeroLinks({ linkList }: Props): React.ReactNode {
    const isCallOnline = useCallCenter();

    const details = (
        <>
            {!isCallOnline && (
                <p>
                    <b>Call 855-227-6738</b>
                    <br />
                    Monday - Friday: 8 am - 10 pm ET
                    <br />
                    Saturday - Sunday: 8 am - 8 pm ET
                </p>
            )}
        </>
    );

    return (
        <div className={styles.heroLinks}>
            <LinkInfoCard detailsBlock={!isCallOnline ? details : false}>
                {linkList.map((link: SiteLink, i) => {
                    link.fields.link_type = 'LinkInfoCard';

                    return (
                        <li key={i}>
                            <RenderAsset asset={link} />
                        </li>
                    );
                })}
            </LinkInfoCard>
        </div>
    );
}
