import styles from './plandetailcard.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import dynamic from 'next/dynamic';

const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const {
    mainWrapper,
    textContentWrapper,
    headiline,
    descriptionText,
    icon,
    descriptionOffer,
    descriptionPrice,
    iconAndText,
    buttonWrapper,
    headingWrapper,
    mobileIcon,
} = styles;

export interface PlanDetailCardProps {
    iconSource: Omit<ImageProps, 'width' | 'height'>;
    detailLink?: string;
    detailLinkText?: string;
    arialabel?: string;
    heading?: string;
    subText1?: string;
    subText2?: string;
    subText3?: string;
    subText4?: string;
}

const PlanDetailCard = ({
    iconSource,
    detailLink,
    detailLinkText,
    arialabel,
    heading,
    subText1,
    subText2,
    subText3,
    subText4,
}: PlanDetailCardProps): JSX.Element => {
    return (
        <div className={mainWrapper}>
            <div className={iconAndText}>
                <ImageWrapper
                    aria-hidden="true"
                    src={iconSource.source}
                    alt={iconSource.altText ?? ''}
                    className={icon}
                />
                <div className={textContentWrapper}>
                    <div className={headingWrapper}>
                        <ImageWrapper
                            aria-hidden="true"
                            src={iconSource.source}
                            alt={iconSource.altText ?? ''}
                            className={mobileIcon}
                        />
                        <h5 className={headiline}>{heading}</h5>
                    </div>

                    <span className={descriptionText}>
                        {subText1}
                        <span className={descriptionPrice}> {subText2} </span>
                        {subText3}
                        <a href="#pageOfferDetails" className={descriptionOffer}>
                            {subText4}
                        </a>
                    </span>
                </div>
            </div>
            <div className={buttonWrapper}>
                <Button
                    type="button"
                    theme="secondary"
                    text={detailLinkText}
                    href={detailLink}
                    ariaLabel={arialabel}
                    className={styles.button}
                />
            </div>
        </div>
    );
};

export default PlanDetailCard;
