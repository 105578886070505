'use client';
import { RenderRTE } from '@/components/RenderRTE';
import { Headline } from '@/components/molecules/Headline';
import { WidgetHero, ImageHero as ImageHeroMolecule } from '@/components/molecules/Hero';
import Widget from './Widget';
import { StickyHeaderFooterCta } from '@/components/molecules/StickyHeaderFooterCta';
import { ComponentProps, useRef } from 'react';
import styles from './hero.module.scss';
import { PlanCardGroup } from '@/components/molecules/PlanCardGroup';
import { PlanCardProps } from '@/components/molecules/PlanCard/PlanCard';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useMediaQuery } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import OfferAnalytics from '@/components/www/OfferAnalytics';
import { StickyTitleWithListSection } from '@/components/organisms/StickyTitleWithListSection';
import { MarketingList } from '@/components/molecules/MarketingList';
import HeroLinks from './HeroLinks';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import List from 'de-sxm-reactlib/dist/components/atoms/List/List';
import { SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import { ButtonProps } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';

interface Props {
    index?: number;
    asset: SiteHero;
    asW2?: boolean;
}

function mapListSectionItems(packages: SiteFeature[], asW2?: boolean): ComponentProps<typeof List> {
    return {
        items: packages.map((p, i) => ({
            body: <RenderRTE pToSpan={true} key={i} contentfulDoc={p.fields.medium_description} asW2={asW2} />,
        })),
        markerIcon: {
            url: 'https://www.siriusxm.com/ns/cms/static/global/css/minified/svg/checkmark.svg',
        },
        type: 'unordered',
    };
}

export default function Hero({ asset, asW2 }: Props): JSX.Element {
    const heroRef = useRef<HTMLElement>(null);
    const brightness = asset.fields.background_brightness;
    const viewportIsTabletSized = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const textColor = brightness === 'Dark' ? 'var(--white)' : 'var(--black)';
    const web2TextColorClassname = brightness === 'Dark' ? undefined : styles.overridesIfLightWeb2;
    let headerText = asset.fields.headline && (
        <RenderRTE contentfulDoc={asset.fields.headline} removePTag={true} asW2={asW2} />
    );
    const description = asset.fields.description && (
        <RenderRTE contentfulDoc={asset.fields.description} removePTag={true} asW2={asW2} />
    );
    const breadcrumbs =
        asset.fields.displayBreadcrumbs && asset.fields.breadcrumbs && asset.fields.breadcrumbs.length
            ? asset.fields.breadcrumbs
            : undefined;
    let subhead = asset.fields.subheadline && (
        <RenderRTE contentfulDoc={asset.fields.subheadline} removePTag={true} asW2={asW2} />
    );
    const offerIcons: ImageProps[] = [];
    let cta: ButtonProps | undefined = undefined;
    let packageCards;
    let stickyCTA = <></>;
    const offerAnalytics = [];
    let pdpHeroExtension;
    const isSticky = asset.fields.link?.fields.isSticky;

    const socialMedia: SocialMediaIconLinksProps = {
        className: brightness === 'Dark' ? styles.socialIconsWhite : styles.socialIconsBlack,
        items: [],
    };
    const underCtaText = asset.fields.link?.fields.phoneNumber?.fields.phone && (
        <>
            {asset.fields.link?.fields.phoneNumber?.fields.link_caption}{' '}
            <a href={`tel:${asset.fields.link?.fields.phoneNumber?.fields.phone}`}>
                {asset.fields.link?.fields.phoneNumber?.fields.phone}
            </a>
        </>
    );

    if (asset.fields.link) {
        cta = {
            href: asset.fields.link.fields.href,
            ariaLabel: asset.fields.link.fields.aria_label,
            text: asset.fields.link.fields.link_text,
            targetBlank: asset.fields.link.fields.link_target === '_blank',
            type: 'button',
            buttonColor: asset.fields.link.fields.ctaColor,
            theme: asset.fields.link.fields.theme || 'text',
            inheritParams: asset.fields.link.fields.inheritParams,
        };

        if (asset.fields.link.fields.isSticky) {
            const { ...ctaProps } = cta;
            const stickyCTAProps: ButtonProps = { ...ctaProps } as ButtonProps;
            stickyCTAProps.theme = 'primary';
            stickyCTA = (
                <StickyHeaderFooterCta
                    triggerRef={heroRef}
                    alwaysVisibleInMobile={isSticky}
                    planName={
                        <RenderRTE contentfulDoc={asset.fields.link.fields.secondaryText} removePTag asW2={asW2} />
                    }
                    ctaOption="button"
                    cta={stickyCTAProps}
                />
            );
        }
    }

    if (asset.fields.socialMedia) {
        for (const network in asset.fields.socialMedia) {
            socialMedia.items.push({
                name: network as SocialMediaNames,
                link: {
                    href: asset.fields.socialMedia[network],
                    ariaLabel: network,
                },
            });
        }
    }

    if (asset.fields.pageType === 'PackageDetail') {
        const offers = asset.fields.programCode?.fields.offers;
        const sitePackageData = offers && offers.length > 0 ? offers[0].fields.package?.fields : undefined;
        const features = sitePackageData?.package_highlights;

        if (sitePackageData && features) {
            const listProps: ComponentProps<typeof StickyTitleWithListSection> = {
                title: <RenderRTE contentfulDoc={sitePackageData.headline} pToSpan={true} asW2={asW2} />,
                body: <RenderRTE contentfulDoc={sitePackageData.long_description} pToSpan={true} asW2={asW2} />,
                listData: mapListSectionItems(features, asW2),
            };

            pdpHeroExtension = <StickyTitleWithListSection {...listProps} />;
        }
    }

    if (
        asset.fields.pageType === 'Marketing' ||
        asset.fields.pageType === 'TaggedMLP' ||
        asset.fields.pageType === 'PackageDetail'
    ) {
        const offers = asset.fields.programCode?.fields.offers;

        if (offers) {
            if (offers.length === 1) {
                // SINGLE OFFER
                const offer = offers[0] as SiteOffer;
                offerAnalytics.push(<OfferAnalytics key={offer.cms_id} offer={offer} />);

                if (!asset.fields.widget) {
                    offer.fields.package?.fields.listening_options?.forEach((listeningOption) => {
                        if (listeningOption.fields.device_image && listeningOption.fields.device_image?.url) {
                            offerIcons.push({
                                source: listeningOption.fields.device_image?.url,
                                altText:
                                    listeningOption.fields.device_image?.altText || listeningOption.fields.headline || '',
                                width: 100,
                                height: 100,
                            });
                        }
                    });
                }

                const offerTokens: { [index: string]: string | number | undefined } = {
                    '{price}': offer.fields.price_per_month === 0 ? 'Free' : '$' + offer.fields.price_per_month,
                };

                if (offer.fields.cta && !asset.fields.widget) {
                    const theme = offer.fields.cta.fields.theme || 'text';

                    cta = {
                        href: offer.fields.cta.fields.href,
                        ariaLabel: offer.fields.cta.fields.aria_label,
                        text: offer.fields.cta.fields.link_text,
                        targetBlank: offer.fields.cta.fields.link_target === '_blank',
                        type: 'button',
                        buttonColor: offer.fields.cta.fields.ctaColor,
                        theme,
                        inheritParams: offer.fields.cta.fields.inheritParams,
                        className: styles.ctaButton,
                    };
                }

                headerText = asset.fields.headline && <RenderRTE contentfulDoc={asset.fields.headline} asW2={asW2} />;

                if (!asset.fields.widget) {
                    subhead = (
                        <>
                            <Text tag="span" variant="h4" className={styles.mlpSubheadlineH3}>
                                <RenderRTE
                                    contentfulDoc={offer.fields.headline}
                                    tokens={offerTokens}
                                    removePTag
                                    asW2={asW2}
                                />
                                <br />
                            </Text>
                            <RenderRTE
                                contentfulDoc={offer.fields.subheadline}
                                tokens={offerTokens}
                                removePTag
                                asW2={asW2}
                            />
                            <br />
                            <RenderRTE contentfulDoc={offer.fields.credit_card_text} removePTag asW2={asW2} />
                        </>
                    );
                }

                if (cta) {
                    // We remove the cta className prop since its only needed for the ImageHero and not for the Sticky CTA
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { className, ...ctaProps } = cta;
                    const stickyCTAProps: ButtonProps = { ...ctaProps } as ButtonProps;
                    stickyCTAProps.theme = 'primary';
                    let highlightText = offer.fields.headline ? documentToPlainTextString(offer.fields.headline) : '';

                    for (const token in offerTokens) {
                        highlightText = highlightText.replace(token, `${offerTokens[token]}`);
                    }

                    stickyCTA = (
                        <StickyHeaderFooterCta
                            triggerRef={heroRef}
                            planName={<b>{offer.fields.package && offer.fields.package.fields.package_title}</b>}
                            highlight={<b>{highlightText}</b>}
                            highlightColor="var(--raspberry)"
                            alwaysVisibleInMobile={isSticky}
                            details={
                                <>{offer.fields.subheadline && documentToPlainTextString(offer.fields.subheadline)}</>
                            }
                            body={
                                <>
                                    See <b>Offer Details</b> below.
                                </>
                            }
                            ctaOption="button"
                            cta={stickyCTAProps}
                        />
                    );
                }

                if (!viewportIsTabletSized) {
                    cta = undefined;
                }
            } else {
                // DUAL OFFER
                const cards: PlanCardProps[] = [];

                for (let i = 0; i < 2; i++) {
                    const offer = offers[i] as SiteOffer;
                    if (offer) {
                        offerAnalytics.push(<OfferAnalytics key={i} offer={offer} />);
                        const offerTokens = {
                            '{price}': '',
                        };
                        const cardHeaderTextWithNoPrice = (
                            <RenderRTE
                                contentfulDoc={offer.fields.headline}
                                tokens={offerTokens}
                                removePTag
                                asW2={asW2}
                            />
                        );
                        if (offer.fields.package) {
                            const offerPackage = offer.fields.package as SitePackage;
                            const cardIcons: ImageProps[] = [];
                            const features: ComponentProps<typeof MarketingList>['items'] = [];
                            const offerTokens = {
                                '{price}': offer.fields.price,
                            };

                            offerPackage.fields.listening_options?.forEach((listeningOption) => {
                                if (listeningOption.fields.device_image && listeningOption.fields.device_image?.url) {
                                    cardIcons.push({
                                        source: listeningOption.fields.device_image?.url,
                                        altText:
                                            listeningOption.fields.device_image?.altText ||
                                            listeningOption.fields.headline || '',
                                        width: 100,
                                        height: 100,
                                    });
                                }
                            });

                            if (
                                offerPackage.fields.package_highlights &&
                                offerPackage.fields.package_highlights.length
                            ) {
                                offerPackage.fields.package_highlights?.forEach((feature) => {
                                    if (feature.fields.medium_description) {
                                        features.push({
                                            mediumDescription: (
                                                <RenderRTE
                                                    contentfulDoc={feature.fields.medium_description}
                                                    removePTag
                                                    asW2={asW2}
                                                />
                                            ),
                                        });
                                    }
                                });
                            }

                            cards.push({
                                promoted: offer.fields.promoted_text ? true : false,
                                promotedPlanLabel: offer.fields.promoted_text,
                                packageTitle: offerPackage.fields.package_display_name || '',
                                packageCost: offer.fields.price_per_month || 0,
                                packageCostDuration: offer.fields.term_length || 0,
                                packageCostCopy: (
                                    <RenderRTE
                                        contentfulDoc={offer.fields.subheadline}
                                        tokens={offerTokens}
                                        removePTag
                                        asW2={asW2}
                                    />
                                ),
                                packageCostSubtext: (
                                    <RenderRTE contentfulDoc={offer.fields.credit_card_text} removePTag asW2={asW2} />
                                ),
                                offerIcons: cardIcons,
                                packageCopy: (
                                    <RenderRTE
                                        contentfulDoc={offerPackage.fields.short_description}
                                        removePTag
                                        asW2={asW2}
                                    />
                                ),
                                packageFeatures: features,
                                ctaButtonText: offer.fields.cta?.fields.link_text || '',
                                ctaButtonTheme: i === 0 ? 'primary' : 'secondary',
                                ctaUrl: offer.fields.cta?.fields.href || '',
                                offerHeaderTextWithNoPrice: cardHeaderTextWithNoPrice,
                            });
                        }
                    }
                }

                packageCards = (
                    <Grid container>
                        <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                            <PlanCardGroup planCards={cards} isInHero></PlanCardGroup>
                        </Grid>
                    </Grid>
                );
            }
        }
    }

    if (asset.fields.widget) {
        const widget = asset.fields.widget;
        const widgetDescription = description ? (
            <>
                {description} <br />
            </>
        ) : undefined;
        const phoneNumberLinkNode = widget.fields.phone_number && (
            <>
                Questions? Call{' '}
                <a
                    href={`tel:${widget.fields.phone_number.fields.phone}`}
                    aria-label={widget.fields.phone_number.fields.phone}
                >
                    {widget.fields.phone_number.fields.phone}
                </a>
            </>
        );

        return (
            <>
                <WidgetHero
                    ref={heroRef}
                    text={{
                        header: headerText,
                        subhead: subhead,
                        isSubheadNarrow: true,
                        breadcrumbs: breadcrumbs,
                        offerIcons: offerIcons,
                        cta: cta,
                        description: widgetDescription,
                        socialMedia: socialMedia,
                        socialMediaStickToBottom: true,
                        socialMediaText: asset.fields.socialText,
                        underCtaText: underCtaText,
                    }}
                    mobileOnlyForegroundImage={{
                        source: asset.fields.mobile_foreground_image?.url,
                        altText: asset.fields.mobile_foreground_image?.altText,
                        width: 768,
                        height: 0,
                    }}
                    backgroundColor={asset.fields.background_color}
                    backgroundImageTablet={asset.fields.background_image?.url}
                    backgroundImageMobile={asset.fields.mobile_background_image?.url}
                    textColor={textColor}
                    widget={<Widget widget={widget} />}
                    widgetPhoneNumberLink={phoneNumberLinkNode}
                    darkTheme={brightness === 'Dark'}
                    heroTextClassName={web2TextColorClassname}
                />
                {stickyCTA}
                {offerAnalytics}
            </>
        );
    }

    if (asset.fields.linkGroup.length > 0) {
        return (
            <>
                <WidgetHero
                    ref={heroRef}
                    text={{
                        header: headerText,
                        subhead: subhead,
                        isSubheadNarrow: true,
                        breadcrumbs: breadcrumbs,
                        offerIcons: offerIcons,
                        cta: cta,
                        socialMedia: socialMedia,
                        socialMediaStickToBottom: true,
                        socialMediaText: asset.fields.socialText,
                        underCtaText: underCtaText,
                    }}
                    mobileOnlyForegroundImage={{
                        source: asset.fields.mobile_foreground_image?.url,
                        altText: asset.fields.mobile_foreground_image?.altText,
                        width: 768,
                        height: 0,
                    }}
                    backgroundColor={asset.fields.background_color}
                    backgroundImageTablet={asset.fields.background_image?.url}
                    backgroundImageMobile={asset.fields.mobile_background_image?.url}
                    textColor={textColor}
                    widget={<HeroLinks linkList={asset.fields.linkGroup} />}
                    widgetBackground={false}
                    heroTextClassName={web2TextColorClassname}
                />
                {stickyCTA}
                {offerAnalytics}
            </>
        );
    }

    if (asset.fields.type === 'Short' || asset.fields.type === 'Center Hero') {
        return (
            <div className={web2TextColorClassname}>
                <Headline
                    backgroundColor={asset.fields.background_color}
                    backgroundImageMobile={asset.fields.mobile_background_image?.url}
                    backgroundImageTablet={asset.fields.background_image?.url}
                    textColor={textColor}
                    desktopCols={12}
                    tag="h1"
                    className="h1"
                    subhead={subhead}
                    variant={asset.fields.type === 'Short' ? 'Hero' : 'Listen'}
                    breadcrumbs={breadcrumbs}
                    foregroundImage={{
                        tablet: {
                            source: asset.fields.foreground_image?.url,
                            altText: asset.fields.foreground_image?.altText,
                            // height: 392,
                            // width: 392,
                        },
                        mobile: {
                            source: asset.fields.mobile_foreground_image?.url,
                            altText: asset.fields.mobile_foreground_image?.altText,
                            // height: 392,
                            // width: 392,
                        },
                    }}
                >
                    {headerText}
                </Headline>
            </div>
        );
    }

    return (
        <>
            <ImageHeroMolecule
                ref={heroRef}
                text={{
                    header: headerText,
                    subhead: subhead,
                    breadcrumbs: breadcrumbs,
                    offerIcons: offerIcons,
                    cta: cta,
                    hideCtaOnMobile: !!cta && asset?.fields?.link?.fields?.isSticky,
                    description: description,
                    isSubheadNarrow: true,
                    socialMedia: socialMedia,
                    socialMediaStickToBottom: true,
                    socialMediaText: asset.fields.socialText,
                    underCtaText: underCtaText,
                    className: web2TextColorClassname,
                }}
                backgroundColor={asset.fields.background_color}
                backgroundImageTablet={asset.fields.background_image?.url}
                backgroundImageMobile={asset.fields.mobile_background_image?.url}
                foregroundImage={{
                    tablet: {
                        source: asset.fields.foreground_image?.url,
                        altText: asset.fields.foreground_image?.altText,
                        // height: 392,
                        // width: 392,
                    },
                    mobile: {
                        source: asset.fields.mobile_foreground_image?.url,
                        altText: asset.fields.mobile_foreground_image?.altText,
                        // height: 392,
                        // width: 392,
                    },
                }}
                textColor={textColor}
                darkTheme={brightness === 'Dark'}
            />
            {pdpHeroExtension}
            {stickyCTA}
            {packageCards}
            {offerAnalytics}
        </>
    );
}
