'use client';
import dynamic from 'next/dynamic';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import cn from 'classnames';
import { useState } from 'react';
import styles from './show.module.scss';
import IconDropDown from '@/assets/icons/icon-dropdown.svg';
import { ShowSchedule } from '@/components/molecules/ShowSchedule';
import { inChronologicalOrder } from '@/packages/array';
import dayjs, { Dayjs } from 'dayjs';
import { SafeHtml } from '@/components/atoms/Display';
const AudioIcon = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/AnimatedIcons/AudioIcon/AudioIcon'));
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

interface ShowItemProps {
    show: SiteChannelShow;
    logoAlt: string;
    className?: string;
    roundCornersWeb2?: boolean;
}

export default function Show({ className, show, logoAlt, roundCornersWeb2 = true }: ShowItemProps): JSX.Element {
    dayjs.extend(duration);
    dayjs.extend(isBetween);
    dayjs.extend(relativeTime);

    const [desActive, setDecActive] = useState(false);
    const [scheduleBtnActive, setScheduleBtnActive] = useState(false);
    const [scheduleActive, setScheduleActive] = useState(false);

    const closestShowSchedule = show.show_schedules
        .map((schedule) => schedule.day_schedules)
        .flat()
        .sort((scheduleA, scheduleB) => inChronologicalOrder('start_time')(scheduleA, scheduleB))[0];

    if (!closestShowSchedule) {
        return <></>;
    }

    const now = dayjs(new Date());
    const closestShowStartTime = dayjs(closestShowSchedule.start_time);
    const isPlayingNow = now.isBetween(closestShowStartTime, closestShowSchedule.end_time);

    const nextAirsText = getNextAirsText(closestShowSchedule, closestShowStartTime);
    const durationText = getNextDurationText(closestShowSchedule);

    const PreviewOrFull = (): JSX.Element => {
        const content = show.description;
        if (content && content.length > 125) {
            const results = content.substring(0, 124);
            return (
                <p className={styles.shortDescription}>
                    {results} &hellip;{' '}
                    <button onClick={toggleDescription} aria-expanded={desActive} className={styles.moreDescription}>
                        more
                    </button>
                </p>
            );
        }
        return <p className={styles.shortDescription}>{content}</p>;
    };

    const NowPlaying = () => (
        <div className="flex">
            <span className={styles.nowPlayingHeadline}>Now Playing</span>
            <AudioIcon></AudioIcon>
        </div>
    );

    const NextAirs = () => <span>{nextAirsText}</span>;

    function toggleDescription() {
        setDecActive(!desActive);
    }

    const contentDetailsClass = cn(styles.contentDetails, {
        [styles.active]: desActive,
    });

    function toggleSchedule() {
        setScheduleBtnActive(!scheduleBtnActive);
        setScheduleActive(!scheduleActive);
    }

    const showScheduleBtnClass = cn(styles.showScheduleBtn, {
        [styles.active]: scheduleBtnActive,
    });

    const showSchedulesClass = cn(styles.showSchedule, {
        [styles.active]: scheduleActive,
    });

    const imageClasses = cn({
        [styles.roundCorners]: roundCornersWeb2,
    });

    return (
        <>
            <article className={cn(className, styles.showLineup)}>
                <div className={styles.lineupImg}>
                    {show.logo && (
                        <ImageWrapper
                            optimizedWidth={102}
                            optimizedHeight={102}
                            src={show.logo}
                            alt={logoAlt || ''}
                            className={imageClasses}
                        />
                    )}
                </div>
                <div className={styles.lineupDetails}>
                    <h3 className={styles.lineupName}>{show.show_name}</h3>

                    <div className={styles.lineupContent}>
                        <div className={styles.contentContainer}>
                            <div className={styles.contentNextAir}>
                                <div className={styles.nextAirBlock}>
                                    <div className="flex flex-column">
                                        {isPlayingNow ? <NowPlaying /> : <NextAirs />}
                                        <div>{durationText}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={contentDetailsClass}>
                                <PreviewOrFull />
                                <SafeHtml
                                    print={show.description as string}
                                    tag="p"
                                    className={styles.longDescription}
                                />
                                <button
                                    onClick={toggleSchedule}
                                    className={showScheduleBtnClass}
                                    aria-expanded="false"
                                    aria-label="Show Schedule"
                                >
                                    <span>{scheduleBtnActive ? 'Hide Schedule' : 'Show Schedule'}</span>
                                    <IconDropDown />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={showSchedulesClass} aria-hidden="false">
                    <div className={styles.scheduleDropdown}>
                        <div className={styles.scheduleDropdownDetail}>
                            <ShowSchedule shows={[show]} />
                        </div>
                    </div>
                </div>
            </article>
        </>
    );
}

function getNextAirsText(schedule: DaySchedules, closestShowStartTime: Dayjs) {
    const now = dayjs(new Date());

    const imminentShowText =
        closestShowStartTime.isBetween(now, now.add(2, 'day').startOf('day')) &&
        (closestShowStartTime.isBefore(now.add(1, 'day').startOf('day')) ? 'Today' : 'Tomorrow');

    const nextShowDay = imminentShowText || closestShowStartTime.format('dddd');
    const nextShowTime = closestShowStartTime.format(closestShowStartTime.minute() === 0 ? 'h a' : 'h:mm a');
    return `Next Airs ${nextShowDay} at ${nextShowTime}`;
}

function getNextDurationText(schedule: DaySchedules) {
    const closestDurationHours = Math.floor(dayjs.duration(schedule.duration).asHours());
    const closestDurationMinutes = dayjs.duration(schedule.duration).minutes();

    const hourText = `${
        closestDurationHours >= 1 ? `${closestDurationHours} hr${closestDurationHours > 1 ? 's' : ''}` : ''
    }`;
    const minuteText = closestDurationMinutes > 0 ? `${closestDurationMinutes} mins` : '';

    return `${hourText} ${minuteText}`;
}
