import { useContext, useState } from 'react';
import { CGContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import ChannelHeader from '../ChannelHeader';
import { Genrelist } from '../interfaces';
import { useSearchParams } from 'next/navigation';

export default function HowardStern(): JSX.Element {
    const searchParams = useSearchParams();
    const context = useContext(CGContext);
    const sortedChannels = context.channels;
    const upsellLink = searchParams.get('upsell') !== 'false' ? context.channelGuide.fields.upsellLink : undefined;
    const display_listen_live = context.channelGuide.fields.display_listen_live;
    const [genre, setGenre] = useState('Howard Stern');

    const genreList: Genrelist = [
        [
            { value: 'Howard Stern', label: 'Howard Stern' },
            { value: 'All', label: 'All Howard Stern' },
        ],
    ];

    return (
        <>
            <ChannelHeader genreList={genreList} onGenreChange={setGenre} />
            {sortedChannels && sortedChannels['Howard Stern'] && sortedChannels['Howard Stern'][genre] && (
                <ChannelList
                    channels={sortedChannels['Howard Stern'][genre]}
                    upsellLink={upsellLink}
                    display_listen_live={display_listen_live}
                />
            )}
        </>
    );
}
