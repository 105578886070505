'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import styles from './channelguide.module.scss';
import { YMM } from '@/components/molecules/YMM';
import { useEffect, useState } from 'react';
import { Link } from '@/components/atoms/Link';
import { Music, Sports, TalkEntertainment, NewsIssues } from './GenreTabs';
import { CGContext } from './ChannelGuideContext';
import { ChannelFeedResponse, Props, SortedChannels } from './interfaces';
import { ucfirst } from '@/packages/string';
import { useSearchParams } from 'next/navigation';
import { type TabItem } from 'de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs';

const ChannelCardStripSkeleton = dynamic(
    () => import('@/components/atoms/ChannelCardStripSkeleton/ChannelCardStripSkeleton'),
);
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Tabs = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const ShowMore = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/ShowMore/ShowMore'));

export interface CategoryMap {
    Music: string;
    Sports: string;
    'Talk & Entertainment': string;
    'News & Issues': string;
    Comedy: string;
    'Howard Stern': string;
}

export const categoryMap: CategoryMap = {
    Music: 'All Music',
    Sports: 'Sports',
    'Talk & Entertainment': 'Talk',
    'News & Issues': 'News',
    Comedy: 'Talk',
    'Howard Stern': 'Talk',
};

const XtraChannels = [
    'Top 100',
    'New Music Discovery',
    'Music Mash',
    "Today's Hits",
    'Party',
    'Workout',
    'The 80s Decade',
    'Cool Down',
    'The 70s Decade',
    'BBQ',
    'The 90s Decade',
    'Instrumentals',
    'Just Music',
    'Deep Cuts',
];

export default function ChannelGuide({ asset, pageContext }: Props): JSX.Element {
    const searchParams = useSearchParams();
    const packages = asset.fields.allPackages;
    const lineupLink = asset.fields.channelLineup;

    const [randomNumber, setRandom] = useState<number>(0);
    const [showContent, setShowContent] = useState<boolean>(false);

    const tabs: TabItem[] = [
        { title: 'All Music', body: <Music /> },
        { title: 'Sports', body: <Sports /> },
        { title: 'Talk', body: <TalkEntertainment /> },
        { title: 'News', body: <NewsIssues /> },
    ];

    const [openYmm, setOpenYmm] = useState(false);
    const [packageId, setPackageId] = useState<string>(
        searchParams.get('package') || packages[0]?.fields.sms_package_id || '',
    );
    const [feedResponse, setFeedResponse] = useState<ChannelFeedResponse>();
    const [channels, setChannels] = useState<SortedChannels>({});

    useEffect(() => {
        async function fetchChannels() {
            if (packageId) {
                let url: string = `/v2/channelfeed/${packageId}`;
                if (!asset.fields.display_upgraded_channels) {
                    url = `/v2/channelfeed/package/${packageId}`;
                }
                const response = await fetch(url);
                const json = await response.json();
                if (json.errorCode) {
                    setFeedResponse({
                        channels: [],
                        packageDisplayName: '',
                        packageTitle: '',
                        radioType: pageContext.platform,
                    });
                } else {
                    setFeedResponse(json);
                }
            }
        }

        fetchChannels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageId, pageContext.platform]);

    useEffect(() => {
        const sortedChannels: SortedChannels = {};

        feedResponse?.channels?.forEach((channel) => {
            const category: string | undefined = categoryMap[channel.categoryTitle as keyof CategoryMap];

            if (category) {
                if (!sortedChannels[category]) {
                    sortedChannels[category] = { All: [], AllX: [] };
                }

                if (!sortedChannels[category][channel.genreTitle]) {
                    sortedChannels[category][channel.genreTitle] = [];
                }

                sortedChannels[category].All.push(channel);
                sortedChannels[category][channel.genreTitle].push(channel);

                if (XtraChannels.indexOf(channel.genreTitle) >= 0) {
                    sortedChannels[category].AllX.push(channel);
                }
            }
        });

        setChannels(sortedChannels);
    }, [feedResponse, packageId]);

    function getPackageById(packageId: string) {
        for (const platformPackage of packages) {
            if (platformPackage.fields.sms_package_id === packageId) {
                return platformPackage;
            }
        }
    }

    function showMoreClick() {
        setShowContent(!showContent);
        setTimeout(() => setRandom(Math.random()), 1);
    }

    function renderYMM() {
        const sxmPackage = getPackageById(packageId);
        const platform = ucfirst(sxmPackage?.fields.platform.replace(/ip/gi, 'siriusxm') || 'siriusxm').replace(
            /xm/gi,
            'XM',
        );

        if (searchParams.get('package')) {
            return (
                <>
                    Showing channels for {platform} <b>{sxmPackage?.fields.package_display_name}</b>
                </>
            );
        } else {
            const mainPackages = packages.slice(0, packages.length - asset.fields.showMorePackageCount);
            const morePackages = packages.slice(packages.length - asset.fields.showMorePackageCount);

            return (
                <>
                    <YMM.Trigger onClick={() => setOpenYmm(true)} className={styles.ymm} variant="simple">
                        <div className={styles.packageContainer}>
                            &nbsp;with {platform}&nbsp;
                            <Select
                                type="text"
                                theme="purple"
                                label="select a package"
                                value={packageId}
                                placeholder={sxmPackage?.fields.package_display_name}
                                name="siriusxm package"
                                onChange={(e) => setPackageId(e.value)}
                                dataCy="channel-guide-ymm-select"
                                recalculateHeight={randomNumber}
                            >
                                <OptionGroup>
                                    {mainPackages.map((sxmPackage, i) => {
                                        if (
                                            sxmPackage.fields.package_display_name &&
                                            sxmPackage.fields.sms_package_id
                                        ) {
                                            return (
                                                <Option key={i} value={sxmPackage.fields.sms_package_id}>
                                                    {sxmPackage.fields.package_display_name}
                                                </Option>
                                            );
                                        }

                                        return <></>;
                                    })}
                                </OptionGroup>

                                {morePackages.length > 0 ? (
                                    <ShowMore
                                        id="cgPackagesShowMore"
                                        className={styles.showmore}
                                        buttonClassName={styles.showmoreButton}
                                        shownClassName={styles.show}
                                        contentClassName={styles.hidden}
                                        onClickHandler={showMoreClick}
                                        isContentShown={showContent}
                                        showText={<div className={styles.showMoreText}>Show More Plans</div>}
                                        hideText={<div className={styles.showMoreText}>Show Less Plans</div>}
                                        color="#ffffff"
                                        activeToggleTextPosition="top"
                                    >
                                        <OptionGroup>
                                            {morePackages.map((sxmPackage, i) => {
                                                if (
                                                    sxmPackage.fields.package_display_name &&
                                                    sxmPackage.fields.sms_package_id
                                                ) {
                                                    return (
                                                        <Option key={i} value={sxmPackage.fields.sms_package_id}>
                                                            {sxmPackage.fields.package_display_name}
                                                        </Option>
                                                    );
                                                }

                                                return <></>;
                                            })}
                                        </OptionGroup>
                                    </ShowMore>
                                ) : (
                                    <></>
                                )}
                            </Select>
                        </div>
                    </YMM.Trigger>
                    <YMM.Modal open={openYmm} onClose={() => setOpenYmm(false)} />
                </>
            );
        }
    }

    const wrapperClassnames = cn(styles.section, {
        [styles.openYmm]: openYmm,
    });

    return (
        <section data-componenttype="ChannelGuide" className={wrapperClassnames}>
            <Grid container className={styles.platformBar}>
                <Grid col={{ sm: 12, lg: 9 }}>{renderYMM()}</Grid>
                {lineupLink && lineupLink.fields.href && (
                    <Grid col={{ sm: 12, lg: 3 }} className={styles.downloadLinkDesktop}>
                        <Link href={lineupLink.fields.href} target="_blank">
                            {lineupLink.fields.link_text || 'Download the Full Channel Guide'}
                        </Link>
                    </Grid>
                )}
            </Grid>
            <div className={styles.tabsWrapper}>
                <CGContext.Provider value={{ channels: channels, channelGuide: asset, platform: pageContext.platform }}>
                    <Tabs
                        items={tabs}
                        barClassName={styles.stickyBar}
                        defaultIndex={0}
                        fullWidth={true}
                        isScrollContentToTop
                        scrollContentToTopOffset={62}
                    />
                </CGContext.Provider>
            </div>

            <Grid container className={styles.loadingSkeletonsWrapper}>
                <Grid col={{ sm: 12 }}>
                    <div className={styles.loadingSkeletons}>
                        <div className={styles.headerSkeleton} />
                        <ChannelCardStripSkeleton />
                        <ChannelCardStripSkeleton />
                        <ChannelCardStripSkeleton />
                        <ChannelCardStripSkeleton />
                        <ChannelCardStripSkeleton />
                        <ChannelCardStripSkeleton />
                    </div>
                </Grid>
            </Grid>

            {lineupLink && lineupLink.fields.href && (
                <Grid col={{ sm: 12 }} className={styles.downloadLinkMobile}>
                    <Link href={lineupLink.fields.href} target="_blank">
                        {lineupLink.fields.link_text || 'Download the Full Channel Guide'}
                    </Link>
                </Grid>
            )}
        </section>
    );
}
