'use client';
import dynamic from 'next/dynamic';
import { useMemo, useState } from 'react';
import type { MediaWithTextProps, MediaWithTextSingleProps } from './interfaces';
import styles from './mediawithtext.module.scss';
import cn from 'classnames';
import { MediaWithTextImage } from './MediaWithTextImage';
import { SafeHtml } from '@/components/atoms/Display';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import { Cols, ColBreakpoints } from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const AffiliateLinkList = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/AffiliateLinkList/AffiliateLinkList'),
);
const AudioPlayer = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Media/AudioPlayer/AudioPlayer'));
const Video = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Media/Video/Video'));
const LinkWrapperIfHref = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/LinkWrapperIfHref/LinkWrapperIfHref'),
);
const ContentSamplingPlayer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Media/ContentSamplingPlayer/ContentSamplingPlayer'),
);

interface GridProps {
    col: ColBreakpoints;
    order?: ColBreakpoints;
}

type ColumnType = 'media' | 'text';

type ColumnProps = Record<ColumnType, GridProps>;

export default function MediaWithTextSingle({
    isTwelveColumn = false,
    isReversed = false,
    isImageSmallStyle = false,
    items,
    theme = 'pingPong',
    itemSeparators = 'all',
    compactTextSpacing = false,
    classNames,
    contentSamplerStyles,
}: MediaWithTextSingleProps): JSX.Element {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const isPingPong: boolean = useMemo(() => theme === 'pingPong', [theme]);

    const pingPongProps: ColumnProps = useMemo(() => {
        if (isImageSmallStyle) {
            return {
                media: { col: { xs: 12, md: isTwelveColumn ? 5 : 4 } },
                text: { col: { xs: 12, md: isTwelveColumn ? 7 : 6 } },
            };
        } else if (contentSamplerStyles) {
            return {
                media: { col: { xs: 12, lg: isTwelveColumn ? 7 : 6 } },
                text: { col: { xs: 12, lg: isTwelveColumn ? 5 : 4 } },
            };
        } else {
            return {
                media: { col: { xs: 12, md: 7, lg: isTwelveColumn ? 7 : 6 } },
                text: { col: { xs: 12, md: 5, lg: isTwelveColumn ? 5 : 4 } },
            };
        }
    }, [isImageSmallStyle, isTwelveColumn, contentSamplerStyles]);

    const partnerProps: ColumnProps = {
        media: { col: { xs: 6, md: 4, lg: 3 } },
        text: { col: { xs: 12, md: 8, lg: 6 } },
    };

    function isEven(n: number): boolean {
        return (n + 1) % 2 === 0;
    }

    function calculateOrder(isReversed: boolean, i: number, isMedia: boolean): Cols {
        const even: boolean = isEven(i);

        if (isReversed) {
            return isMedia ? (even ? 1 : 2) : even ? 2 : 1;
        } else {
            return isMedia ? (even ? 2 : 1) : even ? 1 : 2;
        }
    }

    function getGridProps(i: number, columnType: ColumnType): GridProps {
        const baseGridProps = isPingPong ? pingPongProps[columnType] : partnerProps[columnType];

        const gridProps: GridProps = isPingPong
            ? { ...baseGridProps, order: { lg: calculateOrder(isReversed, i, columnType === 'text' ? false : true) } }
            : baseGridProps;

        return gridProps;
    }

    const containerClasses = cn(classNames, styles.mediaWithText, styles[`separators-${itemSeparators}`], {
        [styles.reversed]: isReversed,
        [styles[theme]]: theme,
    });

    function getMediaComponent(item: MediaWithTextProps) {
        if (item.image) {
            return (
                <MediaWithTextImage
                    imageIsLogo={item.imageIsLogo}
                    imageOverlay={item.imageOverlay}
                    imageSize={item.imageSize}
                    image={item.image}
                    mediaBackgroundColor={item.mediaBackgroundColor}
                    link={item.link}
                />
            );
        } else if (item.video && isPingPong) {
            return <Video {...item.video} />;
        }
    }

    return (
        <>
            {items.length > 0 &&
                items.map((item, i) => {
                    const headline =
                        item.headline &&
                        (typeof item.headline === 'string' ? (
                            <Text
                                tag="span"
                                variant={isPingPong ? 'h4' : 'bold'}
                                className={contentSamplerStyles ? contentSamplerStyles.headlineStyles : undefined}
                            >
                                <SafeHtml print={item.headline as string} />
                            </Text>
                        ) : (
                            <div className={styles.headlineAlt}>{item.headline}</div>
                        ));
                    return (
                        <Grid
                            key={i}
                            container
                            align={isPingPong && !isImageSmallStyle ? 'center' : 'stretch'}
                            standardMoleculeSpacing={isPingPong ? true : false}
                            className={containerClasses}
                        >
                            {!isTwelveColumn && <Grid col={{ xs: 12, lg: 1 }} />}

                            <Grid {...getGridProps(i, 'media')}>
                                <div
                                    className={
                                        contentSamplerStyles?.mediaElementSingleStyles ?? styles.mediaElementSingle
                                    }
                                    data-componenttype="AssetGroup:MediaWithText_img"
                                >
                                    {getMediaComponent(item)}
                                </div>
                            </Grid>
                            <Grid {...getGridProps(i, 'text')}>
                                <div
                                    className={cn(styles.contentCard, {
                                        [styles.contentCardEven]: isEven(i),
                                        [styles.compactTextSpacing]: compactTextSpacing,
                                        [contentSamplerStyles?.spacingStyles as string]: Boolean(
                                            contentSamplerStyles?.spacingStyles,
                                        ),
                                    })}
                                >
                                    {(headline || item.subHeader) && (
                                        <LinkWrapperIfHref
                                            link={(item.headlineAsLink && item.link) || undefined}
                                            className={item.headlineAsLink ? styles.headlineAsLink : styles.headline}
                                        >
                                            {headline}
                                            {item.subHeader && (
                                                <span className={styles.subHeader}>{item.subHeader}</span>
                                            )}
                                        </LinkWrapperIfHref>
                                    )}

                                    {item.body && (
                                        <Text
                                            tag="div"
                                            className={
                                                contentSamplerStyles ? contentSamplerStyles.bodyStyles : styles.bodyText
                                            }
                                            style={{
                                                color: contentSamplerStyles
                                                    ? ''
                                                    : isPingPong && !contentSamplerStyles
                                                      ? 'var(--almost-black)'
                                                      : 'var(--black)',
                                            }}
                                            dataCy="molecules-media-with-text-single-body"
                                        >
                                            {item.body}
                                        </Text>
                                    )}

                                    {item.audio?.source && !contentSamplerStyles && (
                                        <AudioPlayer
                                            {...item.audio}
                                            isActive={activeIndex === i}
                                            onActivate={() => setActiveIndex(i)}
                                            name={typeof item.headline === 'string' ? item.headline : ''}
                                        />
                                    )}

                                    {item.audio?.source && contentSamplerStyles && (
                                        <ContentSamplingPlayer
                                            {...item.audio}
                                            modal={item.modalData}
                                            isLightTheme={item.isLightTheme}
                                            isActive={activeIndex === i}
                                            onActivate={() => setActiveIndex(i)}
                                            name={typeof item.headline === 'string' ? item.headline : ''}
                                        />
                                    )}

                                    {item.affiliateLinks && (
                                        <AffiliateLinkList
                                            links={item.affiliateLinks.links?.map((e) => ({
                                                children: e.link_text,
                                                href: e.href,
                                                target: e.link_target,
                                            }))}
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    );
                })}
        </>
    );
}
