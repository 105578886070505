'use client';
import dynamic from 'next/dynamic';
import styles from './channelCardStrip.module.scss';
import { HTMLAttributes, ReactNode, useRef, useState } from 'react';
import IconAudioPlayButtonFilledShadow from '@/assets/icons/icon-audio-play-button-filled-shadow.svg';
import IconMenuKabab from '@/assets/icons/icon-menu-kebab.svg';
import IconLock from '@/assets/icons/icon-lock.svg';
import { Link } from '@/components/atoms/Link';
import { useClickOutside } from '@/hooks';
import cn from 'classnames';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export interface ChannelCardStripProps {
    tableCellRoles?: boolean;
    radioImageUrl: string;
    radioImageAlt: string;
    title: string;
    subTitle: React.ReactNode;
    subTitleDetails?: string;
    showAndScheduleLink: string;
    showAndScheduleLinkText: string;
    showsAndScheduleLinkAriaLabel: string;
    listenLink: string;
    listenText?: string;
    listenLinkAriaLabel: string;
    borderColor: string;
    activeState?: boolean;
    bannerText?: string;
    channelNumber?: React.ReactNode;
    locked?: boolean;
    display_listen_live: boolean;
}

interface channelCardStripComponentState {
    isActive: boolean;
}

export default function ChannelCardStrip({
    tableCellRoles = false,
    title,
    subTitle,
    subTitleDetails,
    showAndScheduleLink,
    showAndScheduleLinkText,
    showsAndScheduleLinkAriaLabel,
    listenLink,
    listenText = 'listen live',
    listenLinkAriaLabel,
    borderColor,
    radioImageUrl,
    channelNumber,
    bannerText,
    locked,
    display_listen_live = true,
}: ChannelCardStripProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState({
        isActive: false,
    });

    useClickOutside(wrapperRef, state, closeOnClickOutside);

    function closeOnClickOutside(state: channelCardStripComponentState) {
        setState({ ...state, isActive: false });
    }

    const changeActiveState = () => setState((state) => ({ isActive: !state.isActive }));

    const rowRoleAttr: HTMLAttributes<HTMLElement> = tableCellRoles ? { role: 'row' } : {};
    const cellRoleAttr: HTMLAttributes<HTMLElement> = tableCellRoles ? { role: 'cell' } : {};
    const listenLinkAttr = { href: listenLink, 'aria-label': listenLinkAriaLabel, target: '_blank' };
    const showsAndScheduleLinkAttr = {
        href: showAndScheduleLink,
        'aria-label': showsAndScheduleLinkAriaLabel,
    };

    const BannerHtml = (
        <div className={styles.bannerContainer}>
            <div className={styles.bannerBackground}></div>
            <div className={styles.bannerText}>{bannerText ? bannerText : ''}</div>
        </div>
    );

    const enhanceXLIndicator = (subTitle: ReactNode, stringToFind: string) => {
        if (typeof subTitle === 'string') {
            const index = subTitle.indexOf(stringToFind);
            if (index !== -1) {
                const indicator = subTitle.substring(index, index + stringToFind.length);
                const title = subTitle.substring(0, index);

                return (
                    <Text tag="span" className={styles.explicitLanguage}>
                        <p>{title}</p>
                        <span className={styles.explicitLanguageIndicator}>{indicator}</span>
                    </Text>
                );
            }
        }

        return (
            <Text tag="p" className={styles.subTitle}>
                {subTitle}
            </Text>
        );
    };

    return (
        <div
            ref={wrapperRef}
            className={cn(styles.contentContainer, { [styles.active]: state.isActive, [styles.hasBanner]: bannerText })}
            {...rowRoleAttr}
            data-cy="channel-strip-wrapper"
        >
            <div className={styles.backgroundWrapper}>
                <div className={styles.shadow}></div>
                <div className={styles.contentWrapper}>
                    <div className={styles.radioInfoContainer}>
                        <div className={styles.radioLink} {...cellRoleAttr}>
                            <Link
                                {...showsAndScheduleLinkAttr}
                                className={styles.titleLink}
                                dataCy="molecules-channel-card-strip-title-link"
                            >
                                {title}
                            </Link>
                        </div>

                        <div className={styles.radioTextInfo} {...cellRoleAttr}>
                            {title && (
                                <Text tag="p" className={styles.title}>
                                    {title}
                                </Text>
                            )}
                            {subTitle && enhanceXLIndicator(subTitle, 'XL')}
                            {subTitleDetails && (
                                <Text tag="p" className={styles.subTitleDetails}>
                                    {subTitleDetails}
                                </Text>
                            )}
                            {showAndScheduleLink && (
                                <Link
                                    href={showAndScheduleLink}
                                    aria-label={showsAndScheduleLinkAriaLabel}
                                    className={styles.showAndScheduleLink}
                                    target="blank"
                                    dataCy="molecules-channel-card-strip-shows-schedules-link"
                                >
                                    {showAndScheduleLinkText}
                                </Link>
                            )}
                        </div>
                    </div>

                    <div className={styles.albumContainer}>
                        <div className={styles.radioImageContainer} {...cellRoleAttr}>
                            <Link
                                className={styles.radioImageLink}
                                href={showAndScheduleLink || listenLink}
                                target={showAndScheduleLink ? '' : '_blank'}
                                aria-hidden="true"
                                tabIndex={-1}
                                dataCy="molecules-channel-card-strip-radio-image-link"
                            >
                                <ImageWrapper src={radioImageUrl} alt="" optimizedWidth={80} optimizedHeight={40} />
                            </Link>
                        </div>

                        <div className={styles.channel} {...cellRoleAttr}>
                            {locked ? (
                                <IconLock stroke="green" data-cy="channel-card-strip-lock-icon" />
                            ) : (
                                channelNumber
                            )}
                        </div>

                        <button
                            onClick={changeActiveState}
                            aria-label={`Click to ${state.isActive ? 'hide' : 'expand'} menu`}
                            className={styles.button}
                            data-cy="channel-card-strip-expansion-kabab"
                            {...cellRoleAttr}
                        >
                            <IconMenuKabab className={styles.kabab} />
                        </button>
                    </div>
                </div>

                <div {...cellRoleAttr}>
                    {display_listen_live && (
                        <Link
                            className={styles.colorBorder}
                            {...listenLinkAttr}
                            dataCy="channel-card-strip-listen-live-link"
                            data-player-link={''}
                        >
                            <div style={{ backgroundColor: borderColor }} className={styles.borderBackground}></div>
                            <div className={styles.listenLiveText}>{listenText}</div>
                            <IconAudioPlayButtonFilledShadow className={styles.playIcon} />
                        </Link>
                    )}
                </div>
            </div>
            {bannerText && BannerHtml}
        </div>
    );
}
