'use client';
import cn from 'classnames';
import { RenderRTE } from '@/components/RenderRTE';
import { SafeHtml } from '@/components/atoms/Display';
import { SiteContextualComponent } from '@/interfaces/site/SiteContextualComponent';
import styles from './pulseschedule.module.scss';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import { type SocialMediaIconLinksProps } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { inChronologicalOrder } from '@/packages/array';
import { ShowSchedule } from '@/components/molecules/ShowSchedule';
import IconDropDown from '@/assets/icons/icon-dropdown.svg';
import { useState } from 'react';
import { Host } from '../Host/Host';
import AudioIcon from 'de-sxm-reactlib/dist/components/atoms/AnimatedIcons/AudioIcon/AudioIcon';

interface PulseScheduleProps {
    asset: SiteContextualComponent;
    asW2?: boolean;
}

const showMax = 3;

const PulseSchedule = ({ asset, asW2 }: PulseScheduleProps) => {
    dayjs.extend(duration);
    dayjs.extend(isBetween);
    dayjs.extend(relativeTime);
    dayjs.extend(utc);
    dayjs.extend(timezone);

    const [activeTab, setActiveTab] = useState<'SHOWS' | 'HOSTS'>('SHOWS');
    const [maxItems, setMaxItems] = useState(showMax);
    const [showMore, setShowMore] = useState(false);
    const [showSchedules, setShowSchedules] = useState<number[]>([]);
    const now = dayjs().tz(dayjs.tz.guess());

    const hostView = asset.fields?.pulseHosts?.length === 0 ? styles.host_hideable : '';

    const getNextAirsText = (closestShowStartTime: Dayjs) => {
        const imminentShowText =
            closestShowStartTime.isBetween(now, now.add(2, 'day').startOf('day')) &&
            (closestShowStartTime.isBefore(now.add(1, 'day').startOf('day')) ? 'Today' : 'Tomorrow');

        const nextShowDay = imminentShowText || closestShowStartTime.format('dddd');
        const nextShowTime = closestShowStartTime.format(closestShowStartTime.minute() === 0 ? 'h a' : 'h:mm a');
        return `Next Airs ${nextShowDay} at ${nextShowTime}`;
    };

    const toggleSchedule = (index: number) => {
        let activeSchedules = [...showSchedules];
        if (showSchedules.includes(index)) {
            activeSchedules = activeSchedules.filter((item) => item !== index);
        } else {
            activeSchedules.push(index);
        }
        setShowSchedules(activeSchedules);
    };

    const toggleShowAll = () => {
        if (showMore) {
            setShowMore(false);
            setMaxItems(3);
        } else {
            setShowMore(true);
            setMaxItems(-1);
        }
    };

    const NowPlaying = () => (
        <div className="flex">
            <span className={styles.nowPlayingHeadline}>Now Playing</span>
            <AudioIcon></AudioIcon>
        </div>
    );

    const limitDescription = (text: string, maxLength: number) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const formatTimeZoneShort = (date: Date) => {
        return new Intl.DateTimeFormat('en-US', {
            timeZoneName: 'short',
        })
            .formatToParts(date)
            .find((part) => part.type === 'timeZoneName')?.value;
    };

    const renderItems = (pulseShows: SiteFeaturedShow[] | undefined) => {
        if (!pulseShows) {
            return null;
        }
        const shows = pulseShows.map((show, index) => {
            const closestShowSchedule = show.fields.channel_show?.show_schedules
                .map((schedule) => schedule.day_schedules)
                .flat()
                .sort((scheduleA, scheduleB) => inChronologicalOrder('start_time')(scheduleA, scheduleB))[0];
            if (!closestShowSchedule) {
                return <></>;
            }

            const closestShowStartTime = dayjs(closestShowSchedule.start_time).tz(dayjs.tz.guess());
            const closestShowEndTime = dayjs(closestShowSchedule.end_time).tz(dayjs.tz.guess());
            const isPlayingNow = now.isBetween(closestShowStartTime, closestShowEndTime);
            const nextAirsText = getNextAirsText(closestShowStartTime);

            const scheduleShow = showSchedules.includes(index);
            const showTitle = `${show.fields.channel_show?.show_name} | Channel ${show.fields.channel_show?.siriusChannelNumber}`;
            const showDescription = limitDescription(show.fields.channel_show?.description ?? '', 190);
            const showDescriptionShort = limitDescription(show.fields.channel_show?.description ?? '', 100);

            return (
                <>
                    <div
                        key={index}
                        className={`${styles.scheduleItem} ${index < maxItems || maxItems === -1 ? styles.visible : ''}`}
                    >
                        <div className={styles.channelImage}>
                            <img
                                src={show.fields.channel_show?.channel_logo?.url}
                                alt={show.fields.channel_show?.channel_logo?.altText}
                            ></img>
                        </div>
                        <div className={styles.showContent}>
                            <div className={styles.title}>{showTitle}</div>
                            <div className={styles.nextPlay}>{isPlayingNow ? <NowPlaying /> : nextAirsText}</div>
                            <div className={`${styles.description} ${styles.desktopDescription}`}>
                                <SafeHtml tag="span" print={showDescription} />
                            </div>
                            <div className={`${styles.description} ${styles.mobileDescription}`}>
                                <SafeHtml tag="span" print={showDescriptionShort} />
                            </div>
                            <div>
                                <button
                                    className={`${styles.scheduleButton} ${scheduleShow ? styles.active : ''}`}
                                    onClick={() => toggleSchedule(index)}
                                >
                                    <span>{scheduleShow ? 'Hide Schedule' : 'Show Schedule'}</span> <IconDropDown />
                                </button>
                                <div className={`${styles.schedule} ${scheduleShow ? styles.active : ''}`}>
                                    <ShowSchedule shows={[show.fields.channel_show as SiteChannelShow]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        });
        return shows;
    };

    const renderHosts = (pulseHosts: SiteHost[] | undefined) => {
        const hosts = pulseHosts?.map((host, index) => {
            const socialMedia: SocialMediaIconLinksProps = { items: [] };
            const props = {
                name: host.fields.talent_name || '',
                description: <RenderRTE contentfulDoc={host.fields.host_description} pToSpan={true} asW2={asW2} />,
                image: {
                    source: host.fields.host_logo?.url || '',
                    altText: host.fields.host_logo?.altText || '',
                    width: 200,
                    height: 200,
                },
                shows: host.fields.host_shows ?? [],
                socialMedia: socialMedia,
                channels: host.fields.related_channels,
            };
            return (
                <div
                    className={`${styles.hostItem} ${index < maxItems || maxItems === -1 ? styles.visible : ''}`}
                    key={index}
                >
                    <Host className={cn({ [styles.host]: true })} {...props} />
                </div>
            );
        });
        return hosts;
    };

    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 12 }}>
                <div className={styles.header}>
                    <button
                        className={`${activeTab === 'SHOWS' ? styles.activeTab : ''}`}
                        onClick={() => setActiveTab('SHOWS')}
                    >
                        All Shows
                    </button>
                    <button
                        className={`${activeTab === 'HOSTS' ? styles.activeTab : ''} ${hostView}`}
                        onClick={() => setActiveTab('HOSTS')}
                    >
                        All Hosts
                    </button>
                </div>
                <div className={styles.subHeader}>
                    <span>{`All times listed ${formatTimeZoneShort(now.toDate())}`}</span>
                </div>
                <div className={`${styles.showsContainer} ${activeTab === 'SHOWS' ? styles.active : ''}`}>
                    {renderItems(asset.fields?.pulseShows)}
                </div>
                <div className={`${styles.hostsContainer} ${activeTab === 'HOSTS' ? styles.active : ''}`}>
                    {renderHosts(asset.fields?.pulseHosts)}
                </div>
                <div className={`${styles.footer} ${showMore ? styles.showMore : ''}`}>
                    <button onClick={() => toggleShowAll()}>
                        <span>{showMore ? 'Show less' : 'Show more'}</span>
                        <IconDropDown />
                    </button>
                </div>
            </Grid>
        </Grid>
    );
};

export default PulseSchedule;
