'use client';
import dynamic from 'next/dynamic';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import StyledContainer from 'de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer';
import Text from 'de-sxm-reactlib/dist/components/atoms/Display/Text/Text';
import Button from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
import { ButtonProps } from 'de-sxm-reactlib/dist/components/atoms/Button/Button';
const LinkWrapperIfHref = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/LinkWrapperIfHref/LinkWrapperIfHref'),
);
import Video from 'de-sxm-reactlib/dist/components/atoms/Media/Video/Video';
import { VideoProps } from 'de-sxm-reactlib/dist/components/atoms/Media/Video/Video';
import ImageWrapper, { getBackgroundImage } from '@/shared-components/ImageWrapper/ImageWrapper';
import cn from 'classnames';
import styles from './contentshowcase.module.scss';
import { ComponentProps, useEffect, useRef, useState } from 'react';
import OfferDetails from '@/components/molecules/OfferDetails/OfferDetails';
import { OfferIcons } from '@/components/atoms/OfferIcons';
import { MarketingList } from '@/components/molecules/MarketingList';
import { StickyHeaderFooterCta } from '@/components/molecules/StickyHeaderFooterCta';
import { Slider } from '@/components/molecules/Slider';
import { ContentShowcaseSliderOptions } from '@/components/molecules/Slider/SliderOptions';
import { Document } from '@contentful/rich-text-types';
import { useMediaQuery } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import { RenderRTE } from '@/components/RenderRTE';

export type CtaType = 'none' | 'onScroll' | 'afterRef';

interface CtaProps extends ButtonProps {
    secondaryText?: Document;
    qrCode?: {
        url: string;
        altText: string;
    };
    media?: {
        url: string;
        altText: string;
    };
}

interface ContentShowcaseProps {
    backgroundImageDesktop?: string;
    backgroundImageMobile?: string;
    backgroundColor?: string;
    backgroundGradientDesktop?: string;
    backgroundGradientMobile?: string;
    foregroundImage?: {
        desktop?: ImageProps;
        mobile?: ImageProps;
        stickToBottom?: boolean;
    };
    partnerImage?: ImageProps;
    partnerImageLink?: Link;
    isParterImageBelowCta?: boolean;
    isContentLeft?: boolean;
    isFullHeight?: boolean;
    isOfferPink?: boolean;
    isThemeDark?: boolean;
    eyebrow?: string;
    headline?: React.ReactNode;
    isHeadlineAnimated?: boolean;
    subHeadline?: React.ReactNode;
    isHero?: boolean;
    offer?: ComponentProps<typeof OfferDetails>;
    offerIcons?: ComponentProps<typeof OfferIcons>;
    marketingList?: ComponentProps<typeof MarketingList>;
    marketingListTitle?: string;
    marketingCopy?: React.ReactNode;
    ctas?: CtaProps[];
    isCtaSideBySide?: boolean;
    description?: React.ReactNode;
    isDescriptionBelowCarousel?: boolean;
    sliderTitle?: string;
    slider?: ComponentProps<typeof Slider>;
    stickyBodyText: React.ReactNode;
    isInTabsGroup?: boolean;
    backgroundVideo?: {
        autoStart: boolean;
        loop: boolean;
        source: string;
        poster: string;
    };
    ctaStyle: CtaType;
}

export default function ContentShowcase({
    backgroundImageDesktop,
    backgroundImageMobile,
    backgroundColor,
    backgroundGradientDesktop,
    backgroundGradientMobile,
    foregroundImage,
    partnerImage,
    isParterImageBelowCta = false,
    isContentLeft = false,
    isFullHeight = false,
    isOfferPink = false,
    isThemeDark = false,
    isHero = false,
    partnerImageLink,
    eyebrow,
    headline,
    isHeadlineAnimated = false,
    subHeadline,
    offer,
    offerIcons,
    marketingList,
    marketingListTitle,
    marketingCopy,
    ctas,
    isCtaSideBySide = false,
    description,
    isDescriptionBelowCarousel = false,
    sliderTitle,
    slider,
    stickyBodyText,
    isInTabsGroup,
    backgroundVideo,
    ctaStyle,
}: ContentShowcaseProps): JSX.Element {
    const stickyCtaRef = useRef<HTMLDivElement>(null);
    const [showStaticCta, setShowStaticCta] = useState(true);
    const isViewportDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);

    useEffect(() => {
        const handleScroll = () => setShowStaticCta(window.scrollY >= 74 ? false : true);
        const removeHandleScroll = () => window.removeEventListener('scroll', handleScroll);
        if (ctaStyle === 'onScroll' && isViewportDesktop) {
            window.addEventListener('scroll', handleScroll);
        } else {
            removeHandleScroll();
        }

        return () => removeHandleScroll();
    }, [ctaStyle, isViewportDesktop]);

    useEffect(() => {
        if (ctaStyle !== 'none' && !isViewportDesktop) {
            setShowStaticCta(false);
        } else {
            setShowStaticCta(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewportDesktop]);

    const wrapperClasses = cn(styles.wrapper, {
        [styles.darkTheme]: isThemeDark,
        [styles.fullHeightTheme]: isFullHeight,
        [styles.hero]: isHero,
        [styles.ctaSideBySide]: isCtaSideBySide,
        [styles.hasVideo]: backgroundVideo?.source,
        [styles.videoOnLeft]: backgroundVideo?.source && !isContentLeft,
    });

    const headlineClasses = cn(styles.headline, {
        [styles.animatedHeadline]: isHeadlineAnimated,
    });

    const PartnerImageBlock = (
        <div className={styles.imagePartner}>
            <LinkWrapperIfHref link={partnerImageLink}>
                <ImageWrapper
                    optimizedWidth={partnerImage?.width || 300}
                    optimizedHeight={partnerImage?.height || 200}
                    src={partnerImage?.source || ''}
                    alt={partnerImage?.altText || ''}
                    loadingStrategy="eager"
                />
            </LinkWrapperIfHref>
        </div>
    );

    const DescriptionBlock = (
        <Text tag="p" className={styles.descriptionBlock}>
            {description}
        </Text>
    );

    const foregroundImageStyles = cn(styles.foregroundImage, {
        [styles.negativeMargin]: foregroundImage?.stickToBottom && isInTabsGroup,
    });

    const backgroundVideoProps: VideoProps = {
        autoplay: backgroundVideo?.autoStart || true,
        loop: backgroundVideo?.loop || true,
        source: backgroundVideo?.source || '',
        theme: isThemeDark ? 'dark' : 'light',
        poster: backgroundVideo?.poster,
    };

    return (
        <>
            <StyledContainer
                className={wrapperClasses}
                backgroundImageDesktop={getBackgroundImage({ src: backgroundImageDesktop, width: 2699, height: 807 })}
                backgroundImageMobile={getBackgroundImage({ src: backgroundImageMobile })}
                backgroundColor={backgroundColor}
                backgroundGradientDesktop={backgroundGradientDesktop}
                backgroundGradientMobile={backgroundGradientMobile}
                includeNavPadding={isHero}
            >
                {backgroundVideo?.source && (
                    <Video
                        {...backgroundVideoProps}
                        className={styles.backgroundVideo}
                        isControlLeft={!isContentLeft}
                    />
                )}

                <Grid container className={styles.gridContainer}>
                    {(foregroundImage?.mobile?.source || foregroundImage?.desktop?.source) && (
                        <Grid
                            col={{ xs: 12, lg: 6 }}
                            align={foregroundImage?.stickToBottom ? 'end' : 'center'}
                            order={isContentLeft ? { lg: 2 } : undefined}
                        >
                            <div className={foregroundImageStyles}>
                                <ImageWrapper
                                    src={foregroundImage.desktop?.source ?? ''}
                                    alt={foregroundImage.desktop?.altText ?? ''}
                                    mobile={foregroundImage.mobile?.source}
                                    optimizedWidth={foregroundImage.desktop?.width}
                                    optimizedHeight={foregroundImage.desktop?.height}
                                    loadingStrategy="eager"
                                />
                            </div>
                        </Grid>
                    )}

                    <Grid col={{ xs: 12, lg: 6 }} align="center">
                        <div className={styles.contentWrapper}>
                            {/* <!-- Partner Image below CTA variation starts--> */}
                            {partnerImage && !isParterImageBelowCta && PartnerImageBlock}
                            {/* <!-- // Partner Image below CTA variation ends --> */}
                            {/* <!-- Header section starts --> */}
                            {(eyebrow || headline || subHeadline) && (
                                <div className={styles.headerSection}>
                                    {eyebrow && (
                                        <Text tag="p" variant="eyebrowLg">
                                            {eyebrow}
                                        </Text>
                                    )}
                                    {headline && (
                                        <Text tag={isHero ? 'h1' : 'h2'} className={headlineClasses}>
                                            {headline}
                                        </Text>
                                    )}
                                    {subHeadline && (
                                        <Text tag="span" variant="subhead">
                                            {subHeadline}
                                        </Text>
                                    )}
                                </div>
                            )}
                            {/* <!-- // Header section ends --> */}
                            {/* <!-- Package section starts --> */}
                            {offer && Object.keys(offer).length > 0 && (
                                <div className={styles.offer}>
                                    <OfferDetails
                                        {...offer}
                                        isHighlighted={isOfferPink ? true : false}
                                        className={styles.offerDetails}
                                    />
                                    {offerIcons && offerIcons.icons.length > 0 && (
                                        <OfferIcons {...offerIcons} whiteFilter={isThemeDark ? true : false} />
                                    )}
                                </div>
                            )}
                            {/* <!-- // Package section ends --> */}
                            {/* <!-- Marketing message section starts --> */}
                            {marketingListTitle && (
                                <Text tag="p" className="bold">
                                    {marketingListTitle}
                                </Text>
                            )}
                            {marketingList && marketingList.items.length > 0 && (
                                <div className={styles.marketingList}>
                                    <MarketingList {...marketingList} whiteFilter={isThemeDark ? true : false} />
                                </div>
                            )}
                            {marketingCopy && (
                                <Text tag="p" className={styles.marketingCopy}>
                                    {marketingCopy}
                                </Text>
                            )}
                            {/* <!-- // Marketing message section ends --> */}
                            {ctas && ctas.length > 0 && (
                                <div className={styles.ctaWrapper}>
                                    {ctas?.map((cta, i) => {
                                        const bodyText = <RenderRTE contentfulDoc={cta.secondaryText} />;
                                        return (
                                            <div
                                                ref={stickyCtaRef}
                                                key={i}
                                                className={cn({
                                                    [styles.marginTopCta]: i === 1 && !isCtaSideBySide,
                                                })}
                                            >
                                                {cta.qrCode && (
                                                    <div className={styles.imagesWrapperStyles}>
                                                        {cta.media?.url && (
                                                            <ImageWrapper
                                                                src={cta.media.url}
                                                                alt={cta.media.altText}
                                                                className={styles.staticImageStyles}
                                                            />
                                                        )}
                                                        {cta.qrCode?.url && (
                                                            <ImageWrapper
                                                                src={cta.qrCode.url}
                                                                alt={cta.qrCode.altText}
                                                                className={styles.qrCodeStyles}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {cta.qrCode && bodyText && (
                                                    <div className={styles.bodyTextStyles}>{bodyText}</div>
                                                )}
                                                <div className={styles.ctaButtonWrapper}>
                                                    {showStaticCta && (
                                                        <Button
                                                            {...cta}
                                                            type={cta.type ?? 'button'}
                                                            theme={cta.theme ?? 'primary'}
                                                            key={i}
                                                            className={cn(styles.ctaButton, {
                                                                [styles.whiteText]: cta.theme === 'text' && isThemeDark,
                                                                [styles.customTheme]: cta.theme === 'custom',
                                                            })}
                                                            dataCy="organisms-content-showcase-cta-button"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {/* <!-- Partner Image below CTA variation starts--> */}
                            {partnerImage && isParterImageBelowCta && PartnerImageBlock}
                            {/* <!-- // Partner Image below CTA variation ends --> */}
                            {/* <!-- Description above Carousel variation starts--> */}
                            {description && !isDescriptionBelowCarousel && DescriptionBlock}
                            {/* <!-- Description above Carousel variation ends--> */}
                            {slider && sliderTitle && (
                                <Text tag="p" variant="h5">
                                    {sliderTitle}
                                </Text>
                            )}
                            {slider?.slides && (
                                <Slider
                                    slides={slider.slides}
                                    isThemeDark={isThemeDark ? true : false}
                                    className={styles.slider}
                                    isArrowsTopAligned
                                    splideOptions={ContentShowcaseSliderOptions}
                                    type="ContentShowcase"
                                />
                            )}
                            {/* <!-- Description below Carousel variation starts--> */}
                            {description && isDescriptionBelowCarousel && DescriptionBlock}
                            {/* <!-- Description below Carousel variation ends--> */}
                        </div>
                    </Grid>
                </Grid>
            </StyledContainer>

            {ctas && ctas.length > 0 && (
                <StickyHeaderFooterCta
                    triggerRef={ctaStyle !== 'none' ? stickyCtaRef : undefined}
                    body={stickyBodyText}
                    ctaOption="button"
                    cta={ctas.map((cta) => ({
                        ...cta,
                        type: cta.type ?? 'button',
                        theme: cta.theme === 'dark' ? 'primary' : cta.theme ?? 'primary',
                    }))}
                    alwaysVisibleInMobile={ctaStyle !== 'none'}
                    scrollStartPosition={ctaStyle === 'onScroll' ? 72 : undefined}
                    promoMobileText={stickyBodyText}
                    isTextSmall={false}
                    showSecondaryText={false}
                    stickyCta
                />
            )}
        </>
    );
}
