'use client';

import dynamic from 'next/dynamic';
import { responsiveBreakpointsPx } from '@/constants';
import { useMediaQuery } from '@/hooks';
import { type SocialMediaIconLinkItem } from 'de-sxm-reactlib/dist/components/atoms/SocialMediaIconLinks';
import { Footer } from 'de-sxm-reactlib/dist/components/molecules/NavFooter';
import type {
    FooterItem,
    SecondaryLink,
    BasicFooterLinks,
    AppLink,
} from 'de-sxm-reactlib/dist/components/molecules/NavFooter';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));

interface Props {
    type: 'Full' | 'Simple' | 'Basic';
    primaryLinks: FooterItem[];
    socialLinks: SocialMediaIconLinkItem[];
    secondaryLinks: SecondaryLink[];
    copyrightText: React.ReactNode;
    basicFooterLinks: BasicFooterLinks;
    playStore: AppLink;
    appleStore: AppLink;
}

export default function ClientNavFooter({
    type,
    primaryLinks,
    socialLinks,
    secondaryLinks,
    copyrightText,
    basicFooterLinks,
    playStore,
    appleStore,
}: Props) {
    const isViewportDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`) && type !== 'Basic';

    function renderFooter() {
        if (type === 'Full') {
            return (
                <Footer.Full
                    primaryLinks={primaryLinks}
                    socialMediaItems={socialLinks}
                    playStore={playStore}
                    appleStore={appleStore}
                    secondaryLinks={secondaryLinks}
                    copyrightText={<>{copyrightText}</>}
                />
            );
        } else if (type === 'Simple') {
            return (
                <Footer.Simple
                    primaryLinks={primaryLinks}
                    secondaryLinks={secondaryLinks}
                    socialMediaItems={socialLinks}
                    playStore={playStore}
                    appleStore={appleStore}
                    copyrightText={<>{copyrightText}</>}
                />
            );
        } else if (type === 'Basic') {
            return <Footer.Basic links={basicFooterLinks} copyrightText={<>{copyrightText}</>} />;
        }
    }

    return (
        <Grid container noPadding={type === 'Basic' ? false : !isViewportDesktop}>
            <Grid col={{ sm: 12 }}>
                <footer data-componenttype={`${type}Footer`} data-swiftype-index="false">
                    {renderFooter()}
                </footer>
            </Grid>
        </Grid>
    );
}
