import dayjs from 'dayjs';

export function inChronologicalOrder<ObjectType extends { [key: string]: ObjectType[keyof ObjectType] }>(
    key: keyof ObjectType,
    order: 'ascending' | 'descending' = 'ascending',
) {
    return (objectA: ObjectType, objectB: ObjectType) => {
        const timeA = dayjs(objectA[key]);
        const timeB = dayjs(objectB[key]);

        if (timeA.isSame(timeB)) {
            return 0;
        }

        const sortResult = timeA.isBefore(timeB) ? -1 : 1;
        return order === 'ascending' ? sortResult : -sortResult;
    };
}
