'use client';

import useInheritParams from '@/hooks/useInheritParams';
import Link, { type LinkProps } from 'de-sxm-reactlib-2/dist/components/atoms/Link/Link';
import { forwardRef, Ref, useEffect, useState } from "react";
import globalProperties from '../../../../properties/global';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    inheritParams?: boolean;
    dataCy?: string;
}

const SXMLink = forwardRef(function (props: Props, ref: Ref<HTMLElement>) {
    const { inheritParams, ...linkProps } = props;
    const [newProps, setNewProps] = useState<LinkProps>(linkProps);
    const href = useInheritParams(props.href || '#', globalProperties.urlInheritParams);
    const isPlayer = /smart\.link/gi.test(props.href || '');
    const extraProps = {
        ...(isPlayer && { is: 'sxm-player-link' }),
    };

    useEffect(() => {
        if (isPlayer) {
            setNewProps({
                ...props,
                target: '_blank',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { inheritParams, ...linkProps } = props;
        if (inheritParams) {
            setNewProps(linkProps);
        }
    }, [href, props]);

    if (!inheritParams) {
        return <Link {...linkProps} ref={ref as Ref<HTMLAnchorElement>} />;
    }

    if (!newProps) {
        return <></>;
    }

    return <Link {...newProps} {...extraProps} ref={ref as Ref<HTMLAnchorElement>} />;
});

SXMLink.displayName = 'SXMLink';
export default SXMLink;