interface SearchParams {
    [index: string]: string;
}

export default function inheritQueryString(path: string, searchParams: SearchParams, inheritParams: string[]) {
    const pathParts = path.split('?');
    const pathParams = new URLSearchParams(pathParts[1]);

    inheritParams.forEach((param) => {
        const value = searchParams[param];
        if (value) {
            pathParams.set(param, value);
        }
    });

    return pathParts[0] + '?' + pathParams.toString();
}
