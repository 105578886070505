'use client';
import dynamic from 'next/dynamic';
import { ReactNode, forwardRef, Ref, ComponentProps } from 'react';
import { useUserDeviceType, DeviceTypes } from '@/hooks';
import cn from 'classnames';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
import styles from './stickycta.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

const {
    mainWrapperStyles,
    imagesWrapperStyles,
    staticImageStyles,
    qrCodeStyles,
    bodyTextStyles,
    ctaButtonStyles,
    hideOnMobile,
    displayButton,
} = styles;

type ImagePropTypes = {
    url: string;
    altText: string;
};

type ButtonTheme = ComponentProps<typeof Button>['theme'];

interface StickyCtaProps {
    staticImage?: ImagePropTypes;
    qrCode?: ImagePropTypes;
    bodyText?: ReactNode;
    ctaText?: JSX.Element;
    ctaUrl?: string;
    headerFooterBarVersion?: boolean;
    theme?: ButtonTheme;
}

const StickyCta = forwardRef(
    (
        {
            staticImage,
            qrCode,
            bodyText,
            ctaText,
            ctaUrl,
            headerFooterBarVersion = false,
            theme = 'primary',
        }: StickyCtaProps,
        ref: Ref<HTMLDivElement>,
    ): JSX.Element => {
        const userDeviceType = useUserDeviceType();
        const isMobileDevice = userDeviceType === DeviceTypes.android || userDeviceType === DeviceTypes.ios;

        const mainWrapper = cn(mainWrapperStyles, {
            [hideOnMobile]: isMobileDevice,
        });

        return (
            <>
                <div className={mainWrapper} ref={ref}>
                    <div className={imagesWrapperStyles}>
                        {staticImage?.url && (
                            <ImageWrapper
                                src={staticImage.url}
                                alt={staticImage.altText}
                                className={staticImageStyles}
                            />
                        )}
                        {qrCode?.url && <ImageWrapper src={qrCode.url} alt={qrCode.altText} className={qrCodeStyles} />}
                    </div>
                    {!headerFooterBarVersion && <div className={bodyTextStyles}>{bodyText}</div>}
                </div>
                {headerFooterBarVersion && (
                    <div>
                        <Button
                            theme={theme}
                            type="button"
                            href={ctaUrl}
                            text={ctaText}
                            className={isMobileDevice ? displayButton : ctaButtonStyles}
                            dataCy="atoms-sticky-cta"
                        />
                    </div>
                )}
            </>
        );
    },
);

StickyCta.displayName = 'StickyCta';
export default StickyCta;
