import dynamic from 'next/dynamic';

const Filter = dynamic(() => import('./Filter/Filter'));
const SafeHtml = dynamic(() => import('./SafeHtml/SafeHtml'));
const StickyBox = dynamic(() => import('./StickyBox/StickyBox'));
const TrialEligibilityStickyBoxOfferDetails = dynamic(
    () => import('./TrialEligibilityStickBoxOfferDetails/TrialEligibilityStickyBoxOfferDetails'),
);
const TrialEligibilityStickyBox = dynamic(() => import('./TrialEligibilityStickyBox/TrialEligibilityStickyBox'));

import StickyCta from './StickyCta/StickyCta';
export { Filter };
export { SafeHtml };
export { StickyBox };
export { StickyCta };
export { TrialEligibilityStickyBoxOfferDetails };
export { TrialEligibilityStickyBox };
