'use client';
import dynamic from 'next/dynamic';
import styles from './ymm.module.scss';
import { getCapabilities, getMakesForYear, getModelsForMake, getYMMCookies, getYearsFrom } from '@/packages/sxm/ymm';
import { useEffect, useState } from 'react';
import { Capabilities, Model } from '@/packages/sxm/ymm/types';
import type { Props } from './interfaces';
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));

export function YMM(props: Props): JSX.Element {
    const resetYMM = !!props.reset;
    const [years, setYears] = useState<string[]>([]);
    const [makes, setMakes] = useState<string[]>([]);
    const [models, setModels] = useState<Model[]>([]);
    const [year, setYear] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [id, setId] = useState('');
    const [capabilities, setCapabilities] = useState<Capabilities>();

    const selectTheme = props?.selectTheme ? props.selectTheme : 'outline';

    async function getYears() {
        const yearList = await getYearsFrom('2004');
        setYears(yearList);
    }

    async function getMakes(customYear: string = year) {
        const makesList = await getMakesForYear(customYear);
        setMakes(makesList);
    }

    async function getModels(customYear: string = year, customMake: string = make) {
        const modelsList = await getModelsForMake(customYear, customMake);
        setModels(modelsList);
    }

    async function getCarCapabilities(customId: string = id) {
        const capabilities = await getCapabilities(customId);
        setCapabilities(capabilities);
    }

    useEffect(() => {
        async function reset() {
            const ymmCookie = getYMMCookies().sxm_radio.split('|');

            if (props.reset) {
                if (ymmCookie[1]) {
                    await getMakes(ymmCookie[1]);
                    await getModels(ymmCookie[1], ymmCookie[2]);

                    setYear(ymmCookie[1]);
                    setMake(ymmCookie[2]);
                    setModel(ymmCookie[3]);
                    setId(ymmCookie[4]);
                }
            }
        }

        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetYMM]);

    useEffect(() => {
        async function init() {
            const ymmCookie = getYMMCookies().sxm_radio.split('|');
            await getYears();

            if (ymmCookie[1]) {
                setYear(ymmCookie[1]);
                setMake(ymmCookie[2]);
                setModel(ymmCookie[3]);
                setId(ymmCookie[4]);
            }
        }
        init();
    }, []);

    useEffect(() => {
        if (id) {
            getCarCapabilities(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        props.onCarReady({
            year: year,
            make: make,
            model: model,
            id: id,
            capabilities: capabilities,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, make, model, id, capabilities]);

    function yearHandler(element: HTMLInputElement | undefined, params: { value: string }) {
        if (params.value && year !== params.value) {
            setYear(params.value);
            setMake('');
            setModel('');
            setId('');
            setMakes([]);
            setModels([]);
            getMakes(params.value);
        }
    }

    function makeHandler(element: HTMLInputElement | undefined, params: { value: string }) {
        if (params.value && make !== params.value) {
            setMake(params.value);
            setModel('');
            setId('');
            setModels([]);
            getModels(year, params.value);
        }
    }

    function modelHandler(element: HTMLInputElement | undefined, params: { value: string; data?: Model }) {
        if (params.value && params.data && model !== params.value) {
            setCapabilities(undefined);
            setModel(params.value);
            setId(params.data.ymmComboId);
        }
    }

    return (
        <div className={styles.bar + ' ' + styles[props.theme]}>
            <div className={styles.year}>
                <Select
                    label="Year"
                    type="form"
                    theme={selectTheme}
                    name="year"
                    disabled={years.length === 0}
                    onChange={yearHandler}
                    value={year}
                    placeholder={year}
                    dataCy="molecules-ymm-form-year-select"
                >
                    <OptionGroup>
                        {years
                            .sort()
                            .reverse()
                            .map((year) => (
                                <Option key={year} value={`${year}`}>{`${year}`}</Option>
                            ))}
                    </OptionGroup>
                </Select>
            </div>
            <div className={styles.make}>
                <Select
                    label="Make"
                    type="form"
                    theme={selectTheme}
                    name="make"
                    disabled={makes.length === 0}
                    onChange={makeHandler}
                    value={make}
                    placeholder={make}
                    dataCy="molecules-ymm-form-make-select"
                >
                    <OptionGroup>
                        {makes.map((make) => (
                            <Option key={make} value={make}>
                                {make}
                            </Option>
                        ))}
                    </OptionGroup>
                </Select>
            </div>
            <div className={styles.model}>
                <Select<Model>
                    label="Model"
                    type="form"
                    theme={selectTheme}
                    name="model"
                    disabled={models.length === 0}
                    onChange={modelHandler}
                    value={model}
                    placeholder={model}
                    dataCy="molecules-ymm-form-model-select"
                >
                    <OptionGroup>
                        {models.map((model) => (
                            <Option key={model.vehicleModel} value={model.vehicleModel} object={model}>
                                {model.vehicleModel}
                            </Option>
                        ))}
                    </OptionGroup>
                </Select>
            </div>
        </div>
    );
}
