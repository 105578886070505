import { useEffect, useState, useLayoutEffect } from 'react';

type ScrollDirection = 'up' | 'down' | 'none';

export const useScrollDirection = (thresholdValue = 0) => {
    const [scrollDir, setScrollDir] = useState<ScrollDirection>('none');
    const [onPageTop, setOnPageTop] = useState(true);

    useLayoutEffect(() => {
        setOnPageTop(Boolean(window.scrollY === 0));
    }, []);

    useEffect(() => {
        const threshold = thresholdValue;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateScrollDir = () => {
            const scrollY = window.scrollY;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollDir(scrollY > lastScrollY ? 'down' : 'up');
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;

            if (lastScrollY === 0 && !onPageTop) {
                setOnPageTop(true);
            }
        };

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
            if (onPageTop) {
                setOnPageTop(false);
            }
        };

        const avoidPageScroll = (event: KeyboardEvent) => {
            if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                event.preventDefault();
            }
        };

        window.addEventListener('scroll', onScroll);
        window.addEventListener('keydown', avoidPageScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('keydown', avoidPageScroll);
        };
    }, [scrollDir, onPageTop, thresholdValue]);

    return { scrollDir, onPageTop };
};
