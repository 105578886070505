const LibImage = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Image/Image'));
import dynamic from 'next/dynamic';
import { type ImageProps as LibImageProps } from 'de-sxm-reactlib/dist/components/atoms/Image/Image';
import ImageOptimization from '@/services/image_optimization';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;
    mobile?: string;
    ariaLabel?: string;
    ariaHidden?: boolean;
    loadingStrategy?: 'eager' | 'lazy';
    optimizedWidth?: number;
    optimizedHeight?: number;
    skipOptimization?: boolean;
}

export default function ImageWrapper({
    src,
    alt,
    className,
    style,
    id,
    loadingStrategy = 'lazy',
    optimizedWidth,
    optimizedHeight,
    skipOptimization = false,
    ...rest
}: Props) {
    if (!src) {
        return <></>;
    }

    const optimizedSrc = ImageOptimization({
        src: src,
        width: optimizedWidth,
        height: optimizedHeight,
        skipOptimization: skipOptimization,
    });

    const libImageProps: LibImageProps = {
        src: `${optimizedSrc}`,
        alt: alt ? alt : '',
        className,
        style,
        ariaLabel: rest['aria-label'] || rest.ariaLabel,
        ariaHidden: !!rest['aria-hidden'] || rest.ariaHidden,
        id,
        loadingStrategy,
    };

    if (rest.mobile) {
        const optimizedMobileSrc = ImageOptimization({
            src: rest.mobile,
            width: optimizedWidth,
            height: optimizedHeight,
            skipOptimization: skipOptimization,
        });

        return (
            <picture>
                <source media="(min-width: 1024px)" srcSet={`${optimizedSrc}`} />
                <source srcSet={optimizedMobileSrc} />
                <LibImage {...libImageProps} />
            </picture>
        );
    }

    return <LibImage {...libImageProps} />;
}

export interface BackgroundImageProps {
    src?: string;
    width?: number;
    height?: number;
}

export const getBackgroundImage = ({ src, width, height }: BackgroundImageProps) => {
    return src && `url("${ImageOptimization({ src, width, height })}")`;
};
