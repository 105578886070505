import cn from 'classnames';
import styles from './hero.module.scss';
import { Ref, forwardRef } from 'react';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import StyledContainer from 'de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer';
import ImageWrapper, { getBackgroundImage } from '@/shared-components/ImageWrapper/ImageWrapper';

interface Props {
    backgroundColor?: string;
    backgroundImageMobile?: string;
    backgroundImageTablet?: string;
    backgroundLogo?: string;
    textColor?: string;
    includeNavPadding?: boolean;
    leftSlot?: { mobileSlot?: 'top' | 'bottom'; content: React.ReactNode | React.ReactNode[] };
    rightSlot?: { content: React.ReactNode | React.ReactNode[] };
    theme?: 'Team';
    className?: string;
}

export const Hero = forwardRef(function (props: Props, ref: Ref<HTMLElement>): JSX.Element {
    const classNames = cn(styles.hero, {
        [styles.teamHero]: props.theme === 'Team',
    });

    return (
        <header ref={ref} className={classNames}>
            <StyledContainer
                backgroundColor={props.backgroundColor}
                textColor={props.textColor ? props.textColor : 'var(--white)'}
                backgroundImageTablet={getBackgroundImage({ src: props.backgroundImageTablet })}
                backgroundImageMobile={getBackgroundImage({ src: props.backgroundImageMobile })}
                includeNavPadding={props.includeNavPadding ?? true}
                className={props.className}
            >
                {props.backgroundLogo && (
                    <div className={styles.backgroundLogo}>
                        <StyledContainer includeNavPadding={props.includeNavPadding ?? true}>
                            <ImageWrapper
                                optimizedWidth={284}
                                optimizedHeight={284}
                                src={props.backgroundLogo}
                                alt=""
                                aria-hidden={true}
                                loadingStrategy="eager"
                            />
                        </StyledContainer>
                    </div>
                )}

                <Grid container>
                    <Grid col={{ xs: 12, md: 6 }} order={{ xs: props.leftSlot?.mobileSlot === 'top' ? 1 : 2, md: 1 }}>
                        <>{props.leftSlot?.content}</>
                    </Grid>

                    <Grid
                        col={{ xs: 12, md: 6 }}
                        order={{ xs: props.leftSlot?.mobileSlot === 'top' ? 2 : 1, md: 2 }}
                        className={cn({
                            hide: !props.rightSlot,
                        })}
                    >
                        <>{props.rightSlot?.content}</>
                    </Grid>
                </Grid>
            </StyledContainer>
        </header>
    );
});

Hero.displayName = 'Hero';
