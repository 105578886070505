import { groupBy, keyBy } from '@/packages/array';
import { ReactNode } from 'react';
import { FootnoteReferenceNumbers, SourcePackageData, TooltipData } from './interfaces';

const STRING_FOR_CHECKMARK = 'yes';

export function sourceDataToPlanRows(
    rowNames: string[],
    selectedData: SourcePackageData[],
    simplifyRowDescriptions = false,
    tooltipData: TooltipData,
    footnoteReferenceNumbers?: FootnoteReferenceNumbers,
    featureBlacklist: string[] = [],
) {
    return {
        headers: ['Plan', ...selectedData.map((data) => data.packageName)],
        rows: rowNames
            .filter((rowName) => !featureBlacklist.includes(rowName))
            .map((rowName) =>
                buildRows(rowName, selectedData, simplifyRowDescriptions, tooltipData, footnoteReferenceNumbers),
            ),
        subHeaders: {},
        shortHeaders: {},
        best: {},
        purchaseHrefs: {},
        trackLinkTexts: {},
        packageCodes: {},
    };
}

function buildRows(
    rowName: string,
    selectedData: SourcePackageData[],
    simplifyRowDescriptions: boolean,
    tooltipDataObject: TooltipData,
    footnoteReferenceNumbers?: FootnoteReferenceNumbers,
) {
    const featuresByName = Object.fromEntries(
        Object.entries(
            groupBy(
                'featureDisplayText',
                selectedData.flatMap((planData) =>
                    planData.packageComparisonFeatures.map((feature) => ({
                        ...feature,
                        planName: planData.packageName,
                    })),
                ),
            ),
        ).map(([key, value]) => [key, keyBy('planName', value)]),
    );

    const tooltipObject = tooltipDataObject?.tooltipData?.filter((tooltip) => tooltip.planName === rowName);

    return {
        name: rowName,
        plans: Object.fromEntries(
            selectedData
                .map((planData) => {
                    const feature = featuresByName[rowName]?.[planData.packageName];

                    return [
                        planData.packageName,
                        feature &&
                            ((simplifyRowDescriptions
                                ? getSimpleRowDescription(feature)
                                : feature?.featureDescription) ||
                                STRING_FOR_CHECKMARK),
                    ];
                })
                .filter(([, packgeData]) => !!packgeData),
        ),
        tooltip: tooltipObject?.[0]?.tooltip,
        ...(typeof footnoteReferenceNumbers?.features?.[rowName] === 'number'
            ? { footnoteReferenceNumber: footnoteReferenceNumbers.features[rowName] }
            : {}),
    };
}

function getSimpleRowDescription(feature: SourcePackageData['packageComparisonFeatures'][0]): ReactNode {
    return feature?.nonSourceFields?.fullTextWhenSimplified ? feature?.featureDescription : STRING_FOR_CHECKMARK;
}
