'use client';
import dynamic from 'next/dynamic';
import { useState, useEffect } from 'react';
import styles from './channelCard.module.scss';
import IconMenuKabab from '@/assets/icons/icon-menu-kebab.svg';
import IconAudioPlayButtonFilledShadow from '@/assets/icons/icon-audio-play-button-filled-shadow.svg';
import cn from 'classnames';
import { Link } from '@/components/atoms/Link';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Album = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Media/AudioPlayer/Album/Album'));
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import ImageOptimization from '@/services/image_optimization';

export interface ChannelCardProps {
    radioImageUrl: string;
    radioImageAlt?: string;
    radioImageHeight: number | `${number}` | undefined;
    radioImageWidth: number | `${number}` | undefined;
    title: string;
    subTitle?: React.ReactNode;
    showAndScheduleLink?: string;
    showAndScheduleLinkText?: string;
    showsAndScheduleLinkAriaLabel?: string;
    showAndScheduleLinkTarget?: string;
    listenLink: string;
    listenLinkAriaLabel: string;
    borderColor: string;
    albumImageSrc: string;
    albumImageAlt?: string;
    songTitle: string;
    songArtist: string;
    className?: string;
    wrapperClass?: string;
    onKebabClick?: () => void;
    activeItem?: number;
    index?: number;
}

export default function ChannelCard({
    radioImageUrl,
    radioImageAlt = 'Radio Channel Image',
    radioImageWidth,
    radioImageHeight,
    title,
    subTitle,
    showAndScheduleLink,
    showAndScheduleLinkText,
    showsAndScheduleLinkAriaLabel,
    showAndScheduleLinkTarget,
    listenLink,
    listenLinkAriaLabel,
    borderColor,
    albumImageSrc,
    albumImageAlt,
    songArtist,
    songTitle,
    className,
    onKebabClick,
    activeItem,
    index,
    wrapperClass,
}: ChannelCardProps) {
    const [isActive, setIsActive] = useState(false);

    const handleOnClick = () => {
        onKebabClick?.();
        setIsActive(!isActive);
    };

    useEffect(() => {
        if (activeItem && index) {
            if (activeItem === index) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        }
    }, [activeItem, index]);

    const wrapperClasses = cn(styles.channelCard, wrapperClass, {
        [styles.active]: isActive,
    });

    const componentClasses = cn(styles.contentWrapper, className);

    const listenLinkAttr = {
        href: listenLink,
        'aria-label': listenLinkAriaLabel,
        target: '_blank',
        dataCy: 'molecules-channel-card-listen-live-link',
    };
    albumImageSrc = ImageOptimization({ src: albumImageSrc, width: 136, height: 136 });
    const AlbumHtml = (
        <>
            <IconMenuKabab className={styles.kabab} />

            <div className={styles.albumWrapper} aria-hidden="true">
                <Album
                    showInfoOnHover={false}
                    imageSrc={albumImageSrc}
                    imageHeight="136"
                    imageWidth="136"
                    alt={albumImageAlt}
                    artist={songArtist}
                    songTitle={songTitle}
                    targetClass={styles.albumCover}
                ></Album>
            </div>
        </>
    );

    const ColorBorderHtml = (
        <>
            <div style={{ backgroundColor: borderColor }} className={styles.borderBackground}></div>
            <div className={styles.listenLiveText}>listen live</div>
            <IconAudioPlayButtonFilledShadow className={styles.playIcon} />
        </>
    );

    return (
        <div className={wrapperClasses} data-channelcard data-cy="molecules-channel-card-wrapper">
            <div className={componentClasses}>
                <div className={styles.radioInfoContainer}>
                    <Link
                        className={styles.radioImageLink}
                        href={showAndScheduleLink || ''}
                        aria-label={showsAndScheduleLinkAriaLabel || ''}
                        dataCy="molecules-channel-card-link"
                    >
                        <ImageWrapper
                            optimizedWidth={radioImageWidth ? +radioImageWidth : 104}
                            optimizedHeight={radioImageHeight ? +radioImageHeight : 50}
                            className={styles.radioImage}
                            src={radioImageUrl}
                            alt={radioImageAlt}
                        />
                        <div className={styles.radioImageBackground}></div>
                    </Link>

                    <div className={styles.radioTextInfo}>
                        <Text tag="p">{title}</Text>
                        <Text tag="p">{subTitle}</Text>
                        {showAndScheduleLink && (
                            <Link
                                href={showAndScheduleLink}
                                aria-label={showsAndScheduleLinkAriaLabel}
                                target={showAndScheduleLinkTarget}
                                dataCy="molecules-channel-card-shows-and-schedule-link"
                            >
                                {showAndScheduleLinkText}
                            </Link>
                        )}
                    </div>
                </div>

                <div className={styles.albumContainerMobile}>
                    <button onClick={handleOnClick} className={styles.button}>
                        {AlbumHtml}
                    </button>

                    <Link className={styles.colorBorder} {...listenLinkAttr} data-player-link={''}>
                        {ColorBorderHtml}
                    </Link>
                </div>

                <Link className={styles.albumContainerDesktop} {...listenLinkAttr} data-player-link={''}>
                    <div className={styles.button}>{AlbumHtml}</div>

                    <div className={styles.colorBorder}>{ColorBorderHtml}</div>
                </Link>
            </div>

            <div className={styles.shadow}>
                <div className={styles.desktopColorBorder} style={{ backgroundColor: borderColor }}></div>
            </div>
        </div>
    );
}
