import { HTMLAttributes } from 'react';
import styles from './hero.module.scss';
import { switchCase } from '@/packages/switch';
import { NflOptInForm } from '../StaticContent';
import Script from 'next/script';

interface WidgetAttribute extends HTMLAttributes<HTMLElement> {
    class: string;
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements extends HTMLElement {
            'sxm-promo-code-validation-widget': WidgetAttribute;
            'sxm-rflz-widget': WidgetAttribute;
            'sxm-flepz-widget': WidgetAttribute;
            'sxm-streaming-flepz-widget': WidgetAttribute;
            'sxm-ui-login-form': WidgetAttribute;
        }
    }
}

interface Props {
    widget: SiteAngularWidget;
}

const switchWidget = (
    tabsToShow: string[] | undefined,
    extraParams: string,
    redirectUrl: string,
    registrationUrl: string,
) =>
    switchCase<AngularWidgetType, JSX.Element>({
        Promocode: (
            <div className={styles.formContainer}>
                <sxm-promo-code-validation-widget class="widget-styles"></sxm-promo-code-validation-widget>
            </div>
        ),
        RFLZ: (
            <div className={styles.formContainer}>
                <sxm-rflz-widget class="widget-styles"></sxm-rflz-widget>
            </div>
        ),
        FLEPZ: (
            <div className={styles.formContainer}>
                <sxm-flepz-widget
                    class="widget-styles"
                    tabs-to-show={tabsToShow}
                    extra-params={extraParams}
                ></sxm-flepz-widget>
            </div>
        ),
        'Streaming Flepz': (
            <div className={styles.formContainer}>
                <sxm-streaming-flepz-widget class="widget-styles"></sxm-streaming-flepz-widget>
            </div>
        ),
        Login: (
            <div className={styles.formContainer}>
                <sxm-ui-login-form
                    redirect-uri={redirectUrl}
                    registration-uri={registrationUrl}
                    class="widget-styles"
                ></sxm-ui-login-form>
                <Script src="/ns/cms/static/global/widget/ui-nav/sxm-ui-navigation.js" />
            </div>
        ),
        'NFL RFLZ': (
            <div className={styles.formContainer}>
                <sxm-rflz-widget enable-nfl-opt-in="true" class="widget-styles"></sxm-rflz-widget>
            </div>
        ),
        'NFL FLEPZ': (
            <div className={styles.formContainer}>
                <sxm-flepz-widget
                    tabs-to-show={tabsToShow}
                    extra-params={extraParams}
                    enable-nfl-opt-in="true"
                    class="widget-styles"
                ></sxm-flepz-widget>
            </div>
        ),
        'NFL Opt-in Form': (
            <div className={`${styles.formContainer} ${styles.nflForm}`}>
                <NflOptInForm></NflOptInForm>
            </div>
        ),
    })(<></>);

export default function Widget({ widget }: Props): React.JSX.Element {
    const tabsToShow = widget.fields.tabs;
    const extraParams = `${widget.fields.extra_params}  ${widget.fields.hide_tabs_nav}`;
    const redirectUrl = `${widget.fields.supplementary_link?.fields.href || ''}/account/manage`;
    const registrationUrl = `${widget.fields.supplementary_link?.fields.href || ''}/account/registration`;
    const type = widget.fields.widget_type as AngularWidgetType;

    return switchWidget(tabsToShow, extraParams, redirectUrl, registrationUrl)(type);
}
