'use client';
import dynamic from 'next/dynamic';
import { SportStrip } from '@/components/molecules/Sports/SportStrip';
import type { Details as Team } from '@/components/molecules/Sports/SportStrip';
import dayjs from 'dayjs';
import timeZoneExtension from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import styles from './sportschedule.module.scss';
import { useMemo, useState } from 'react';
import { Event } from '@/packages/sxm/sports/events/EventsResponse';
import { DateOption, DateRange, GameList, EventProps } from './interfaces';
import filters from './league.config';
import { DynamicTableRow } from '@/components/molecules/DynamicTable/DynamicTable';
import { Link } from '@/components/atoms/Link';
import NoGames from './NoGames';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));

export default function LeagueSchedule({
    leagueAbbr,
    remind_me_logo,
    remind_me_title,
    reminder_url,
    events,
    playerUrl,
}: EventProps) {
    dayjs.extend(advancedFormat);
    dayjs.extend(timeZoneExtension);
    dayjs.extend(isBetween);

    const today = dayjs();

    const dateOptions = useMemo<DateOption[]>(() => {
        const ranges: string[] = [];
        const dateOptions: DateOption[] = [];

        events?.forEach((event: Event) => {
            if (event.eventName) {
                const filterData = filters[leagueAbbr] || filters.default;

                const eventDate = dayjs(event.gameDate);
                let start = eventDate.startOf('day');
                let end = eventDate.endOf('day');
                let label = start.isSame(today, 'day') ? start.format('[Today], MMMM D') : start.format('dddd, MMMM D');

                if (filterData.schedule === 'monthly') {
                    start = eventDate.startOf('month');
                    end = eventDate.endOf('month');
                    label = start.format('MMMM');
                } else if (filterData.schedule === 'weekly') {
                    if (filterData.weekStart) {
                        if (start.day() < filterData.weekStart) {
                            start = start.day(-(filterData.weekStart - 1));
                        } else if (start.day() > filterData.weekStart) {
                            start = start.startOf('week');
                            start = start.day(filterData.weekStart);
                        }
                    }

                    start = start.startOf('day');
                    end = start.clone().add(6, 'day').endOf('day');
                    const endLabel = start.isSame(end, 'month') ? end.format('D') : end.format('MMM D');
                    label = start.format(`MMM D [- ${endLabel}]`);
                }

                const rangeIndex = `${start}${end}`;

                if (ranges.indexOf(rangeIndex) === -1) {
                    ranges.push(rangeIndex);

                    dateOptions.push({
                        range: {
                            start: start,
                            end: end,
                        },
                        value: label,
                        label: label,
                    });
                }
            }
        });

        return dateOptions;
    }, [events, today, leagueAbbr]);

    const [dateRange, setDateRange] = useState<DateRange>(
        dateOptions[0] ? dateOptions[0].range : { start: dayjs(), end: dayjs() },
    );

    function renderChannelLink(
        deeplink: string | undefined,
        channelNumber: string | number | undefined,
        ariaLabel: string,
    ) {
        if (deeplink) {
            return (
                <Link href={deeplink} target="_blank" aria-label={ariaLabel}>
                    {channelNumber}
                </Link>
            );
        }
        return channelNumber || '-';
    }

    const [eventDates, dates] = useMemo<[GameList, string[]]>(() => {
        const eventDates: GameList = {};
        const dates: string[] = [];

        if (events) {
            for (let i = 0; i < events.length; i += 1) {
                const event = events[i] as Event;
                const index = event.gameDate || '';
                const eventDate = dayjs(event.gameDate);

                if (!eventDate.isBetween(dateRange.start, dateRange.end)) {
                    continue;
                }

                if (!eventDates[index]) {
                    const dateStr = eventDate.format('dddd, MMMM D • h:mm A z');
                    eventDates[index] = [
                        <Text
                            key={dateStr}
                            variant="small"
                            tag="h3"
                            className={styles.dateTitle}
                            data-cy="components-contextual-sports-date-title"
                        >
                            {dateStr}
                        </Text>,
                    ];
                    dates.push(index);
                }

                const leagueEvent: Team = {
                    logo: event.homeLogo,
                    pageUrl: event.homeUrl,
                    playerUrl: playerUrl,
                    name: event.eventName || '',
                    location: event.venueName || '',
                    channel: event.nationalStream || (event.nationalInternetStream ? 'ONLINE' : ''),
                };

                const moreWays: DynamicTableRow[] = [];

                if (event.nationalStream || event.nationalInternetStream) {
                    const ariaLabel = `Listen to ${event.eventName} on ${event.nationalInternetStream}`;
                    moreWays.push({
                        rowLabel: 'National',
                        items: [
                            event.nationalStream || -'',
                            renderChannelLink(event.nationalStreamLink, event.nationalInternetStream, ariaLabel),
                        ],
                    });
                }

                if (event.spanishStream || event.spanishInternetStream) {
                    const ariaLabel = `Listen to ${event.eventName} on ${event.spanishInternetStream}`;
                    moreWays.push({
                        rowLabel: 'Espanol',
                        items: [
                            event.spanishStream || '-',
                            renderChannelLink(event.spanishStreamLink, event.spanishInternetStream, ariaLabel),
                        ],
                    });
                }

                const isLive = dayjs().isBetween(event.gameDate, event.gameEndDate);
                eventDates[index].push(
                    <li key={i}>
                        <SportStrip
                            league={leagueEvent}
                            moreWays={moreWays}
                            isLive={isLive}
                            ariaLabel={`Listen to ${event.eventName} on ${event.nationalInternetStream}`}
                        />
                    </li>,
                );
            }
        }

        return [eventDates, dates];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, dateRange]);

    function dateSelectHandler(element: HTMLInputElement, { data }: { data?: DateRange }) {
        if (data) {
            setDateRange(data);
        }
    }

    if (dates.length > 0) {
        return (
            <Grid container>
                <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                    <div className={styles.filterBar}>
                        <Select<DateRange>
                            label="filter events by date"
                            type="bar"
                            theme="purplemini"
                            value={dateOptions[0].value}
                            name="dateFilter"
                            onChange={dateSelectHandler}
                        >
                            <OptionGroup>
                                {dateOptions.map((option, i) => (
                                    <Option key={i} object={option.range} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                                <Option value="" disableOption className={styles.moreGames}>
                                    More events will be added as information becomes available.
                                </Option>
                            </OptionGroup>
                        </Select>
                    </div>

                    {dates.sort().map((index) => (
                        <ul key={index} className={styles.listStylings}>
                            {eventDates[index]}
                        </ul>
                    ))}

                    <StyledContainer paddingTop={48}>
                        <NoGames
                            logo={remind_me_logo}
                            logoSize="small"
                            title={remind_me_title || 'Never Miss a Moment'}
                        >
                            <Link href={reminder_url} dataCy="contentful-event-schedule-add-calendar-link">
                                Add to calender
                            </Link>
                        </NoGames>
                    </StyledContainer>
                </Grid>
            </Grid>
        );
    }

    return <></>;
}
