'use client';
import { useContext, useLayoutEffect } from 'react';
import { ChannelGuideContext } from '../ChannelGuideContext';
import ChannelList from '../ChannelList';
import { categoryMap } from '../utils';

export default function NewsIssues() {
    const { channels, channelGuide, genre, platform } = useContext(ChannelGuideContext);

    useLayoutEffect(() => {
        if (typeof window !== undefined) {
            window.scrollTo(0, 0);
        }
    }, [genre]);

    return (
        <>
            {channels && channels[categoryMap['News & Issues']][genre] ? (
                <ChannelList
                    channels={channels[categoryMap['News & Issues']][genre]}
                    upsellLink={channelGuide.fields.upsellLink}
                    display_listen_live={channelGuide.fields.display_listen_live}
                    defaultChannelImage={channelGuide?.fields?.channelDefaultImage}
                    platform={platform}
                />
            ) : null}
        </>
    );
}
