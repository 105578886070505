'use client';

import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useMediaQuery, useRegisterServiceWorker } from '@/hooks';
import { responsiveBreakpointsPx } from '@/constants';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import globalProperties from '@/../properties/global';

export default function ClientPageHead(): JSX.Element {
    const viewportIsDesktop = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const [snapshotLoaded, setSnapshotLoaded] = useState(false);
    const [ddLoaded, setDdLoaded] = useState(false);
    const searchParams = useSearchParams();
    const pathname = usePathname();
    const dymamicLoadScripts: string[] = [];
    if (!globalProperties.envName.startsWith('CORP-')) {
        dymamicLoadScripts.push(`/ns/cms/static/global/widget/identification/de-care-elements.js`);
    }

    useRegisterServiceWorker('/service-worker.js');

    useEffect(() => {
        window.dispatchEvent(new Event('hydrate'));
    }, []);

    useEffect(() => {
        if (!snapshotLoaded) {
            const navInterval = setInterval(() => {
                const widget = document.querySelector('sxm-ui-nav-account-presence-icons');
                if (widget) {
                    if (viewportIsDesktop) {
                        const desktopNav = document.querySelector('.snapshot-widget-li');
                        if (desktopNav && !desktopNav.querySelector('sxm-ui-nav-account-presence-icons')) {
                            desktopNav.appendChild(widget);
                        }
                    }
                    setSnapshotLoaded(true);
                    clearInterval(navInterval);
                }
            }, 100);
        }
    }, [viewportIsDesktop, snapshotLoaded]);

    useEffect(() => {
        const paramStr = searchParams.toString();
        if (paramStr) {
            window.sessionStorage.setItem('paramStr', paramStr);
        }
    }, [searchParams]);

    useEffect(() => {
        const browserWindow = window as BrowserWindow;

        if (browserWindow.DD_RUM) {
            browserWindow.DD_RUM.init({
                clientToken: 'pub2efebd86de973d3161e20023ac07e68a',
                applicationId: 'c27edd08-fd30-4d05-ace8-21ad46980f08',
                site: 'datadoghq.com',
                service: 'sxm-cecm-web-www',
                env: globalProperties.ddenv,
                version: process.env.NEXT_PUBLIC_DD_VERSION || '1.0.0',
                sessionSampleRate: 45,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
            });

            browserWindow.DD_RUM.startSessionReplayRecording();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ddLoaded]);

    // Unique case to reload the nav script on subsequent page renders
    // Currently only be used for nav widget, may not be needed after
    // nav is migrated to Contentful
    useEffect(() => {
        const scripts: HTMLScriptElement[] = [];

        dymamicLoadScripts.forEach((scriptUrl) => {
            const script = document.createElement('script');
            script.src = scriptUrl;
            script.async = true;
            script.defer = true;
            scripts.push(script);
            document.body.appendChild(script);
        });

        return () => {
            scripts.forEach((script) => {
                script?.parentNode?.removeChild(script);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            <Script
                src="https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js"
                onLoad={() => {
                    setDdLoaded(true);
                }}
            />

            <Script
                src={globalProperties.adobe.launchScript}
                async
                onLoad={() => {
                    const script = document.createElement('script');
                    script.setAttribute('src', '/resources/js/analytics.js');
                    document.body.appendChild(script);
                }}
            />

            {process.env.NODE_ENV !== 'development' && (
                <Script
                    src={globalProperties.onetrust.script}
                    async
                    defer
                    rel="prefetch"
                    data-domain-script={globalProperties.onetrust.domainScript}
                    onLoad={() => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        function OptanonWrapper() {}
                    }}
                />
            )}
        </>
    );
}
