import React from 'react';
import styles from './simpleoffer.module.scss';
import cn from 'classnames';
import { PRICING } from '@/constants/pulse/pricing';
import WhiteArrowLarge from '@/assets/icons/icon-arrow-white-large.svg';
import { getCohortFromSlug } from '../GenerateOffer';
import Link from '@/components/atoms/Link/Link';
import { ReadonlyURLSearchParams } from 'next/navigation';
import { buildAppLinkUrlAllParams } from '@/packages/pulse';

interface OfferDetailsProps {
    offerData: {
        url: string;
        tier: string;
        tagline: string;
        offer: string;
        terms: string;
    };
    isConversion: boolean;
    pageSlug?: string;
    searchParams: ReadonlyURLSearchParams;
}

const OfferDetails: React.FC<OfferDetailsProps> = React.memo(({ 
    offerData, 
    isConversion, 
    pageSlug, 
    searchParams 
}) => {
    const cohort = getCohortFromSlug(pageSlug || '');
    const { url, tier, tagline, offer, terms } = offerData;
    const { cohortGeneral, see, offerDetails, below, arrowAltText } = PRICING;
    const appLinkHref = buildAppLinkUrlAllParams(url, searchParams);

    return (
        <Link 
            href={appLinkHref}
            className={styles.link} 
            inheritParams={true}>

                <div className={styles.cta}>
                    <div
                        className={cn(styles.info, {
                            [styles.conversion]: isConversion,
                            [styles.general]: cohort === cohortGeneral
                        })}
                    >
                        <h5 className={styles.h5}>{tier}</h5>
                        <h4 className={styles.h4}>{tagline}</h4>
                        <h2 className={styles.h2}>{offer}</h2>
                        <p className={styles.p}>
                            {terms} {see} <b>{offerDetails}</b> {below}
                        </p>
                    </div>
                    <div className={styles.play}>
                        <div className={styles.arrow}>
                            <WhiteArrowLarge className={styles.arrow} alt={arrowAltText} />
                        </div>
                    </div>
                </div>
        </Link>
    );
});

OfferDetails.displayName = "Pulse Offer Details";

export default OfferDetails;
