'use client';
import LinkReactLib from 'de-sxm-reactlib/dist/components/atoms/Link/Link';
import { type LinkProps } from 'de-sxm-reactlib/dist/components/atoms/Link/Link';
import useInheritParams from '@/hooks/useInheritParams';
import globalProperties from '../../../../properties/global';
import { useEffect, useState } from 'react';
import { Ref, forwardRef } from 'react';

type Props = LinkProps & {
    children?: React.ReactNode;
    inheritParams?: boolean;
    dataCy?: string;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = forwardRef(function (props: Props, ref: Ref<HTMLElement>) {
    const { inheritParams, ...linkProps } = props;
    const [newProps, setNewProps] = useState<LinkProps>(linkProps);
    const href = useInheritParams(props.href || '#', globalProperties.urlInheritParams);
    const isPlayer = /smart\.link/gi.test(props.href || '');
    const extraProps = {
        ...(isPlayer && { is: 'sxm-player-link' }),
    };

    useEffect(() => {
        if (isPlayer) {
            setNewProps({
                ...props,
                target: '_blank',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { inheritParams, ...linkProps } = props;
        if (inheritParams) {
            setNewProps(linkProps);
        }
    }, [href, props]);

    if (!inheritParams) {
        return <LinkReactLib {...linkProps} ref={ref as Ref<HTMLAnchorElement>} />;
    }

    if (!newProps) {
        return <></>;
    }

    return <LinkReactLib {...newProps} {...extraProps} ref={ref as Ref<HTMLAnchorElement>} />;
});

Link.displayName = 'Link';
export default Link;
