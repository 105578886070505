import type { Item, DataGroups } from '@/components/molecules/FilterTable/FilterTable';

export const metroAreaGroups: DataGroups = {
    'United States (Metro Areas A-D)': [
        'Akron',
        'Albany-Schenectady-Troy',
        'Albuquerque',
        'Allentown-Bethlehem-Easton',
        'Ann Arbor',
        'Atlanta-Sandy Springs-Marietta',
        'Austin-Round Rock',
        'Baltimore-Towson',
        'Baton Rouge',
        'Beaumont',
        'Birmingham-Hoover',
        'Boston-Cambridge-Quincy',
        'Bridgeport-Stamford-Norwalk',
        'Brigham City',
        'Buffalo-Niagara Falls',
        'Cape Coral-Fort Myers',
        'Charlotte-Gastonia-Concord',
        'Chattanooga',
        'Chicago-Naperville-Joliet',
        'Cincinnati-Middletown',
        'Cleveland-Elyria-Mentor',
        'Colorado Springs',
        'Columbia',
        'Columbus',
        'Dallas-Fort Worth-Arlington',
        'Dayton',
        'Deltona-Daytona Beach-Ormond Beach',
        'Denver-Aurora',
        'Des Moines',
        'Detroit-Warren-Livonia',
        'Durham',
    ],
    'United States (Metro Areas E-L)': [
        'El Paso',
        'Ellensburg',
        'Flint',
        'Fresno',
        'Grand Rapids-Wyoming',
        'Greensboro-High Point',
        'Greenville-Mauldin-Easley',
        'Hammond',
        'Harrisburg-Carlisle',
        'Hartford-West Hartford-East Hartford',
        'Hickory-Lenoir-Morganton',
        'Houston-Sugar Land-Baytown',
        'Indianapolis-Carmel',
        'Jacksonville',
        'Kansas City',
        'Knoxville',
        'Lakeland',
        'Lancaster',
        'Lansing-East Lansing',
        'Las Vegas-Paradise',
        'Lincoln',
        'Little Rock-North Little Rock-Conway',
        'Los Angeles-Long Beach-Santa Ana',
        'Louisville-Jefferson County',
    ],
    'United States (Metro Areas M-P)': [
        'Macon',
        'Manchester-Nashua',
        'Memphis',
        'Merced',
        'Miami-Fort Lauderdale-Pompano Beach',
        'Milwaukee-Waukesha-West Allis',
        'Minneapolis-St. Paul-Bloomington',
        'Mobile',
        'Modesto',
        'Mount Airy',
        'Napa',
        'Naples-Marco Island',
        'Nashville-Davidson-Murfreesboro-Franklin',
        'New Haven-Milford',
        'New Orleans-Metairie-Kenner',
        'New York/N. New Jersey/Long Island',
        'Norwich-New London',
        'Ogden-Clearfield',
        'Oklahoma City',
        'Olympia',
        'Omaha-Council Bluffs',
        'Orlando-Kissimmee',
        'Oxnard-Thousand Oaks-Ventura',
        'Palm Bay-Melbourne-Titusville',
        'Pensacola-Ferry Pass-Brent',
        'Philadelphia-Camden-Wilmington',
        'Phoenix-Mesa-Scottsdale',
        'Pittsburgh',
        'Port St. Lucie',
        'Portland-Vancouver-Beaverton',
        'Poughkeepsie-Newburgh-Middletown',
        'Providence-New Bedford-Fall River',
        'Provo-Orem',
        'Punta Gorda',
    ],
    'United States (Metro Areas R-S)': [
        'Raleigh-Cary',
        'Richmond',
        'Riverside-San Bernardino-Ontario',
        'Rochester',
        'Sacramento-Arden-Arcade-Roseville',
        'Salem',
        'Salinas',
        'Salt Lake City',
        'San Antonio',
        'San Diego-Carlsbad-San Marcos',
        'San Francisco-Oakland-Fremont',
        'San Jose-Sunnyvale-Santa Clara',
        'Santa Barbara-Santa Maria-Goleta',
        'Santa Cruz-Watsonville',
        'Santa Rosa-Petaluma',
        'Sarasota-Bradenton-Venice',
        'Savannah',
        'Scranton-Wilkes-Barre',
        'Seattle-Tacoma-Bellevue',
        'Sioux Falls',
        'Spartanburg',
        'Spokane',
        'Springfield',
        'St. Louis',
        'Statesville-Mooresville',
        'Stockton',
        'Syracuse',
    ],
    'United States (Metro Areas T-Y)': [
        'Tampa-St. Petersburg-Clearwater',
        'Toledo',
        'Trenton-Ewing',
        'Tucson',
        'Tulsa',
        'Tuscaloosa',
        'Vallejo-Fairfield',
        'Virginia Beach-Norfolk-Newport News',
        'Washington-Arlington-Alexandria',
        'Winston-Salem',
        'Worcester',
        'York-Hanover',
        'Youngstown-Warren-Boardman',
    ],
    'Canada (All Metro Areas)': [
        'Burnaby',
        'Calgary',
        'Edmonton',
        'Gatineau',
        'Hamilton-Burlington',
        'Kitchener-Cambridge-Waterloo-Guelph',
        'Laval',
        'London',
        'Mississauga',
        'Montreal',
        'Ottawa',
        'Quebec City',
        'St Catherines-Niagara Falls',
        'Surrey',
        'Toronto',
        'Vancouver',
        'Windsor-Lakeshore',
    ],
};

export const metroAreas: Item[] = [
    { name: 'Akron', details: 'OH' },
    { name: 'Albany-Schenectady-Troy', details: 'NY' },
    { name: 'Albuquerque', details: 'NM' },
    { name: 'Allentown-Bethlehem-Easton', details: 'PA-NJ' },
    { name: 'Ann Arbor', details: 'MI' },
    { name: 'Atlanta-Sandy Springs-Marietta', details: 'GA' },
    { name: 'Austin-Round Rock', details: 'TX' },
    { name: 'Baltimore-Towson', details: 'MD' },
    { name: 'Baton Rouge', details: 'LA' },
    { name: 'Beaumont', details: 'TX' },
    { name: 'Birmingham-Hoover', details: 'AL' },
    { name: 'Boston-Cambridge-Quincy', details: 'MA-NH' },
    { name: 'Bridgeport-Stamford-Norwalk', details: 'CT' },
    { name: 'Brigham City', details: 'UT' },
    { name: 'Buffalo-Niagara Falls', details: 'NY' },
    { name: 'Cape Coral-Fort Myers', details: 'FL' },
    { name: 'Charlotte-Gastonia-Concord', details: 'NC-SC' },
    { name: 'Chattanooga', details: 'TN-GA' },
    { name: 'Chicago-Naperville-Joliet', details: 'IL-IN-WI' },
    { name: 'Cincinnati-Middletown', details: 'OH-KY-IN' },
    { name: 'Cleveland-Elyria-Mentor', details: 'OH' },
    { name: 'Colorado Springs', details: 'CO' },
    { name: 'Columbia', details: 'SC' },
    { name: 'Columbus', details: 'OH' },
    { name: 'Dallas-Fort Worth-Arlington', details: 'TX' },
    { name: 'Dayton', details: 'OH' },
    { name: 'Deltona-Daytona Beach-Ormond Beach', details: 'FL' },
    { name: 'Denver-Aurora', details: 'CO' },
    { name: 'Des Moines', details: 'IA' },
    { name: 'Detroit-Warren-Livonia', details: 'MI' },
    { name: 'Durham', details: 'NC' },
    { name: 'El Paso', details: 'TX' },
    { name: 'Ellensburg', details: 'WA' },
    { name: 'Flint', details: 'MI' },
    { name: 'Fresno', details: 'CA' },
    { name: 'Grand Rapids-Wyoming', details: 'MI' },
    { name: 'Greensboro-High Point', details: 'NC' },
    { name: 'Greenville-Mauldin-Easley', details: 'SC' },
    { name: 'Hammond', details: 'LA' },
    { name: 'Harrisburg-Carlisle', details: 'PA' },
    { name: 'Hartford-West Hartford-East Hartford', details: 'CT' },
    { name: 'Hickory-Lenoir-Morganton', details: 'NC' },
    { name: 'Houston-Sugar Land-Baytown', details: 'TX' },
    { name: 'Indianapolis-Carmel', details: 'IN' },
    { name: 'Jacksonville', details: 'FL' },
    { name: 'Kansas City', details: 'MO-KS' },
    { name: 'Knoxville', details: 'TN' },
    { name: 'Lakeland', details: 'FL' },
    { name: 'Lancaster', details: 'PA' },
    { name: 'Lansing-East Lansing', details: 'MI' },
    { name: 'Las Vegas-Paradise', details: 'NV' },
    { name: 'Lincoln', details: 'NE' },
    { name: 'Little Rock-North Little Rock-Conway', details: 'AR' },
    { name: 'Los Angeles-Long Beach-Santa Ana', details: 'CA' },
    { name: 'Louisville-Jefferson County', details: 'KY-IN' },
    { name: 'Macon', details: 'GA' },
    { name: 'Manchester-Nashua', details: 'NH' },
    { name: 'Memphis', details: 'TN-MS-AR' },
    { name: 'Merced', details: 'CA' },
    { name: 'Miami-Fort Lauderdale-Pompano Beach', details: 'FL' },
    { name: 'Milwaukee-Waukesha-West Allis', details: 'WI' },
    { name: 'Minneapolis-St. Paul-Bloomington', details: 'MN-WI' },
    { name: 'Mobile', details: 'AL' },
    { name: 'Modesto', details: 'CA' },
    { name: 'Mount Airy', details: 'NC' },
    { name: 'Napa', details: 'CA' },
    { name: 'Naples-Marco Island', details: 'FL' },
    { name: 'Nashville-Davidson-Murfreesboro-Franklin', details: 'TN' },
    { name: 'New Haven-Milford', details: 'CT' },
    { name: 'New Orleans-Metairie-Kenner', details: 'LA' },
    { name: 'New York/N. New Jersey/Long Island', details: 'NY-NJ' },
    { name: 'Norwich-New London', details: 'CT' },
    { name: 'Ogden-Clearfield', details: 'UT' },
    { name: 'Oklahoma City', details: 'OK' },
    { name: 'Olympia', details: 'WA' },
    { name: 'Omaha-Council Bluffs', details: 'NE-IA' },
    { name: 'Orlando-Kissimmee', details: 'FL' },
    { name: 'Oxnard-Thousand Oaks-Ventura', details: 'CA' },
    { name: 'Palm Bay-Melbourne-Titusville', details: 'FL' },
    { name: 'Pensacola-Ferry Pass-Brent', details: 'FL' },
    { name: 'Philadelphia-Camden-Wilmington', details: 'PA-NJ-DE-MD' },
    { name: 'Phoenix-Mesa-Scottsdale', details: 'AZ' },
    { name: 'Pittsburgh', details: 'PA' },
    { name: 'Port St. Lucie', details: 'FL' },
    { name: 'Portland-Vancouver-Beaverton', details: 'OR-WA' },
    { name: 'Poughkeepsie-Newburgh-Middletown', details: 'NY' },
    { name: 'Providence-New Bedford-Fall River', details: 'RI-MA' },
    { name: 'Provo-Orem', details: 'UT' },
    { name: 'Punta Gorda', details: 'FL' },
    { name: 'Raleigh-Cary', details: 'NC' },
    { name: 'Richmond', details: 'VA' },
    { name: 'Riverside-San Bernardino-Ontario', details: 'CA' },
    { name: 'Rochester', details: 'NY' },
    { name: 'Sacramento-Arden-Arcade-Roseville', details: 'CA' },
    { name: 'Salem', details: 'OR' },
    { name: 'Salinas', details: 'CA' },
    { name: 'Salt Lake City', details: 'UT' },
    { name: 'San Antonio', details: 'TX' },
    { name: 'San Diego-Carlsbad-San Marcos', details: 'CA' },
    { name: 'San Francisco-Oakland-Fremont', details: 'CA' },
    { name: 'San Jose-Sunnyvale-Santa Clara', details: 'CA' },
    { name: 'Santa Barbara-Santa Maria-Goleta', details: 'CA' },
    { name: 'Santa Cruz-Watsonville', details: 'CA' },
    { name: 'Santa Rosa-Petaluma', details: 'CA' },
    { name: 'Sarasota-Bradenton-Venice', details: 'FL' },
    { name: 'Savannah', details: 'GA' },
    { name: 'Scranton-Wilkes-Barre', details: 'PA' },
    { name: 'Seattle-Tacoma-Bellevue', details: 'WA' },
    { name: 'Sioux Falls', details: 'SD' },
    { name: 'Spartanburg', details: 'SC' },
    { name: 'Spokane', details: 'WA' },
    { name: 'Springfield', details: 'MA' },
    { name: 'St. Louis', details: 'MO-IL' },
    { name: 'Statesville-Mooresville', details: 'NC' },
    { name: 'Stockton', details: 'CA' },
    { name: 'Syracuse', details: 'NY' },
    { name: 'Tampa-St. Petersburg-Clearwater', details: 'FL' },
    { name: 'Toledo', details: 'OH' },
    { name: 'Trenton-Ewing', details: 'NJ' },
    { name: 'Tucson', details: 'AZ' },
    { name: 'Tulsa', details: 'OK' },
    { name: 'Tuscaloosa', details: 'AL' },
    { name: 'Vallejo-Fairfield', details: 'CA' },
    { name: 'Virginia Beach-Norfolk-Newport News', details: 'VA-NC' },
    { name: 'Washington-Arlington-Alexandria', details: 'DC-VA-MD-WV' },
    { name: 'Winston-Salem', details: 'NC' },
    { name: 'Worcester', details: 'MA' },
    { name: 'York-Hanover', details: 'PA' },
    { name: 'Youngstown-Warren-Boardman', details: 'OH-PA' },
    { name: 'Burnaby', details: 'BC, Canada' },
    { name: 'Calgary', details: 'AB, Canada' },
    { name: 'Edmonton', details: 'AB, Canada' },
    { name: 'Gatineau', details: 'QC, Canada' },
    { name: 'Hamilton-Burlington', details: 'ON, Canada' },
    { name: 'Kitchener-Cambridge-Waterloo-Guelph', details: 'ON, Canada' },
    { name: 'Laval', details: 'QC, Canada' },
    { name: 'London', details: 'ON, Canada' },
    { name: 'Mississauga', details: 'ON, Canada' },
    { name: 'Montreal', details: 'QC, Canada' },
    { name: 'Ottawa', details: 'ON, Canada' },
    { name: 'Quebec City', details: 'QC, Canada' },
    { name: 'St Catherines-Niagara Falls', details: 'ON, Canada' },
    { name: 'Surrey', details: 'BC, Canada' },
    { name: 'Toronto', details: 'ON, Canada' },
    { name: 'Vancouver', details: 'BC, Canada' },
    { name: 'Windsor-Lakeshore', details: 'ON, Canada' },
];
