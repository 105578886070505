'use client';
import React from 'react';
import cn from 'classnames';
import { useSearchParams } from 'next/navigation';
import dynamic from 'next/dynamic';
import styles from './listennowgeneral.module.scss';
import { PULSE } from '@/constants/pulse/pulse';
import BlackArrow from '@/assets/icons/icon-black-arrow.svg';
import BlueArrow from '@/assets/icons/icon-blue-arrow.svg';
import CarDashboard from '@/assets/icons/icon-car-dash.svg';
import Mobile from '@/assets/icons/icon-mobile-outline.svg';
import { getPulseAnalyticsAttributes, buildAppLinkUrlAllParams } from '@/packages/pulse';
import { showConversionView } from '../StaticContent/pulse/Pricing/GenerateOffer';
import Link from '@/components/atoms/Link/Link';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
const PrimaryCallToAction = dynamic(() => import('../ListenNow/PrimaryCallToAction'));

interface ListenNowGeneralProps {
    index?: number;
    asset: SiteAssetGroup;
}

const ListenNowGeneral = ({ asset }: ListenNowGeneralProps) => {
    const listenNowGeneralContent = asset.fields.assets && asset.fields.assets[0];
    const backgroundImageUrl = listenNowGeneralContent?.fields.backgroundImage.url;
    const { href, link_target, link_text, analytics_tags, inheritUrlParameters } =
        listenNowGeneralContent?.fields.moreLink.fields;
    const {
        blueArrowAltText,
        blackArrowAltText,
        carDashboardAltText,
        appLinkUrl,
        appLinkText,
        appTitle,
        appText,
        mobileAltText,
        backgroundAltText,
        carTitle,
        carText,
    } = PULSE;

    const analyticsAttributes = getPulseAnalyticsAttributes(analytics_tags as AnalyticsTags[]);
    const searchParams = useSearchParams();
    const showConversion = showConversionView(searchParams);
    const appLinkHref = buildAppLinkUrlAllParams(appLinkUrl, searchParams);

    return (
        <>
            <div className={cn(styles.listen_now, { [styles.conversion]: showConversion })}>
                {/* Hide on mobile conversion; show on mobile & desktop discovery */}
                <div className={styles.stella_cta_wrapper}>
                    <div className={styles.stella_wrapper}>
                        <ImageWrapper
                            src="/content/dam/sxm-com/pulse/stella-alert.png"
                            alt="Stella Alert"
                            optimizedHeight={100}
                            optimizedWidth={100}
                            loadingStrategy="eager"
                            skipOptimization={true}
                        />
                    </div>
                    <div className={styles.main_cta_wrapper}>
                        <PrimaryCallToAction showConversion={showConversion} />
                    </div>
                </div>
                {/* Mobile Conversion CTA */}
                <div className={styles.mobile_cta_wrapper}>
                    <PrimaryCallToAction showConversion={showConversion} />
                </div>
                <div className={styles.main_content_wrapper}>
                    <div className={styles.listen_now_cohort_content_container}>
                        <div className={styles.content_layout_mobile}>
                            <div className={styles.listen_now_cohort_content}>
                                <div className={styles.listen_now_cohort_content_title}>
                                    {listenNowGeneralContent?.fields.title}
                                </div>
                                <div className={styles.listen_now_cohort_content_text}>
                                    {listenNowGeneralContent?.fields.description}
                                </div>
                            </div>
                        </div>
                        <div className={styles.listen_now_mobile_cohort_background}>
                            <div className={styles.listen_now_cohort_background}>
                                <ImageWrapper
                                    src={backgroundImageUrl}
                                    alt={backgroundAltText}
                                    className={styles.listen_now_cohort_background_image}
                                    optimizedWidth={1664}
                                    optimizedHeight={450}
                                    loadingStrategy="lazy"
                                />
                            </div>
                            <div className={styles.listen_now_cohort_background_overlay}>
                                <div className={styles.white_box}></div>
                                <div className={styles.color_box_mobile}>
                                    <div className={styles.x}></div>
                                    <div className={styles.y}></div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.content_layout_mobile}>
                            <div className={styles.listen_now_cohort_content}>
                                <div className={styles.listen_now_cohort_content_subtext}>
                                    {listenNowGeneralContent?.fields.additional_description}
                                </div>
                            </div>
                        </div>
                        {/* Mobile Channel Button */}
                        <div className={styles.cohort_channel_button_mobile}>
                            <Link
                                className={`${styles.view_more_link} ${styles.appLinkHref}`}
                                href={href}
                                inheritParams={inheritUrlParameters}
                                target={link_target}
                                {...analyticsAttributes}
                            >
                                <div className={styles.cohort_channel_button_text}>{link_text}</div>
                                <BlackArrow className={styles.arrow_image} alt={blackArrowAltText} />
                            </Link>
                        </div>
                        <div className={styles.content_layout_desktop}>
                            <div className={styles.listen_now_cohort_content}>
                                <div className={styles.listen_now_cohort_content_title}>
                                    {listenNowGeneralContent?.fields.title}
                                </div>
                                <div className={styles.listen_now_cohort_content_text}>
                                    {listenNowGeneralContent?.fields.description}
                                </div>
                                <div className={styles.listen_now_cohort_content_subtext}>
                                    {listenNowGeneralContent?.fields.additional_description}
                                </div>
                            </div>
                        </div>
                        <div className={styles.cohort_channel_reference}>
                            {/* Desktop Channel Button */}
                            <Link
                                className={`${styles.view_more_link} ${styles.appLinkHref}`}
                                href={href}
                                inheritParams={inheritUrlParameters}
                                target={link_target}
                                {...analyticsAttributes}
                            >
                                <div className={styles.cohort_channel_button}>
                                    <div className={styles.cohort_channel_button_text}>{link_text}</div>
                                    <BlackArrow className={styles.arrow_image} alt={blackArrowAltText} />
                                </div>
                            </Link>
                            <div className={styles.color_box}>
                                <div className={styles.black_box}></div>
                                <div className={styles.orange_box}></div>
                            </div>
                        </div>
                    </div>
                    <div className={cn(styles.listen_now_wrapper, { [styles.hidden]: showConversion })}>
                        <div className={styles.listen_now_container}>
                            <div className={styles.car}>
                                <div className={styles.text_wrap_mobile}>
                                    <div className={styles.list_title}>{carTitle}</div>
                                    <div className={styles.list_text}>{carText}</div>
                                </div>
                                <div className={styles.image_text_wrapper}>
                                    <div className={styles.car_image_wrapper}>
                                        <CarDashboard className={styles.car_image} alt={carDashboardAltText} />
                                    </div>
                                    <div className={styles.list_text_desktop}>{carText}</div>
                                </div>
                            </div>
                            <div className={styles.app}>
                                <div className={styles.app_block}>
                                    <div className={styles.text_wrap_mobile}>
                                        <div className={styles.list_title}>{appTitle}</div>
                                        <div className={styles.list_text}>{appText}</div>
                                        <div className={styles.link_mobile}>
                                            <Link
                                                className={styles.appLinkHref}
                                                href={appLinkHref}
                                                inheritParams={true}
                                                data-component="listenNow"
                                                data-category="general"
                                                data-subcategory="general"
                                            >
                                                <div>{appLinkText}</div>
                                                <BlueArrow className={styles.arrow_image} alt={blueArrowAltText} />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={styles.image_text_wrapper}>
                                        <div className={styles.mobile_image_wrapper}>
                                            <Mobile className={styles.mobile_image} alt={mobileAltText} />
                                        </div>
                                        <div className={styles.desktop_image_wrapper}>
                                            <ImageWrapper
                                                src="/resources/images/mobile-icon-small.svg"
                                                alt={mobileAltText}
                                                className={styles.mobile_image}
                                                optimizedWidth={106}
                                                optimizedHeight={60}
                                            />
                                        </div>
                                        <div className={styles.list_text_desktop}>{appText}</div>
                                    </div>
                                </div>
                                <div className={styles.link}>
                                    <Link
                                        className={styles.appLinkHref}
                                        href={appLinkHref}
                                        data-category="general"
                                        data-subcategory="general"
                                        data-component="listenNow"
                                    >
                                        <div>{appLinkText}</div>
                                        <BlueArrow className={styles.arrow_image} alt={blueArrowAltText} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.lower_margin}>
                    <div className={styles.lower_margin_blue} />
                </div>
            </div>
        </>
    );
};

export default ListenNowGeneral;
