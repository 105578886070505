'use client';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import timeZoneExtension from 'dayjs/plugin/timezone';

const useCallCenter = (): boolean => {
    const isDaylightSavingsTime = () => {
        const today = new Date();
        const january = new Date(today.getFullYear(), 0, 1);
        const july = new Date(today.getFullYear(), 6, 1);

        const stdTimezoneOffset = Math.max(january.getTimezoneOffset(), july.getTimezoneOffset());
        return today.getTimezoneOffset() < stdTimezoneOffset;
    };

    dayjs.extend(utc);
    dayjs.extend(timeZoneExtension);
    dayjs.extend(weekday);
    dayjs.extend(isBetween);

    const utcOffsetValue = isDaylightSavingsTime() ? -240 : -300;
    const now = dayjs().tz('America/New_York');
    const start = dayjs().utcOffset(utcOffsetValue).hour(8).minute(0).second(0);
    const end = dayjs().utcOffset(utcOffsetValue).hour(20).minute(0).second(0);

    return now.isBetween(start, end);
};

export default useCallCenter;
