import { responsiveBreakpointsPx } from '@/constants';

interface ContentSliderOptionsProps {
    desktopPadding: number;
    mobilePadding?: number;
    perPageDesktop: number;
    perPageLgBreakpoint: number;
    arrowsDesktop: boolean;
}

export function contentCardSliderOptions({
    desktopPadding,
    mobilePadding = 100,
    perPageDesktop,
    perPageLgBreakpoint,
    arrowsDesktop = false,
}: ContentSliderOptionsProps) {
    return {
        rewind: false,
        gap: '16px',
        pagination: false,
        arrows: arrowsDesktop,
        drag: true,
        focusableNodes: 'button, textarea, input, select, iframe',
        perPage: perPageDesktop,
        perMove: 1,
        padding: `${desktopPadding}px`,
        type: 'slide',
        breakpoints: {
            [responsiveBreakpointsPx.lg]: {
                gap: '16px',
                pagination: false,
                perPage: perPageLgBreakpoint,
                perMove: 1,
                arrows: false,
                drag: true,
                padding: `${mobilePadding}px`,
                type: 'slide',
            },
            [responsiveBreakpointsPx.md]: {
                gap: '16px',
                pagination: false,
                perMove: 1,
                perPage: 1,
                arrows: false,
                padding: `${mobilePadding}px`,
                drag: true,
                type: 'slide',
            },
        },
    };
}

export const StandardMoleculeSliderOptions = {
    rewind: false,
    gap: '16px',
    pagination: false,
    arrows: false,
    drag: false,
    focusableNodes: 'button, textarea, input, select, iframe',
    perPage: 3,
    breakpoints: {
        [responsiveBreakpointsPx.lg]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 2,
            arrows: false,
            drag: true,
            padding: {
                left: '16px',
                right: '24px',
                toop: '24px',
                bottom: '24px',
            },
            type: 'slide',
        },
        [responsiveBreakpointsPx.md]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 1,
            arrows: false,
            padding: {
                left: '16px',
                right: '24px',
                toop: '24px',
                bottom: '24px',
            },
            drag: true,
            type: 'slide',
        },
    },
    lazyLoad: true,
};

export const EntityTileGridSliderOptions = {
    rewind: false,
    gap: '16px',
    pagination: false,
    arrows: false,
    drag: false,
    focusableNodes: 'button, textarea, input, select, iframe',
    perPage: 3,
    breakpoints: {
        // We subtract 1 from these desktop-first breakpoints so the mobile-first SCSS breaks on exactly the same width:
        [responsiveBreakpointsPx.lg - 1]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 2,
            arrows: false,
            drag: true,
            padding: {
                left: '24px',
                right: '24px',
            },
            type: 'slide',
        },
        [responsiveBreakpointsPx.md - 1]: {
            gap: '16px',
            pagination: false,
            perMove: 1,
            perPage: 1,
            arrows: false,
            padding: {
                left: '16px',
                right: '24px',
            },
            drag: true,
            type: 'slide',
        },
    },
    lazyLoad: true,
};
