'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { SportsCard } from '@/components/molecules/SportsCard';
import { Slider } from '@/components/molecules/Slider';
import { Game } from '@/packages/sxm/sports/games/GamesResponse';
import { Race } from '@/packages/sxm/sports/races';
import { Event } from '@/packages/sxm/sports/events';
import styles from './upcomingsports.module.scss';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import { LeagueGameEntry } from '@/services/contentful/contentTypes/UpcomingSports';
import { SportsSliderOptions } from '@/components/molecules/Slider/SliderOptions';

type GameRaceEvent = Game | Race | Event;

interface Slide {
    content: React.ReactNode;
    link?: Link;
}

interface Props {
    asset: SiteUpcomingSports;
}

export default function UpcomingSports({ asset }: Props): JSX.Element {
    const teamData = asset.fields.league_games.team;
    const nonTeamData = asset.fields.league_games.nonTeam;
    const teams = asset.fields.teams;
    const leagues = asset.fields.leagues;
    const renderSportsCards = (data: LeagueGameEntry[]) => {
        return data.flatMap((leagueEntry) => {
            const league = leagueEntry.league;
            const games = leagueEntry.games;

            return games
                .map((game: GameRaceEvent, index: number) => {
                    if ('awayTeam' in game && 'homeTeam' in game) {
                        const leagueData = leagues[game.homeLeagueAbbreviation ?? ''];
                        const leagueTitle = leagueData.isSoccer ? 'Soccer' : leagueData.title ?? '';

                        const awayTeamData = teams[leagueData.abbreviation + (game.awayTeamAbbreviation ?? '')];
                        const homeTeamData = teams[leagueData.abbreviation + (game.homeTeamAbbreviation ?? '')];

                        const awayTeamName = awayTeamData?.teamName;
                        const homeTeamName = homeTeamData?.teamName;

                        const awayTeamCity = awayTeamData?.teamCity;
                        const homeTeamCity = homeTeamData?.teamCity;

                        const awayLogoUrl = awayTeamData?.logo?.url ?? '';
                        const homeLogoUrl = homeTeamData?.logo?.url ?? '';
                        const awayTeamPageUrl = awayTeamData?.pageUrl ? awayTeamData?.pageUrl : '#';
                        const homeTeamPageUrl = homeTeamData?.pageUrl ? homeTeamData?.pageUrl : '#';
                        const leaguePageUrl = leagueData?.leaguePageUrl ?? '#';

                        const ctaBtn = (
                            <Button
                                text={`${leagueTitle} schedule`}
                                type="button"
                                theme="secondary"
                                targetBlank={false}
                                href={leaguePageUrl}
                            />
                        );

                        // determine which game channel number will be displayed
                        let awayChannelNumber;
                        let awayOnline = false;
                        let homeChannelNumber;
                        let homeOnline = false;
                        if (game.awayStream) {
                            awayChannelNumber = game.awayStream;
                        } else if (game.nationalStream) {
                            awayChannelNumber = game.nationalStream;
                        } else if (game.awayInternetStream || game.nationalInternetStream) {
                            awayOnline = true;
                        }
                        if (game.homeStream) {
                            homeChannelNumber = game.homeStream;
                        } else if (game.nationalStream) {
                            homeChannelNumber = game.nationalStream;
                        } else if (game.homeInternetStream || game.nationalInternetStream) {
                            homeOnline = true;
                        }

                        const sportsCardBlock = (
                            <div key={`${league}-${index}`} className={styles.sportsCard}>
                                <Text tag="span" variant="eyebrowSm" className={cn(styles.eyebrow, styles.noTransform)}>
                                    {leagueTitle}
                                </Text>
                                <SportsCard
                                    startTime={game.gameDate ? game.gameDate : ''}
                                    endTime={game.endDateTime ? game.endDateTime : ''}
                                    matchup={[
                                        {
                                            name: awayTeamName ?? '',
                                            city: awayTeamCity ?? '',
                                            channel: awayChannelNumber?.toString(),
                                            isOnline: awayOnline,
                                            link: {
                                                href: awayTeamPageUrl,
                                                aria_label: game.awayTeam,
                                            },
                                            logo: {
                                                source: awayLogoUrl,
                                                altText: game.awayTeam ?? '',
                                                width: 0,
                                                height: 0,
                                            },
                                        },
                                        {
                                            name: homeTeamName ?? '',
                                            city: homeTeamCity ?? '',
                                            channel: homeChannelNumber?.toString(),
                                            isOnline: homeOnline,
                                            link: {
                                                href: homeTeamPageUrl,
                                                aria_label: game.homeTeam,
                                            },
                                            logo: {
                                                source: homeLogoUrl,
                                                altText: game.homeTeam ?? '',
                                                width: 0,
                                                height: 0,
                                            },
                                        },
                                    ]}
                                    eventDateTime={game.gameDate ? new Date(game.gameDate) : new Date()}
                                    cta={ctaBtn}
                                    theme="Carousel"
                                />
                            </div>
                        );

                        return {
                            content: sportsCardBlock,
                        };
                    } else if ('eventName' in game) {
                        const eventTeamData = leagues[game.homeLeagueAbbreviation ?? ''];
                        const eventTitle = eventTeamData?.title ?? '';
                        const eventLogoUrl = eventTeamData?.logo?.url ?? '';
                        const leaguePageUrl = eventTeamData?.leaguePageUrl ? eventTeamData?.leaguePageUrl : '#';

                        const ctaBtn = (
                            <Button
                                text={`${eventTitle} schedule`}
                                type="button"
                                theme="secondary"
                                targetBlank={false}
                                href={leaguePageUrl}
                            />
                        );

                        // determine which event channel number will be displayed
                        let eventChannelNumber;
                        let eventOnline = false;
                        if (game.nationalStream) {
                            eventChannelNumber = game.nationalStream;
                        } else if (game.nationalInternetStream) {
                            eventOnline = true;
                        }

                        const sportsCardBlock = (
                            <div key={`${league}-${index}`} className={styles.sportsCard}>
                                <Text tag="span" variant="eyebrowSm" className={cn(styles.eyebrow, styles.noTransform)}>
                                    {eventTitle}
                                </Text>
                                <SportsCard
                                    startTime={game.gameDate ? game.gameDate : ''}
                                    endTime={game.gameEndDate ? game.gameEndDate : ''}
                                    matchup={[
                                        {
                                            name: game.eventName ?? '',
                                            city: '',
                                            channel: eventChannelNumber?.toString(),
                                            isOnline: eventOnline,
                                            link: {},
                                            logo: {
                                                source: eventLogoUrl,
                                                altText: 'Race',
                                                width: 0,
                                                height: 0,
                                            },
                                        },
                                    ]}
                                    eventDateTime={game.gameDate ? new Date(game.gameDate) : new Date()}
                                    cta={ctaBtn}
                                    theme="Carousel"
                                />
                            </div>
                        );

                        return {
                            content: sportsCardBlock,
                        };
                    } else if ('raceOrDriverName' in game) {
                        const eventTeamData = leagues[game.seriesKey ?? ''];
                        const eventTitle = 'NASCAR';
                        const eventLogoUrl = eventTeamData?.logo?.url ?? '';
                        const leaguePageUrl = eventTeamData?.leaguePageUrl ? eventTeamData?.leaguePageUrl : '#';

                        const ctaBtn = (
                            <Button
                                text={`${eventTitle} schedule`}
                                type="button"
                                theme="secondary"
                                targetBlank={false}
                                href={leaguePageUrl}
                            />
                        );

                        // determine which race channel number will be displayed
                        let raceChannelNumber;
                        let raceOnline = false;
                        if (game.nationalStream) {
                            raceChannelNumber = game.nationalStream;
                        } else if (game.nationalInternetStream) {
                            raceOnline = true;
                        }

                        const sportsCardBlock = (
                            <div key={`${league}-${index}`} className={styles.sportsCard}>
                                <Text tag="span" variant="eyebrowSm" className={cn(styles.eyebrow, styles.noTransform)}>
                                    {league}
                                </Text>
                                <SportsCard
                                    startTime={game.startDateTime ?? ''}
                                    endTime={game.endDateTime ?? ''}
                                    matchup={[
                                        {
                                            name: game.raceOrDriverName ?? '',
                                            city: '',
                                            channel: raceChannelNumber?.toString(),
                                            isOnline: raceOnline,
                                            link: {},
                                            logo: {
                                                source: eventLogoUrl,
                                                altText: 'Race',
                                                width: 0,
                                                height: 0,
                                            },
                                        },
                                    ]}
                                    eventDateTime={game.startDateTime ? new Date(game.startDateTime) : new Date()}
                                    cta={ctaBtn}
                                    theme="Carousel"
                                />
                            </div>
                        );

                        return {
                            content: sportsCardBlock,
                        };
                    }
                })
                .filter((slide) => slide !== null) as Slide[];
        });
    };

    return (
        <>
            <Grid container>
                <Grid col={{ sm: 12, lg: 12 }}>
                    <div className={styles.upcomingSportsTitle}>{asset.fields.title}</div>
                    <Slider
                        slides={renderSportsCards(teamData)}
                        isThemeDark={false}
                        isArrowsTopAligned={false}
                        splideOptions={{ ...SportsSliderOptions, label: 'Team Sports Slider' }}
                        type="Sports"
                    />
                    <Slider
                        slides={renderSportsCards(nonTeamData)}
                        isThemeDark={false}
                        isArrowsTopAligned={false}
                        splideOptions={{ ...SportsSliderOptions, label: 'Non-Team Sports Slider' }}
                        type="Sports"
                    />
                </Grid>
            </Grid>
        </>
    );
}
