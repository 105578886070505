'use client';
import dynamic from 'next/dynamic';
import { HTMLAttributes, useEffect, useId, useRef, useState } from 'react';
import cn from 'classnames';
import FocusTrap from 'focus-trap-react';
import { TrialEligibilityStickyBox } from '@/components/atoms/Display';
import { TrialEligibilityStickyBoxOfferDetails } from '@/components/atoms/Display';
import { TrialEligibilityLinkWithArrow } from '@/components/atoms/TrialEligibilityLinkWithArrow';
import { useClickOutside } from '@/hooks';
import { useToggleWithButton } from '@/hooks/useToggleWithButton';
import styles from './trialEligibility.module.scss';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Rule = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Rule/Rule'));
const Tabs = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs'));
import { Variants } from 'de-sxm-reactlib/dist/components/atoms/Layout/Tabs/Tabs';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import TrialEligibilityModal from '@/components/molecules/TrialEligibilityModal/TrialEligibilityModal';
const TrialEligibilityBottomImageWithTextGroup = dynamic(
    () => import('@/components/molecules/TrialEligibilityBottomImageWithText/TrialEligibilityBottomImageWithTextGroup'),
);
const TrialEligibilityHero = dynamic(() => import('@/components/molecules/TrialEligibilityHero/TrialEligibilityHero'));
const TrialEligibilityAnimatedImageWithTextGroup = dynamic(
    () =>
        import(
            '@/components/molecules/TrialEligibilityAnimatedImageWithText/TrialEligibilityAnimatedImageWithTextGroup'
        ),
);

interface TrialEligibilityProps {
    offerDetails?: React.ReactNode;
}

interface WidgetAttribute extends HTMLAttributes<HTMLElement> {
    class: string;
}

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements extends HTMLElement {
            'sxm-rflz-widget-new': WidgetAttribute;
        }
    }
}

const BASE_URL = 'https://www.siriusxm.com/content/dam/sxm-com/sales-flows/trial-eligibility';

const heroSectionData = {
    backgroundColor: 'var(--black)',
    backgroundImageMobile: `${BASE_URL}/TrialEligibility-BG-Mobile.jpg`,
    backgroundImageTablet: `${BASE_URL}/TrialEligibility-BG-Desktop.jpg`,
    animatedImageBackDesktop: `${BASE_URL}/TrialEligibility-Talent-Blurred-desktop-v2.png`,
    animatedImageBackTablet: `${BASE_URL}/TrialEligibility-Talent-Blurred-mobile-v2.png`,
    animatedImageFrontDesktop: `${BASE_URL}/TrialEligibility-Talent-InFocus-Desktop.png`,
    animatedImageFrontTablet: `${BASE_URL}/TrialEligibility-Talent-InFocus-mobile-v2.png`,
    heading: 'Enjoy 3 months Free',
    subheadingBeforeButton: 'No credit card required. Eligibility and',
    subheadingAfterButton: 'apply.',
    subheadingButtonText: 'Offer Details',
};

const TabContentCar = {
    desktop: {
        source: `${BASE_URL}/BeyondtheCar_TV+Streaming_Desktop.png`,
    },
    mobile: {
        source: `${BASE_URL}/BeyondtheCar_TV+Streaming_Mobile.png`,
        altText:
            'SiriusXM is available on Roku, Xfinity, Google, Apple TV and Amazon Echo streaming devices — plus Sony, Samsung, Vizio and LG Smart televisions.',
    },
};

const TabContentApp = {
    desktop: {
        source: `${BASE_URL}/BeyondtheCar_Web+App_Desktop.png`,
    },
    mobile: {
        source: `${BASE_URL}/BeyondtheCar_Web+App_Mobile.png`,
        altText:
            'Available on the Apple App Store, Google Play Store and on accessible on popular web browsers including Safari, Chrome, Edge and Firefox.',
    },
};

const TabContentSpeaker = {
    desktop: {
        source: `${BASE_URL}/BeyondtheCar_SmartSpeakers_Desktop.png`,
    },
    mobile: {
        source: `${BASE_URL}/BeyondtheCar_SmartSpeakers_Mobile.png`,
        altText:
            'Available on select smart speakers from Bose, Sonos, Amazon Echo, Sony, Crestron, Autonomic, Control 4, Nuvo and Russound.',
    },
};

const TabsItems = [
    {
        title: 'Web & app',
        body: (
            <div className={styles.trialEligibilityTab}>
                <Grid container>
                    <Grid col={{ xs: 12 }}>
                        <ImageWrapper
                            src={TabContentApp.desktop.source}
                            alt={TabContentApp.mobile.altText}
                            mobile={TabContentApp.mobile.source}
                        />
                    </Grid>
                </Grid>
            </div>
        ),
    },
    {
        title: 'Smart speakers',
        body: (
            <div className={styles.trialEligibilityTab}>
                <Grid container standardMoleculeSpacing>
                    <Grid col={{ xs: 12 }}>
                        <ImageWrapper
                            src={TabContentSpeaker.desktop.source}
                            alt={TabContentSpeaker.mobile.altText}
                            mobile={TabContentSpeaker.mobile.source}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid col={{ xs: 12 }} className="text-center">
                        <TrialEligibilityLinkWithArrow
                            link={{
                                href: 'https://www.siriusxm.com/ways-to-listen',
                            }}
                        >
                            See if your specific device is compatible
                        </TrialEligibilityLinkWithArrow>
                    </Grid>
                </Grid>
            </div>
        ),
    },
    {
        title: 'In the car',
        body: (
            <div className={styles.trialEligibilityTab}>
                <Grid container standardMoleculeSpacing>
                    <Grid col={{ xs: 12 }}>
                        <ImageWrapper
                            src={TabContentCar.desktop.source}
                            alt={TabContentCar.mobile.altText}
                            mobile={TabContentCar.mobile.source}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid col={{ xs: 12 }} className="text-center">
                        <TrialEligibilityLinkWithArrow
                            link={{
                                href: 'https://www.siriusxm.com/ways-to-listen',
                            }}
                        >
                            See if your specific device is compatible
                        </TrialEligibilityLinkWithArrow>
                    </Grid>
                </Grid>
            </div>
        ),
    },
];

const AnimatedImageWithTextItems = [
    {
        backgroundGradient: 'linear-gradient(270deg, #402268 0.34%, #372D82 51.4%, #0F63FF 99.73%)',
        foregroundImage: {
            source: `${BASE_URL}/TrialEligibility-Tile-Comedy.png`,
        },
        title: 'The funniest comics on stage',
        subTitle: 'LOL stand-up in every style and taste from satire to observational and cringe to squeaky clean',
    },
    {
        backgroundGradient: 'linear-gradient(136deg, #D62E8A 0.74%, #235970 45.73%)',
        foregroundImage: {
            source: `${BASE_URL}/TrialEligibility-Tile-Channels.png`,
        },
        title: 'Expertly-curated music',
        subTitle: 'Ad-free channels by genre and era, plus custom stations inspired by artists and bands ',
    },
    {
        backgroundGradient: 'radial-gradient(74.89% 38.98% at 50.12% 54.37%, #0F63FF 0%, #000 100%)',
        foregroundImage: {
            source: `${BASE_URL}/TrialEligibility-Tile-Devices.png`,
        },
        title: 'Easy access',
        subTitle: 'Effortless listening on your phone and all your connected home and mobile devices',
    },
    {
        backgroundGradient: 'linear-gradient(0deg, #040404 20.52%, rgba(4, 4, 4, 0.00) 39.76%)',
        backgroundImage: `${BASE_URL}/TrialEligibility-Tile-CarDash.jpg`,
        title: 'The best audio on the road',
        subTitle: 'Your favorite channels, on your car radio, coast to coast',
    },
    {
        backgroundGradient: 'radial-gradient(82.37% 34.19% at 52.87% 40.42%, #FFF 0%, #D8DEE0 100%)',
        foregroundImage: {
            source: `${BASE_URL}/TrialEligibility-Tile-Celebrity.png`,
        },
        title: 'The celebs you love',
        subTitle: 'Original shows and insider moments with A-list personalities and stars',
        textColor: 'var(--black)',
    },
    {
        backgroundGradient: 'linear-gradient(61deg, #000 21.05%, rgba(0, 0, 0, 0.00) 58.15%)',
        backgroundImage: `${BASE_URL}/TrialEligibility-Tile-TalentLarge.jpg`,
        title: 'Talk about everything',
        subTitle: 'Tastemaker and expert takes on dating, fashion, films, finance, faith, and more',
    },
];

const BottomGroupItems = [
    {
        backgroundImage: `${BASE_URL}/TrialEligibility-FooterSatCTA.jpg`,
        heading: 'Get 3 months Free in your car and on the app.',
        subheadingBeforeButton: 'No credit card required. See',
        subheadingAfterButton: '.',
        subheadingButtonText: 'Offer Details',
    },
    {
        backgroundColor: 'var(--white)',
        foregroundImage: {
            tablet: {
                source: `${BASE_URL}/TrialEligibility-FooterAppCTA-Desktop.png`,
            },
            mobile: {
                source: `${BASE_URL}/TrialEligibility-FooterAppCTA-Mobile.png`,
            },
            stickToBottom: true,
        },
        textColor: 'var(--black)',
        highLightedHeadingColor: 'var(--deep-gray)',
        highLightedHeading: 'No Car?',
        heading: 'Everything is in the app too.',
        link: {
            href: 'https://care.siriusxm.com/subscribe/checkout/purchase/streaming/self-pay/organic?promoCode=TA3MOFREE',
            link_target: '_blank',
        },
        linkText: 'Get All Access (App Only)',
    },
];

const modalProps = {
    title: 'Enjoy 3 months Free',
    subheadingBeforeButton: 'No credit card required. Eligibility and',
    subheadingAfterButton: 'apply.',
    subheadingButtonText: 'Offer Details',
    widget: (
        <Grid container>
            <Grid col={{ xs: 12, md: 6, lg: 4 }} start={{ md: 4, lg: 5 }}>
                <sxm-rflz-widget-new class="widget-styles" />
            </Grid>
        </Grid>
    ),
};

export default function TrialEligibility({ offerDetails }: TrialEligibilityProps): JSX.Element {
    const modalId = useId();
    const [ToggleModalButton, modalIsVisible, setModalVisibility] = useToggleWithButton(false, modalId);
    const [showOfferDetails, setShowOfferDetails] = useState<boolean>(false);
    const [focusPause, setFocusPause] = useState(false);

    const handleShowOfferDetails = () => {
        setShowOfferDetails(true);
    };

    const handleHideOfferDetails = () => {
        setShowOfferDetails(false);
    };

    const headerRef = useRef<HTMLElement | null>(null);
    const mainRef = useRef<HTMLElement | null>(null);
    const targetRef = useRef<HTMLElement | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = [
        '#onetrust-consent-sdk', // One Trust cookie consent banner
        '.LPMcontainer', // Live Person chat invocation buttons
        '#lpChat', // Interactive Live Person chat window
    ].join(', ');

    useClickOutside(ref, {}, (state, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);
                if (closest) {
                    targetRef.current = target;
                }
                setFocusPause(!!closest);
            }
        }
    });

    useEffect(() => {
        if (focusPause && targetRef.current) {
            targetRef.current.click();
        }
    }, [focusPause]);

    const scrollToNextSection = (ref: React.RefObject<HTMLElement>) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const textValue: JSX.Element = <>{'Check eligibility'}</>;

    const BottomGroupItemsWithCTA = [
        {
            ...BottomGroupItems[0],
            ctaButton: <ToggleModalButton text={textValue} theme="primary" />,
        },
        ...BottomGroupItems.slice(1),
    ];

    return (
        <>
            <div
                className={cn(styles.wrapper, {
                    [styles.modalOpened]: modalIsVisible,
                })}
            >
                <header ref={headerRef} data-componenttype="TrialEligibility-Hero">
                    <TrialEligibilityHero
                        {...heroSectionData}
                        ctaButton={<ToggleModalButton text={textValue} theme="secondary" />}
                        onButtonClickHandler={handleShowOfferDetails}
                        isExitAnimated={modalIsVisible}
                        onArrowButtonClickHandler={() => scrollToNextSection(mainRef)}
                    />
                </header>
                <main ref={mainRef}>
                    <TrialEligibilityStickyBox
                        stickyBoxProps={{
                            position: 'top',
                            initialTopOffset: 0,
                            triggerRef: headerRef,
                            alwaysVisibleInMobile: false,
                            triggerThreshold: 0.01,
                        }}
                        ctaButton={<ToggleModalButton text={textValue} theme="secondary" buttonSize="short" />}
                        onButtonClickHandler={handleShowOfferDetails}
                    />
                    <section>
                        <Grid container standardMoleculeSpacing>
                            <Grid col={{ xs: 12 }}>
                                <Text tag="h2" variant="h1" className="text-center">
                                    Jam, laugh, and learn
                                </Text>
                            </Grid>
                        </Grid>

                        <TrialEligibilityAnimatedImageWithTextGroup items={AnimatedImageWithTextItems} />
                    </section>
                    <section>
                        <Grid container standardMoleculeSpacing>
                            <Grid col={{ xs: 12 }}>
                                <Text tag="h2" variant="h1" className="text-center">
                                    Listen beyond the car
                                </Text>
                            </Grid>
                        </Grid>
                        <Tabs variant={Variants.trialEligibility} fullWidth items={TabsItems} />
                    </section>
                    <section className="background-offwhite" data-componenttype="TrialEligibility-LowerBody">
                        <TrialEligibilityBottomImageWithTextGroup
                            items={BottomGroupItemsWithCTA}
                            onTextButtonClickHandler={handleShowOfferDetails}
                        />
                    </section>
                    <div className="background-offwhite">
                        <Grid container>
                            <Grid col={{ xs: 12 }}>
                                <Rule />
                            </Grid>
                        </Grid>
                    </div>
                </main>
            </div>

            <FocusTrap active={modalIsVisible} paused={focusPause}>
                <TrialEligibilityModal
                    ref={ref}
                    offerDetails={offerDetails}
                    open={modalIsVisible}
                    onClose={() => setModalVisibility(false)}
                    id={modalId}
                    {...modalProps}
                />
            </FocusTrap>

            <TrialEligibilityStickyBoxOfferDetails
                stickyBoxProps={{ position: 'bottom', alwaysVisibleInMobile: true }}
                visible={showOfferDetails}
                onClose={handleHideOfferDetails}
                closeButtonAriaLabel="Close Offer Details"
            >
                {offerDetails}
            </TrialEligibilityStickyBoxOfferDetails>
        </>
    );
}
