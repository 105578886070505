'use client';
import { ReactNode, CSSProperties } from 'react';
import Slider from '@/components/molecules/Slider/Slider';
import { useMediaQuery } from '@/hooks';
import { EntityTileGridSliderOptions, responsiveBreakpointsPx } from '@/constants';
import styles from './entityTileGrid.module.scss';

const { noSlideWrapper, tileWrapper, promoItemStyle, tileStyles, promoItemSlideStyle } = styles;

interface SlideItem {
    content: ReactNode;
}

interface EntityTileGridProps {
    slides: SlideItem[];
    sliderAriaLabel?: string;
    entityPromoItemIndex?: number;
}

export default function EntityTileGrid({ slides, entityPromoItemIndex }: EntityTileGridProps) {
    const viewportIsMd = useMediaQuery(`(min-width: ${responsiveBreakpointsPx.md}px)`);
    const isEntityPromoItem = entityPromoItemIndex !== undefined;

    if (isEntityPromoItem && viewportIsMd) {
        const style = {
            '--grid-fraction-layout': entityPromoItemIndex === 0 ? '2fr 1fr' : '1fr 2fr',
        } as CSSProperties;

        return (
            <div className={noSlideWrapper} style={style}>
                {slides.map((slide, index) => {
                    return (
                        <div key={index} className={entityPromoItemIndex === index ? promoItemStyle : tileWrapper}>
                            {slide.content}
                        </div>
                    );
                })}
            </div>
        );
    }

    if (isEntityPromoItem && !viewportIsMd) {
        const entityPromoItemSlides = slides.map((slide, index) => {
            return {
                content: (
                    <div key={index} className={entityPromoItemIndex !== index ? tileStyles : promoItemSlideStyle}>
                        {slide.content}
                    </div>
                ),
            };
        });

        const sliderOptions = {
            rewind: false,
            gap: '16px',
            pagination: false,
            arrows: false,
            drag: true,
            autoWidth: true,
            lazyLoad: true,
            padding: {
                left: '16px',
                right: '16px',
            },
        };

        return <Slider slides={entityPromoItemSlides} splideOptions={sliderOptions} type="Default" />;
    }

    return <Slider slides={slides} splideOptions={EntityTileGridSliderOptions} type="EntityTileGrid" />;
}
