const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
import dynamic from 'next/dynamic';
const StyledContainer = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/Layout/StyledContainer/StyledContainer'),
);
import { HTMLAttributes } from 'react';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements extends HTMLElement {
            'sxm-zag-widget': HTMLAttributes<HTMLElement>;
        }
    }
}

export default function ZagWidget(): JSX.Element {
    return (
        <StyledContainer includeNoHeroPadding paddingBottom={64}>
            <Grid container>
                <Grid col={{ sm: 12, lg: 8 }} start={{ lg: 3 }} className="widget-styles">
                    <sxm-zag-widget></sxm-zag-widget>
                </Grid>
            </Grid>
        </StyledContainer>
    );
}
