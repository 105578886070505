import styles from './staticChannelCardStrip.module.scss';
import IconAudioPlayButtonFilledShadow from '@/assets/icons/icon-audio-play-button-filled-shadow.svg';
import { Link } from '@/components/atoms/Link';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export interface StaticChannelCardStripProps {
    radioImageUrl: string;
    radioImageAlt?: string;
    listenLink: string;
    listenLinkAriaLabel?: string;
    borderColor: string;
    bannerText?: string;
}

export default function StaticChannelCardStrip({
    listenLink,
    listenLinkAriaLabel = 'listen link',
    borderColor,
    radioImageUrl,
    radioImageAlt = 'channel image',
    bannerText,
}: StaticChannelCardStripProps) {
    const style: React.CSSProperties = {
        ...({ '--border-color': borderColor } as React.CSSProperties),
    };

    const BannerHtml = <div className={styles.bannerContainer}>{bannerText ? bannerText : ''}</div>;

    return (
        <Link
            style={style}
            href={listenLink}
            aria-label={listenLinkAriaLabel}
            className={styles.contentContainer}
            data-player-link={''}
        >
            <div className={styles.wrapper}>
                <div className={styles.imageContainer}>
                    <div className={styles.radioImage}>
                        <ImageWrapper
                            optimizedWidth={120}
                            optimizedHeight={60}
                            src={radioImageUrl}
                            alt={radioImageAlt}
                        />
                    </div>
                </div>

                <div className={styles.colorBorder}>
                    <div className={styles.listenLiveText}>listen live</div>
                    <IconAudioPlayButtonFilledShadow className={styles.playIcon} />
                    <div className={styles.borderBackground} style={style} />
                </div>
            </div>
            {bannerText && BannerHtml}
        </Link>
    );
}
