const LinkWrapperIfHref = dynamic(
    () => import('de-sxm-reactlib/dist/components/atoms/LinkWrapperIfHref/LinkWrapperIfHref'),
);
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { ReactNode } from 'react';
import { MediaWithTextImageProps } from './interfaces';
import styles from './mediawithtext.module.scss';
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';

export function MediaWithTextImage(props: MediaWithTextImageProps): JSX.Element {
    const linkClasses = cn({
        [styles.hoverLink]: !!props.link?.href,
    });

    const imageClasses = cn(styles.imageStyles, {
        [styles.imageIcon]: props.imageSize === 'Icon',
        [styles.logoImage]: props.imageIsLogo,
    });

    const link = {
        ...props.link,
        target: props.link?.link_target,
    };

    return (
        <LinkWrapperIfHref
            link={link}
            className={linkClasses}
            downloadable={props.downloadable}
            dataCy="molecules-media-with-text-image-link"
        >
            <WrapperIfLogo imageIsLogo={!!props.imageIsLogo} mediaBackgroundColor={props.mediaBackgroundColor}>
                <div className={styles.contentWrapper}>
                    <ImageWrapper
                        // optimizedWidth={600}
                        // optimizedHeight={400}
                        src={props.image?.source}
                        alt={props.image?.altText ?? ''}
                        className={imageClasses}
                    />

                    {props.imageOverlay?.source && (
                        <ImageWrapper
                            // optimizedWidth={250}
                            // optimizedHeight={250}
                            src={props.imageOverlay.source}
                            alt={props.imageOverlay.altText}
                            className={styles.imageOverlay}
                        />
                    )}
                </div>
            </WrapperIfLogo>

            {!!props.link?.href && <div className={styles.hoverForeground}></div>}
        </LinkWrapperIfHref>
    );
}

function WrapperIfLogo(props: {
    children: ReactNode;
    imageIsLogo: boolean;
    mediaBackgroundColor?: string;
}): JSX.Element {
    return props.imageIsLogo ? (
        <div className={styles.media}>
            <div className={styles.mediaBackgroundColor} style={{ backgroundColor: props.mediaBackgroundColor }} />
            <div className={styles.imageContainer}>{props.children}</div>
        </div>
    ) : (
        <>{props.children}</>
    );
}
