import { getYMMCookies } from '@/packages/sxm/ymm';
import { useState } from 'react';

export default function useYmm() {
    const [cookies, setCookies] = useState(getYMMCookies());
    const newCookies = getYMMCookies();

    if (JSON.stringify(cookies) !== JSON.stringify(newCookies)) {
        setCookies(newCookies);
    }

    return cookies;
}
