'use client';
import { useState, useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { getCookie, setCookie } from 'cookies-next';
import dayjs from 'dayjs';
import FocusTrap from 'focus-trap-react';
import DisplayModal from 'de-sxm-reactlib/dist/components/atoms/Display/Modal/Modal';
import Link from '@/components/atoms/Link/Link';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Select = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Select'));
const Option = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/Option'));
const OptionGroup = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Inputs/Select/OptionGroup'));
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
import { getLast120Years, hasRequiredAge } from './utils';
import { useClickOutside } from '@/hooks';
import styles from './ageModal.module.scss';

const { title, selectStyles, selectInputWrapper, buttonStyles, linksWrapper, linkStyles } = styles;

const FormData = {
    title: 'Welcome! Please enter your birth date to verify you are eligible for this sweepstakes.',
    errorText: "We're sorry. You are not eligible to enter this sweepstakes.",
    months: [
        'January - 01',
        'February - 02',
        'March - 03',
        'April - 04',
        'May - 05',
        'June - 06',
        'July - 07',
        'August - 08',
        'September - 09',
        'October - 10',
        'November - 11',
        'December - 12',
    ],
    years: getLast120Years(),
};

const MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR = '#onetrust-consent-sdk';

interface AgeModalProps {
    age: number;
}

export function AgeModal({ age }: AgeModalProps) {
    const ref = useRef<HTMLDivElement>(null);
    const [openModal, setOpenModal] = useState(true);
    const [ymmValid, setYmmValid] = useState('untouched');
    const [disabledButton, setDisableButton] = useState(true);
    const [focusPause, setFocusPause] = useState(false);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');

    function monthHandler(element: HTMLInputElement | undefined, params: { value: string }) {
        if (params.value) {
            setMonth(params.value);
        }
    }

    function yearHandler(element: HTMLInputElement | undefined, params: { value: string }) {
        if (params.value) {
            setYear(params.value);
        }
    }

    function handleCookie() {
        const domain = process.env.NODE_ENV === 'development' ? 'localhost' : '.siriusxm.com';
        const expires = dayjs().add(1, 'day').toDate();

        const cookie = getCookie('age_restriction');
        if (cookie) {
            return;
        } else {
            setCookie('age_restriction', 'invalid', {
                path: '/',
                expires,
                domain,
            });
        }
    }

    useEffect(() => {
        if (year !== '' && month !== '') {
            setDisableButton(false);
        }
    }, [year, month]);

    useEffect(() => {
        const ageCookie = getCookie('age_restriction');
        if (ageCookie && ageCookie === 'invalid') {
            setYmmValid('invalid');
        }
    }, []);

    useEffect(() => {
        document.dispatchEvent(new CustomEvent(`ageblock${ymmValid}`));
    }, [ymmValid]);

    const handleAgeVerification = () => {
        if (year !== '' && month !== '') {
            if (hasRequiredAge(`${year}-${month}`, age)) {
                setYmmValid('valid');
                setOpenModal(false);
            } else {
                setYmmValid('invalid');
                handleCookie();
            }
        }
    };

    useClickOutside(ref, {}, (state, event) => {
        if (event) {
            if (event.target) {
                const target = event.target as HTMLElement;
                const closest = target.closest(MODAL_CLICK_THROUGH_EXCEPTIONS_SELECTOR);

                setFocusPause(!!closest);
            }
        }
    });

    const invalidAge = ymmValid === 'invalid';

    return (
        <FocusTrap active={openModal} paused={focusPause}>
            <DisplayModal title={invalidAge ? 'Ineligible' : 'Verification'} open={openModal} ref={ref}>
                <div data-agerestriction />
                <Text tag="h4" variant="h4" className={title}>
                    {invalidAge ? FormData.errorText : FormData.title}
                </Text>
                {!invalidAge && (
                    <div className={selectInputWrapper}>
                        <div className={selectStyles}>
                            <Select label="Month" type="form" name="month" theme="outline" onChange={monthHandler}>
                                <OptionGroup>
                                    {FormData.months.map((month) => (
                                        <Option key={month} value={month}>
                                            {month}
                                        </Option>
                                    ))}
                                </OptionGroup>
                            </Select>
                        </div>
                        <div className={selectStyles}>
                            <Select label="Year" type="form" name="year" theme="outline" onChange={yearHandler}>
                                <OptionGroup>
                                    {FormData.years.map((year) => (
                                        <Option key={year} value={`${year}`}>{`${year}`}</Option>
                                    ))}
                                </OptionGroup>
                            </Select>
                        </div>
                    </div>
                )}
                {!invalidAge && (
                    <Button
                        className={buttonStyles}
                        theme="primary"
                        type="button"
                        text="Continue"
                        buttonSize="full-width"
                        disabled={disabledButton}
                        onClick={handleAgeVerification}
                    />
                )}
                <div className={linksWrapper}>
                    <Link href="https://www.siriusxm.com/privacy-policy" className={linkStyles}>
                        Privacy Policy
                    </Link>
                </div>
            </DisplayModal>
        </FocusTrap>
    );
}
