'use client';

import { Block, Inline } from '@contentful/rich-text-types';
import { useEffect, useState } from 'react';
import RenderAsset from '@/components-2.0/structure/RenderAsset';
import Context from '@/services/contentful/pageContext';

import Link from '@/services/contentful/contentTypes/Link';
import Media from '@/services/contentful/contentTypes/Media';

interface Props {
    node: Block | Inline;
}

const validEmbedEntry = ['Link', 'Media'];
type ValidEmbedTypes = (typeof validEmbedEntry)[number];

export default function InlineEmbed({ node }: Props) {
    const [component, setComponent] = useState(<></>);

    const asset = node.data.target;
    const type: ValidEmbedTypes = asset.sys.contentType?.sys.id;

    useEffect(() => {
        async function parse() {
            let parsed: SiteAsset = {
                cms_id: '',
                cms_type: '',
                fields: {},
            };

            if (type === 'Link') {
                parsed = await Link(asset, {} as Context);
            } else if (type === 'Media') {
                parsed = await Media(asset);
            }

            if (parsed && validEmbedEntry.includes(parsed.cms_type)) {
                setComponent(<RenderAsset asset={parsed} inline={true} />);
            }
        }

        parse();
    }, [asset, type]);

    return component;
}
