import { ReadonlyURLSearchParams } from "next/navigation";

/**
 * Copies all query params to new URL
 */
export const buildAppLinkUrlAllParams = (
    baseUrl: string,
    searchParams: ReadonlyURLSearchParams | null
): string => {
    if (!searchParams) return baseUrl;
    
    const url = new URL(baseUrl);
    
    // Copy all params
    searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
    });
    
    return url.toString();
};

export function getPulseAnalyticsAttributes(analytics_tags: AnalyticsTags[]) : object {
    return analytics_tags.reduce((obj: { [key: string]: string }, tag: AnalyticsTags) => {
        obj[`data-${tag.key}`] = tag.value;
        return obj;
    }, {});
};

export function getPulseAnalyticsAttributesArray(sections: SiteAsset[]) : object[] {
    return sections.map((section: SiteAsset) => {
        const sectionAnalyticsAttributes: AnalyticsTags[] = section.fields.moreLink.fields.analytics_tags as AnalyticsTags[];
        return getPulseAnalyticsAttributes(sectionAnalyticsAttributes);
    });
}