'use client';

import dynamic from 'next/dynamic';
import { type SocialMediaIconLinkItem } from 'de-sxm-reactlib-2/dist/components/molecules/SocialMediaIconLinks';
import type { FooterItem, AppLink } from 'de-sxm-reactlib-2/dist/components/organisms/Footer';
import type { SecondaryLink } from 'de-sxm-reactlib-2/dist/components/molecules/CopyrightLegal';
import styles from './navigationFooter.module.scss';
import FullFooter from 'de-sxm-reactlib-2/dist/components/organisms/Footer/FullFooter';
import CopyrightLegal from 'de-sxm-reactlib-2/dist/components/molecules/CopyrightLegal/CopyrightLegal';
import SimpleFooter from 'de-sxm-reactlib-2/dist/components/organisms/Footer/SimpleFooter';

const Grid = dynamic(() => import('de-sxm-reactlib-2/dist/components/atoms/Layout/Grid/Grid'));

interface Props {
    type: 'Full' | 'Simple' | 'Basic';
    primaryLinks: FooterItem[];
    socialLinks: SocialMediaIconLinkItem[];
    secondaryLinks: SecondaryLink[];
    copyrightText: React.ReactNode;
    playStore: AppLink;
    appleStore: AppLink;
}

export default function ClientNavFooter({
    type,
    primaryLinks,
    socialLinks,
    secondaryLinks,
    copyrightText,
    playStore,
    appleStore,
}: Props) {
    function renderFooter() {
        if (type === 'Full') {
            return (
                <>
                    <FullFooter
                        primaryLinks={primaryLinks}
                        appleStore={appleStore}
                        playStore={playStore}
                        socialMediaItems={socialLinks}
                    />
                    <CopyrightLegal secondaryLinks={secondaryLinks} copyrightText={<>{copyrightText}</>} />
                </>
            );
        } else if (type === 'Simple') {
            return (
                <>
                    <SimpleFooter
                        primaryLinks={primaryLinks}
                        appleStore={appleStore}
                        playStore={playStore}
                        socialMediaItems={socialLinks}
                    />
                    <CopyrightLegal secondaryLinks={secondaryLinks} copyrightText={<>{copyrightText}</>} />
                </>
            );
        } else if (type === 'Basic') {
            return <CopyrightLegal secondaryLinks={secondaryLinks} copyrightText={<>{copyrightText}</>} />;
        }
    }

    return (
        <Grid container className={styles.footerGrid}>
            <Grid col={{ sm: 12 }}>
                <footer data-swiftype-index="false">{renderFooter()}</footer>
            </Grid>
        </Grid>
    );
}
