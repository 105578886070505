'use client';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import dayjs from 'dayjs';
import styles from './schedule.module.scss';
import { toLocalTimezone } from '@/packages/dateTime';
import { SafeHtml } from '@/components/atoms/Display';
const Grid = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid'));
const AudioIcon = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/AnimatedIcons/AudioIcon/AudioIcon'));

interface ScheduleItemProps {
    scheduleDate?: Date;
    title?: string;
    description?: string;
    children?: React.ReactNode | React.ReactNode[];
    isNowPlaying?: boolean;
}

export default function Schedule(props: ScheduleItemProps): JSX.Element {
    const localDate = props.scheduleDate && dayjs(toLocalTimezone(props.scheduleDate));

    const timeString = localDate?.minute() === 0 ? localDate.format('h a') : localDate?.format('h:mm a');

    const nowPlayingClass = cn(styles.scheduleNowPlaying, {
        [styles.active]: props.isNowPlaying,
    });

    return (
        <>
            <div className={styles.scheduleLineup}>
                <Grid container>
                    <Grid col={{ xs: 4, md: 1 }}>
                        <span className={styles.scheduleTime}>{timeString}</span>
                    </Grid>
                    <Grid col={{ xs: 8, md: 11 }}>
                        <div className={nowPlayingClass}>
                            <span className={styles.nowPlayingSpan}>
                                <p>Now Playing</p>
                            </span>
                            <AudioIcon></AudioIcon>
                        </div>
                        <div>
                            <span className={styles.scheduleItemTitle}>{props.title}</span>
                            <SafeHtml tag="span" className={styles.scheduleItemDesc} print={props.description} />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
