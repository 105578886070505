// eslint-disable-next-line
export default function debounce<F extends Function>(func: F, wait: number): (this: any, ...args: any[]) => void {
    let timeoutID: number;

    // eslint-disable-next-line
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutID);
        // eslint-disable-next-line
        const context = this;

        timeoutID = window.setTimeout(function () {
            func.apply(context, args);
        }, wait);
    };
}
