import styles from './staticChannelCard.module.scss';
import dynamic from 'next/dynamic';
import { Link } from '@/components/atoms/Link';
import IconAudioPlayButtonFilledShadow from '@/assets/icons/icon-audio-play-button-filled-shadow.svg';
const Text = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Display/Text/Text'));
const Album = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Media/AudioPlayer/Album/Album'));
import ImageWrapper from '@/shared-components/ImageWrapper/ImageWrapper';
import ImageOptimization from '@/services/image_optimization';

interface StaticChannelCardProps {
    listenLink: string;
    listenLinkAriaLabel?: string;
    title: string;
    subTitle: string;
    borderColor: string;
    radioImageUrl: string;
    radioImageAlt?: string;
    albumImageSrc: string;
    albumImageAlt?: string;
}

export default function StaticChannelCard({
    listenLink,
    listenLinkAriaLabel = 'click to listen to this channel',
    title,
    subTitle,
    borderColor,
    radioImageUrl,
    radioImageAlt = 'Radio Channel Logo',
    albumImageSrc,
    albumImageAlt = 'Album Image',
}: StaticChannelCardProps) {
    albumImageSrc = ImageOptimization({ src: albumImageSrc, width: 184, height: 184 });
    const style: React.CSSProperties = {
        ...({ '--border-color': borderColor } as React.CSSProperties),
        ...{
            '--background-image': !albumImageSrc ? "url('/resources/images/DefaultAlbumArt-FadeOverlay.png')" : null,
        },
    };

    return (
        <div style={style} className={styles.channelCard}>
            <Link href={listenLink} aria-label={listenLinkAriaLabel} data-player-link={''}>
                <div className={styles.staticDisplay}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.radioInfoContainer}>
                            <div className={styles.radioImageLink}>
                                <ImageWrapper
                                    optimizedHeight={80}
                                    optimizedWidth={165}
                                    className={styles.radioImage}
                                    src={radioImageUrl}
                                    alt={radioImageAlt}
                                />
                            </div>

                            <div className={styles.radioTextInfo}>
                                <Text tag="p">{title}</Text>
                                <Text tag="p">{subTitle}</Text>
                            </div>
                        </div>

                        <div className={styles.albumContainerDesktop}>
                            <div className={styles.button}>
                                <div
                                    className={styles.albumWrapper}
                                    style={!albumImageSrc ? { backgroundColor: borderColor } : {}}
                                    aria-hidden="true"
                                >
                                    <Album
                                        showInfoOnHover={false}
                                        imageSrc={albumImageSrc}
                                        imageClassName={styles.albumImage}
                                        imageHeight="184"
                                        imageWidth="184"
                                        alt={albumImageAlt}
                                        artist={''}
                                        songTitle={''}
                                    />
                                </div>
                            </div>

                            <div className={styles.colorBorder}>
                                <div style={{ backgroundColor: borderColor }} className={styles.borderBackground}></div>
                                <div className={styles.listenLiveText}>listen live</div>
                                <IconAudioPlayButtonFilledShadow className={styles.playIcon} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.shadow}>
                        <div className={styles.desktopColorBorder} style={{ backgroundColor: borderColor }}></div>
                    </div>
                </div>
            </Link>
        </div>
    );
}
