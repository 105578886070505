'use client';
import { useEffect, useRef } from 'react';
import Grid from 'de-sxm-reactlib/dist/components/atoms/Layout/Grid/Grid';
import NoGames from '@/components/contentful/ContextualComponent/Sports/NoGames';
import { RenderRTE } from '@/components/RenderRTE';
import { Link } from '@/components/atoms/Link';
import sportsStyles from '@/components/contentful/ContextualComponent/Sports/sportschedule.module.scss';
import { Document } from '@contentful/rich-text-types';

interface Props {
    logo?: AEMImage;
    off_season_message_title: string;
    off_season_message_description?: Document;
    reminder_url: string;
    asW2?: boolean;
}
export default function ContextualComponent({
    logo,
    off_season_message_title,
    off_season_message_description,
    reminder_url,
    asW2,
}: Props): JSX.Element {
    const calReplyRef = useRef<HTMLElement>(null);
    useEffect(() => {
        const browserWindow = window as BrowserWindow;
        if (calReplyRef.current && browserWindow.__CalReplyWidgetLoader) {
            browserWindow.__CalReplyWidgetLoader.loadCustomButton(calReplyRef.current);
        }
    }, [calReplyRef]);

    return (
        <Grid container>
            <Grid col={{ sm: 12, lg: 10 }} start={{ lg: 2 }}>
                <NoGames logo={logo} title={off_season_message_title || ''}>
                    <RenderRTE contentfulDoc={off_season_message_description} asW2={asW2} />
                    {reminder_url && (
                        <Link
                            ref={calReplyRef}
                            className={sportsStyles.noGamesAnchor}
                            href={reminder_url}
                            dataCy="contextualcomponent-sports-remindme-link"
                        >
                            REMIND ME
                        </Link>
                    )}
                </NoGames>
            </Grid>
        </Grid>
    );
}
