import IconCheckmarkLite from '@/assets/icons/icon-checkmark-lite.svg';
import dynamic from 'next/dynamic';
import { Link } from '@/components/atoms/Link';
import cn from 'classnames';
import styles from '../comparisongrid.module.scss';
import { DataRow, PlanDetails, PlansData } from '../data/interfaces';
const Button = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Button/Button'));
const Tooltip = dynamic(() => import('de-sxm-reactlib/dist/components/atoms/Tooltip/Tooltip'));

const FEES_TEXT: React.ReactNode = (
    <>
        Fees, taxes and <Link href="#pageOfferDetails">Offer Details</Link> apply.
    </>
);
const BlankCell = ({ showIcon }: { showIcon: boolean }): JSX.Element => {
    return showIcon ? (
        <div className={styles.dash}>
            <span className="show-for-sr">Not included</span>
        </div>
    ) : (
        <span className="show-for-sr">Not included</span>
    );
};
export function PlanGrid({
    plansData,
    id,
    ariaLabelledby,
    className,
    activeTab,
    showEmptyCellIcon = false,
    hideHeader = false,
    alignment = 'center',
    variant = 'normal',
}: {
    plansData: PlansData;
    id: string;
    ariaLabelledby: string;
    className?: string;
    activeTab: number;
    showEmptyCellIcon?: boolean;
    hideHeader?: boolean;
    alignment?: 'left' | 'center';
    variant?: 'normal' | 'spacious';
}) {
    // Temp markup to be updated soon
    const tableClasses = cn(styles.table, className, {
        [styles.leftAlign]: alignment === 'left',
        [styles.spaciousVariant]: variant === 'spacious',
    });
    const tableHeadClasses = cn({
        'show-for-sr': !!hideHeader,
    });

    return (
        <div role="tabpanel">
            <table className={tableClasses} id={id} aria-labelledby={ariaLabelledby}>
                <thead className={tableHeadClasses}>
                    <tr className={styles.topRowMobile}>
                        <th>
                            <p>{FEES_TEXT}</p>
                        </th>
                    </tr>
                    <tr className={styles.rowHead}>
                        {plansData.headers.map((header) => (
                            <th key={header} className={plansData.best[header] ? styles.bestPlanCol : ''}>
                                {plansData.best[header] && <div className={styles.bestPlan}>Best Plan</div>}
                                <div className={styles.planHeaderWrapper}>
                                    <span className={styles.showDesktop}>{header}</span>
                                    {plansData.subHeaders?.[header] && (
                                        <span className={styles.showDesktop}>{plansData.subHeaders[header]}</span>
                                    )}
                                </div>
                                <div className={styles.planHeaderWrapper}>
                                    <span className={styles.showMobile}>{plansData.shortHeaders[header]}</span>
                                    {plansData.subHeaders?.[header] && (
                                        <span className={styles.showMobile}>{plansData.subHeaders[header]}</span>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {plansData.rows.flatMap((row: DataRow, i: number) => {
                        const currentRow = (
                            <tr
                                key={i}
                                className={
                                    row.name === 'Price'
                                        ? styles.rowPrice
                                        : row.name === 'Total Channels'
                                          ? styles.rowChannels
                                          : ''
                                }
                            >
                                {row.name === 'Price' ? (
                                    <th>
                                        <p className={styles.priceHeader}>Cancel anytime.</p>
                                        <p>{FEES_TEXT}</p>
                                    </th>
                                ) : (
                                    <>
                                        <th colSpan={3} rowSpan={1} className={styles.mobileTh}>
                                            <div>
                                                {row.name}
                                                {typeof row.footnoteReferenceNumber === 'number' && (
                                                    <sup>{row.footnoteReferenceNumber}</sup>
                                                )}
                                                {row.tooltip && (
                                                    <Tooltip
                                                        className={styles.tooltip}
                                                        {...row.tooltip}
                                                        contentClassName={styles.tooltipContent}
                                                        updateKey={activeTab}
                                                    />
                                                )}
                                            </div>
                                        </th>
                                        <th scope="row" className={styles.desktopTh}>
                                            <div>
                                                {row.name}
                                                {typeof row.footnoteReferenceNumber === 'number' && (
                                                    <sup>{row.footnoteReferenceNumber}</sup>
                                                )}
                                                {row.tooltip && (
                                                    <Tooltip
                                                        className={styles.tooltip}
                                                        {...row.tooltip}
                                                        contentClassName={styles.tooltipContent}
                                                        updateKey={activeTab}
                                                    />
                                                )}
                                            </div>
                                        </th>
                                    </>
                                )}
                                {plansData.headers.slice(1).map((planName, j) => (
                                    <td key={j} className={plansData.best[planName] ? styles.bestPlanCol : ''}>
                                        {row.name === 'Price' ? (
                                            <>
                                                <Button
                                                    className={styles.ctaButton}
                                                    href={plansData.purchaseHrefs[planName]}
                                                    type="button"
                                                    theme="primary"
                                                    text="Get"
                                                    buttonSize="narrow"
                                                    ariaLabel={`Get ${planName}`}
                                                    data={{
                                                        'data-tracklinktext': plansData.trackLinkTexts[planName],
                                                        'data-packagecode': plansData.packageCodes[planName],
                                                    }}
                                                    dataCy="choose-plan-get-button"
                                                />
                                                <p className={styles.colPrice}>
                                                    {row.plans[planName as keyof PlanDetails]}
                                                </p>
                                            </>
                                        ) : row.name === 'Total Channels' ? (
                                            <p>{row.plans[planName as keyof PlanDetails]}</p>
                                        ) : row.plans[planName as keyof PlanDetails] === 'yes' ? (
                                            <>
                                                <IconCheckmarkLite aria-hidden="true" />
                                                <span className="show-for-sr">Included</span>
                                            </>
                                        ) : (
                                            row.plans[planName as keyof PlanDetails] || (
                                                <BlankCell showIcon={!!showEmptyCellIcon} />
                                            )
                                        )}
                                    </td>
                                ))}
                            </tr>
                        );
                        if (row.name === 'Price') {
                            const customRow = (
                                <tr key={`custom-${i}`} className={styles.topRowMobile}>
                                    <th>
                                        <p>{FEES_TEXT}</p>
                                    </th>
                                </tr>
                            );
                            return [currentRow, customRow];
                        }
                        return currentRow;
                    })}
                </tbody>
            </table>
        </div>
    );
}
