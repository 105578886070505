import { useState, useEffect } from 'react';
import { Fields } from './useValidator/interfaces';

const useErrorCount = (fields: Fields): number => {
    const [errorCount, setErrorCount] = useState(0);

    useEffect(() => {
        const errors = Object.entries(fields).reduce((acc, [key, fieldState]) => {
            // Skip the "Form" field
            return key === 'form' ? acc : acc + (fieldState.invalid === 'invalid' ? 1 : 0);
        }, 0);

        setErrorCount(errors);
    }, [fields]);

    return errorCount;
};

export default useErrorCount;
