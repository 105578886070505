/* eslint-disable @typescript-eslint/no-explicit-any */

interface JSON {
    [index: string]: any;
}

function objectToQuery(key1: string, obj: JSON) {
    const query: string[] = [];
    Object.keys(obj).forEach((key2) => {
        const value = obj[key2];
        const key = `${key1}[${key2}]`;

        if (Array.isArray(value)) {
            query.push(arrayToQuery(key, value));
        } else if (typeof value === 'object') {
            query.push(objectToQuery(key, value));
        } else {
            query.push(valueToQuery(key, value));
        }
    });

    return query.join('&');
}

function arrayToQuery(key: string, arr: any[]) {
    const query: string[] = [];
    key = `${key}[]`;
    arr.forEach((value) => {
        if (Array.isArray(value)) {
            query.push(arrayToQuery(key, value));
        } else if (typeof value === 'object') {
            query.push(objectToQuery(key, value));
        } else {
            query.push(valueToQuery(key, value));
        }
    });
    return query.join('&');
}

function valueToQuery(key: string, value: any): string {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}

export function jsonToQuery(json: JSON): string {
    const query: string[] = [];
    Object.keys(json).forEach((key) => {
        const value = json[key];

        if (Array.isArray(value)) {
            query.push(arrayToQuery(key, value));
        } else if (typeof value === 'object') {
            query.push(objectToQuery(key, value));
        } else {
            query.push(valueToQuery(key, value));
        }
    });

    return query.join('&');
}
/* eslint-enable @typescript-eslint/no-explicit-any */
